import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-addsuspendedcomments',
  templateUrl: './addsuspendedcomments.component.html',
  styleUrls: ['./addsuspendedcomments.component.scss']
})
export class AddsuspendedcommentsComponent implements OnInit {
  suspendedform: FormGroup;
  loadspinner: any= false;
  commentLists: any = [];
  comment:any;
  loginName:any = '';
  suspendedId:any= '';
  constructor(public dialog: MatDialogRef<UserComponent>, @Inject(MAT_DIALOG_DATA) public data: string,private formBuilder: FormBuilder,private apiService: ApiServiceService) {
    this.loginName=data['login'];
    this.suspendedform = this.formBuilder.group({
      comment: [''],
      additionalcomment:['']
    });
  }

  ngOnInit(): void {
    this.default_data();
  }

  default_data() {
    this.loadspinner = true;
    const endpoint1 = 'users';
    const endpoint2 = 'getlatestcomment/' + this.loginName;
    this.apiService.suspended_comments(endpoint1,endpoint2, 'GET').subscribe((response) => {
        this.loadspinner = false;
        this.commentLists = response['commentsNotes'];
        this.suspendedform.controls['comment'].setValue(this.commentLists['comments']);
        this.suspendedform.controls['additionalcomment'].setValue(this.commentLists['notes']);
        this.suspendedId=this.commentLists['id'];
      },
      (err) => {
        this.loadspinner = false;
      });
  }

  cancelbtn(){
    this.dialog.close();
  }

  submit(){
    this.loadspinner = true;
    const endpoint1 = 'users';
    const endpoint2 = 'updatenotes';
    let mainLoginName = localStorage.getItem('loginId');
    let body = {
      "loginName": this.loginName,
      "mainLoginName": mainLoginName,
      "notes": this.suspendedform.controls.additionalcomment.value,
      "id": this.suspendedId,
    }
    this.apiService.update_suscomments(endpoint1,endpoint2,body, 'PATCH').subscribe((response) => {
        this.loadspinner = false;
        if(response["message"]==="success"){
          this.dialog.close('yes');
        }
      },
      (err) => {
        this.loadspinner = false;
      });
  }


}
