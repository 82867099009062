<div class="filtermaincontainer">

  <div *ngIf="from=='Suspended' || from==='Suspend_Task'">
    <div class="row">
        <div class="col-12 heading">
          <span>{{Headingmessage}}</span>
        </div>
    </div>

    <div class="suspendedCls">
      <form [formGroup]="Suspendedform">

        <div class="row topCss">
          <div class="col-12 col-md-12 inputcolumn">
              <mat-label class="labelname"><span class="required"> * </span>Suspended Date</mat-label>
              <mat-form-field floatLabel="always" appearance="none" class="formfielddate">
                  <input matInput [matDatepicker]="Suspendedpicker" (dateChange)=changeSuspendedDate($event.value)
                      (click)="Suspendedpicker.open()"  style="cursor: pointer;" class="textinputusertablefilterdate"
                      placeholder="dd/mm/yyyy" formControlName="SuspendedDate" readonly>
                  <mat-datepicker-toggle matSuffix [for]="Suspendedpicker"> </mat-datepicker-toggle>
                  <mat-datepicker #Suspendedpicker></mat-datepicker>
              </mat-form-field>
          </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-12 inputcolumn" style="margin-top: 5%;">
                <mat-label class="labelname"><span class="required"> * </span>Notes</mat-label>
                <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
                  <textarea matInput class="textarea" formControlName="notes"></textarea>
                  <mat-error
                    *ngIf="Suspendedform.get('notes').hasError('required')"
                    class="mat-error">
                    <img src="assets/images/error.svg" class="errormsgicon" alt="error icon">
                    Please enter notes <br>
                  </mat-error>
                </mat-form-field>
        </div>
      </div>

      <div class="row topcls">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 cancel-btn-col"><br><br>
          <button mat-button class="cancel-btn" (click)="cancelbtn()">
            <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
            Cancel
          </button>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol"><br><br>
          <button mat-button class="Filterbtn" (click)="suspended_submit()">
            <mat-icon class="filterbtnicon">done</mat-icon>
            OK
          </button>
        </div>
      </div>

      </form>
    </div>

  </div>

  <div *ngIf="from==='Active' || from==='Inactive'|| from==='Archived' || from==='UnSuspend_Task'">
    <div class="row">
      <div class="col-12 heading">
        <span>{{Headingmessage}}</span>
      </div>
    </div>

    <div class="suspendedCls">
      <form [formGroup]="unsuspendedform">

        <div class="row topCss">
            <div class="col-12 col-md-12 inputcolumn">
                <mat-label class="labelname"><span class="required"> * </span>Unsuspended Date</mat-label>
                <mat-form-field floatLabel="always" appearance="none" class="formfielddate">
                    <input matInput [matDatepicker]="unSuspendedpicker" (dateChange)=changeUnSuspendedDate($event.value)
                        (click)="unSuspendedpicker.open()"  style="cursor: pointer;" class="textinputusertablefilterdate"
                        placeholder="dd/mm/yyyy" formControlName="UnSuspendedDate" readonly>
                    <mat-datepicker-toggle matSuffix [for]="unSuspendedpicker"> </mat-datepicker-toggle>
                    <mat-datepicker #unSuspendedpicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-12 inputcolumn" style="margin-top: 5%;">
                  <mat-label class="labelname"><span class="required"> * </span>Notes</mat-label>
                  <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
                    <textarea matInput class="textarea" formControlName="unsuspendednotes"></textarea>
                    <mat-error
                    *ngIf="unsuspendedform.get('unsuspendednotes').hasError('required')"
                    class="mat-error">
                    <img src="assets/images/error.svg" class="errormsgicon" alt="error icon">
                    Please enter notes <br>
                    </mat-error>
                  </mat-form-field>
          </div>
        </div>

        <div class="row topcls">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 cancel-btn-col"><br><br>
            <button mat-button class="cancel-btn" (click)="cancelbtn()">
              <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
              Cancel
            </button>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol"><br><br>
            <button mat-button class="Filterbtn" (click)="unsuspended_submit()">
              <mat-icon class="filterbtnicon">done</mat-icon>
              OK
            </button>
          </div>
        </div>
        
      </form>
    </div>

  </div>

</div>