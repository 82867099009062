import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-addorremovetaskfilter',
  templateUrl: './addorremovetaskfilter.component.html',
  styleUrls: ['./addorremovetaskfilter.component.scss']
})
export class AddorremovetaskfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton:any;
  user_data: any = [];
  tasklistOwner: any;
  compId: any;
  constructor(private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>) {
    this.filterform = this.formBuilder.group({
      TasklistOwner:['']
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
  }

  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    this.loadspinner = true;
    this.loadspinner = true;
    const endpoint1 = 'task';
    const endpoint2 = 'gettasklistiddetails';
    let cacheddata = localStorage.getItem('role');
    this.compId = localStorage.getItem('procCompId');
    let tasklistowner= localStorage.getItem("JobPosition_,tasklistowner");
    let body;
    if (cacheddata === "System Administrator") {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if(globalcompanyIDselected=='' || globalcompanyIDselected==undefined || globalcompanyIDselected==null){
          body = {
            "companyId": tasklistowner
           }
          }
          else{
            body = {
              "companyId": globalcompanyIDselected
             }
          }
    }
    else {
      body = {
        "companyId": this.compId
      }
    }
    this.apiService.tasknamesfilter(endpoint1,endpoint2, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
        let temp_data= response["taskListDetails"];
        this.user_data=temp_data;
        this.setdefaultdropdownvalues();
        this.loadspinner = false;
      });
  
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      // this.filterform.controls['TasklistName'].setValue(data["Task list Name"]);
      this.filterform.controls['TasklistOwner'].setValue(data["Task List Name"]);
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if(clearfilteritemkey[i]==="Task List Name"){
          this.filterform.controls['TasklistOwner'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  inputchange0(value){
    this.dynamicEnableDisablebtn()
    }

    selecttasklistowner(value) {
      if(value==="" || value === undefined || value===null){
        // if none select set value as ''
        this.filterform.controls['TasklistOwner'].setValue("");
        this.dynamicEnableDisablebtn()
      }
      else{
        this.disablebutton=false;
      }
    }

    cancelbtn() {
      // if filterdata not present --- on first load cancel - clear data
      let data = JSON.parse(localStorage.getItem('clearfilteritemkey'));
      if (data === "" || data === undefined || data === null) {
      }
      else {
        let groupfilterdata = this.getbodycontent();
        localStorage.setItem('filterdata', JSON.stringify(groupfilterdata));
        localStorage.removeItem('clearfilteritemkey');
      }
      this.dialog.close({ method: 'close' })
    }

    EnterSubmit(event){
      if(event.keyCode === 13){
       this.dynamicEnableDisablebtn();
       if(this.disablebutton===true){
        event.preventDefault();
       }
       else{
        event.preventDefault();
        this.Filter();
       }

      }
    }


    Filter() {
      if (this.filterform.valid) {
        localStorage.removeItem('filterdata');
        localStorage.removeItem('chipdisplayfilterdata');
        localStorage.removeItem('clearfilteritemkey');
        let groupfilterdata = this.getbodycontent();
        localStorage.setItem('filterdata', JSON.stringify(groupfilterdata));
        this.dialog.close({ data: groupfilterdata, groupapibodycontent: this.groupapibodycontent, method: 'filter' });
      }

    }

    getbodycontent() {
      let groupfilterdata = {
        "Task List Name": this.filterform.value.TasklistOwner.trim(),
      }
      this.getapibodycontent();
      return groupfilterdata

    }

    getapibodycontent() {
      let tasklistid= "";
      for (let i = 0; i < this.user_data.length; i++) {
        if (this.user_data[i].taskListName === this.filterform.value.TasklistOwner) {
          tasklistid = this.user_data[i].taskListId;
        }
      }
      this.groupapibodycontent ={
        "taskListId":tasklistid,
        "taskId":""
    }
    }

    checkformvalue(){
      let formempty;
      if(this.filterform.value.TasklistOwner.trim()===""){
        formempty=true;
      }
      else {
        formempty = false;
      }
      return formempty;
    }

    dynamicEnableDisablebtn(){
      let checkformfield= this.checkformvalue();
      if(checkformfield===true){
        this.disablebutton=true;
      }
      else{
        this.disablebutton=false;
      }
    }
}