import { Component, Inject, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AssignableContentComponent } from 'src/app/Administrator/assignable-content/assignable-content.component';
import { DialogpopupComponent } from '../../dialog-popup/dialog-popup.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {
  sel_data:any=[];
  backup_data:any=[];
  user_data:any=[];
  selected_users=[];
  temp_selected_user=[];
  temp_backup_users=[];
  backup_users:any=[];
  rem_data:any=[];
  loadspinner: boolean;
  AlluserList:any=[];
  companyId: any;
  Login: any;
  username: any;
  loginId: string;
  sel_user_array=[];
  temp_group_data:any=[];
  filter_companyid:any='';
  usersListData: any;
  taskId: any;
  requirement_selectall_right=false;
  req_selectall=false;
  compId: string;
  examId: any;
  totalitemlength: any;
  courseId: any;
  title: any;
  companyId1: any;
  pagesize: any;
  sortby: any;
  sortOrder: any;
  ExternalELEMENT_DATA: any = [];
  lastName: string;
  company_list:any=[];
  ids:any=[]
  from:any='';
  owncompany:any=[];
  common_ids:any=[];
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogopen: MatDialog, public dialog: MatDialogRef<AssignableContentComponent>, private formBuilder: FormBuilder, public _Service: ApiServiceService,) {
      this.loginId = localStorage.getItem('loginId');
      this.title=data['title']
    this.ids = data['ids'];
    this.from= data['from'];
    this.owncompany= data['owncompany'];
  }

  ngOnInit(): void {
   if(this.from=='Content'){
    this.common_ids=this.ids;
    this.sel_companieslist_content();

  }else if(this.from=='Forms'){
    this.common_ids.push(this.ids[0].formId);
    this.sel_companieslist_forms();
  }else if(this.from=='Catalog'){
    this.common_ids=this.ids;
    this.sel_companieslist_catalog();
  }else if(this.from=='tasklist'){
    this.common_ids=this.ids;
   this.sel_companieslist_task();
  }
  else if(this.from=='Exams'){
    this.common_ids=this.ids;
    this.sel_companieslist_Exams();
  }
  
  }

  sel_companieslist_task(){
    this.loadspinner = true;
    let sel_content_id=this.ids[0]
    const endpoint1 = 'task/getsharetasklistacrosscompany?taskListId='+sel_content_id;
   
    this._Service.tasktocompany_selcompanies(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        let temp_data: any = response['shareCompanyDetails'];
        this.temp_selected_user=temp_data;
        this.temp_backup_users =  this.temp_selected_user;
        this.listcompanies();
      },
      (err) => {
        this.loadspinner = false;
      });
  }

  sel_companieslist_forms(){
    
    this.loadspinner = true;
      const endpoint1 = 'pef/getshareformsacrosscompany/';
      let formid=this.ids[0].formId;
      let version=this.ids[0].version;
      let body={
        "formId":formid,
        "version":version
        }
      this._Service.formtocompany_selcompanies(endpoint1, body, 'POST')
        .subscribe((response) => {
          this.loadspinner = false;
          let temp_data: any = response['shareCompanyDetails'];
          this.temp_selected_user=temp_data;
          this.temp_backup_users =  this.temp_selected_user;
          this.listcompanies();
        },
        (err) => {
          this.loadspinner = false;
         
        });
  }


  sel_companieslist_content(){
    this.loadspinner = true;
      const endpoint1 = 'course/getcompanieslistcontent';
      let body={
        "contentList":this.ids
        }
      this._Service.contenttocompany_selcompanies(endpoint1, body, 'POST')
        .subscribe((response) => {
          this.loadspinner = false;
          let temp_data: any = response;
          this.temp_selected_user=temp_data;
          this.temp_backup_users =  this.temp_selected_user;
          this.listcompanies();
        },
        (err) => {
          this.loadspinner = false;

        });
  }


  sel_companieslist_catalog(){
    this.loadspinner = true;
      const endpoint1 = 'course/getcompanieslistcatalogs';
      let body={
        "contentList":this.ids
        }
      this._Service.catalogtocompany_selcompanies(endpoint1, body, 'POST')
        .subscribe((response) => {
          
          this.loadspinner = false;
          let temp_data: any = response; 
          this.temp_selected_user=temp_data;
          this.temp_backup_users =  this.temp_selected_user;
          this.listcompanies();
        },
        (err) => {
          this.loadspinner = false;
        });
  }

  sel_companieslist_Exams(){
    this.loadspinner = true;
      const endpoint1 = 'exams/getshareexamcompanieslist';
      let body={
        "examContentList":this.ids
        }
      this._Service.getpoollist(endpoint1, body, 'POST')
        .subscribe((response) => {
          
          this.loadspinner = false;
          let temp_data: any = response;  
          this.temp_selected_user=temp_data;
          this.temp_backup_users =  this.temp_selected_user;
          this.listcompanies();
        },
        (err) => {
          this.loadspinner = false;
        });
  }

  listcompanies(){
    this.loadspinner = true;
      const endpoint1 = 'lookup';
      this._Service.Simpleget(endpoint1, 'GET')
        .subscribe((response) => {
          let temp_data: any = response["companies"];
          for (var i = 0; i < temp_data.length; i++) {
            temp_data[i].isSelected = false;
          }
          let temp_sel_data:any=[];
          for (var i = 0; i < temp_data.length; i++) {
            for (var j = 0; j < this.temp_selected_user.length; j++) {
              if (temp_data[i].companyId == this.temp_selected_user[j].companyId) {
                temp_data[i].isSelected = true;
                temp_sel_data.push(temp_data[i]);
              }
            }
          }
         

          temp_data = temp_data.filter(x => x.isSelected != true);
          let own_id=this.owncompany[0];
          temp_data = temp_data.filter(x => x.companyId != own_id)
  
  ;
          this.user_data = temp_data;
         
          this.backup_data = this.user_data;

          temp_sel_data.forEach(element => {
            element.isSelected=false;
          });
          this.selected_users=temp_sel_data;
          this.backup_users = this.selected_users;
          this.loadspinner = false;

        },
        (err) => {
          this.loadspinner = false;
          

        });
  }

  listcompanies1(){
    this.loadspinner = true;
      const endpoint1 = 'lookup';
      this._Service.Simpleget(endpoint1, 'GET')
        .subscribe((response) => {
          
          let temp_data: any = response["companies"];
          for (var i = 0; i < temp_data.length; i++) {
            temp_data[i].isSelected = false;
          }
          this.user_data = temp_data;
          this.backup_data = this.user_data;
          this.loadspinner = false;

        },
        (err) => {
          this.loadspinner = false;
          

        });
  }

  close() {
    this.dialog.close();
    let element = document.getElementById("assign-content")
    element.classList.remove('blur-content');
  }
  

  search_left(ev){
    this.user_data=this.backup_data;
    let filterValue=ev;
    this.user_data= this.user_data.filter(option => ( option.companyName.toLowerCase().includes(filterValue.toLowerCase())));
    for(var l=0; l<this.user_data.length; l++){
      if(this.user_data[l].isSelected===true){
        this.req_selectall=true;
      }
      else{
        this.req_selectall=false;
        break
      }
    }
  }


  search_right(ev){
    this.selected_users=this.backup_users;
    let filterValue=ev;
    this.selected_users= this.selected_users.filter(option => ( option.companyName.toLowerCase().includes(filterValue.toLowerCase()) ));

    for(var l=0; l<this.selected_users.length; l++){
      if(this.selected_users[l].isSelected===true){
        this.requirement_selectall_right=true;
      }
      else{
        this.requirement_selectall_right=false;
        break
      }
    }
  }

  itemSelected_leftbox(ev){
    let instance=this;
    instance.req_selectall=null;
    let present=false;
    for(var k=0; k<this.sel_data.length; k++){
      if(ev.companyId==this.sel_data[k].companyId){
      present=true;
      this.sel_data.splice(k, 1);
    }
  }
  if(!present){
    this.sel_data.push(ev)
  }

  for(var l=0; l<this.backup_data.length; l++){
    if(!present){
    if(ev.companyId == this.backup_data[l].companyId){
      this.backup_data[l].isSelected=true;
    }
  }else{
    if(ev.companyId == this.backup_data[l].companyId){
      this.backup_data[l].isSelected=false;
    }
  }
  }
  for(var l=0; l<this.user_data.length; l++){
    if(!present){
    if(ev.companyId == this.user_data[l].companyId){
      this.user_data[l].isSelected=true;
    }
  }else{
    if(ev.companyId == this.user_data[l].companyId){
      this.user_data[l].isSelected=false;
    }
  }
  }
 
  for(var l=0; l<this.user_data.length; l++){
      if(this.user_data[l].isSelected===true){
        this.req_selectall=true;
      }
      else{
        this.req_selectall=false;
        break
      }
    }

  }

  itemSelected_rightbox(ev){
    this.requirement_selectall_right=false;
    let present=false;
    for(var k=0; k<this.rem_data.length; k++){
      if(ev.companyId==this.rem_data[k].companyId){
        present=true;
        this.rem_data.splice(k, 1);
      }
    }
    if(!present){
      this.rem_data.push(ev)
    }
    for(var l=0; l<this.backup_users.length; l++){
      if(!present){
      if(ev.companyId == this.backup_users[l].companyId){
        this.backup_users[l].isSelected=true;
      }
    }else{
      if(ev.companyId == this.backup_users[l].companyId){
        this.backup_users[l].isSelected=false;
      }
    }
    }

    for(var l=0; l<this.selected_users.length; l++){
      if(!present){
      if(ev.companyId == this.selected_users[l].companyId){
        this.selected_users[l].isSelected=true;
      }
    }else{
      if(ev.companyId == this.selected_users[l].companyId){
        this.selected_users[l].isSelected=false;
      }
    }
    }
   
    for(var l=0; l<this.selected_users.length; l++){
      if(this.selected_users[l].isSelected===true){
        this.requirement_selectall_right=true;
      }
      else{
        this.requirement_selectall_right=false;
        break
      }
    }
  }

  add_users(){
    this.req_selectall=null;
    this.requirement_selectall_right=null;
    if(this.sel_data.length>0){

  for(var j=0; j<this.backup_data.length; j++){

    if(this.backup_data[j].isSelected){
      this.selected_users.push(this.backup_data[j])
      
    }
  }


  this.user_data=this.backup_data;
  this.user_data = this.user_data.filter(x => x.isSelected != true);
  this.sel_data=[];
  for(var m=0; m<this.backup_data.length; m++){
    for(var n=0; n<this.selected_users.length; n++){
      if(this.selected_users[n].companyId==this.backup_data[m].companyId){
        this.backup_data[m].isSelected=true;
      }
    }
  }

  this.backup_data = this.backup_data.filter(x => x.isSelected != true);
  this.user_data=this.backup_data;
  for(var j=0; j<this.selected_users.length; j++){
    this.selected_users[j].isSelected=false;
  }
  this.backup_users=this.selected_users;
  //setting the serach value
  const name:any =  document.getElementById('sid1');
  name.value = "";
  }
  }

  remove_user() {
    let temp_rem_data:any=[];
    if (this.rem_data.length != 0) {
      this.rem_data.forEach((item)=>{
        temp_rem_data.push(item.companyId)
      })
        this.req_selectall=null;
      this.requirement_selectall_right=null;
      if(this.rem_data.length>0){

      for(var j=0; j<this.backup_users.length; j++){
        if(this.backup_users[j].isSelected){
          this.user_data.push(this.backup_users[j])
        }
      }
    this.backup_users = this.backup_users.filter(x => x.isSelected != true);
    this.selected_users=this.backup_users;

    for(var j=0; j<this.backup_data.length; j++){
      this.backup_data[j].isSelected=false
      }

    this.rem_data=[];
    for(var m=0; m<this.backup_users.length; m++){
      for(var n=0; n<this.user_data.length; n++){
        if(this.user_data[n].companyId==this.backup_users[m].companyId){
          this.backup_users[m].isSelected=true;
        }
      }
    }
      }
    
     if(this.from=='Content'){
      this.update_content_db(temp_rem_data);
  
    }else if(this.from=='Forms'){
      this.update_form_db(temp_rem_data);
    }else if(this.from=='Catalog'){
      this.update_catalog_db(temp_rem_data);
    }else if(this.from=='tasklist'){
      this.update_task_db(temp_rem_data);
    } 
    else if(this.from=='Exams'){
      this.update_Exams_db(temp_rem_data);
    } 
    }

  }

  update_content_db(temp_rem_data){
    this.loadspinner = true;
    let endpoint = 'course/removesharecompaniescontent';
    let company_list: any = temp_rem_data;

    let body ={
      "courseIds":this.common_ids,
      "companies":company_list
  }
    this._Service.sharetocompanycontent(endpoint, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
      },
        (err) => {
          this.loadspinner = false;
        });
  }

  update_form_db(temp_rem_data){
    this.loadspinner = true;
  
    let endpoint = 'course/removesharecompaniescontent';
    let company_list: any = temp_rem_data;

    let body ={
      "courseIds":this.common_ids,
      "companies":company_list
  }
    this._Service.sharetocompanycontent(endpoint, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
      },
        (err) => {
          this.loadspinner = false;
        });
  }

  update_catalog_db(temp_rem_data){
    this.loadspinner = true;
    let endpoint = 'course/removesharecompaniescontent';
    let company_list: any = temp_rem_data;

    let body ={
      "courseIds":this.common_ids,
      "companies":company_list
  }
    this._Service.sharetocompanycontent(endpoint, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
      },
        (err) => {
          this.loadspinner = false;
        });
  }

  update_task_db(temp_rem_data){
    this.loadspinner = true;
    let endpoint = 'task/removesharecompaniescontent';
    let company_list: any = temp_rem_data;

    let body ={
      "taskListIds":this.common_ids,
      "companies":company_list
  }
    this._Service.sharetocompanytasklist(endpoint, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
      },
        (err) => {
          this.loadspinner = false;
        });
  }

  update_Exams_db(temp_rem_data){
    this.loadspinner = true;
    let endpoint = 'exams/removesharecompaniescontent';
    let company_list: any = temp_rem_data;

    let body ={
      "examIds":this.common_ids,
      "companies":company_list
  }
    this._Service.getpoollist(endpoint, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
      },
        (err) => {
          this.loadspinner = false;
        });
  }


  reqAll(){
    if(this.req_selectall==true){
    this.user_data.forEach(element => {
    element.isSelected=true;
    })
    this.sel_data=[];
    //this.backup_data=[];
    this.user_data.forEach(element => {
    this.sel_data.push(element);
    //this.backup_data.push(element)

    })

    }else if(this.req_selectall==false){
    this.user_data.forEach(element => {
    element.isSelected=false;
    })

    this.sel_data=[];
   // this.backup_data= this.user_data;
    this.user_data.forEach(element => {
    })
    }

    }




  reqAll_right(){
    if(this.requirement_selectall_right==true){
    this.selected_users.forEach(element => {
    element.isSelected=true;
    })
    this.rem_data=[];
    //this.backup_users=[];
    this.selected_users.forEach(element => {
    this.rem_data.push(element);
    //this.backup_users.push(element)

    })

    }else if(this.requirement_selectall_right==false){
    this.selected_users.forEach(element => {
    element.isSelected=false;
    })

    this.rem_data=[];
   // this.backup_users=this.selected_users;
    this.selected_users.forEach(element => {
    })

    }

    }

submit(){
  if(this.from=='Content'){
    this.save_content();
  }else if(this.from=='Forms'){
    this.save_form();
  }else if(this.from=='Catalog'){
    this.save_catalog();
  }else if(this.from=='tasklist'){
    this.save_tasklist();
  }
  else if(this.from=='Exams'){
    this.save_Exams();
  }

}

    save_content() {
    this.loadspinner = true;
  
    let endpoint = 'course/sharecompaniescontent';
    let company_list: any = [];
    const loginId: string = localStorage.getItem('loginId');

    if (this.backup_users.length > 0) {
      for (var i = 0; i < this.backup_users.length; i++) {
        company_list.push(this.backup_users[i].companyId)
      }
    }

    let body ={
      "courseIds":this.ids,
      "companies":company_list,
      "loginName":loginId
  }
    this._Service.sharetocompanycontent(endpoint, body, 'POST')
      .subscribe((response) => {
        
        this.loadspinner = false;
        if (response["message"] === 'success') {
          this.dialog.close('success');
          
        }
        else {
          this.loadspinner = false;

          let data = {
            status: response["message"]
          };
          this.dialogopen.open(DialogpopupComponent, {
            width: '550px',
            data: {  from: 'error-popup',
            name: data },
            position: {
              top: '40vh'
            }
          });
        }
      },
        (err) => {
          this.loadspinner = false;
        });
   }

   save_form() {
    this.loadspinner = true;
    let endpoint = 'pef/shareformsacrosscompany/';
    let company_list: any = [];
    const loginId: string = localStorage.getItem('loginId');
    let formids:any=[];
      this.ids.forEach((element)=>{
        formids.push({
          'formId':element.formId,
          'version':element.version,
          'status':element.status
        })
      })

    if (this.backup_users.length > 0) {
      for (var i = 0; i < this.backup_users.length; i++) {
        company_list.push(this.backup_users[i].companyId)
      }
    }

    let body ={
      "shareFormDto":formids,
      "companyId":company_list,
      "loginName":loginId
  }
    this._Service.sharetocompanyform(endpoint, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
        if (response["message"] === 'success') {
       
          this.dialog.close('success');
          
        }
        else {
          this.loadspinner = false;

          let data = {
            status: response["message"]
          };
          this.dialogopen.open(DialogpopupComponent, {
            width: '550px',
            data: {  from: 'error-popup',
            name: data },
            position: {
              top: '40vh'
            }
          });
        }
      },
        (err) => {
          this.loadspinner = false;
          

        });
   }

   save_catalog(){
    this.loadspinner = true;
  
    let endpoint = 'course/sharecatalogscontent';
    let company_list: any = [];
    const loginId: string = localStorage.getItem('loginId');

    if (this.backup_users.length > 0) {
      for (var i = 0; i < this.backup_users.length; i++) {
        company_list.push(this.backup_users[i].companyId)
      }
    }

    let body ={
      "catalogIds":this.ids,
      "companies":company_list,
      "loginName":loginId
  }
    this._Service.sharetocompanycatalog(endpoint, body, 'POST')
      .subscribe((response) => {
        
        this.loadspinner = false;
        if (response["message"] === 'success') {
          this.dialog.close('success');
          
        }
        else {
          this.loadspinner = false;

          let data = {
            status: response["message"]
          };
          this.dialogopen.open(DialogpopupComponent, {
            width: '550px',
            data: {  from: 'error-popup',
            name: data },
            position: {
              top: '40vh'
            }
          });
        }
      },
        (err) => {
          this.loadspinner = false;
        });
   }

   save_tasklist(){
    this.loadspinner = true;
  
    let endpoint = 'task/sharetasklistacrosscompany';
    let company_list: any = [];
    const loginId: string = localStorage.getItem('loginId');

    if (this.backup_users.length > 0) {
      for (var i = 0; i < this.backup_users.length; i++) {
        company_list.push(this.backup_users[i].companyId)
      }
    }

    let body ={
      "taskListId":this.ids,
      "companyId":company_list,
      "loginName":loginId
  }
    this._Service.sharetocompanytasklist(endpoint, body, 'POST')
      .subscribe((response) => {
        
        this.loadspinner = false;
        if (response["message"] === 'success') {
          this.dialog.close('success');
          
        }
        else {
          this.loadspinner = false;

          let data = {
            status: response["message"]
          };
          this.dialogopen.open(DialogpopupComponent, {
            width: '550px',
            data: {  from: 'error-popup',
            name: data },
            position: {
              top: '40vh'
            }
          });
        }
      },
        (err) => {
          this.loadspinner = false;
        });
   }



   save_Exams() {
    this.loadspinner = true;
  
    let endpoint = 'exams/shareexamcompaniescontent';
    let company_list: any = [];
    const loginId: string = localStorage.getItem('loginId');

    if (this.backup_users.length > 0) {
      for (var i = 0; i < this.backup_users.length; i++) {
        company_list.push(this.backup_users[i].companyId)
      }
    }

    let body ={
      "examIds":this.ids,
      "companies":company_list,
      "loginName":loginId
  }
    this._Service.getpoollist(endpoint, body, 'POST')
      .subscribe((response) => {
        
        this.loadspinner = false;
        if (response["message"] === 'success') {
          this.dialog.close('success');
          
        }
        else {
          this.loadspinner = false;

          let data = {
            status: response["message"]
          };
          this.dialogopen.open(DialogpopupComponent, {
            width: '550px',
            data: {  from: 'error-popup',
            name: data },
            position: {
              top: '40vh'
            }
          });
        }
      },
        (err) => {
          this.loadspinner = false;
        });
   }
}
