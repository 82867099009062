<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>



<div class="usermaincontainer formsettings_div" id="user-content">

<div class="col-12">

<div class="row" style="margin-top: -1%; display: none;" id="success_block1">
<div class="col-8"> &#160;</div>
<div class="col-4">
<div class="alert success_tab" role="alert">
<span> <img width="15" height="15" src="assets/images/success.svg" alt="successlogo">&#160;&#160;SUCCESSFULLY saved.</span>
</div>
</div>
</div>


<mat-card class="example-card mat-elevation-z8 matcard">

<div class="row">
<div class="col-11"> </div>
<div class="col-1" >
    <span class="exporticon" *ngIf="A_C_FORMS_SETTINGS_EDIT!=false">
        <img src="assets/images/edit_active.png" alt="filter" title="Edit" style="width: 50px;display: none;" id="edit_active">
        <img *ngIf="sharedForm == 'No'" src="assets/images/edit.svg" alt="filter" title="Edit" style="width: 50px; cursor: pointer;" id="view_active" (click)="enable_edit()">
        <img *ngIf="sharedForm != 'No'" src="assets/images/edit.svg" alt="filter" title="Edit" style="width: 50px; cursor: pointer;opacity:0.5;cursor: not-allowed;" id="view_active" >
    </span>
</div>

</div>

<form class="example-form" #form="ngForm" [formGroup]="formssettings" novalidate>
<div class="row">
<div class="col-sm-12 col-md-9 col-lg-9 col-xl-9">
<div class="row">

<div class="col-12 space">
<div class="row">
<mat-label class="col-3">Assignment Scoring</mat-label>
<div class="col-9">
<mat-radio-group class="row" aria-label="Select an option"
#score formControlName="assignmentscore">
<mat-radio-button class="col-3" value="Passed/Failed">
<span class="radiotext"> Passed/Failed</span>
</mat-radio-button>
<mat-radio-button class="col-3" value="Qualified/Not Qualified">
<span class="radiotext"> Qualified/Not
Qualified</span>
</mat-radio-button>
</mat-radio-group>
</div>
</div>
</div>


<div class="col-12" style="margin-bottom: 2%;">
<div class="row">
<div class="col-6">
<mat-label class="labelname rating_dropdown">Steps
Performance Rating </mat-label>
<br>
<mat-form-field appearance="outline" id="evaluatorid"
class="formfield property-selector"
style="width: 100% !important;">
<mat-select matNativeControl
placeholder="Select number of attempts" #attempts
formControlName="performancerating"
panelClass="myPanelmatselectClass"
(selectionChange)=selectscalerating()
disableOptionCentering>
<mat-option *ngFor="let data of performancerating"
[value]="data"> 
{{data}}
</mat-option>
</mat-select>
</mat-form-field>
</div>
<div class="col-6">
<button mat-button class="customize_btn" (click)="scale_popup()" [disabled]="savebutton"> Customize
</button>
</div>
</div>
</div>



<div class="col-12 space">
<div class="row">
<mat-label class="col-3">Allow users to be marked "PASSED" with
failed step</mat-label>
<div class="col-9">
<mat-radio-group class="row" aria-label="Select an option"
#score formControlName="allowfrommarked">
<mat-radio-button class="col-3" value="Yes"><span
class="radiotext"> Yes </span>
</mat-radio-button>
<mat-radio-button class="col-3" value="No">
<span class="radiotext"> No </span>
</mat-radio-button>
</mat-radio-group>
</div>
</div>
</div>


<div class="col-12 space">
<div class="row">
<mat-label class="col-3">Evaluator Signature</mat-label>
<div class="col-9">
<mat-radio-group class="row" aria-label="Select an option"
#score formControlName="evaluatorsignature">
<mat-radio-button class="col-3" value="Required"><span
class="radiotext"> Required </span>
</mat-radio-button>
<mat-radio-button class="col-3" value="Not Required">
<span class="radiotext"> Not Required </span>
</mat-radio-button>
</mat-radio-group>
</div>
</div>
</div>

<div class="col-12 space">
<div class="row">
<mat-label class="col-3">Evaluatee Signature</mat-label>
<div class="col-9">
<mat-radio-group class="row" aria-label="Select an option"
#score formControlName="evaluateesignature">
<mat-radio-button class="col-3" value="Required"><span
class="radiotext"> Required </span>
</mat-radio-button>
<mat-radio-button class="col-3" value="Not Required">
<span class="radiotext"> Not Required </span>
</mat-radio-button>
</mat-radio-group>
</div>
</div>
</div>

<div class="col-12 space">
<div class="row">
<mat-label class="col-3">Evaluatee Image</mat-label>
<div class="col-9">
<mat-radio-group class="row" aria-label="Select an option"
#score formControlName="evaluateeimage">
<mat-radio-button class="col-3" value="Required"><span
class="radiotext"> Required </span>
</mat-radio-button>
<mat-radio-button class="col-3" value="Not Required">
<span class="radiotext"> Not Required </span>
</mat-radio-button>
</mat-radio-group>
</div>
</div>
</div>

<br> &#160; <br>



</div>
</div>

<div class="col-sm-12 col-md-3 col-md-3 col-lg-3 col-xl-3">


</div>

</div>



<div class="col-12">
<div class="row buttons-container" id="formsettings_btn" style="display: none;">
<div class="col-3"> </div>
<div class="col-3 cancelbtn-outer">
<button type="button" mat-button class="cancelbtn" (click)="cancel()" [disabled]="savebutton">
<mat-icon class="Cancelicon" alt="clear">clear
</mat-icon>
Cancel
</button>
</div>
<div class="col-3 createbtn-outer">
<button mat-button class="createbtn" type="submit" (click)="save_settings()" [disabled]="cancelbutton">
<mat-icon class="createbtnicon">done</mat-icon>
Save
</button>
</div>
<div class="col-3"> </div>
</div>
</div>



</form>



</mat-card>


<br>



</div>

</div>
