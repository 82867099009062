import { Component, Inject, OnInit, ViewChild, ElementRef} from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { DialogpopupComponent } from '../../dialog-popup/dialog-popup.component';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
@Component({
  selector: 'app-awardcompletion',
  templateUrl: './awardcompletion.component.html',
  styleUrls: ['./awardcompletion.component.scss']
})
export class AwardcompletionComponent implements OnInit {
  @ViewChild('fileInput', {static: false})
  InputVar: ElementRef;
  loadspinner:any;
  statusTypes:any=["Completed", "Passed", "Failed"];
  Types=["Other", "PEF"];
  titlesList:any=[];
  api_fileName:any='';
  sel_Type:any='Other';
  AwardForm: FormGroup;
  loginId:any='';
  compId:any='';
  maxdate = new Date();
  fileName:any='';
  file:any='';
  sel_username:any='';
  submit_click=false;
  loginName:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialog: MatDialogRef<UserComponent>, public dialogopen: MatDialog, private formBuilder: FormBuilder, public _Service:ApiServiceService, public httpClient:HttpClient) {
    this.loginId = localStorage.getItem('loginId');
    this.loginName= localStorage.getItem('selectedUserLogin');
    this.compId = localStorage.getItem('procCompId');
    
    this.sel_username=data['sel_loginname'];
   

  }

  ngOnInit(): void {
    this.AwardForm = this.formBuilder.group({
      awardType: ['Other'],
      awardTitle: ['', [Validators.required]],
      completionDate: ['', [Validators.required]],
      awardStatus: ['', [Validators.required]]
    })
    this.getData();
  }

  close(){
    this.dialog.close('')
  }
 submit(){
  this.submit_click=true;
  if(this.AwardForm.valid){
    this.submit_api();
  }
 }

 onFileSelect(event) {
    
  if (event.target.files.length > 0) {
    const file = event.target.files[0];
    this.fileName=event.target.files[0].name;
   this.file=file;
   
   let filepath=this.fileName.split('.');
   let fileExtention=filepath[filepath.length-1];
   if( fileExtention.toLowerCase() !='doc' && fileExtention.toLowerCase() !='docx' && fileExtention.toLowerCase() !='pdf' && fileExtention.toLowerCase() !='jpeg' && fileExtention.toLowerCase() !='jpg' && fileExtention.toLowerCase() !='png'){
    this.file='';
    this.fileName='';
    this.InputVar.nativeElement.value = "";
   this.open_modal('Format not supported', '(Only doc, pdf, jpeg and png formats are supported)');
   }else{
   if(file.size > 104857600){
     this.file='';
     this.fileName='';
     this.InputVar.nativeElement.value = "";
    this.open_modal('File size is too large', 'Maximum size allowed is 100 MB');
  }else{
    this.api_fileName=this.fileName;
  }
  }
}
}

open_modal(title, subtitle){
  const dialogRef = this.dialogopen.open(DialogpopupComponent, {
    width: '530px',
    height: '330px',
    data:{
      from:'image-size',
      title:title,
      subtitle:subtitle
    }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      });
  }

 dateValid(){

 }

 removeFile(){
  
  this.fileName='';
  this.file='';
  this.api_fileName='';
  this.InputVar.nativeElement.value = "";
}

 typeChange(value){
  this.sel_Type=value;
  if(this.sel_Type=="PEF"){
    this.statusTypes=["Passed", "Failed", "Qualified", "Not Qualified"];
  }
  else{
    this.statusTypes=["Completed", "Passed", "Failed"];
  }
  this.getData();
 }

 getData(){
  let selectedUserCompanyID= localStorage.getItem("selectedUserCompanyID");
  let companyid:any='';
  let cacheddata = localStorage.getItem('role');
  if (cacheddata === "System Administrator") {
    let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
    if (globalcompanyIDselected == '' || globalcompanyIDselected == undefined || globalcompanyIDselected == null) {
     
      companyid=selectedUserCompanyID
      this.getTitleList(this.sel_Type, companyid)
    }
    else {
     
      companyid=globalcompanyIDselected;
      this.getTitleList(this.sel_Type, companyid)
    }
  }
  else {
    
    companyid=this.compId;
    this.getTitleList(this.sel_Type, companyid)
  }
 }

 getTitleList(type, coid){
  this.loadspinner = true;
  const endpoint1 = 'transcript';
  const endpoint2 = 'gettitlelist';
  let body = {
      "type":type,
      "companyId":coid
      }
      this._Service.getTitleslist(endpoint1, endpoint2, body, 'POST')
      .subscribe((response) => {
      this.loadspinner = false;
      this.titlesList=response["courseFormsListData"];
     
    },
    (err) => {
      this.loadspinner = false;
    })
}

submit_api(){
  
 let completionDate:any;
  if(this.AwardForm.controls.completionDate.value != '' && this.AwardForm.controls.completionDate.value != null){
    completionDate=moment(this.AwardForm.controls.completionDate.value).format('yyyy-MM-DD');
    }
  this.loadspinner=true;
  let data:any;
  if(this.sel_Type == 'Other'){
    data={
      "type":this.AwardForm.controls.awardType.value,
      "category":this.AwardForm.controls.awardTitle.value.category,
      "title":this.AwardForm.controls.awardTitle.value.title,
      "requirementId":this.AwardForm.controls.awardTitle.value.requirement_id,
      "completionDate":completionDate,
      "loginName":this.sel_username,
      "version":"",
      "status":this.AwardForm.controls.awardStatus.value
      }
  }else{
    data={
      "type":this.AwardForm.controls.awardType.value,
      "category":this.AwardForm.controls.awardTitle.value.category,
      "title":this.AwardForm.controls.awardTitle.value.title,
      "requirementId":this.AwardForm.controls.awardTitle.value.requirement_id,
      "completionDate":completionDate,
      "loginName":this.sel_username,
      "version":this.AwardForm.controls.awardTitle.value.version,
      "status":this.AwardForm.controls.awardStatus.value
      }
  }
  const token: string = localStorage.getItem('jwtToken');
  let enpoint1="transcript";
  let endpoint2="coursesubmit";
  
  const formData = new FormData();
  formData.append('file', this.file);
  formData.append('metadata', JSON.stringify(data));
  this.httpClient.post<any>(this._Service.assignmentList+'/'+enpoint1+'/'+endpoint2, formData, {​​​
   }​​​).subscribe(
     (res) => {​​​
      
  
  if(res.message=="Success"){
    //oqcs-5121 15-03-2024
    this.taskstatusupdate()
  }else{
    this.loadspinner = false;
    let data = {
      status: res.message
    };
    this.dialogopen.open(DialogpopupComponent, {
      width: '550px',
      data: {  from: 'error-popup',
      name: data },
      position: {
        top: '40vh'
      }
    });
  
  }
  
     }​​​,
     (err) => {​​​ 
     this.loadspinner = false;
       }​​​
   );
}

taskstatusupdate(){
  
  var login = this.sel_username;
  this.loadspinner=true;
  let endpoint1="transcript/taskstatusupdate?loginName="+login
  this._Service.get_taskruleinfo(endpoint1)
  .subscribe((response) => {
    if(response["message"]="Success"){
      this.dialog.close('submit')
    setTimeout(() => {
      this.loadspinner = false;
    }, 500);
    }
  },
  (err) => {

    this.loadspinner = false;

  });
}


}


 
