<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>
<div>
  <div class="row headerrow">
    <div class="col-10">
      <span class="heading">{{title}}</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()" style="cursor: pointer;">clear</mat-icon>
    </div>
  </div>

  <div class="tableheight">
    <table mat-table [dataSource]="dataSource" style="width: 100%" matSort>

        <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef>
                <div class="status">
                    <span class="spanstatus">Company Added</span>
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="group_tablecontent">{{element.company_name}} ({{element.company_id}})</div>
            </td>
        </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="background-color: #143249"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

</div>


