import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AssignedUserComponent } from 'src/app/Evaluator/assigned-user/assigned-user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
@Component({
  selector: 'app-assigned-user-filter',
  templateUrl: './assigned-user-filter.component.html',
  styleUrls: ['./assigned-user-filter.component.scss']
})
export class AssignedUserFilterComponent implements OnInit {
  filterform: FormGroup;
  formnames: any = [""];
  loadspinner: any = false;
  apibodycontent: any;
  disablebutton:any;
  loginId: any;
  constructor(private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<AssignedUserComponent>) {
    this.loginId=localStorage.getItem('loginId');
    this.filterform = this.formBuilder.group({
      Login: [''],
      Name: [''],
      FormID: [''],
      FormName: [''],
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
  }

  apicallgetdefaultdropdown() {
    // get default value on load form names -- api call
    this.loadspinner = true;
    const endpoint1 = 'evaluator';
    const endpoint2 = 'getFormNamelist';
    let compId = localStorage.getItem('procCompId');
    let body = {
      "owner":compId,
      "evaluatorId":this.loginId,
      "type": "PEF"
    }
    this.apiService.assigneduser_filter(endpoint1, endpoint2, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
        this.formnames = response["formNameData"];
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
        });
  }
  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['Login'].setValue(data["Evaluatee Login"]);
      this.filterform.controls['Name'].setValue(data["Evaluatee Name"]);
      this.filterform.controls['FormID'].setValue(data["Form Id"]);
      this.filterform.controls['FormName'].setValue(data["Form Name"]);
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if(clearfilteritemkey[i]==="Evaluatee Login"){
          this.filterform.controls['Login'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Evaluatee Name"){
          this.filterform.controls['Name'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Form Id"){
          this.filterform.controls['FormID'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Form Name"){
          this.filterform.controls['FormName'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

   // on keyup enable filter button
  inputchangeLogin(value) {
    this.dynamicEnableDisablebtn();
  }

  inputchangeName(value) {
    this.dynamicEnableDisablebtn();
  }

  inputchangeFormId(value) {
    this.dynamicEnableDisablebtn()
  }

  selectFormName(value){
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['FormName'].setValue("");
      this.dynamicEnableDisablebtn();
    }
    else{
      this.disablebutton=false;
    }
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }


  Filter(){
    if (this.filterform.valid) {
      localStorage.removeItem('filterdata');
      localStorage.removeItem('chipdisplayfilterdata');
      localStorage.removeItem('clearfilteritemkey');
      let filterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
    }

  }

  EnterSubmit(event){
    if(event.keyCode === 13){
     this.dynamicEnableDisablebtn();
     if(this.disablebutton===true){
      event.preventDefault();
     }
     else{
      event.preventDefault();
      this.Filter();
     }

    }
  }

  getbodycontent() {
    let filterdata = {
      "Evaluatee Login": this.filterform.value.Login.trim(),
      "Evaluatee Name": this.filterform.value.Name.trim(),
      "Form Id": this.filterform.value.FormID.trim(),
      "Form Name": this.filterform.value.FormName.trim(),
    }
    this.getapibodycontent();
    return filterdata

  }

  getapibodycontent() {
    let companyvalue;
    let compId = localStorage.getItem('procCompId');
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      companyvalue=compId;
    }
    else{
      companyvalue="";
    }
    this.apibodycontent = {
      "id": "",
      "evaluateeId": this.filterform.value.Login.trim(),
      "evaluateeFirstName": "",
      "evaluateeLastName": "",
      "evaluateeName": this.filterform.value.Name.trim(),
      "evaluatorId":this.loginId,
      "taskId": "",
      "formId": this.filterform.value.FormID.trim(),
      "formName": this.filterform.value.FormName.trim(),
      "type": "PEF",
      "owner": companyvalue
    }
  }


  checkformvalue(){
    let formempty;
    if(this.filterform.value.Login.trim()==="" && this.filterform.value.Name.trim()==="" &&
    this.filterform.value.FormID.trim()==="" && this.filterform.value.FormName.trim()==="" ){
      formempty=true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn(){
    let checkformfield= this.checkformvalue();
    if(checkformfield===true){
      this.disablebutton=true;
    }
    else{
      this.disablebutton=false;
    }
  }
}
