import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { CommondataserviceService } from "../../../Services/commondataservice.service";
import * as $ from 'jquery';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-user-table-filter',
  templateUrl: './user-table-filter.component.html',
  styleUrls: ['./user-table-filter.component.scss'],
  providers: [DatePipe]
})
export class UserTableFilterComponent implements OnInit {
  loadspinner: any;
  filterform: FormGroup;
  countriesflaglist: any;
  statelist: any;
  stateonloadselected: any;
  PhoneNumbercode: any;
  companylistdata: any = []
  roleslist: any = [];
  groupslist: any = [];
  jobTitles: any = [];
  managers: any = [];
  evaluators: any = [];
  statuslist: any = [];
  defaultload: any;
  disablebutton: any;
  today = new Date();
  apibodycontent: any;
  allSelected = false;
  @ViewChild('select') select: MatSelect;
  api_PhoneNumber = "";
  PhoneNumber = "";
  countrycodeError=false;
  isDisabled:any;
  backupcompanylistdata:any;

  constructor(private datePipe: DatePipe, public dialog: MatDialogRef<UserComponent>, private formBuilder: FormBuilder, private CommondataserviceService: CommondataserviceService,
    private apiService: ApiServiceService) {
    this.filterform = this.formBuilder.group({
      Login: [''],
      Name: [''],
      PortabilityID: [''],
      EmpID: [''],
      CompanyId: [''],
      CreationDate: [''],
      ArchiveDate: [''],
      UserRole: [''],
      Status: [''],
      Groups: [''],
      Department: [''],
      JobTitle: [''],
      DirectManager: [''],
      EvaluatorID: [''],
      State: [''],
      Email: ['', [Validators.pattern('(?!.{51})[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-zA-Z]{2,3}')]],
      PhoneNumber: ['', [Validators.pattern('[0-9,-]+')]]
    });


    this.defaultload = true;
    this.PhoneNumbercode = "";
    this.apicallgetdefaultdropdown();
    // get the county list and selected value
    this.countriesflaglist = this.CommondataserviceService.Getcountriesflaglist();
  }


  setdefaultdropdownvalues() {
    // if cached data empty  --- first load
    let cacheddata = localStorage.getItem('filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton = true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton = false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['Login'].setValue(data["Login"]);
      this.filterform.controls['Name'].setValue(data["Name"]);
      this.filterform.controls['PortabilityID'].setValue(data["Portability ID"]);
      this.filterform.controls['EmpID'].setValue(data["Emp ID"]);
      this.filterform.controls['CompanyId'].setValue(data["Company (COID)"]);
      this.filterform.controls['UserRole'].setValue(data["User Role"]);
      // check if all value selected by default- set selectall true
      let newStatus = false;
      for (let i = 0; i < data["User Role"].length; i++) {
        if (data["User Role"].length === 7) {
          newStatus = true;
        }
      }
      this.allSelected = newStatus;
      ///////////////////////////////////
      this.filterform.controls['Status'].setValue(data["Status"]);
      this.selectCompanyID(data["Company (COID)"], '');
      if (data["Creation Date"] === null || data["Creation Date"] === undefined) {
        this.filterform.controls['CreationDate'].setValue("");
      } else {
        this.filterform.controls['CreationDate'].setValue(new Date(data["Creation Date"]));
      }
      if (data["Archive Date"] === null || data["Archive Date"] === undefined) {
        this.filterform.controls['ArchiveDate'].setValue("");
      } else {
        this.filterform.controls['ArchiveDate'].setValue(new Date(data["Archive Date"]));
      }

      this.filterform.controls['Groups'].setValue(data["Groups"]);
      this.filterform.controls['Department'].setValue(data["Department"]);
      this.filterform.controls['JobTitle'].setValue(data["Job Title"]);
      this.filterform.controls['DirectManager'].setValue(data["Direct Manager"]);
      this.filterform.controls['EvaluatorID'].setValue(data["Evaluator ID"]);
      this.filterform.controls['Email'].setValue(data["Email"]);

      this.PhoneNumbercode = data["Phone Number"].split(" ")[0];
      this.filterform.controls['PhoneNumber'].setValue(data["Phone Number"].split(" ")[1]);
      if (this.PhoneNumbercode.length === 0) {
        this.filterform.controls['PhoneNumber'].setValue("")
      }
      else {
        this.updateRegex();

      }
      this.statelist = this.CommondataserviceService.Getloadstatelist(this.PhoneNumbercode, data["State"]);

      // filterbtn cliked without selecting state
      if (this.statelist.length === 0) {
        setTimeout(() => {
          $('.selectstate').css("color", "#8f8f8f");
          this.defaultload = true;
          this.filterform.controls['State'].setValue("")
          this.dynamicEnableDisablebtn();
        }, 1000);
      }
      else {
        setTimeout(() => {
          $('.selectstate').css("color", "#222222");
          this.defaultload = false;
          this.filterform.controls['State'].setValue(data["State"])
          // check state value and enable/disable buttom
          this.dynamicEnableDisablebtn();
        }, 1000);
      }

      this.clearfilterfunction();


    }

    let compId = localStorage.getItem('procCompId');
    let compName = localStorage.getItem('proccompanyName');
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      if(rolecacheddata === "Manager"){
        let displayName= localStorage.getItem("displayName");
        this.filterform.controls['DirectManager'].setValue(displayName);
      }
      let data = compName + ' ' + '(' + compId + ')'
      this.filterform.controls['CompanyId'].setValue(data);
      let roledata=[rolecacheddata];
      this.set_defaultvalues(compId)
      this.isDisabled=true;
      this.disablebutton=false;
    }
    else{
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      let globalcompanynameselected = localStorage.getItem('globalcompanynameselected');
      if(globalcompanyIDselected=='' || globalcompanyIDselected==undefined || globalcompanyIDselected==null){
        this.isDisabled=false;
      }
      else{
        let data = globalcompanynameselected + ' ' + '(' + globalcompanyIDselected + ')'
        this.filterform.controls['CompanyId'].setValue(data);
        this.set_defaultvalues(globalcompanyIDselected)
        this.isDisabled=true;
        this.disablebutton=false;
      }
      
    }

  }
  set_defaultvalues(compid){
    const endpoint1 = 'lookup';
    const queryparameter1 = compid;
    this.apiService.getwithparam(queryparameter1, endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.groupslist = response["groups"];
        this.jobTitles = response["jobTitles"];
        this.managers = response["managers"];
        this.evaluators = response["evaluators"];
      
      },
        (err) => {
          this.loadspinner = false;
          

        });
  }

  ngOnInit(): void {
  }

  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    this.apiService.Simpleget(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.companylistdata = response["companies"];
        this.backupcompanylistdata=this.companylistdata;
        this.roleslist = response["roles"];
        this.statuslist = [
          { "status_id": 1, "statusName": "Active" },
          { "status_id": 2, "statusName": "Inactive" },
          { "status_id": 3, "statusName": "Suspended" },
          { "status_id": 4, "statusName": "Archived" },
          { "status_id": 5, "statusName": "Pending" }
        ]
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
          

        });
  }


  inputchangeLogin(value) {
    this.dynamicEnableDisablebtn();
  }
  inputchangeName(value) {
    this.dynamicEnableDisablebtn();
  }
  inputchangeinpportableID(value) {
    this.dynamicEnableDisablebtn();
  }
  inputchangeevaluatorID(value){
    this.dynamicEnableDisablebtn();
  }
  inputchangeinpEmpID(value) {
    this.dynamicEnableDisablebtn();
  }
  inputchangeDepartment(value) {
    this.dynamicEnableDisablebtn();
  }
  inputchangeEmail(value) {
    this.dynamicEnableDisablebtn();
  }

  changeCreationDate(value) {
    this.dynamicEnableDisablebtn();
  }
  changeArchiveDate(value) {
    this.dynamicEnableDisablebtn();
  }

  // dropdown on change
  selectUserRole(value) {
    let formcontrolname = "UserRole";
    this.dynamicEnableDisablebtnDrodown(value.toString().trim(), formcontrolname);

  }

  selectStatus(value) {
    let formcontrolname = "Status";
    this.dynamicEnableDisablebtnDrodown(value, formcontrolname);
  }

  selectGroups(value) {
    let formcontrolname = "Groups";
    this.dynamicEnableDisablebtnDrodown(value, formcontrolname);
  }
  selectJobTitle(value) {
    let formcontrolname = "JobTitle";
    this.dynamicEnableDisablebtnDrodown(value, formcontrolname);
  }

  selectDirectManager(value) {
    let formcontrolname = "DirectManager";
    this.dynamicEnableDisablebtnDrodown(value, formcontrolname);
  }

  selectEvaluatorID(value) {
    let formcontrolname = "EvaluatorID";
    this.dynamicEnableDisablebtnDrodown(value, formcontrolname);
  }

  selectstate(value) {
    let formcontrolname = "State";
    this.dynamicEnableDisablebtnDrodown(value, formcontrolname);
  }

  selectCompanyID(value, property) {
    // update company list to default
    let divID= document.getElementById("inputpagenocompany");
    if(divID !=null && divID !=undefined){
    (document.getElementById("inputpagenocompany") as HTMLInputElement).value = "";
    }
    this.companylistdata = this.backupcompanylistdata;
    
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['CompanyId'].setValue("");
      this.groupslist = [];
      this.jobTitles = [];
      this.managers = [];
      this.evaluators = [];
      this.filterform.controls['Groups'].setValue("");
      this.filterform.controls['JobTitle'].setValue("");
      this.filterform.controls['DirectManager'].setValue("");
      this.filterform.controls['EvaluatorID'].setValue("");
      this.dynamicEnableDisablebtn();

    }
    else {
      this.disablebutton = false;
      if ((this.PhoneNumbercode != "" && this.filterform.value.PhoneNumber.trim() === "") ||
        (this.PhoneNumbercode === "" && this.filterform.value.PhoneNumber.trim() != "")) {
        this.disablebutton = true;
      }
      // change selected name to ID to call api
      let companylistdataid = "";
      for (let i = 0; i < this.companylistdata.length; i++) {
        if (this.companylistdata[i].companyName + ' ' + '(' + this.companylistdata[i].companyId + ')' === value) {
          companylistdataid = this.companylistdata[i].companyId;
        }
      }
      // on select Company (COID) -- get all other dropdown data ---- api call
      this.loadspinner = true;
      const endpoint1 = 'lookup';
      const queryparameter1 = companylistdataid;
      this.apiService.getwithparam(queryparameter1, endpoint1, 'GET')
        .subscribe((response) => {
          this.loadspinner = false;
          this.groupslist = response["groups"];
          this.jobTitles = response["jobTitles"];
          this.managers = response["managers"];
          this.evaluators = response["evaluators"];
          if (property === "onchange") {
            this.filterform.controls['Groups'].setValue("");
            this.filterform.controls['JobTitle'].setValue("");
            this.filterform.controls['DirectManager'].setValue("");
            this.filterform.controls['EvaluatorID'].setValue("");
          }

        },
          (err) => {
            this.loadspinner = false;
            

          });
    }
  }

  toggleAllSelection() {
    if (this.allSelected === true) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }

  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if (clearfilteritemkey[i] === "State") {
          setTimeout(() => {
            $('.selectstate').css("color", "#8f8f8f");
            this.defaultload = true;
            this.filterform.controls['State'].setValue("")
          }, 1000);
        }
        else if (clearfilteritemkey[i] === "Phone Number") {
          this.PhoneNumbercode = "";
          this.updateRegex();
          this.filterform.controls['PhoneNumber'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Portability ID") {
          this.filterform.controls['PortabilityID'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Emp ID") {
          this.filterform.controls['EmpID'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Company (COID)") {
          this.filterform.controls['CompanyId'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Creation Date") {
          this.filterform.controls['CreationDate'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Archive Date") {
          this.filterform.controls['ArchiveDate'].setValue("");
        }
        else if (clearfilteritemkey[i] === "User Role") {
          this.filterform.controls['UserRole'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Job Title") {
          this.filterform.controls['JobTitle'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Direct Manager") {
          this.filterform.controls['DirectManager'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Evaluator ID") {
          this.filterform.controls['EvaluatorID'].setValue("");
        }
        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");

        }

      }
    }
    this.dynamicEnableDisablebtn();
  }

  onKey(value) {
    this.countriesflaglist = this.CommondataserviceService.Getcountriesflaglist();
    if (value.trim()) {
      const filterValue = value.toLowerCase();
      let data = this.countriesflaglist.filter(option => option.dial_code.toLowerCase().includes(filterValue));
      this.countriesflaglist = data;
    }
  }

  onKeyCompany(value) {
    if(value.trim().length===0){
      this.companylistdata=this.backupcompanylistdata;
    }
    else{
      const filterValue = value.toLowerCase();
      let data = this.backupcompanylistdata.filter(option => option.companyName.toLowerCase().includes(filterValue) ||
      option.companyId.toLowerCase().includes(filterValue));
      this.companylistdata = data;
    }
  }

  onKeyDownCompany(event) {
    // to disable mat select default search functionality
    event.stopImmediatePropagation();
  }



  countryflagCodeonchange(event) {
    if (event.value === undefined || event.value === null || event.value === "") {
      this.PhoneNumbercode = '';
    }
    else {
      this.PhoneNumbercode = event.value;
    }
    this.Getstateslist(event.value);
    let data = this.filterform.value.PhoneNumber;
    this.phnokeychange(data, event);
    // update country list to default
    (document.getElementById("inputpageno") as HTMLInputElement).value = "";
    this.countriesflaglist = this.CommondataserviceService.Getcountriesflaglist();

    this.updateRegex();
    this.dynamicEnableDisablebtn();

  }

  updateRegex() {

    if (this.PhoneNumbercode === "1") {
      this.filterform.get('PhoneNumber').clearValidators();
      this.filterform.get('PhoneNumber').setValidators([Validators.pattern("^((?!(0))[0-9,-]{12,12})$")]);
      this.filterform.get('PhoneNumber').updateValueAndValidity()
    }
    else {
      this.filterform.get('PhoneNumber').clearValidators();
      this.filterform.get('PhoneNumber').setValidators([Validators.pattern('[0-9,-]+')]);
      this.filterform.get('PhoneNumber').updateValueAndValidity()
    }

  }

  Getstateslist(event) {
    $('.selectstate').css("color", "#222222");
    this.defaultload = false;
    this.statelist = this.CommondataserviceService.Getloadstatelist(event, "none");
    this.filterform.controls['State'].setValue("");
    // select 1st item of array on load
    setTimeout(() => {
    }, 1000);
  }

  // disable/enable btn on key empty/present
  phnokeychange(value, event) {
    if(this.PhoneNumbercode==""){
      this.countrycodeError=true;
    }else{
      this.countrycodeError=false;
    }
    this.filterform.get('PhoneNumber').markAllAsTouched();
    
    this.dynamicEnableDisablebtn();
    var key = event.keyCode;
    // if usa selected and its a number
    if (this.PhoneNumbercode === "1" && key != 8) {

      this.getupdatedphonenumber(value);
     
    }
  }

  EnterSubmit(event) {
    if (event.keyCode === 13) {
      this.dynamicEnableDisablebtn();
      if (this.disablebutton === true) {
        event.preventDefault();
      }
      else {
        event.preventDefault();
        this.Filter();
      }

    }
  }


  Filter() {
    if (this.filterform.valid) {
      // clear all cached data and save updated
      localStorage.removeItem('filterdata');
      localStorage.removeItem('chipdisplayfilterdata');
      localStorage.removeItem('clearfilteritemkey');
      let filterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
    }

  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let filterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(filterdata));
      localStorage.removeItem('clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  getupdatedphonenumber(value) {

    if (this.PhoneNumbercode === "1") {
      let numberformat = value.trim();
      if (numberformat.length === 3) {
        let numberformat1 = numberformat.slice(0, 3);
        let concatnumberformat = numberformat1 + '-';
        this.filterform.controls['PhoneNumber'].setValue(concatnumberformat)

      } else if (numberformat.length === 7) {
        let numberformat1 = numberformat.slice(0, 3);
        let numberformat2 = numberformat.slice(4, 7);
        let concatnumberformat = numberformat1 + '-' + numberformat2 + "-";
        this.filterform.controls['PhoneNumber'].setValue(concatnumberformat)

      } else if (numberformat.length >= 10) {
        let combinenumber = "";
        let a = numberformat.split('-');
        // first split remove - then reinsert - in proper places
        for (let i = 0; i < a.length; i++) {
          let partednumber = a[i];
          combinenumber = combinenumber + partednumber;
        }

        let numberformat1 = combinenumber.trim().slice(0, 3);
        let numberformat2 = combinenumber.trim().slice(3, 6);
        let numberformat3 = combinenumber.trim().slice(6, 10);
        let concatnumberformat = numberformat1 + '-' + numberformat2 + '-' + numberformat3;
        this.filterform.controls['PhoneNumber'].setValue(concatnumberformat);
        this.PhoneNumber = this.PhoneNumbercode + " " + concatnumberformat;
        this.api_PhoneNumber = this.PhoneNumbercode.trim() + " " + concatnumberformat;
        if (this.PhoneNumber.trim() === "" || this.PhoneNumber === null || this.PhoneNumber === undefined) {
          this.PhoneNumber = "";
          this.api_PhoneNumber = "";
        }
      }
    }

    else {
      let numberformat = value.trim().split('-');
      if (numberformat.length > 0) {
        let partednumber: any;
        let concatednumber = "";
        let concatnumberformat = "";

        for (let i = 0; i < numberformat.length; i++) {
          partednumber = numberformat[i];
          concatednumber = concatednumber + partednumber;
        }
        let numberformat1 = concatednumber.trim().slice(0, 3);
        let numberformat2 = concatednumber.trim().slice(3, 6);
        let numberformat3 = concatednumber.trim().slice(6, 10);
        let a = this.filterform.value.PhoneNumber.trim().length;
        let numberformat4 = concatednumber.trim().slice(11, a);
        concatnumberformat = numberformat1 + numberformat2 + numberformat3 + numberformat4;

        this.PhoneNumber = this.PhoneNumbercode + " " + concatnumberformat;
        this.api_PhoneNumber = this.PhoneNumbercode.trim() + " " + concatnumberformat;
        if (this.PhoneNumber.trim() === "" || this.PhoneNumber === null || this.PhoneNumber === undefined) {
          this.PhoneNumber = "";
          this.api_PhoneNumber = "";
        }
        this.filterform.controls['PhoneNumber'].setValue(concatnumberformat)
      }
    }
  }


  getbodycontent() {
    let value = this.filterform.value.PhoneNumber;
    this.getupdatedphonenumber(value)
    let CompanyID="";
    let DirectManager="";
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      if(rolecacheddata === "Manager"){
        CompanyID="";
        DirectManager="";
      }
      else{
        CompanyID="";
        DirectManager=this.filterform.value.DirectManager
      }
    }
    else{
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if(globalcompanyIDselected=='' || globalcompanyIDselected==undefined || globalcompanyIDselected==null){
        CompanyID=this.filterform.value.CompanyId,
        DirectManager=this.filterform.value.DirectManager
      }
      else{
        CompanyID="";
        DirectManager=this.filterform.value.DirectManager
      }
     
    }

    let filterdata = {
      "Login": this.filterform.value.Login.trim(),
      "Name": this.filterform.value.Name.trim(),
      "Portability ID": this.filterform.value.PortabilityID.trim(),
      "Emp ID": this.filterform.value.EmpID.trim(),
      "Company (COID)": CompanyID,
      "Creation Date": this.datePipe.transform(this.filterform.value.CreationDate, 'MM-dd-yyyy'),
      "Archive Date": this.datePipe.transform(this.filterform.value.ArchiveDate, 'MM-dd-yyyy'),
      "User Role": this.filterform.value.UserRole,
      "Status": this.filterform.value.Status,
      "Groups": this.filterform.value.Groups,
      "Department": this.filterform.value.Department.trim(),
      "Job Title": this.filterform.value.JobTitle,
      "Direct Manager": DirectManager,
      "Evaluator ID": this.filterform.value.EvaluatorID,
      "Email": this.filterform.value.Email.trim(),
      "State": this.filterform.value.State.trim(),
      "Phone Number": this.PhoneNumber,
    }

    this.getapibodycontent(this.api_PhoneNumber);
    return filterdata;

  }

  // body content for api call
  getapibodycontent(api_PhoneNumber) {
    
    let evaluatorsloginName = "";
    let managersloginName = "";
    let companylistdataid = "";
    
    for (let i = 0; i < this.managers.length; i++) {
      if (this.managers[i].displayName === this.filterform.value.DirectManager) {
        managersloginName = this.managers[i].loginName;
      }
    }

    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i].companyName + ' ' + '(' + this.companylistdata[i].companyId + ')' === this.filterform.value.CompanyId) {
        companylistdataid = this.companylistdata[i].companyId;
      }
    }

    this.apibodycontent = {
      "loginName": this.filterform.value.Login.trim(),
      "displayName": this.filterform.value.Name.trim(),
      "portableId": this.filterform.value.PortabilityID.trim(),
      "empCode": this.filterform.value.EmpID.trim(),
      "companyId": companylistdataid,
      "creationDate": this.datePipe.transform(this.filterform.value.CreationDate, 'yyyy-MM-dd'),
      "statusUpdatedDate": this.datePipe.transform(this.filterform.value.ArchiveDate, 'yyyy-MM-dd'),
      "roleName": this.filterform.value.UserRole.toString(),
      "statusName": this.filterform.value.Status,
      "groupName": this.filterform.value.Groups,
      "departmentName": this.filterform.value.Department.trim(),
      "jobPositionTitle": this.filterform.value.JobTitle,
      "managerName": managersloginName,
      "evaluatorName": "",
      "email": this.filterform.value.Email.trim(),
      "state": this.filterform.value.State.trim(),
      "phone": api_PhoneNumber,
      "generatedEvaluatorId": this.filterform.value.EvaluatorID.trim()
    }

  }


  checkformvalue() {
    let formempty;
    if (this.filterform.value.Login.trim() === "" && this.filterform.value.Name.trim() === "" &&
      this.filterform.value.PortabilityID.trim() === "" && this.filterform.value.EmpID.trim() === "" &&
      this.filterform.value.CompanyId === "" && this.filterform.value.CreationDate === "" &&
      this.filterform.value.ArchiveDate === "" && this.filterform.value.UserRole.toString().trim() === "" &&
      this.filterform.value.Status.trim() === "" && this.filterform.value.Groups.trim() === "" &&
      this.filterform.value.Department.trim() === "" && this.filterform.value.JobTitle.trim() === "" &&
      this.filterform.value.EvaluatorID === "" && this.filterform.value.DirectManager.trim() === "" &&
      this.filterform.value.Email.trim() === "" && this.filterform.value.State.trim() === "" && this.PhoneNumbercode === "" &&
      this.filterform.value.PhoneNumber.trim() === ""
    ) {
      formempty = true;
    }
    else {
      formempty = false;
      // if any phcode and ph field any empty disable button
      if ((this.PhoneNumbercode != "" && this.filterform.value.PhoneNumber.trim() === "") ||
        (this.PhoneNumbercode === "" && this.filterform.value.PhoneNumber.trim() != "")) {
        formempty = true;
      }
    }
    return formempty;
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }

  dynamicEnableDisablebtnDrodown(value, formcontrolname) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls[formcontrolname].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      this.disablebutton = false;
      // if any phcode and ph field any empty disable button
      if ((this.PhoneNumbercode != "" && this.filterform.value.PhoneNumber.trim() === "") ||
        (this.PhoneNumbercode === "" && this.filterform.value.PhoneNumber.trim() != "")) {
        this.disablebutton = true;
      }
    }
  }

}
