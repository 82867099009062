import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AssignableContentComponent } from 'src/app/Administrator/assignable-content/assignable-content.component';
import { DialogpopupComponent } from '../../dialog-popup/dialog-popup.component';
import { MatTableDataSource } from '@angular/material/table';
import * as $ from 'jquery';
@Component({
  selector: 'app-assignwithdraw',
  templateUrl: './assignwithdraw.component.html',
  styleUrls: ['./assignwithdraw.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignwithdrawComponent implements OnInit {
  rem_data: any = [];
  sel_data: any = [];
  loadspinner: boolean;
  user_data: any = [];
  title: any;
  selected_users = [];
  courseId: any;
  loginName: string;
  firstName: string;
  lastName: string;
  companyId: any;
  loginId: string;
  pagesize: any;
  Rowperpage: any;
  sortby: any;
  sortOrder: any;
  ExternalELEMENT_DATA: any = [];
  dataSource: any;
  pagesizeRightSide: any;
  totalpages: any;
  totalpagesRight: any;
  adduserList: any = [];
  removeuserList: any = [];
  adduserListfulldata: any = [];
  removeuserListfulldata: any = [];
  backup_usersRightcolumn: any = [];
  backup_dataleftcolumn: any = [];
  leftsearchfielddata:any="";
  rightsearchfielddata:any="";
  user_dataLength:any;
  selected_usersLength:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogopen: MatDialog, public dialog: MatDialogRef<AssignableContentComponent>, private formBuilder: FormBuilder, public _Service: ApiServiceService,) {
    this.courseId = data['courseId'];
    this.loginId = localStorage.getItem('loginId');
    this.title=data["title"];
    this.companyId = data["companyId"];

    this.pagesize = '1';
    this.Rowperpage = "100";
    this.sortby = "lastName";
    this.sortOrder = "ASC";
    this.pagesizeRightSide = "1";
  }

  ngOnInit(): void {
    this.RightcolumnApi("","");
  }

  close() {
    this.dialog.close();
    let element = document.getElementById("assign-content")
    element.classList.remove('blur-content');
  }

  onPaginateChange(arrowdirection) {
    let pagesizenumber: any;
    if (arrowdirection === "arrowright") {
      pagesizenumber = parseInt(this.pagesize) + 1;
      this.pagesize = pagesizenumber.toString();
      // if any data present on search field pass the data
      this.LeftcolumnApi(this.leftsearchfielddata,"")

    }
    else if (arrowdirection === "arrowleft") {
      pagesizenumber = parseInt(this.pagesize) - 1;
      this.pagesize = pagesizenumber.toString();
      this.LeftcolumnApi(this.leftsearchfielddata,"")
    }
  }

  onPaginateChangeRightSide(arrowdirection) {
    let pagesizenumber: any;
    if (arrowdirection === "arrowright") {
      pagesizenumber = parseInt(this.pagesizeRightSide) + 1;
      this.pagesizeRightSide = pagesizenumber.toString();
      this.RightcolumnApi(this.rightsearchfielddata,"")
    }
    else if (arrowdirection === "arrowleft") {
      pagesizenumber = parseInt(this.pagesizeRightSide) - 1;
      this.pagesizeRightSide = pagesizenumber.toString();
      this.RightcolumnApi(this.rightsearchfielddata,"")
    }

  }

  customCSS() {
    if (parseInt(this.pagesize) === 1) {
      $('#paginationarrowleftidassignwidraw').css("color", '#c9c7c7');   // disable
      $('#paginationarrowrightidassignwidraw').css("color", '#696969'); // enable
      $('#paginationarrowleftidassignwidraw').css("pointer-events", 'none');
      $('#paginationarrowrightidassignwidraw').css("pointer-events", 'auto');
      if (this.totalpages === 1) {
        $('#paginationarrowleftidassignwidraw').css("color", '#c9c7c7');
        $('#paginationarrowrightidassignwidraw').css("color", '#c9c7c7');
        $('#paginationarrowleftidassignwidraw').css("pointer-events", 'none');
        $('#paginationarrowrightidassignwidraw').css("pointer-events", 'none');
      }

    }
    else if (parseInt(this.pagesize) === this.totalpages) {
      $('#paginationarrowleftidassignwidraw').css("color", '#696969');
      $('#paginationarrowrightidassignwidraw').css("color", '#c9c7c7');
      $('#paginationarrowleftidassignwidraw').css("pointer-events", 'auto');
      $('#paginationarrowrightidassignwidraw').css("pointer-events", 'none');
    }
    else {
      $('#paginationarrowleftidassignwidraw').css("color", '#696969');
      $('#paginationarrowrightidassignwidraw').css("color", '#696969');
      $('#paginationarrowleftidassignwidraw').css("pointer-events", 'auto');
      $('#paginationarrowrightidassignwidraw').css("pointer-events", 'auto');
    }
  }


  customCSSRight() {
    if (parseInt(this.pagesizeRightSide) === 1) {
      $('#paginationarrowleftidassignwidrawRightbox').css("color", '#c9c7c7');   // disable
      $('#paginationarrowrightidassignwidrawRightbox').css("color", '#696969'); // enable
      $('#paginationarrowleftidassignwidrawRightbox').css("pointer-events", 'none');
      $('#paginationarrowrightidassignwidrawRightbox').css("pointer-events", 'auto');
      if (this.totalpagesRight === 1) {
        $('#paginationarrowleftidassignwidrawRightbox').css("color", '#c9c7c7');
        $('#paginationarrowrightidassignwidrawRightbox').css("color", '#c9c7c7');
        $('#paginationarrowleftidassignwidrawRightbox').css("pointer-events", 'none');
        $('#paginationarrowrightidassignwidrawRightbox').css("pointer-events", 'none');
      }

    }
    else if (parseInt(this.pagesizeRightSide) === this.totalpagesRight) {
      $('#paginationarrowleftidassignwidrawRightbox').css("color", '#696969');
      $('#paginationarrowrightidassignwidrawRightbox').css("color", '#c9c7c7');
      $('#paginationarrowleftidassignwidrawRightbox').css("pointer-events", 'auto');
      $('#paginationarrowrightidassignwidrawRightbox').css("pointer-events", 'none');
    }
    else {
      $('#paginationarrowleftidassignwidrawRightbox').css("color", '#696969');
      $('#paginationarrowrightidassignwidrawRightbox').css("color", '#696969');
      $('#paginationarrowleftidassignwidrawRightbox').css("pointer-events", 'auto');
      $('#paginationarrowrightidassignwidrawRightbox').css("pointer-events", 'auto');
    }
  }

  // right side api
  RightcolumnApi(searchdataRight, Type) {
    // create body content for api call
    let searchdataRightlogin = searchdataRight;
    this.loadspinner = true;
    const endpoint1 = 'enrollment';
    const endpoint2 = 'searchassignedlist';
    let pageNo = this.pagesizeRightSide;
    let sortby = this.sortby;
    let sortOrder = this.sortOrder;
    let itemPerPage = this.Rowperpage;
    let compId = localStorage.getItem('procCompId');
    let cacheddata = localStorage.getItem('role');
    let data;
    if (cacheddata === "System Administrator") {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if (globalcompanyIDselected == '' || globalcompanyIDselected == undefined || globalcompanyIDselected == null) {
        data = {
          "filters": {
            "courseId": this.courseId,
            "companyId": "",
            "loginName": searchdataRightlogin,
          }
        }
      }
      else {
        data = {
          "filters": {
            "courseId": this.courseId,
            "companyId": globalcompanyIDselected,
            "loginName": searchdataRightlogin,
          }
        }
      }

    }
    else {
      data = {
        "filters": {
          "courseId": this.courseId,
          "companyId": compId,
          "loginName": searchdataRightlogin,
        }
      }
    }
    (document.getElementById("sid2") as HTMLInputElement).disabled = true;
    // call api get response
    this._Service.Enrollment_filterpaginationpost(pageNo, sortby, sortOrder, itemPerPage, endpoint1, endpoint2, data, 'POST')
      .subscribe((response) => {
        (document.getElementById("sid2") as HTMLInputElement).disabled = false;
        this.loadspinner = false;
        this.ExternalELEMENT_DATA = response['enrolledUersList'];
        this.dataSource = new MatTableDataSource<any>(this.ExternalELEMENT_DATA);
        let tempdata: any = []
        this.ExternalELEMENT_DATA.forEach(element => {
            tempdata.push(element)
        });

        //Inital check on load right side -- check the added user list, if any present add  the same data on right side along with api data
        if(Type !="DBSearch"){
        if (this.adduserListfulldata.length > 0) {
          for (var i = 0; i < this.adduserListfulldata.length; i++) {
            // add/append the newly added item on the right side box
            tempdata.push(this.adduserListfulldata[i])
          }
        }
        }

         //Inital check on load -- check the remove user list, if any present remove the same data from right side column
         if (this.removeuserListfulldata.length > 0) {
          for (var j = 0; j < this.removeuserListfulldata.length; j++) {
            tempdata= tempdata.filter(object => {
              return object.loginName !== this.removeuserListfulldata[j].loginName;
            });
          }

        }

        this.selected_users = tempdata;
        this.backup_usersRightcolumn = this.selected_users;
        this.totalpagesRight = response['data']['totalpages'];
        this.customCSSRight();
        // avoid calling left side data on search on right side
        if(Type !="DBSearch"){
        this.LeftcolumnApi(this.leftsearchfielddata,"");
        }

        const Divname: any = document.getElementById('sid2');
        setTimeout(() => {
          Divname.click();
          this.selected_users = [...this.selected_users]
        }, 1000)
      },
        (err) => {
          this.loadspinner = false;
        });
  }

  // left side api call
  LeftcolumnApi(searchdataleft, Type) {
    let searchdataleftlogin = searchdataleft;
    let compId = localStorage.getItem('procCompId');
    this.loadspinner = true;
    let pageNo = this.pagesize;
    let sortby = this.sortby;
    let sortOrder = this.sortOrder;
    let itemPerPage = this.Rowperpage;
    const endpoint1 = 'users';
    const endpoint2 = 'searchusersfilterwithpagination/'+ this.courseId;
    let cacheddata = localStorage.getItem('role');
    let body;
    if (cacheddata === "System Administrator") {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if (globalcompanyIDselected == '' || globalcompanyIDselected == undefined || globalcompanyIDselected == null) {
        body = {
          "filters": {
            "loginName": searchdataleftlogin,
            "companyId": this.companyId,
            "managerName": "",
            "firstName":"",
            "lastName":"",
            "statusName":"",
            "displayName":""
          }
        }
      }
      else {
        body = {
          "filters": {
            "loginName": searchdataleftlogin,
            "companyId": globalcompanyIDselected,
            "managerName": "",
            "firstName":"",
            "lastName":"",
            "statusName":"",
            "displayName":""
          }
        }
      }
    } else {
      let loginId = localStorage.getItem('loginId');
      if (cacheddata === "Manager") {
        body = {
          "filters": {
            "loginName": searchdataleftlogin,
            "companyId": compId,
            "managerName": loginId,
            "firstName":"",
            "lastName":"",
            "statusName":"",
            "displayName":""
          }
        }
      }
      else {
        body = {
          "filters": {
            "loginName": searchdataleftlogin,
            "companyId": compId,
            "managerName": "",
            "firstName":"",
            "lastName":"",
            "statusName":"",
            "displayName":""
          }
        }
      }
    }
    (document.getElementById("sid1") as HTMLInputElement).disabled = true;
    this._Service.manager_filterpaginationpost(pageNo, sortby, sortOrder, itemPerPage, endpoint1, endpoint2, body, 'POST')
      .subscribe((response) => {
        (document.getElementById("sid1") as HTMLInputElement).disabled = false;
        let temp_data: any = response["userListFilter"];
        this.sel_data=[];
        this.rem_data=[];
      
        //Inital check on load -- check the added user list, if any present remove the same data from left side column
        if (this.adduserListfulldata.length > 0) {
          for (var j = 0; j < this.adduserListfulldata.length; j++) {
            temp_data = temp_data.filter(object => {
              return object.loginName !== this.adduserListfulldata[j].loginName;
            });
          }
        }

        //Inital check on load -- check the remove user list, if any present add  the same data on left side column
        // On DB search this method not required as we are just searching data
        if(Type !="DBSearch"){
        if (this.removeuserListfulldata.length > 0) {
          for (var i = 0; i < this.removeuserListfulldata.length; i++) {
            // add/append the newly added item on the right side box
            temp_data.push(this.removeuserListfulldata[i])
          }
          }
        }

        this.user_data = temp_data;
        var setValue = this.user_data;
        const dataRemoved = setValue.filter((el) => {
          return el.displayName !== this.lastName + "," + this.lastName;
        });

        this.user_data = dataRemoved;
        this.backup_dataleftcolumn = this.user_data;
        this.loadspinner = false;

        this.totalpages = response['data']['totalpages'];
        this.customCSS();

        const Divname: any = document.getElementById('sid1');
        setTimeout(() => {
          Divname.click();
          this.user_data = [...this.user_data]

        }, 1000)
      },
        (err) => {
          this.loadspinner = false;
        });
  }

  search_left(ev) {
    this.leftsearchfielddata=ev;
    if (ev.length === 0) {
      this.pagesize = 1;
      this.LeftcolumnApi(ev,"")
    }

    else if(ev.length >= 3) {
       // do operation only if data present in array(left side box)
      if(this.user_data.length > 0){
      this.user_data = this.backup_dataleftcolumn;
      let filterValue = ev;
      this.user_data = this.user_data.filter(option => (option.loginName.toLowerCase().includes(filterValue.toLowerCase())));

      if (this.user_data.length === 0) {
        this.user_dataLength = ev.length;
        this.pagesize = 1;
        this.LeftcolumnApi(ev,"DBSearch");
      }
    }
    else{
      // existing key where data exist, if it matches on click backspace call api
      if(ev.length === this.user_dataLength-1){
        this.pagesize = 1;
        this.LeftcolumnApi(ev,"DBSearch");
      }
    }
    }
  }

  search_right(ev) {
    this.rightsearchfielddata=ev;
    if (ev.length === 0) {
      this.pagesizeRightSide = 1;
      this.RightcolumnApi(ev,"")
    }

    else if(ev.length >= 3) {
     // do operation only if data present in array(right side box)
     if(this.selected_users.length > 0){
      this.selected_users = this.backup_usersRightcolumn;
      let filterValue = ev;
      this.selected_users = this.selected_users.filter(option => (option.loginName.toLowerCase().includes(filterValue.toLowerCase())));

      if (this.selected_users.length === 0) {
        this.selected_usersLength = ev.length;
        this.pagesizeRightSide = 1;
        this.RightcolumnApi(ev, "DBSearch")
      }
    }
    else{
      // existing key where data exist, if it matches on click backspace call api
      if(ev.length === this.selected_usersLength-1){
        this.pagesizeRightSide = 1;
        this.RightcolumnApi(ev, "DBSearch")
      }

    }
    }
  }
  itemSelected_leftbox(ev, event) {
    if (event === true) {
      this.sel_data.push(ev)
    }
    else {
      this.sel_data = this.sel_data.filter(object => {
        return object.loginName !== ev.loginName;
      });
    }
  }

  itemSelected_rightbox(ev, event) {
    if (event === true) {
      this.rem_data.push(ev)
    }
    else {
      this.rem_data = this.rem_data.filter(object => {
        return object.loginName !== ev.loginName;
      });

    }
  }

  add_users() {
    if (this.sel_data.length > 0) {

      // api data array  ... api data logic
      //  on click add, first add the data from adduserList
      for (var i = 0; i < this.sel_data.length; i++) {

        // validate on click of add if user was already present on leftbox or new (i.e assigned from right box)
        // check api response registration flag -- if not registered add to array
        if ((this.sel_data[i].registered === false) || (this.sel_data[i].registered === true && this.sel_data[i].completed === true)) {

          this.adduserList.push({
            "loginName": this.sel_data[i].loginName,
            "completed": this.sel_data[i].completed,
            "registered":this.sel_data[i].registered
          })

          // removing the duplicate from adduserlist and remove the unchecked value
          const ids = this.adduserList.map(o => o.loginName)
          this.adduserList = this.adduserList.filter(({ loginName }, index) => !ids.includes(loginName, index + 1))
        }
        else {
          // if the same user is added then same is removed then remove the users from the removed user list
          this.removeuserList = this.removeuserList.filter(object => {
            return object.loginName != this.sel_data[i].loginName;
          });
        
      }
      }

      let finalapiarraylength = this.adduserList.length + this.removeuserList.length;
      if (finalapiarraylength > 50) {
        // once the max limit exceeds remove the last selected value from the addedlist, so that on click 
        // save the extra value does not save
        for (var i = 0; i < this.sel_data.length; i++) {
        this.adduserList = this.adduserList.filter(object => {
          return object.loginName != this.sel_data[i].loginName;
        });
      }

        let data = {
          status: 'Enrollment Limit: Maximum 50  users allowed'
        };
        let element = document.getElementById("assign-content")
        element.classList.add('blur-content')
        let dialogRef = this.dialogopen.open(DialogpopupComponent, {
          width: '550px',
          disableClose: true,
          data: {
            from: 'manager-error-popup',
            name: data
          },
          position: {
            top: '40vh'
          }
        });
        dialogRef.afterClosed().subscribe(res => {
          let element = document.getElementById("assign-content")
          element.classList.remove('blur-content');
          this.loadspinner = false;
        })
      }

      else {
        // frontend logic
        this.loadspinner = true;
        this.user_data.map(i => { i["isSelected"] = false });

        // assign the added data to adduserListfulldata (cache for further use)
        for (let i = 0; i < this.sel_data.length; i++) {
          this.adduserListfulldata.push(this.sel_data[i])
        }


        // on click of add add the data to right box and remove the data from left box
        if (this.adduserListfulldata.length > 0) {

          for (var j = 0; j < this.adduserListfulldata.length; j++) {

            // removing the duplicate as selected user already consist of the data previously pushed
            this.selected_users = this.selected_users.filter(object => {
              return object.loginName !== this.adduserListfulldata[j].loginName;
            });

            // add/append the newly added item on the right side box
            this.selected_users.push(this.adduserListfulldata[j])

            this.backup_usersRightcolumn = this.selected_users

            // remove them selected from the left side box
            this.user_data = this.user_data.filter(object => {
              return object.loginName !== this.adduserListfulldata[j].loginName;
            });
            this.backup_dataleftcolumn = this.user_data


            // remove the same data from the removeuserListfulldata
            this.removeuserListfulldata = this.removeuserListfulldata.filter(object => {
              return object.loginName !== this.adduserListfulldata[j].loginName;
            });
          }
        }


        // clear the selected array once add button clicked
        this.sel_data = [];

         //setting the serach value
       
        const Divname: any = document.getElementById('sid1');
        setTimeout(() => {
          Divname.click();
          this.user_data = [...this.user_data]
          this.selected_users = [...this.selected_users]
        }, 1000)
      }
      this.loadspinner = false;
    }
  }

  remove_user() {
    if (this.rem_data.length != 0) {
      let element = document.getElementById("assign-content")
      element.classList.add('blur-content')
      let dialogRef = this.dialogopen.open(DialogpopupComponent, {
        width: '530px',
        height: '330px',
        disableClose: true,
        data:
        {
          title: 'Are you sure you want to withdraw?',
          subtitle: 'By withdrawing the selected user, you will lose the data for the current course attempt. Would you like to proceed?',
          imagelogo: "assets/images/create-u-ser.svg",
          from: "confirm-popup"
        },
        backdropClass: 'deletePopup',

        position: {
          top: '27vh'
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        let element = document.getElementById("assign-content")
        element.classList.remove('blur-content');
        if (res.method == "delete") {
          if (this.rem_data.length > 0) {

            // api data array(only to send data to api)
            // on click remove push the data to the removeuserList
            for (var i = 0; i < this.rem_data.length; i++) {
              // validate on click remove if user was already present on rightbox or new (i.e assigned from left box)
              // check api response registration flag -- if registered dont add to array
              if (this.rem_data[i].registered === true) {

                this.removeuserList.push({
                  "loginName": this.rem_data[i].loginName,
                  "completed": this.rem_data[i].completed,
                  "registered":this.rem_data[i].registered
                })

                // removing the duplicate from removeuserList and remove the unchecked value
                const ids = this.removeuserList.map(o => o.loginName)
                this.removeuserList = this.removeuserList.filter(({ loginName }, index) => !ids.includes(loginName, index + 1))
              }

              else {
                // if the same user is added then same is removed then remove the users from the added user list
                this.adduserList = this.adduserList.filter(object => {
                  return object.loginName != this.rem_data[i].loginName;
                });
              }
            }

            let finalapiarraylength = this.adduserList.length + this.removeuserList.length;
            if (finalapiarraylength > 50) {

              // once the max limit exceeds remove the last selected value from the addedlist, so that on click 
              // save the extra value does not save
              for (var i = 0; i < this.rem_data.length; i++) {
              this.removeuserList = this.removeuserList.filter(object => {
                return object.loginName != this.rem_data[i].loginName;
              });
            }

              let data = {
                status: 'Enrollment Limit: Maximum 50  users allowed'
              };
              let element = document.getElementById("assign-content")
              element.classList.add('blur-content')
              let dialogRef = this.dialogopen.open(DialogpopupComponent, {
                width: '550px',
                disableClose: true,
                data: {
                  from: 'manager-error-popup',
                  name: data
                },
                position: {
                  top: '40vh'
                }
              });
              dialogRef.afterClosed().subscribe(res => {
                let element = document.getElementById("assign-content")
                element.classList.remove('blur-content');
                this.loadspinner = false;
              })
            }

            else {
              // frontend logic
              this.loadspinner = true;
              this.selected_users.map(i => { i["isSelected"] = false });

              // assign the removed data to removeuserListfulldata (cache for further use)
              this.removeuserListfulldata = this.rem_data;

              // on click of remove remove the data from right box and add the data to left box
              if (this.removeuserListfulldata.length > 0) {
                for (var j = 0; j < this.removeuserListfulldata.length; j++) {
                  // add/append the newly added item on the left side box
                  this.user_data.push(this.removeuserListfulldata[j])
                  this.backup_dataleftcolumn = this.user_data

                  // remove the selected from the right side box
                  this.selected_users = this.selected_users.filter(object => {
                    return object.loginName !== this.removeuserListfulldata[j].loginName;
                  });
                  this.backup_usersRightcolumn = this.selected_users

                  // remove the same data from the adduserListfulldata
                  this.adduserListfulldata = this.adduserListfulldata.filter(object => {
                    return object.loginName !== this.removeuserListfulldata[j].loginName;
                  });
                }
              }

              this.loadspinner = false;
              this.rem_data = [];
              const Divname: any = document.getElementById('sid1');
              setTimeout(() => {
                Divname.click();
                this.user_data = [...this.user_data]
                this.selected_users = [...this.selected_users]
              }, 1000)
            }
          }
        }

      })
    }
  }



  save() {
    let finalbody = {
      "addUserList": this.adduserList,
      "removeUserList": this.removeuserList
    }
    
    this.loadspinner = true;

    let endpoint = 'enrollment/addremoveusers/' + this.courseId
    this._Service.addwithdraw_enrolled(endpoint, finalbody, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
        if (response["message"] === 'success') {
          this.dialog.close('success');
        }
      },
        (err) => {
          this.loadspinner = false;
          

        });

  }
}
