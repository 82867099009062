import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { SidemenudataserviceService } from 'src/app/Services/sidemenudataservice.service';
import { CommondataserviceService } from 'src/app/Services/commondataservice.service'
import { HeaderComponent } from '../header/header.component';
import { launch_callback_serverurl } from 'src/environments/environment'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  @Input() data: any;
  appPages;
  showLevel1: any;
  showMenu: any;
  logo: any;
  name: any;
  role: any;
  rolearray: any = [];
  screenwidth: any;
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription;
  commondata: any;
  settingonclick: boolean = false;
  footerlist: any;
  cacheddata: any;
  companylogo: any;
  SETTING_MENU: any;
  impersonate;
  EVID_Status:any='';
  constructor(private router: Router, private SidemenudataserviceService: SidemenudataserviceService, private CommondataserviceService: CommondataserviceService,
  ) {
    this.appPages = this.SidemenudataserviceService.GetSidemenuData();
    this.footerlist = this.SidemenudataserviceService.GetSidemenuDatafooter();
    this.commondata = this.CommondataserviceService.getsidebarHeaderData();
    this.impersonate = JSON.parse(localStorage.getItem("impersonatemode"));
    this.EVID_Status = localStorage.getItem("EVID_status");
    if (this.impersonate) {
      this.logo = localStorage.getItem("ImpersonateprofileUrl");
      if (this.logo == "" || this.logo == null || this.logo == "null") {
        this.logo = "https://uat.stellar-oqcs.com/assets/images/upload-img.svg";
      }
      let impersonateName = this.CommondataserviceService.getimpersonate_user();
      if (impersonateName != undefined) {
        var match = impersonateName.split(',');
        this.name = match[1].trim() + ' ' + match[0];
      }
    } else {
      this.logo = this.commondata["profile_pic"]
      this.name = this.commondata["name"];
    }
    if (this.logo.includes("https://") || this.logo.includes("http://")) {

    } else {
      this.logo = "https://" + this.logo;
    }


    // side-menu role
    this.rolearray = this.commondata["rolearray"];
    this.cacheddata = localStorage.getItem('role');
    if (this.cacheddata === null || this.cacheddata === '' || this.cacheddata === undefined) {
      this.role = this.commondata["role"];
    }
    else {
      this.role = localStorage.getItem('role');

    }

  }

  ngOnInit() {
    this.setdefaultpermission();
    this.screenwidth = window.innerWidth;
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
      this.screenwidth = evt.target["innerWidth"];
    })
    this.companylogo = localStorage.getItem('companylogo');

    if (this.companylogo == null || this.companylogo == "" || this.companylogo == "null") {
      this.companylogo = "https://uat.stellar-oqcs.com/assets/images/mea-logo.svg";
    } else {
      this.companylogo = "https://" + this.companylogo;
    }



  }


  setdefaultpermission() {
    let a = localStorage.getItem("Permissiongroup");
    let Permissiongroupdata = JSON.parse(a)
    
    let login_role = localStorage.getItem("loginRole");
    if (login_role == 'System Administrator') {

      if (Object.keys(Permissiongroupdata["rolesPermissions"]["System Administrator"]).length != 0) {
        this.SETTING_MENU = Permissiongroupdata["rolesPermissions"]["System Administrator"]["SETTING_MENU"];
      }
      else {
        this.SETTING_MENU = true;
      }
    }
    else if (login_role == 'Primary Administrator') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Primary Administrator"]).length != 0) {
        this.SETTING_MENU = Permissiongroupdata["rolesPermissions"]["Primary Administrator"]["SETTING_MENU"];
      }
      else {
        this.SETTING_MENU = false;
      }
    }
    else if (login_role == 'Company Administrator') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Company Administrator"]).length != 0) {
        this.SETTING_MENU = Permissiongroupdata["rolesPermissions"]["Company Administrator"]["SETTING_MENU"];
      }
      else {
        this.SETTING_MENU = false;
      }
    }
    else if (login_role == 'Manager') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Manager"]).length != 0) {
      }
    }

    else if (login_role == 'Proctor') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Proctor"]).length != 0) {
      }
    }
    else if (login_role == 'Learner') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Learner"]).length != 0) {
      }
    }

  }

  addExpandClass(element: any, index) {
    var idx = 'idx' + index;
    if (element.path == '') {
      if (this.isLevel1Shown(idx)) {
        this.showMenu = null;
        this.showLevel1 = null;
      } else {
        this.showLevel1 = idx;
        this.showMenu = element.title;
      }
    }
    else {
      this.showMenu = null;
      this.showLevel1 = null;
    }

  }


  // return the level of tree i.e 1,2
  isLevel1Shown(idx: string) {
    return this.showLevel1 === idx;
  }

  matMenubtn(item) {

    //to avoid notification on when the user logged
    localStorage.removeItem("user_loggedIN_terms")
    localStorage.removeItem("user_loggedIN")
    //to avoid notification on when the user logged

    localStorage.setItem('role', item);
    this.role = item;
    localStorage.setItem('loginRole', this.role);
    this.appPages = this.SidemenudataserviceService.GetSidemenuData();

    let rootlink: any = [];
    if (this.appPages == undefined) {
      this.router.navigate(["/AccessDenied"]);
    } else {
      for (var i = 0; i < this.appPages.length; i++) {
        if (this.appPages[i].Showdata == true) {
          rootlink.push(this.appPages[i]);
          break;
        }
      }
      if (rootlink.length == 0) {
        this.router.navigate(["/AccessDenied"]);
      }
    }

    if (rootlink.length > 0) {

      this.router.navigate([rootlink[0].path], { queryParams: { menuTitle: rootlink[0].title } });
    }
    

    // else{
    setTimeout(() => {
      //clearing all browser history
      history.go()
      // }
      // cache value for display breadcrumb and refresh component
    }, 1000);
    // }

  }

  reloadcomponent(rootlink) {
    let currentUrl = this.router.url.split("?");
    let menutitle = this.router.url.split("=");
    
    

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    if (rootlink.length > 0) {
      this.router.navigate([rootlink[0].path], { queryParams: { menuTitle: rootlink[0].title } });
    }
    else {
      this.router.navigate([currentUrl[0]], { queryParams: { menuTitle: menutitle[1] } });
    }
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login'])
  }



  settingbtn() {
    $("#settingbtnid").css("background-color", "#ffffff");
    this.settingonclick = true;
    this.router.navigate(["/Settings"])
  }

  helpRedirect(){
    window.location.href = "https://d17nzlegqdq03e.cloudfront.net/help.htm";
  }

}
