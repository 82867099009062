import { Component, OnInit, ViewChild, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { MatAccordion } from "@angular/material/expansion";
import { ApiServiceService } from 'src/app/Services/api-service.service';
import * as $ from 'jquery';
import { DialogpopupComponent } from 'src/app/Popups/dialog-popup/dialog-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { MaterialModuleModule } from 'src/app/shared/sharedmodule/material-module.module';
import Quill from 'quill';
import { SELECT_ITEM_HEIGHT_EM } from '@angular/material/select';
export interface DragDropListItem {
  camera: boolean,
  performanceRatingNA: boolean,
  stepText: {
    type: string,
    label: string
  },
  stepDescription: {
    type: string,
    label: string
  },
  htmlContent :string,
  isPanelOpen: boolean,
  levels: [
    {
      label: string,
      inputType: string,
      subLevel: [
        {
          label: string,
          inputType: string,
        }
      ]
    }
  ]
}

@Component({
  selector: 'app-structure',
  templateUrl: './structure.component.html',
  styleUrls: ['./structure.component.scss']
})
export class StructureComponent implements OnInit {

  public selectTabIndex: any;
  loadspinner: any;
  panelOpenState: boolean = false;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  structureForm: FormGroup;
  structurelist: DragDropListItem[];
  structuredata: any;
  disablebutton: any;
  expandbtn: boolean = false;
  collapsebtn: boolean = true;
  addline: boolean = false;
  addsubline: boolean = false;
  savebutton: boolean = true;
  publishbutton: boolean = true;
  cancelbutton: boolean = true;
  User: any;
  count: any;
  fromstr: any;
  subRubrosSelec;
  public myImage: HTMLElement;
  public options = { animation: 150 };
  A_C_FORMS_STRUCTURE_VIEW: any;
  A_C_FORMS_STRUCTURE_PUBLISH: any;
  sharedForm: any = 'No';
  stepsvalue = [];
  dragdisable=false;
  //html editor code changes starts
  html = '';
    tools = {

      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],
  
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction
        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],
         // link and image, video
      ],
    };

     //html editor code changes ends
  constructor(private cd: ChangeDetectorRef, private formBuilder: FormBuilder, private apiservice: ApiServiceService, public dialog: MatDialog) {
    
    if (localStorage.getItem("formsharedFlagtocompany") != null && localStorage.getItem("formsharedFlagtocompany") != 'null' && localStorage.getItem("formsharedFlagtocompany") != undefined && localStorage.getItem("formsharedFlagtocompany") != 'undefined') {
      this.sharedForm = localStorage.getItem("formsharedFlagtocompany")
    }
    this.structureForm = this.formBuilder.group({
      imagecapturing: [''],
      performancerating: [],
      steptext: [''],
      stepdesc: ['']
    })
 }

  public started(e) {
    this.myImage = e.srcElement
      .getElementsByTagName('mat-expansion-panel-header')[0]
      .cloneNode(true);
    document.body.appendChild(this.myImage);
    e.dataTransfer.setDragImage(this.myImage, 0, 0);
  }

  public ended() {
    document.body.removeChild(this.myImage);
  }

  ngOnInit(): void {
    this.loadspinner = true;
    this.disablebutton = false;
    this.default_date();
    this.setdefaultpermission();
  }

  setdefaultpermission() {
    let a = localStorage.getItem("Permissiongroup");
    let Permissiongroupdata = JSON.parse(a)
    
    let login_role = localStorage.getItem("loginRole");
    
    if (login_role == 'System Administrator') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["System Administrator"]).length != 0) {
        this.A_C_FORMS_STRUCTURE_VIEW = Permissiongroupdata["rolesPermissions"]["System Administrator"]["A_C_FORMS_STRUCTURE_VIEW"];
        this.A_C_FORMS_STRUCTURE_PUBLISH = Permissiongroupdata["rolesPermissions"]["System Administrator"]["A_C_FORMS_STRUCTURE_PUBLISH"];
      }
    }
    else if (login_role == 'Primary Administrator') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Primary Administrator"]).length != 0) {
        this.A_C_FORMS_STRUCTURE_VIEW = Permissiongroupdata["rolesPermissions"]["Primary Administrator"]["A_C_FORMS_STRUCTURE_VIEW"];
        this.A_C_FORMS_STRUCTURE_PUBLISH = Permissiongroupdata["rolesPermissions"]["Primary Administrator"]["A_C_FORMS_STRUCTURE_PUBLISH"];
      }
    }
    else if (login_role == 'Company Administrator') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Company Administrator"]).length != 0) {
        this.A_C_FORMS_STRUCTURE_VIEW = Permissiongroupdata["rolesPermissions"]["Company Administrator"]["A_C_FORMS_STRUCTURE_VIEW"];
        this.A_C_FORMS_STRUCTURE_PUBLISH = Permissiongroupdata["rolesPermissions"]["Company Administrator"]["A_C_FORMS_STRUCTURE_PUBLISH"];
      }
    }
    else if (login_role == 'Manager') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Manager"]).length != 0) {
      }
      this.A_C_FORMS_STRUCTURE_VIEW = Permissiongroupdata["rolesPermissions"]["Manager"]["A_C_FORMS_STRUCTURE_VIEW"];
      this.A_C_FORMS_STRUCTURE_PUBLISH = Permissiongroupdata["rolesPermissions"]["Manager"]["A_C_FORMS_STRUCTURE_PUBLISH"];
    }
    else if (login_role == 'Proctor') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Proctor"]).length != 0) {
      }
    }
    else if (login_role == 'Learner') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Learner"]).length != 0) {
      }
    }
  }



  enable_edit() {
    if (localStorage.getItem("versionpopup") == "off") {
      this.version_popup();     
      localStorage.setItem("versionpopup", "on");
    }
    this.apiservice.user_details('', 'Edit');
  
    document.getElementById("btn_div").style.display = "flex";
   document.getElementById("view_active").style.display = "none";
   document.getElementById("edit_active").style.display = "block";
   document.querySelector("body").style.cssText = "--formcursorval: pointer";
   document.querySelector("body").style.cssText = "--formpointerval: all";
   this.savebutton = false;
   this.cancelbutton = false;
   this.publishbutton=true;
 
  }

  disable_edit() {
    this.savebutton = true;
    this.cancelbutton = true;
    this.apiservice.user_details('', 'View');
    document.getElementById("edit_active").style.display = "none";
    document.getElementById("view_active").style.display = "block";
    document.getElementById("btn_div").style.display = "none";
    
  }

  version_popup() {
    if (this.structuredata.pefView.status == "Published") {
      let element = document.getElementById("course-content")
      element.classList.add('blur-content')
      let data = {
        title: 'Editing the form will increment the version.',
        subtitle: 'The form will remain in draft mode until published. Evaluators will need to synchronize to obtain the new version',
        imagelogo: "assets/images/information.svg",
      };
      const dialogRef = this.dialog.open(DialogpopupComponent, {
        width: '550px',
        disableClose: true,
        data: {
          from: 'edit-formdetails-popup',
          name: data
        },
        position: {
          top: '40vh'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        let element = document.getElementById("course-content")
        element.classList.remove('blur-content');
        
        if (result == 'cancel') {
          localStorage.setItem("versionpopup", "off");
          this.disable_edit();
         
        } else {
          localStorage.setItem("versionpopup", "on");
          this.enable_edit();
        }
      });
    }
  }

  default_date() {
    
    this.loadspinner = true;
    const endpoint1 = 'pef/getFormDetails?formId=' + localStorage.getItem("formId") + '&version=' + localStorage.getItem("version") + '';
    this.apiservice.getformdetails(endpoint1).subscribe((res) => {
      
     
      this.structuredata = res;
      localStorage.setItem("form_status", this.structuredata.pefView.status);
      localStorage.setItem("version", this.structuredata.pefView.version);
   
   let temp_structurelist = this.structuredata.formStructure.steps;
   //to check the panel open close functionality for drag functionality
   if(temp_structurelist){
   temp_structurelist.forEach((item)=>{
    item.isPanelOpen = false;
   })

  }
      this.structurelist = temp_structurelist;
      if (this.structurelist == null) {
        this.structurelist = [
          {
            "camera": true,
            "performanceRatingNA": false,
            "stepText": {
              "type": "input",
              "label": ""
            },
            "stepDescription": {
              "type": "textarea",
              "label": ""
            },
            "htmlContent":'',
            "isPanelOpen":false,
            "levels": [
              {
                "label": "",
                "inputType": "TextBox",
                "subLevel": [
                  {
                    "label": "",
                    "inputType": "TextBox"
                  }
                ]
              }
            ]
          }
        ];
        this.count = 1;
      }else{
        
      }
      this.loadspinner = false;
    });
  }

  Addsteps() {
    this.count = this.count + 1;
    if (this.structurelist == null) {
      this.structurelist = [];
    }
    this.structurelist.push({
      camera: true,
      performanceRatingNA: false,
      stepText: {
        type: "input",
        label: ""
      },
      stepDescription: {
        type: "textarea",
        label: ""
      },
      htmlContent :'',
      isPanelOpen: false,
      levels: [
        {
          label: "",
          inputType: "TextBox",
          subLevel: [
            {
              label: "",
              inputType: "TextBox",
            }
          ]
        }
      ]
    });
    this.savebutton = false;
    this.cd.detectChanges();
  }

  //drag and drop functionality for the steps
  drop(event: CdkDragDrop<DragDropListItem[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  cancel() {
    event.preventDefault();
    let element = document.getElementById("course-content")
    element.classList.add('blur-content')
    const dialogRef = this.dialog.open(DialogpopupComponent, {
      width: '530px',
      height: '330px',
      disableClose: true,
      data: {
        from: 'course-cancel'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      let element = document.getElementById("course-content")
      element.classList.remove('blur-content');
    });
  }

  //Delete Steps dynamically
  delete_steps(count) {
    if(this.savebutton == false){
      if (this.structurelist.length > 1) {
        this.structurelist.splice(count, 1);
      } else {
        this.count = this.count - 1;
      }
    }   
  }

  Addlevel(count) {
    
    document.getElementById("addlevel_connection").style.display = "none";
    document.getElementById("addlevel_div" + count).style.display = "block";
    document.getElementById("addlevel_btn" + count).style.display = "none";
  }

  addleveltext(count) {
    
    document.getElementById("addlevel_btn" + count).style.display = "none";
    this.addline = true;
    this.structurelist[count].levels.push({
      label: "",
      inputType: "TextBox",
      subLevel: [
        {
          label: "",
          inputType: "TextBox",
        }
      ]
    });
    this.cd.detectChanges();
  }

  Addsublevels(count, j) {
    this.addsubline = true;
    this.structurelist[count].levels[j].subLevel.push(
      {
        label: "",
        inputType: "TextBox",
      }
    );
    this.cd.detectChanges();
  }

  deleteleveltext(count, j) {
    this.structurelist[count].levels.splice(j, 1);
    if (this.structurelist[count].levels.length < 1) {
      document.getElementById("addlevel_btn" + count).style.display = "block";
    }
  }

  deletesubleveltext(count, j, k) {
    if (this.structurelist[count].levels[j].subLevel.length > 1) {
      this.structurelist[count].levels[j].subLevel.splice(k, 1);
    } else {
      this.count = this.count - 1;
    }
  }

  nexttab_structure(){
    this.stepsvalue = [];
    var levelarr = [];
    var sublevelarr = [];
    var pref;
    for (var i = 0; i < this.structurelist.length; i++) {
      
      levelarr = [];
      sublevelarr = [];
      var radio_in = $('input[name="imagecap-' + i + '"]:checked').val();
      var html_context = this.structurelist[i].htmlContent;
      var isPanelOpen = this.structurelist[i].isPanelOpen;
      if ($('input[name="performance' + i + '"]:checked').val()) {
        pref = true;
      } else {
        pref = false;
      }
      var steptext = $('input[name="steptext' + i + '"]').val();
      if(steptext == undefined){
        steptext = this.structurelist[i].stepText.label
      }
      var stepdesc = $('textarea[name="stepdesc' + i + '"]').val();
      if(stepdesc == undefined){
        stepdesc = this.structurelist[i].stepDescription.label
      }
      // for (var j = 0; j < this.structurelist[i].levels.length; j++) {
      //   sublevelarr = [];
      //   var leveltext = $('textarea[name="leveltext' + i + "-" + j + '"]').val();
      //   for (var k = 0; k < this.structurelist[i].levels[j].subLevel.length; k++) {
      //     var sublevel = $('textarea[name="sublevel' + i + "-" + j + "-" + k + '"]').val();
      //     sublevelarr.push({
      //       "label": sublevel,
      //       "inputType": "TextBox"
      //     })
      //   }
      //   levelarr.push({
      //     "label": leveltext,
      //     "inputType": "TextBox",
      //     "subLevel": sublevelarr
      //   })
      // }
      this.stepsvalue.push({
        camera: radio_in,
        performanceRatingNA: pref,
        "stepText": {
          "type": "input",
          "label": steptext
        },
        "stepDescription": {
          "type": "textarea",
          "label": stepdesc
        },
        "levels": levelarr,
        "htmlContent": html_context,
        "isPanelOpen": isPanelOpen
      });
    }
    //this.savebutton = true;
    this.publishbutton = true;
    this.cancelbutton = true;

    var formstructuredata = {
      "steps": this.stepsvalue
    }


    localStorage.setItem('form_structure_tabchange', JSON.stringify(formstructuredata));

  }

  logChange(ev){
  }

  nexttab_structure_new(status){
    this.stepsvalue = [];
    var levelarr = [];
    var sublevelarr = [];
    var pref;
    
    for (var i = 0; i < this.structurelist.length; i++) {
      levelarr = [];
      sublevelarr = [];

      var radio_in = $('input[name="imagecap-' + i + '"]:checked').val();
      if ($('input[name="performance' + i + '"]:checked').val()) {
        pref = true;
      } else {
        pref = false;
      }
      
      var steptext = $('input[name="steptext' + i + '"]').val();
      if(steptext == undefined){
        steptext = this.structurelist[i].stepText.label
      }
      var stepdesc = $('textarea[name="stepdesc' + i + '"]').val();
      if(stepdesc == undefined){
        stepdesc = this.structurelist[i].stepDescription.label
      }
      var html_context = this.structurelist[i].htmlContent;
      var isPanelOpen = this.structurelist[i].isPanelOpen;
      
      this.stepsvalue.push({
        camera: radio_in,
        performanceRatingNA: pref,
        "stepText": {
          "type": "input",
          "label": steptext
        },
        "stepDescription": {
          "type": "textarea",
          "label": stepdesc
        },
        "levels": levelarr,
        "htmlContent": html_context,
        "isPanelOpen": isPanelOpen
      });
    }

    this.publishbutton = false;
    this.cancelbutton = true;

    var formstructuredata = {
      "steps": this.stepsvalue
    }


    localStorage.setItem('form_structure_tabchange', JSON.stringify(formstructuredata));
    if(localStorage.getItem("form_details_tabchange") != undefined){
      
      let formdetailsdata = localStorage.getItem("form_details_tabchange");
      this.structuredata.pefView = JSON.parse(formdetailsdata);
      
      let catlog_data=localStorage.getItem("form_details_tabchange_catalogs");
      this.structuredata.catalogListNames=[];
      this.structuredata.catalogListNames.push(catlog_data)
    }

    if(localStorage.getItem("form_settings_tabchange") != undefined){
      
      let formsettingsdata = localStorage.getItem("form_settings_tabchange");
      this.structuredata.formSettings = JSON.parse(formsettingsdata);
    }


    let publishflag;
    if (status === "Published") {
      this.publishbutton = true;
      this.cancelbutton = true;
      publishflag = true;
      this.structureForm.disable();
      this.disable_edit();
      this.publish_form(status, this.stepsvalue, publishflag);
    } else {
      publishflag = false;
      this.save_form(status, this.stepsvalue, publishflag);
    }

  }

  save(status) {
    
    window.scrollTo(0,0);
    this.closeAll();
    this.loadspinner = true;
    this.nexttab_structure_new(status);
    
    
  }

  //save the form function
  save_form(status, dict, flag) {
    
    var formstructuredata = {
      "steps": dict
    }

    if(this.structuredata.formSettings.numberFormat == null){
      this.structuredata.formSettings = {
        numberFormat: "1",
        assignmentScoring: "Passed/Failed",
        failedSteps: "Yes",
        evaluatorSign: "Required",
        evaluateeSign: "Required",
        evaluateeImage: "Required",
        stepsPerformanceRating: {
          ratingScale: "2 Scale Rating",
          ratings: [
            { labelCode: 1, labelTitle: "Pass", passingCriteria: false },
            { labelCode: 2, labelTitle: "Fail", passingCriteria: true }
          ]
        }
      }
    }

    let endpoint1 = 'pef/single/savecall?formId=' + localStorage.getItem("formId") + '&version=' + localStorage.getItem("version") + '';
    this.structuredata.formStructure = formstructuredata;
    this.structuredata.pefView.version=localStorage.getItem("version");
    this.structuredata.pefView.status=localStorage.getItem("form_status");
    
    let temp_catalogs:any=[];
    if(this.structuredata.catalogListNames[0] != 'null' && this.structuredata.catalogListNames[0] != null){
      let temp_values=this.structuredata.catalogListNames[0];
      temp_values=temp_values.split(',');
      for(var p=0; p<temp_values.length; p++){
        temp_catalogs.push(temp_values[p])
      }
      this.structuredata.catalogListNames=temp_catalogs;
    }
    
    let body = this.structuredata;
    this.apiservice.saveformdetails(endpoint1, body).subscribe((res) => {
      
      var response;
      response = res;
      this.loadspinner = false;
      this.savebutton=true;
      if (response.message === "success") {
        localStorage.setItem("version", response.version);
        localStorage.setItem("formId", response.formId);
        localStorage.setItem("formSettingId", response.formSettingsId)
        this.loadspinner = false;
        document.getElementById("save_sucess").style.display = "flex";
       localStorage.removeItem("form_details_tabchange");
       localStorage.removeItem("form_details_tabchange_catalogs")
       this.default_date()
        setTimeout(() => {
          document.getElementById("save_sucess").style.display = "none";
        }, 5000);
      }
    });
  }

  //publish the form function
  publish_form(status, data, flag) {
    this.loadspinner = true;
    var body = {
      "formId": localStorage.getItem("formId"),
      "formSettingId": localStorage.getItem("formSettingId"),
      "publishFlag": flag,
      "version": localStorage.getItem("version"),
      "status": status,
      "formStructure": {
        "steps": data
      }
    }
    let endpoint = '/pef/formstructure/';
    this.apiservice.formstructure(endpoint, body).subscribe((res) => {
      var response;
      response = res;
    
      if (response.message === "Success") {
       
        document.getElementById("publish_sucess").style.display = "flex";
        this.disable_edit();
        this.apiservice.user_details('', 'View');
        setTimeout(() => {
          this.disable_edit();
          document.getElementById("publish_sucess").style.display = "none";
          localStorage.removeItem("form_details_tabchange");
          localStorage.removeItem("form_details_tabchange_catalogs")
          this.default_date();
          this.loadspinner = false;
        }, 2000);
      }
    })
  }

  openAll() {
    this.dragdisable=true;
    this.expandbtn = true;
    this.collapsebtn = false;
    this.accordion.openAll();
    this.structurelist.forEach(item => {
      item.isPanelOpen = true;
    });
    
  }

  closeAll() {
    this.dragdisable=false;
    this.expandbtn = false;
    this.collapsebtn = true;
    this.accordion.closeAll();
    this.structurelist.forEach(item => {
      item.isPanelOpen = false;
    });
  }

  onKeyuplevel(event: KeyboardEvent, i, j) {
    $('textarea[name="leveltext' + i + "-" + j + '"]').val().trim();
  }

  onKeyupsublevel(event,i, j, k){
    $('textarea[name="sublevel' + i + "-" + j + "-" + k + '"]').val().trim();
  }

  returnvalue(value){
    
    let data=value.trim();
    return data;
  }

  notifyopen(data){
    
  data.isPanelOpen = true;
    this.dragdisable = true;
let isopenall = true;
this.structurelist.forEach(item => {
  
  if(item.isPanelOpen == false){
    isopenall = false;
  }
  });

  if(isopenall == true){
    this.dragdisable=true;
    this.expandbtn = true;
    this.collapsebtn = false;
  }
  }

  notifyclose(data){
    
    data.isPanelOpen = false;
    let isallclosed=true;
    this.structurelist.forEach(item => {
      if(item.isPanelOpen == true){
        isallclosed = false;
      }

      });
      if(isallclosed==true){
        this.dragdisable = false;
        this.expandbtn = false;
        this.collapsebtn = true;
      }else{
        this.dragdisable = true;
      }

  }
}
