import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { CommondataserviceService } from "../../../Services/commondataservice.service";
@Component({
  selector: 'app-proctorfilter',
  templateUrl: './proctorfilter.component.html',
  styleUrls: ['./proctorfilter.component.scss']
})
export class ProctorfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton:any;
  today = new Date();
  companyId: any;
  dataList: any;
  actionlist:any;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialogRef<UserComponent>) {

    this.filterform = this.formBuilder.group({
      requirementName: [''],
      name: [''],
      taskName: [''],
      login: [''],
      empId: [''],
      status: [''],
      action:['']
    });
    this.apicallgetdefaultdropdown();
  
  }

  ngOnInit(): void {
  }


  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
  }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['requirementName'].setValue(data["Requirement Name"]);
      this.filterform.controls['login'].setValue(data["Login"]);
      this.filterform.controls['taskName'].setValue(data["Task Name"])
      this.filterform.controls['name'].setValue(data["Name"])
      this.filterform.controls['empId'].setValue(data["Emp ID"]);
      this.filterform.controls['status'].setValue(data["Status"]);
      this.filterform.controls['action'].setValue(data["Action"]);
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
      
         if(clearfilteritemkey[i]==="Requirement Name"){
          this.filterform.controls['requirementName'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Login"){
          this.filterform.controls['login'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Task Name"){
          this.filterform.controls['taskName'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Name"){
          this.filterform.controls['name'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Emp ID"){
          this.filterform.controls['empId'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Status"){
          this.filterform.controls['status'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Action"){
          this.filterform.controls['action'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
        this.statuslist = [
          { "status_id": 1, "statusName": "Not Started" },
          { "status_id": 2, "statusName": "In Progress" }
        ]

        this.actionlist = [
          { "action_id": 1, "actionName": "Locked" },
          { "action_id": 2, "actionName": "Unlocked" }
        ]
        this.setdefaultdropdownvalues();
  }


  // on keyup enable filter button
  input1changename(value){
   this.dynamicEnableDisablebtn()
  }
  input2changename(value){
    this.dynamicEnableDisablebtn()
   }
   input3changename(value){
    this.dynamicEnableDisablebtn()
   }
   input4changename(value){
    this.dynamicEnableDisablebtn()
   }
   input5changename(value){
    this.dynamicEnableDisablebtn()
   }


  selectStatus(value) {
    let formcontrolname = "Status";
    this.dynamicEnableDisablebtnDrodown(value, formcontrolname);
  }

  selectAction(value){
    let formcontrolname = "action";
    this.dynamicEnableDisablebtnDrodown(value, formcontrolname);
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let filterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(filterdata));
      localStorage.removeItem('clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }



  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('filterdata');
      localStorage.removeItem('chipdisplayfilterdata');
      localStorage.removeItem('clearfilteritemkey');
      let filterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, groupapibodycontent: this.groupapibodycontent, method: 'filter' });
    }

  }

  getbodycontent() {
    let filterdata = {
      "Requirement Name": this.filterform.value.requirementName.trim(),
      "Task Name": this.filterform.value.taskName.trim(),
      "Login": this.filterform.value.login.trim(),
      "Emp ID": this.filterform.value.empId.trim(),
      "Name": this.filterform.value.name.trim(),
      "Status": this.filterform.value.status.trim(),
      "Action": this.filterform.value.action.trim()
    }
    this.getapibodycontent();
    return filterdata
  }

  getapibodycontent() {
 let compId = localStorage.getItem('procCompId')
    this.groupapibodycontent = {
      "id":"",
      "loginName": this.filterform.value.login.trim(),
      "displayName":this.filterform.value.name.trim(),
      "empId": this.filterform.value.empId.trim(),
      "companyId":compId,
      "taskId":"",
      "requirementName": this.filterform.value.requirementName.trim(),
      "courseId":"",
      "taskName": this.filterform.value.taskName.trim(),
      "status": this.filterform.value.status.trim(),
      // "proctorEnabled":"1",
      "lockUnlockData":this.filterform.value.action.trim()
    }
  }

  checkformvalue(){
    let formempty;
    if(this.filterform.value.requirementName.trim()==="" && this.filterform.value.taskName.trim()==="" && this.filterform.value.login.trim()==="" 
     && this.filterform.value.name.trim()==="" && this.filterform.value.empId.trim()==="" 
     && this.filterform.value.status.trim()==="" && this.filterform.value.action.trim()===""){
      formempty=true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn(){
    let checkformfield= this.checkformvalue();
    if(checkformfield===true){
      this.disablebutton=true;
    }
    else{
      this.disablebutton=false;
    }
  }



  dynamicEnableDisablebtnDrodown(value, formcontrolname) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls[formcontrolname].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      this.disablebutton = false;
    }
  }

  EnterSubmit(event){
    if(event.keyCode === 13){
     this.dynamicEnableDisablebtn();
     if(this.disablebutton===true){
      event.preventDefault();
     }
     else{
      event.preventDefault();
      this.Filter();
     }
    }
  }
}