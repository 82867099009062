import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SidebarComponent} from "../sidebar/sidebar.component";
import { RouterModule } from '@angular/router';
import { MaterialModuleModule } from './material-module.module';
import{HeaderComponent} from "../header/header.component"
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogpopupComponent } from '../../Popups/dialog-popup/dialog-popup.component'
import { ImageCropperModule } from 'ngx-image-cropper';
import {CompanylistFilterComponent} from '../../Popups/companyManagement/companylist-filter/companylist-filter.component'
import { ResetPasswordComponent } from '../../Popups/reset-password/reset-password.component';
import { ReqlicenseComponent } from '../../Popups/companyManagement/reqlicense/reqlicense.component';
import { ChangemanagerComponent } from '../../Popups/User/changemanager/changemanager.component';
import { ManagerFilterComponent } from 'src/app/Popups/User/manager-filter/manager-filter.component';
import { UserTableFilterComponent } from 'src/app/Popups/User/user-table-filter/user-table-filter.component';
import { GrouplistFilterComponent } from 'src/app/Popups/User/grouplist-filter/grouplist-filter.component';
import { AddusertogroupComponent } from 'src/app/Popups/User/addusertogroup/addusertogroup.component'
import { AddremoveusersComponent } from 'src/app/Popups/User/addremoveusers/addremoveusers.component';
import { LicenceapprovallistfilterComponent } from 'src/app/Popups/companyManagement/licenceapprovallistfilter/licenceapprovallistfilter.component';
import { LicencependinglistfilterComponent } from 'src/app/Popups/companyManagement/licencependinglistfilter/licencependinglistfilter.component';
import { ViewcommentComponent } from 'src/app/Popups/companyManagement/viewcomment/viewcomment.component';
import { AddcommentComponent } from 'src/app/Popups/companyManagement/addcomment/addcomment.component';
import { GroupstatslistfilterComponent } from 'src/app/Popups/companyManagement/groupstatslistfilter/groupstatslistfilter.component';
import { ExternaltrainingfilterComponent } from 'src/app/Popups/User/externaltrainingfilter/externaltrainingfilter.component';
import { ViewgroupusersfilterComponent } from 'src/app/Popups/companyManagement/viewgroupusersfilter/viewgroupusersfilter.component';
import { AssignwithdrawComponent } from 'src/app/Popups/assignablecontent/assignwithdraw/assignwithdraw.component';
import { AssignWithdrawcurriculamComponent } from 'src/app/Popups/assignablecontent/curriculam/assign-withdrawcurriculam/assign-withdrawcurriculam.component';
import { AssignWithdrawTitleComponent } from 'src/app/Popups/assignablecontent/curriculam/assign-withdraw-title/assign-withdraw-title.component';
import { AddUserForCurriculumComponent } from 'src/app/Popups/assignablecontent/curriculam/add-user-for-curriculum/add-user-for-curriculum.component';
import { AssignablecontentlistfilterComponent } from 'src/app//Popups/assignablecontent/assignablecontentlistfilter/assignablecontentlistfilter.component';
import { EnrollCourseFilterComponent } from'src/app/Popups/assignablecontent/enroll-course-filter/enroll-course-filter.component';
import { ViewcoursecommentComponent } from 'src/app/Popups/assignmentContent/viewcoursecomment/viewcoursecomment.component';
import { AssignmentFilterComponent } from 'src/app/Popups/Learner/assignment-filter/assignment-filter.component';
import {EnrollmentlistfilterComponent} from 'src/app/Popups/assignablecontent/curriculam/enrollmentlistfilter/enrollmentlistfilter.component'
import { AssignedlistfilterComponent } from 'src/app/Popups/assignablecontent/assignedlistfilter/assignedlistfilter.component';
import {FormslistfilterComponent} from 'src/app/Popups/assignablecontent/formslistfilter/formslistfilter.component';
import {AssignmentslaunchComponent} from 'src/app/Popups/Learner/assignmentslaunch/assignmentslaunch.component';
import { CertificateComponent } from 'src/app/Popups/User/certificate/certificate.component';
import { ScaleratingComponent } from 'src/app/Popups/assignablecontent/scalerating/scalerating.component';
import { CurriculafilterComponent } from 'src/app/Popups/assignablecontent/curriculafilter/curriculafilter.component';
import { CreatefromtemplateComponent } from 'src/app/Popups/assignablecontent/createfromtemplate/createfromtemplate.component';
import { TranscriptlistFilterComponent } from 'src/app/Popups/assignablecontent/transcriptlist-filter/transcriptlist-filter.component';
import {AnoouncementlistfilterComponent} from 'src/app/Popups/Announcements/anoouncementlistfilter/anoouncementlistfilter.component'
import {TasklistfilterComponent} from 'src/app/Popups/Task/tasklistfilter/tasklistfilter.component'
import { CompletionRecordComponent } from 'src/app/Popups/Learner/Transcript/completion-record/completion-record.component';
import { AcknowledgementComponent } from 'src/app/Popups/Announcements/Acknowledgement/acknowledgement.component'
import { SequenceComponent } from 'src/app/Popups/sequence/sequence.component';
import {TasklistnamefilterComponent} from 'src/app/Popups/Task/tasklistnamefilter/tasklistnamefilter.component'
import { AddremovejobpositionComponent } from 'src/app/Popups/Task/addremovejobposition/addremovejobposition.component';
import { TermsconditionspopupComponent } from 'src/app/Popups/Announcements/termsconditionspopup/termsconditionspopup.component';
import { TermsandconditionlistfilterComponent } from 'src/app/Popups/Announcements/termsandconditionlistfilter/termsandconditionlistfilter.component';
import { AssignmentfilterComponent } from 'src/app/Popups/User/assignmentfilter/assignmentfilter.component';
import { AddtaskfilterComponent } from 'src/app/Popups/User/addtaskfilter/addtaskfilter.component';
import { EvaluatorfilterComponent } from 'src/app/Popups/User/evaluatorfilter/evaluatorfilter.component';
import { AddremoveevaluatorsComponent } from 'src/app/Popups/User/addremoveevaluators/addremoveevaluators.component';
import { TaskfilterComponent } from 'src/app/Popups/Learner/taskfilter/taskfilter.component';
import { UploadimageComponent } from 'src/app/Popups/User/uploadimage/uploadimage.component';
import { TasklistviewfilterComponent } from 'src/app/Popups/Learner/tasklistviewfilter/tasklistviewfilter.component';
import { ViewevaluatorfilterComponent } from 'src/app/Popups/User/viewevaluatorfilter/viewevaluatorfilter.component'
import { ApplyRulesComponent } from 'src/app/Popups/Task/apply-rules/apply-rules.component';
import { ImportcontentComponent } from 'src/app/Popups/assignablecontent/importcontent/importcontent.component';
import {AddremovetasknamelistfilterComponent} from 'src/app/Popups/Task/addremovetasknamelistfilter/addremovetasknamelistfilter.component'
import { AddrequiremnetsComponent } from 'src/app/Popups/Task/addrequiremnets/addrequiremnets.component';
import { EquivalentComponent } from 'src/app/Popups/Task/equivalent/equivalent.component';
import { SettingsComponent } from 'src/app/Administrator/assignable-content/editforms/settings/settings.component';
import { ArchivedformsettingComponent } from 'src/app/Administrator/assignable-content/editforms/archivedformsetting/archivedformsetting.component';
import { ArchivedformstructureComponent } from 'src/app/Administrator/assignable-content/editforms/archivedformstructure/archivedformstructure.component';
import { StructureComponent } from 'src/app/Administrator/assignable-content/editforms/structure/structure.component';
import { ProctorfilterComponent } from 'src/app/Popups/Proctor/proctorfilter/proctorfilter.component';
import { AssignedUserFilterComponent } from 'src/app/Popups/Evaluator/assigned-user-filter/assigned-user-filter.component';
import { LogsComponent } from 'src/app/Popups/Setting/logs/logs.component';
import { IsntransferFilterComponent } from 'src/app/Popups/Setting/isntransfer-filter/isntransfer-filter.component';
import {ManagepermissionfilterComponent} from 'src/app/Popups/Setting/managepermissionfilter/managepermissionfilter.component'
import { AddorremovetaskfilterComponent } from 'src/app/Popups/Task/addorremovetaskfilter/addorremovetaskfilter.component';
import { CataloglistfilterComponent } from 'src/app/Popups/assignablecontent/cataloglistfilter/cataloglistfilter.component';
import { AssignremovecourseComponent } from 'src/app/Popups/assignablecontent/assignremovecourse/assignremovecourse.component';
import { AddremovecoursefilterComponent } from 'src/app/Popups/assignablecontent/addremovecoursefilter/addremovecoursefilter.component';
import { ExamsFilterComponent } from 'src/app/Popups/ExamManagement/exams-filter/exams-filter.component';
import { ExamsenrollmentsfilterComponent } from 'src/app/Popups/ExamManagement/examsenrollmentsfilter/examsenrollmentsfilter.component';
import { QuestionlistfilterComponent } from 'src/app/Popups/ExamManagement/questionlistfilter/questionlistfilter.component';
import { AddremovequestionsComponent } from 'src/app/Popups/ExamManagement/addremovequestions/addremovequestions.component'
import { AddremoveusertoexamComponent } from 'src/app/Popups/ExamManagement/addremoveusertoexam/addremoveusertoexam.component'
import { ExampoolfilterComponent } from 'src/app/Popups/ExamManagement/exampoolfilter/exampoolfilter.component';
import { QuestionpoolfilterComponent } from 'src/app/Popups/ExamManagement/questionpoolfilter/questionpoolfilter.component';
import { ShareComponent } from 'src/app/Popups/assignablecontent/share/share.component'
import { ReportscheduleComponent } from 'src/app/Popups/Reports/reportschedule/reportschedule.component'
import {NonscormcontentdisplayComponent} from 'src/app/Popups/assignablecontent/nonscormcontentdisplay/nonscormcontentdisplay.component'
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { EvaluationresultComponent } from 'src/app/Popups/Evaluator/evaluationresult/evaluationresult.component'
import { CameraComponent } from 'src/app/Evaluator/assigned-user/camera/camera.component';
import { AwardcompletionComponent } from 'src/app/Popups/User/Awardcompletion/awardcompletion.component';
import { AssignedcompanylistComponent } from 'src/app/Popups/Announcements/assignedcompanylist/assignedcompanylist.component';
import { SuspendeduserComponent } from 'src/app/Popups/User/suspendeduser/suspendeduser.component';
import { AddsuspendedcommentsComponent } from 'src/app/Popups/User/addsuspendedcomments/addsuspendedcomments.component';
import { SuspendeddetailsComponent } from 'src/app/Popups/User/suspendeddetails/suspendeddetails.component';
import { QuillModule } from 'ngx-quill';
import {ScormPopupComponent} from "src/app/Popups/scorm-popup/scorm-popup.component";
import {FormsenrollmentfilterComponent} from "src/app/Popups/assignablecontent/formsenrollmentfilter/formsenrollmentfilter.component"
import { ResetfailedcoursefilterComponent } from 'src/app/Popups/adminHome/resetfailedcoursefilter/resetfailedcoursefilter.component';
import { ManagenotificationsfilterComponent } from 'src/app/Popups/Setting/managenotificationsfilter/managenotificationsfilter.component'
import {JobpositionfilterComponent} from 'src/app/Popups/Task/jobpositionfilter/jobpositionfilter.component';
import {ArchivedVersionFilterComponent} from 'src/app/Popups/assignablecontent/archived-version-filter/archived-version-filter.component';
import {ProctorResetFilterComponent} from 'src/app/Popups/Proctor/proctor-reset-filter/proctor-reset-filter.component';
import {RecordsFilterComponent} from 'src/app/Popups/Learner/recordsFilter/records-filter.component';

@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
    DialogpopupComponent,
    UserTableFilterComponent,
    GrouplistFilterComponent,
    CompanylistFilterComponent,
    ResetPasswordComponent,
    ReqlicenseComponent,
    ChangemanagerComponent,
    AddremoveusersComponent,
    AssignedcompanylistComponent,
    ManagerFilterComponent,
    SuspendeduserComponent,
    AddsuspendedcommentsComponent,
    SuspendeddetailsComponent,
    AddusertogroupComponent,
    GroupstatslistfilterComponent,
    LicencependinglistfilterComponent,
    LicenceapprovallistfilterComponent,
    ViewcommentComponent,
    AddcommentComponent,
    ExternaltrainingfilterComponent,
    ViewgroupusersfilterComponent,
     AssignwithdrawComponent,
     AssignWithdrawcurriculamComponent,
     AssignWithdrawTitleComponent,
     AddUserForCurriculumComponent,
    ViewcoursecommentComponent,
    AssignablecontentlistfilterComponent,
    EnrollmentlistfilterComponent,
    EnrollCourseFilterComponent,
    AssignmentFilterComponent,
    AssignedlistfilterComponent,
    FormslistfilterComponent,
    IsntransferFilterComponent,
    LogsComponent,
    CataloglistfilterComponent,
    AssignmentslaunchComponent,
    CertificateComponent,
    ScaleratingComponent,
    CurriculafilterComponent,
    AnoouncementlistfilterComponent,
    TasklistfilterComponent,
    ApplyRulesComponent,
    CompletionRecordComponent,
    CreatefromtemplateComponent,
    AssignremovecourseComponent,
    AddremovecoursefilterComponent,
    TranscriptlistFilterComponent,
    RecordsFilterComponent,
    AcknowledgementComponent,
    SequenceComponent,
    TasklistnamefilterComponent,
    AddremovejobpositionComponent,
    TermsconditionspopupComponent,
    TermsandconditionlistfilterComponent,
    AssignmentfilterComponent,
    AddtaskfilterComponent,
    EvaluatorfilterComponent,
    AddremoveevaluatorsComponent,
    TaskfilterComponent,
    UploadimageComponent,
    TasklistviewfilterComponent,
    ViewevaluatorfilterComponent,
    ImportcontentComponent,
    AddremovetasknamelistfilterComponent,
    AddrequiremnetsComponent,
    EquivalentComponent,
    SettingsComponent,
    ArchivedformsettingComponent,
    ArchivedformstructureComponent,
    StructureComponent,
    ProctorfilterComponent,
    AssignedUserFilterComponent,
    ManagepermissionfilterComponent,
    AddorremovetaskfilterComponent,
    ExamsFilterComponent,
    ExamsenrollmentsfilterComponent,
    QuestionlistfilterComponent,
    AddremovequestionsComponent,
    AddremoveusertoexamComponent,
    ExampoolfilterComponent,
    QuestionpoolfilterComponent,
    ShareComponent,
    ReportscheduleComponent,
    NonscormcontentdisplayComponent,
    EvaluationresultComponent,
    CameraComponent,
    AwardcompletionComponent,
    ScormPopupComponent,
    FormsenrollmentfilterComponent,
    ResetfailedcoursefilterComponent,
    ManagenotificationsfilterComponent,
    JobpositionfilterComponent,
    ArchivedVersionFilterComponent,
    ProctorResetFilterComponent
    ],
  exports :[
    SidebarComponent,
    HeaderComponent,
    DialogpopupComponent,
    UserTableFilterComponent,
    GrouplistFilterComponent,
    CompanylistFilterComponent,
    ResetPasswordComponent,
    ReqlicenseComponent,
    ChangemanagerComponent,
    AddremoveusersComponent,
    AssignedcompanylistComponent,
    ManagerFilterComponent,
    SuspendeduserComponent,
    AddsuspendedcommentsComponent,
    SuspendeddetailsComponent,
    AddusertogroupComponent,
    LicencependinglistfilterComponent,
    LicenceapprovallistfilterComponent,
    ViewcommentComponent,
    AddcommentComponent,
    AssignwithdrawComponent,
    AssignWithdrawcurriculamComponent,
    AssignWithdrawTitleComponent,
    
    AddUserForCurriculumComponent,
    EnrollCourseFilterComponent,
    AddcommentComponent,
    ViewcoursecommentComponent,
    EnrollmentlistfilterComponent,
    AssignmentFilterComponent,
    FormslistfilterComponent,
    AssignmentslaunchComponent,
    CertificateComponent,
    ScaleratingComponent,
    AnoouncementlistfilterComponent,
    TasklistfilterComponent,
    ApplyRulesComponent,
    CompletionRecordComponent,
    AcknowledgementComponent,
    SequenceComponent,
    TasklistnamefilterComponent,
    AddremovejobpositionComponent,
    TermsconditionspopupComponent,
    TermsandconditionlistfilterComponent,
    AddtaskfilterComponent,
    AssignmentfilterComponent,
    EvaluatorfilterComponent,
    AddremoveevaluatorsComponent,
    UploadimageComponent,
    TasklistviewfilterComponent,
    ViewevaluatorfilterComponent,
    ImportcontentComponent,
    AddremovetasknamelistfilterComponent,
    SettingsComponent,
    ArchivedformsettingComponent,
    ArchivedformstructureComponent,
    StructureComponent,
    ManagepermissionfilterComponent,
    ExamsFilterComponent,
    ExamsenrollmentsfilterComponent,
    QuestionlistfilterComponent,
    AddremovequestionsComponent,
    AddremoveusertoexamComponent,
    ExampoolfilterComponent,
    QuestionpoolfilterComponent,
    ShareComponent,
    ReportscheduleComponent,
    NonscormcontentdisplayComponent,
    EvaluationresultComponent,
    CameraComponent,
    AwardcompletionComponent,
    ScormPopupComponent,
    FormsenrollmentfilterComponent,
    ResetfailedcoursefilterComponent,
    ManagenotificationsfilterComponent,
    JobpositionfilterComponent,
    ArchivedVersionFilterComponent,
    ProctorResetFilterComponent
   ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModuleModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    PdfViewerModule,
    QuillModule.forRoot() ,
  ],
  providers: [HeaderComponent]
})
export class SharedmoduleModule { }
