import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ApiServiceService} from './Services/api-service.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {HttpConfigInterceptor} from './Services/htttp.interceptor';
import {MatDialogModule} from '@angular/material/dialog';
import {GlobalErrorHandler} from './Services/global-error-handler';
import { AuthGuard } from './shared/guards/auth.guard';
import { LayoutModule } from './layout/layout.module';
import { ImageCropperModule } from 'ngx-image-cropper';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    LayoutModule,
    ImageCropperModule
  ],
  exports:[MatDialogModule],
  providers: [ApiServiceService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    AuthGuard
 ],
  bootstrap: [AppComponent]
})
export class AppModule { }
