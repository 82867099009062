
<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>


  <div class="row mainrow" >
    <div class="col-12 maincol" style="text-align: center;">
      <div class="labelname">Scorm has been closed</div>

    </div>
    </div>