import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-jobpositionfilter',
  templateUrl: './jobpositionfilter.component.html',
  styleUrls: ['./jobpositionfilter.component.scss']
})
export class JobpositionfilterComponent implements OnInit {
  filterform: FormGroup;
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton: any;
  isDisabled: boolean;
  statuslist: any;

  constructor(private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>) {
    this.filterform = this.formBuilder.group({
      JobPosition: [''],
      status: ['']
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
  }

  apicallgetdefaultdropdown() {
    this.statuslist = [
      { "status_id": 1, "statusName": "Active" },
      { "status_id": 2, "statusName": "Inactive" }
    ]
    this.setdefaultdropdownvalues();
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('Manager_filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton = true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton = false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['JobPosition'].setValue(data["Job Position"]);
      this.filterform.controls['status'].setValue(data["status"]);
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('Manager_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if (clearfilteritemkey[i] === "Job Position") {
          this.filterform.controls['JobPosition'].setValue("");
        }
        else if (clearfilteritemkey[i] === "status") {
          this.filterform.controls['status'].setValue("");
        }
        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }
  inputchange0(value) {
    this.dynamicEnableDisablebtn()
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('Manager_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let Manager_filterdata = this.getbodycontent();
      localStorage.setItem('Manager_filterdata', JSON.stringify(Manager_filterdata));
      localStorage.removeItem('Manager_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  EnterSubmit(event) {
    if (event.keyCode === 13) {
      this.dynamicEnableDisablebtn();
      if (this.disablebutton === true) {
        event.preventDefault();
      }
      else {
        event.preventDefault();
        this.Filter();
      }

    }
  }

  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('Manager_filterdata');
      localStorage.removeItem('Manager_chipdisplayfilterdata');
      localStorage.removeItem('Manager_clearfilteritemkey');
      let Manager_filterdata = this.getbodycontent();
      localStorage.setItem('Manager_filterdata', JSON.stringify(Manager_filterdata));
      this.dialog.close({ data: Manager_filterdata, groupapibodycontent: this.groupapibodycontent, method: 'filter' });
    }

  }

  getbodycontent() {
    let Manager_filterdata = {
      "Job Position": this.filterform.value.JobPosition.trim(),
      "status": this.filterform.value.status.trim(),
    }
    this.getapibodycontent();
    return Manager_filterdata

  }

  getapibodycontent() {
    let compId = localStorage.getItem('procCompId');
    let cacheddata = localStorage.getItem('role');
    let companyId;
    if (cacheddata === "System Administrator") {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if (globalcompanyIDselected == '' || globalcompanyIDselected == undefined || globalcompanyIDselected == null) {
        companyId = ""
      }
      else {

        companyId = globalcompanyIDselected

      }
    }
    else {
      companyId = compId

    }
    this.groupapibodycontent = {
      "position": this.filterform.value.JobPosition.trim(),
      "jobDescription": "",
      "companyId": companyId,
      "noOfUsers": "",
      "status": this.filterform.value.status.trim(),
    }
  }

  checkformvalue() {
    let formempty;
    if (this.filterform.value.JobPosition.trim() === "" && this.filterform.value.status.trim() === "") {
      formempty = true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }

  selectStatus(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['status'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      this.dynamicEnableDisablebtn()

    }
  }
}
