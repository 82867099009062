

<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>
<br>
  <div class="acknowledgement">
    <div class="row title">
      {{heading}}
    </div>
 <hr>
 <div class="content">
   <div class="subtitle">
     Posted By - {{postedby}} on {{posteddate  | date: 'yyyy-MM-dd'}}
   </div>
 <div class="content-text scroll-content" id="description">
 
 </div>
 <div class="confirmbtn-cls">
     <button *ngIf="isAcknowledge == true" class="confirmbtn" type="submit" (click)="confirm()">
      CONFIRM
  </button>
  <button *ngIf="isAcknowledge == false" class="confirmbtn" type="submit" (click)="confirm()">
    CLOSE
</button>
 </div>
 <div *ngIf="acknowledgement_list.length>1" class="footer">
{{itemNumber+1}} out of {{acknowledgement_list.length}}
 </div>
</div>
  </div>




