import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';

@Component({
  selector: 'app-archived-version-filter',
  templateUrl: './archived-version-filter.component.html',
  styleUrls: ['./archived-version-filter.component.scss']
})
export class ArchivedVersionFilterComponent implements OnInit {
  filterform: FormGroup;
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton: any;
  isDisabled: boolean;

  constructor(private formBuilder: FormBuilder, public dialog: MatDialogRef<UserComponent>) { 
    this.filterform = this.formBuilder.group({
      FormName: [''],
      FormID: ['']
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
  }
  apicallgetdefaultdropdown() {
    this.setdefaultdropdownvalues();
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('groupfilterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton = true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton = false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['FormName'].setValue(data["Form Name"]);
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if (clearfilteritemkey[i] === "Form Name") {
          this.filterform.controls['FormName'].setValue("");
        }
      
        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  inputchangeID1(value) {
    this.dynamicEnableDisablebtn()

  }

  inputchangeID2(value) {
    this.dynamicEnableDisablebtn()

  }
  
  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('group_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  EnterSubmit(event) {
    if (event.keyCode === 13) {
      //this.Filter();
      this.dynamicEnableDisablebtn();
      if (this.disablebutton === true) {
        event.preventDefault();
      }
      else {
        event.preventDefault();
        this.Filter();
      }

    }
  }

  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('groupfilterdata');
      localStorage.removeItem('group_chipdisplayfilterdata');
      localStorage.removeItem('group_clearfilteritemkey');
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      this.dialog.close({ data: groupfilterdata, groupapibodycontent: this.groupapibodycontent, method: 'filter' });
    }

  }

  getbodycontent() {
    let groupfilterdata = {
      "Form Name": this.filterform.value.FormName.trim(),
      // "FormID": this.filterform.value.FormID.trim(),
    }
    this.getapibodycontent();
    return groupfilterdata

  }

  getapibodycontent() {
    let formId = localStorage.getItem("formId");
    this.groupapibodycontent = {
      "title": this.filterform.value.FormName.trim(),
      "formId": formId,
    }
  }

  checkformvalue() {
    let formempty;
    if (this.filterform.value.FormName.trim() === "") {
      formempty = true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }


}
