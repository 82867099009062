import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { ResetfailedcourseComponent } from 'src/app/Administrator/home/Resetfailedcourse/resetfailedcourse.component';

@Component({
  selector: 'app-resetfailedcoursefilter',
  templateUrl: './resetfailedcoursefilter.component.html',
  styleUrls: ['./resetfailedcoursefilter.component.scss']
})

export class ResetfailedcoursefilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  groupslist: any = [];
  statuslist: any = [""];
  statuslist2: any = [""];
  loadspinner: any = false;
  apibodycontent: any;
  disablebutton: any;
  courseId: any;
  isDisabled: boolean;
  compId: any = '';
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<ResetfailedcourseComponent>) {

    this.filterform = this.formBuilder.group({
      Name: [''],
      EmpID: [''],
      CourseID: [''],
      AutoReset: ['']
    });
    this.apicallgetdefaultdropdown();

  }

  ngOnInit(): void {
  }
  apicallgetdefaultdropdown() {
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    this.apiService.Simpleget(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.companylistdata = response["companies"];

        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
          

        });
  }
  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton = true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton = false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['Name'].setValue(data["User Name"]);
      this.filterform.controls['EmpID'].setValue(data["Employee ID"]);
      this.filterform.controls['CourseID'].setValue(data["Course ID"]);
      this.filterform.controls['AutoReset'].setValue(data["Auto Reset"]);
      this.clearfilterfunction();
    }

  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if (clearfilteritemkey[i] === "User Name") {
          this.filterform.controls['Name'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Employee ID") {
          this.filterform.controls['EmpID'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Course ID") {
          this.filterform.controls['CourseID'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Auto Reset") {
          this.filterform.controls['AutoReset'].setValue("");
        }

        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }



  selectautoreset(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['AutoReset'].setValue("");
      this.dynamicEnableDisablebtn();
    }
    else {
      this.disablebutton = false;
    }
  }

  // on keyup enable filter button
  inputchangeCourse(value) {
    this.dynamicEnableDisablebtn();
  }

  inputchangeEmpID(value) {
    this.dynamicEnableDisablebtn();
  }
  inputchangeName(value) {
    this.dynamicEnableDisablebtn();
  }


  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
    let element = document.getElementById("Resetcourse-content")
    element.classList.remove('blur-content');
  }
  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('filterdata');
      localStorage.removeItem('chipdisplayfilterdata');
      localStorage.removeItem('clearfilteritemkey');
      let filterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
    }

  }

  EnterSubmit(event) {
    if (event.keyCode === 13) {
      this.dynamicEnableDisablebtn();
      if (this.disablebutton === true) {
        event.preventDefault();
      }
      else {
        event.preventDefault();
        this.Filter();
      }

    }
  }

  getbodycontent() {
    let filterdata = {
      "User Name": this.filterform.value.Name.trim(),
      "Employee ID": this.filterform.value.EmpID.trim(),
      "Course ID": this.filterform.value.CourseID.trim(),
      "Auto Reset": this.filterform.value.AutoReset
    }
    this.getapibodycontent();
    return filterdata

  }

  getapibodycontent() {
    this.compId = localStorage.getItem('procCompId');
    let cacheddata = localStorage.getItem('role');
    let body: any = '';
    if (cacheddata === "System Administrator") {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if (globalcompanyIDselected == '' || globalcompanyIDselected == undefined || globalcompanyIDselected == null) {
        this.apibodycontent = {
          "displayName": this.filterform.value.Name.trim(),
          "empId": this.filterform.value.EmpID.trim(),
          "requirementId": this.filterform.value.CourseID.trim(),
          "autoReset": this.filterform.value.AutoReset,
          "companyId": ""
        }
      }
      else {
        this.apibodycontent = {
          "displayName": this.filterform.value.Name.trim(),
          "empId": this.filterform.value.EmpID.trim(),
          "requirementId": this.filterform.value.CourseID.trim(),
          "autoReset": this.filterform.value.AutoReset,
          "companyId": globalcompanyIDselected
        }
      }

    }
    else {
      this.apibodycontent = {
        "displayName": this.filterform.value.Name.trim(),
        "empId": this.filterform.value.EmpID.trim(),
        "requirementId": this.filterform.value.CourseID.trim(),
        "autoReset": this.filterform.value.AutoReset,
        "companyId": this.compId
      }
    }

  }

  checkformvalue() {
    let formempty;
    if (this.filterform.value.Name.trim() === "" && this.filterform.value.EmpID.trim() === "" &&
      this.filterform.value.CourseID.trim() === "" && this.filterform.value.AutoReset.trim() === "") {
      formempty = true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }

  dynamicEnableDisablebtnDrodown(value, formcontrolname) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls[formcontrolname].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      this.disablebutton = false;
    }
  }

}
