import { Component, EventEmitter, OnInit, Inject, Output, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from '../../Services/api-service.service';
import { HttpClient } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { Router } from '@angular/router';
import { SidemenudataserviceService } from 'src/app/Services/sidemenudataservice.service';
import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform
} from 'ngx-image-cropper'
import { CommondataserviceService } from 'src/app/Services/commondataservice.service';
import { VieworedituserComponent } from 'src/app/Administrator/user/vieworedituser/vieworedituser.component';

@Component({
  selector: 'app-dialog-popup',
  templateUrl: './dialog-popup.component.html',
  styleUrls: ['./dialog-popup.component.scss']
})
export class DialogpopupComponent implements OnInit {
  from: any = '';

  //delete popup
  title: any = '';
  subtitle: any = '';
  //delete popup

  //import popup
  sel_option = false;
  loadspinner = false;
  fileName: any = '';
  importformat = false;
  file: any;
  metadata: any;
  importID: any;
  //import popup

  //import success
  import_response: any = [];
  filename: any = [];
  //import success

  //success popup
  successmsg: any;
  successmsgbody: any;
  imagelogo: any;
  //success popup

  //upload image
  file_data: any = '';
  imageChangedEvent: any = '';
  croppedImage: any = '';
  image_type: any = '';
  imageURL: any = '';

  value: number = 1;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  cropperStaticWidth = 165;
  cropperStaticHeight = 165;
  uploadfilename: any;
  filenamenull: any;
  filetype: any;
  filetypecsv: boolean;
  content: any = '';
  headingimage: any;
  button1: any;
  button2: any;
  //upload image
  importscreenname:any

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public router: Router, public dialog: MatDialogRef<UserComponent>, public dialog1: MatDialogRef<VieworedituserComponent>, public dialogopen: MatDialog, public _Service: ApiServiceService, private httpClient: HttpClient, private sidemenuservice: SidemenudataserviceService, private commonservice: CommondataserviceService) {
    this.from = data['from'];
    if (this.from === 'DynamicPopup') {
      this.title = data['title'];
      this.subtitle = data['subtitle'];
      this.headingimage = data['headingimage'];
      this.button1 = data["button1"];
      this.button2 = data["button2"]
    }
    else if (this.from === 'DynamicPopup_singlebtn') {
      this.title = data['title'];
      this.subtitle = data['subtitle'];
      this.headingimage=data['headingimage'];
      this.button1=data["button1"];

    }
    else if (this.from == 'delete' || this.from == 'delete-group' || this.from == 'impersonate') {
      this.title = data['title'];
      this.subtitle = data['subtitle'];
    } else if (this.from == 'import-success') {
      this.import_response = data;
      let currTimestamp = Date.now() //1482905176396
      this.filename = (new Date(currTimestamp)).toUTCString();
    } else if (this.from == 'popup-success' || this.from == 'addexternal-dialog' || this.from == 'userstatus-dialog' || this.from == 'cancel-dialog' || this.from == 'cancel-qrcode' || this.from == 'cancel-dialog-company' || this.from == 'cancel-dialog-Badges' || this.from == 'cancel-dialog-createtasklistname' || this.from == 'adduser-success' || this.from == 'manager-error-popup' ||  this.from == 'error-cancel-popup') {
      this.successmsg = data['successmsg'];
      this.successmsgbody = data['successmsgbody'];
      this.imagelogo = data['imagelogo'];
    } else if (this.from == 'popup-reset') {
      this.content = data['name'];

    } else if (this.from == 'error-popup') {

    }
    else if (this.from == 'upload-image') {
      this.convertfile(data);
    } else if (this.from == 'template-upload-logo') {
      this.imageChangedEvent = data['file'];
    } else if (this.from == 'image-size' || this.from == 'import-image-size') {
      this.title = data['title']
      this.subtitle = data['subtitle']
    } else if (this.from == 'confirm-popup' || this.from == 'other-confirm-popup' || this.from == 'archiveduser-popup' || this.from == 'confirm-warning-popup') {
      this.title = data['title'];
      this.subtitle = data['subtitle'];
      this.imagelogo = data['imagelogo'];
    } else if (this.from == 'edit-formdetails-popup') {
      this.title = data['title'];
      this.subtitle = data['subtitle'];
      this.imagelogo = data['imagelogo'];
    }
  }

  convertfile(data) {
    if (data['server'] != '') {
      this.image_type = 'url';
      this.imageURL = data['file'];
    } else {
      this.image_type = 'file'
      this.imageURL = data['file'];
    }
  }

  ngOnInit(): void {

  }

  close() {
    this.dialog.close({ method: 'close' })
  }

  closeDialog() {
    this.dialog.close();
    let element = document.getElementById("course-content") || document.getElementById("vieweditcurriculam1-content") || document.getElementById("vieweditcurriculam-content") || document.getElementById("assign-content")
    element.classList.remove('blur-content');
  }
  delete() {
    this.dialog.close({ method: 'delete' })
  }

  delete1() {
    this.dialog.close({ method: 'delete' })
    let element = document.getElementById("course-content") || document.getElementById("vieweditcurriculam1-content") || document.getElementById("vieweditcurriculam-content") || document.getElementById("assign-content")
    element.classList.remove('blur-content');
  }

  cancel() {
    this.dialog.close();
    let element = document.getElementById("user-content")
    element.classList.remove('blur-content');
  }

  update() {
    let value = this.sel_option;
    this.metadata = { "changePasswordFlag": false }
  }


  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileName = event.target.files[0].name;
      this.file = file;
      this.filetype = this.file.type;
      if (this.filetype != 'text/csv' && this.filetype != "application/vnd.ms-excel") {
        this.filetypecsv = false;
        this.importformat = false;
      }
      else {
        this.importformat = false;
        this.filetypecsv = true;
      }
    }
  }

  onimagecropselect(event) {
    if (event.target.files.length > 0) {
      this.image_type = 'file';
      this.imageURL = null;
      this.imageURL = event;
      this.scale = 1;
      this.updateSetting('');

    }
  }

  onSubmit() {
    if (this.fileName != '') {
      if (this.filetype == 'text/csv' || this.filetype == "application/vnd.ms-excel") {
        this.importformat = false;
        this.filetypecsv = true;
        this.loadspinner = true;
        this.importformat = false;
        let data = {
          changePasswordFlag: "false"
        };
        const token: string = localStorage.getItem('jwtToken');
        const loginId: string = localStorage.getItem('loginId');
        let cacheddata = localStorage.getItem('role');
        const formData = new FormData();
        formData.append('file', this.file);
        formData.append('roleName', cacheddata);

        let url: any;
        if (this.from == 'import') {
          url = this._Service.generatePortability + "/users/importcsvfile/" + loginId;
        }
        else if (this.from == 'importforms') {
          url = this._Service.formlistimport + "/pef/importcsvfile/" + loginId;
        }

        else if (this.from == 'importtask') {
          url = this._Service.tasklistnameexporturl + "/task/importcsvfile/" + loginId;
        }

        else if (this.from == 'importrecords') {
          url = this._Service.externaltrainingurl + "/training/importcsvfile/" + loginId;
        }
        let apiurl = url;
        this.httpClient.post<any>(apiurl, formData, {
        }).subscribe(
          (res) => {
            this.loadspinner = false;
            this.dialogopen.closeAll();
            if (res.fileName != null || res.fileName != undefined) {
              localStorage.setItem("filenamenull", 'false');
              localStorage.setItem("filename", res.fileName);
            }
            else {
              localStorage.setItem("filenamenull", 'true');
              localStorage.setItem("filename", res.fileName);
            }
            localStorage.setItem("pagefrom", this.from)
            this.successdialog(res);
          },
          (err) => {
            this.loadspinner = false;

          }
        );
      }
      else {
        this.filetypecsv = false;
      }
    } else {
      this.importformat = true;
      this.filetypecsv = false;
    }
  }

  successdialog(data) {
    const dialogRef = this.dialogopen.open(DialogpopupComponent, {
      width: '769px',
      disableClose: true,
      data: {
        data: data,
        disableClose: true,
        from: 'import-success'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      let element = document.getElementById("user-content")
      element.classList.remove('blur-content');
    });
  }

  //success download
  download() {
    let endpoint1: any;
    this.filenamenull = localStorage.getItem("filenamenull");
    let pagefrom = localStorage.getItem("pagefrom");
    let filename = localStorage.getItem("filename");
    this.importscreenname=pagefrom;
    if (filename === 'null' || filename == undefined || filename == null) {
    }
    else {
      if (pagefrom === "import") {
        endpoint1 = 'users/getfile/' + filename;
        this.loadspinner = true;
        this._Service.downloadimportuser(endpoint1, 'GET')
          .subscribe((response: any) => {

            let path: any;
            this.loadspinner = false;
            if (response != '') {
              path = 'https://' + response.download_logs_path;
              const link = document.createElement('a');
              link.setAttribute('href', path);
              link.setAttribute('download', `MEAImportlogs.log`);
              document.body.appendChild(link);
              link.click();
              link.remove();

            }
            else if (response == "") {
            }
            this.dialogopen.closeAll()
          }, (err) => {
            this.loadspinner = false;


          });

      }
      else if (pagefrom === "importforms") {
        endpoint1 = 'pef/getfile/' + filename;
        this.loadspinner = true;
        this._Service.downloadimportforms(endpoint1, 'GET')
          .subscribe((response: any) => {
            let path: any;
            this.loadspinner = false;
            if (response != '') {
              path = 'https://' + response.download_logs_path;
              const link = document.createElement('a');
              link.setAttribute('href', path);
              link.setAttribute('download', `MEAImportlogs.log`);
              document.body.appendChild(link);
              link.click();
              link.remove();

            }
            else if (response == "") {
            }
            this.dialogopen.closeAll()
          }, (err) => {
            this.loadspinner = false;


          });
      }

      else if (pagefrom === "importtask") {
        endpoint1 = 'task/getfile/' + filename;
        this.loadspinner = true;
        this._Service.downloadimporttask(endpoint1, 'GET')
          .subscribe((response: any) => {
            let path: any;
            this.loadspinner = false;
            if (response != '') {
              path = 'https://' + response.download_logs_path;
              const link = document.createElement('a');
              link.setAttribute('href', path);
              link.setAttribute('download', `MEAImportlogs.log`);
              document.body.appendChild(link);
              link.click();
              link.remove();
            }
            else if (response == "") {
            }
            this.dialogopen.closeAll()
          }, (err) => {
            this.loadspinner = false;


          });
      }

      else if (pagefrom === "importrecords") {
        endpoint1 = 'training/getfile/' + filename;
        this.loadspinner = true;
        this._Service.downloadimportexternaltraining(endpoint1, 'GET')
          .subscribe((response: any) => {
            let path: any;
            this.loadspinner = false;
            if (response != '') {
              path = 'https://' + response.download_logs_path;
              const link = document.createElement('a');
              link.setAttribute('href', path);
              link.setAttribute('download', `MEAImportlogs.log`);
              document.body.appendChild(link);
              link.click();
              link.remove();
            }
            else if (response == "") {
            }
            this.dialogopen.closeAll()
          }, (err) => {
            this.loadspinner = false;

          });
      }


    }
  }

  DownloadCSVtemplate(pagefrom) {
    let path: any;
    if (pagefrom === "importrecords") {
      path = this._Service.extnltrningdnldcsvtemplate
      const link = document.createElement('a');
      link.setAttribute('href', path);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    else if (pagefrom === "import") {
      path = this._Service.usersdnldcsvtemplate
      const link = document.createElement('a');
      link.setAttribute('href', path);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    else if (pagefrom === "importforms") {
      path = this._Service.formsdnldcsvtemplate
      const link = document.createElement('a');
      link.setAttribute('href', path);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    else if (pagefrom === "importtask") {
      path = this._Service.Tasksdnldcsvtemplate
      const link = document.createElement('a');
      link.setAttribute('href', path);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  DownloadCSVinstruction(pagefrom) {
    let path: any;
    if (pagefrom === "importrecords") {
      path = this._Service.extnltrningdnldcsvinstruction
      FileSaver.saveAs(path, 'ExternalTrainingCsvFormatInstructions');
    }
    else if (pagefrom === "import") {
      path = this._Service.usersdnldcsvinstruction
      FileSaver.saveAs(path, 'UserDetailsCsvFormatInstructions');
    }
    else if (pagefrom === "importforms") {
      path = this._Service.formsdnldcsvinstruction
      FileSaver.saveAs(path, 'FormDetailsCsvFormatInstructions');
    }
    else if (pagefrom === "importtask") {
      path = this._Service.Tasksdnldcsvinstruction
      FileSaver.saveAs(path, 'TaskDetailsCsvFormatInstructions');
    }
  }

  //success import popup
  Okbtn() {
    this.dialog.close();
    if (this.router.url === "/SetPassword" || this.router.url === "/ForgotPassword") {
      this.router.navigate(['/login'])
    }
    let element = document.getElementById("vieweditcurriculam-content") || document.getElementById("user-content") || document.getElementById("course-content") || document.getElementById("vieweditcurriculam1-content")
    element.classList.remove('blur-content');
  }

  //error button close
  errorclose() {
    this.dialog.close();
    this.dialog1.close('continue');
    let element = document.getElementById("user-content") || document.getElementById("CreateCompany-content") || document.getElementById("course-content")

    if (this.data['name']['status'] === 'Your session is being timed out due to inactivity. Please re-login to access the system') {
      this.router.navigate(['login'])
    }

    if (this.data['name']['status'] === 'An instance of this site is already running. The Site cannot be opened') {
      this.router.navigate(['login'])
    }
    element.classList.remove('blur-content');
  }

  errorcancel() {
    this.dialog.close();
    this.dialog1.close('cancel');
    let element = document.getElementById("user-content") || document.getElementById("CreateCompany-content") || document.getElementById("course-content")
    element.classList.remove('blur-content');
  }
  //error button close

  //cancel -dialog popup
  no() {
    this.dialog.close();
  }

  archivedYes() {
    this.dialog.close('yes');
  }

  yes() {
    this.dialog.close({ method: 'yes' });
    if (this.from == 'cancel-dialog-company') {
      this.router.navigate(['/CompanyManagement']);
    } else if (this.from == 'cancel-dialog-Badges') {

    }
    else if (this.from == 'cancel-dialog-createtasklistname') {
      this.router.navigate(['/Tasks/viewtask'], { queryParams: { menuTitle: 'Tasks' } });
    }
    else if (this.from == 'cancel-qrcode') {
      this.dialog.close('yes');
    } else if (this.from == 'addexternal-dialog') {
      this.dialog.close('yes');
    } else if (this.from == 'userstatus-dialog') {
      this.dialog.close('yes');
    }
    else {
      this.router.navigate(['/user'], { queryParams: { menuTitle: 'Users' } });
    }
  }
  //cancel -dialog popup

  //terms-popup
  close_terms() {
    this.dialog.close();
  }

  //support icon
  link() {
    window.open('https://www.meaenergy.org/services/energyu/energyu-portal/support/', '_blank')
  }


  //upload image
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  save_image() {
    this.uploadAttachmentToServer(this.croppedImage)
  }

  dataURItoBlob(dataURI): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  uploadAttachmentToServer(data) {
    const fileToUpload: File = new File([this.dataURItoBlob(data)], 'filename.jpeg', { type: "image/jpeg" });
    if (fileToUpload.size > 1048576) {
      this.dialog.close();
      this.open_modal('Image size is too large', 'Maximum size allowed is 1 MB');
    } else {
      this.dialog.close({ method: 'upload', file: fileToUpload, crop_image: this.croppedImage })
    }

  }
  open_modal(title, subtitle) {
    const dialogRef = this.dialogopen.open(DialogpopupComponent, {
      width: '530px',
      height: '330px',
      disableClose: true,
      data: {
        from: 'image-size',
        title: title,
        subtitle: subtitle
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }


  cancel_image() {
    const dialogRef = this.dialogopen.open(DialogpopupComponent, {
      width: '530px',
      height: '330px',
      disableClose: true,
      data: {
        from: 'image-cancel'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  image_cancel_no() {
    if (this.from == 'audience-cancel') {
      this.dialog.close('no');
    } else {
      this.dialog.close();
    }
  }

  image_cancel_yes() {
    if (this.from == 'group-cancel') {

      localStorage.setItem("groupback-TabIndex", "1");
      this.dialog.close();

      this.router.navigate(['/user'], { queryParams: { menuTitle: 'Users' } });
    } else if (this.from == 'course-cancel') {

      this.dialog.close();
      this.router.navigate(['/AssignableContent'], { queryParams: { menuTitle: 'AssignableContent' } });
    } else if (this.from == 'exam-cancel') {
      this.commonservice.set_tabvalue(3, 'header');
      this.dialog.close();
      this.router.navigate(['ExamManagement'], { queryParams: { menuTitle: 'ExamManagement' } });
    } else if (this.from == 'exampool-cancel') {
      this.commonservice.set_tabvalue(2, 'header');
      this.dialog.close();
      this.router.navigate(['/ExamManagement'], { queryParams: { menuTitle: 'ExamManagement' } });
    } else if (this.from == 'questionpool-cancel') {
      this.dialog.close();
      this.router.navigate(['/ExamManagement'], { queryParams: { menuTitle: 'ExamManagement' } });
    } else if (this.from == 'managelogo-cancel') {
      this.dialog.close();
      this.router.navigate(['/Adminsetting'], { queryParams: { menuTitle: 'Settings' } });
    }
    else if (this.from == 'category-cancel') {
      localStorage.setItem("categoryback-TabIndex", "2");
      this.dialog.close();
      this.router.navigate(['/Announcements'], { queryParams: { menuTitle: 'Announcements' } });
    }
    else if (this.from == 'audience-cancel') {
      this.dialog.close('yes');
    } else if (this.from == 'settings-cancel') {
      this.dialog.close();
      this.router.navigate(['/AssignableContent/Editforms'], { queryParams: { menuTitle: 'AssignableContent' } });
    }
    else if (this.from == 'job-cancel') {

      this.dialog.close();
      this.router.navigate(['/Tasks'], { queryParams: { menuTitle: 'Tasks' } });
    } else if (this.from == 'task-cancel') {
      this.dialog.close();
      this.router.navigate(['/Tasks'], { queryParams: { menuTitle: 'Tasks' } });
    } else if (this.from == 'forms-cancel') {
      localStorage.setItem("formsback-TabIndex", "1");
      this.dialog.close();
      this.router.navigate(['/AssignableContent'], { queryParams: { menuTitle: 'Tasks' } });
    } else if (this.from == 'import-image-size') {
      this.dialog.close();
    } else if (this.from == 'image-size') {
      this.dialog.close();
    }
    else {
      this.dialogopen.closeAll()
    }
  }


  zoomOut() {
    if (this.scale >= 0.2) {
      this.scale -= 0.1;

      this.transform = {
        ...this.transform,
        scale: this.scale
      };
    }
  }

  zoomIn() {
    if (this.scale < 10) {
      this.scale += 0.1;
      this.transform = {
        ...this.transform,
        scale: this.scale
      };
    }
  }

  updateSetting(event) {
    if (event != '') {
      this.scale = event.value;
    }
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  okay_user() {
    this.dialogopen.closeAll();
  }

  impersonate() {
    localStorage.setItem('impersonatemode', 'true');
    let loginId = localStorage.getItem('loginId');
    localStorage.setItem('previousloginId', loginId);

    this.router.navigate(['/LearnerHome'], { queryParams: { menuTitle: 'Home' } }).then(() => {
      const currentRoute = this.router.url;
      this.router.navigate([currentRoute]);
    });
    document.querySelector("body").style.cssText = "--cval: not-allowed";
    document.querySelector("body").style.cssText = "--pval: none";
    document.querySelector("body").style.cssText = "--btnval: none";
    document.querySelector("body").style.setProperty("cursor", "not-allowed !important;");
    this.dialog.close();

    setTimeout(() => {
      window.location.reload();
    }, 1000);  }
}
