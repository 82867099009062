<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>

<div class="filtermaincontainer">
    <div class="row headerrow">
      <div class="col-10">
        <span class="heading">&nbsp;&nbsp;Add Notes</span>
      </div>
      <div class="col-2 cleariconcol">
        <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
      </div>
    </div>
  
    <form [formGroup]="suspendedform">
      <div class="row">

        <div class="col-12 col-md-12 inputcolumn">
          <mat-label class="labelname">Comments</mat-label>
          <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
              <textarea matInput class="textarea" formControlName="comment" readonly></textarea>
          </mat-form-field>
        </div>
    
        <div class="col-12 col-md-12 inputcolumn" style="margin-top: -2%;">
          <mat-label class="labelname">Additional Notes</mat-label>
          <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
              <textarea matInput class="textarea" formControlName="additionalcomment"></textarea>
          </mat-form-field>
  
        </div>

      </div>
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 cancel-btn-col">
          <button mat-button class="cancel-btn" (click)="cancelbtn()">
            <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
            Cancel
          </button>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol">
          <button mat-button id="btnfiltertermid" class="Filterbtn" (click)="submit()">
            <mat-icon class="filterbtnicon">done</mat-icon>
            Save
          </button>
        </div>
      </div>
    </form>
  
</div> 