<div class="spinner_overlay" *ngIf="loadspinner === true"></div>
<mat-spinner class="spinner" *ngIf="loadspinner === true"></mat-spinner>

<div class="filtermaincontainer">
  <div class="row headerrow">
    <div class="col-10">
      <span class="heading">&nbsp;&nbsp; {{ title }} </span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()"
        >clear</mat-icon
      >
    </div>
  </div>

  <form *ngIf="title == '2 Scale Rating'" [formGroup]="scaleform2">
    <div class="col-12">
      <div class="row label_space">
        <div class="col-3 subtitle">Label Code</div>
        <div class="col-4 subtitle">Label Title</div>
        <div class="col-4 subtitle">Passing Criteria</div>
        <div class="col-12">
          <img
            class="warning-cls"
            src="assets/images/information.svg"
            style="float: right; margin-top: -4%; width: 18px"
          />
          <span class="chooseimgtxt hide"
            >Select the ratings which will allow the steps to be passed</span
          >
        </div>

        <div class="col-3 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale2_labelcode1"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale2_labeltitle1"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space top_space">
          <mat-checkbox
            labelPosition="before"
            formControlName="scale2_criteria1"
            class="labelname create_select"
          >
            &#160;&#160;&#160;
          </mat-checkbox>
        </div>

        <div class="col-3 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale2_labelcode2"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale2_labeltitle2"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space top_space">
          <mat-checkbox
            labelPosition="before"
            formControlName="scale2_criteria2"
            class="labelname create_select pef_settings"
          >
            &#160;&#160;&#160;
          </mat-checkbox>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-6">
        <button
          mat-button
          class="cancelbtn"
          (click)="close('scaleform2')"
          style="float: right"
        >
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel
        </button>
      </div>

      <div class="col-6">
        <button mat-button class="Filterbtn" (click)="save('scaleform2')">
          <mat-icon class="filterbtnicon">done</mat-icon>
          Save
        </button>
      </div>
    </div>
  </form>

  <form *ngIf="title == '3 Scale Rating'" [formGroup]="scaleform3">
    <div class="col-12">
      <div class="row label_space">
        <div class="col-3 subtitle">Label Code</div>
        <div class="col-4 subtitle">Label Title</div>
        <div class="col-4 subtitle">Passing Criteria</div>
        <div class="col-12">
          <img
            class="warning-cls"
            src="assets/images/information.svg"
            style="float: right; margin-top: -4%; width: 18px"
          />
          <span class="chooseimgtxt hide"
            >Select the ratings which will allow the steps to be passed</span
          >
        </div>

        <div class="col-3 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale3_labelcode1"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale3_labeltitle1"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space top_space">
          <mat-checkbox
            labelPosition="before"
            formControlName="scale3_criteria1"
            class="labelname create_select"
          >
            &#160;&#160;&#160;
          </mat-checkbox>
        </div>

        <div class="col-3 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale3_labelcode2"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale3_labeltitle2"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space top_space">
          <mat-checkbox
            labelPosition="before"
            formControlName="scale3_criteria2"
            class="labelname create_select pef_settings"
          >
            &#160;&#160;&#160;
          </mat-checkbox>
        </div>

        <div class="col-3 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale3_labelcode3"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale3_labeltitle3"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space top_space">
          <mat-checkbox
            labelPosition="before"
            formControlName="scale3_criteria3"
            class="labelname create_select"
          >
            &#160;&#160;&#160;
          </mat-checkbox>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-6">
        <button
          mat-button
          class="cancelbtn"
          (click)="close('scaleform3')"
          style="float: right"
        >
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel
        </button>
      </div>

      <div class="col-6">
        <button mat-button class="Filterbtn" (click)="save('scaleform3')">
          <mat-icon class="filterbtnicon">done</mat-icon>
          Save
        </button>
      </div>
    </div>
  </form>

  <form *ngIf="title == '4 Scale Rating'" [formGroup]="scaleform4">
    <div class="col-12">
      <div class="row label_space">
        <div class="col-3 subtitle">Label Code</div>
        <div class="col-4 subtitle">Label Title</div>
        <div class="col-4 subtitle">Passing Criteria</div>
        <div class="col-12">
          <img
            class="warning-cls"
            src="assets/images/information.svg"
            style="float: right; margin-top: -4%; width: 18px"
          />
          <span class="chooseimgtxt hide"
            >Select the ratings which will allow the steps to be passed</span
          >
        </div>

        <div class="col-3 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale4_labelcode1"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale4_labeltitle1"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space top_space">
          <mat-checkbox
            labelPosition="before"
            formControlName="scale4_criteria1"
            class="labelname create_select"
          >
            &#160;&#160;&#160;
          </mat-checkbox>
        </div>

        <div class="col-3 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale4_labelcode2"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale4_labeltitle2"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space top_space">
          <mat-checkbox
            labelPosition="before"
            formControlName="scale4_criteria2"
            class="labelname create_select pef_settings"
          >
            &#160;&#160;&#160;
          </mat-checkbox>
        </div>

        <div class="col-3 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale4_labelcode3"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale4_labeltitle3"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space top_space">
          <mat-checkbox
            labelPosition="before"
            formControlName="scale4_criteria3"
            class="labelname create_select"
          >
            &#160;&#160;&#160;
          </mat-checkbox>
        </div>

        <div class="col-3 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale4_labelcode4"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale4_labeltitle4"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space top_space">
          <mat-checkbox
            labelPosition="before"
            formControlName="scale4_criteria4"
            class="labelname create_select"
          >
            &#160;&#160;&#160;
          </mat-checkbox>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-6">
        <button
          mat-button
          class="cancelbtn"
          (click)="close('scaleform4')"
          style="float: right"
        >
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel
        </button>
      </div>

      <div class="col-6">
        <button mat-button class="Filterbtn" (click)="save('scaleform4')">
          <mat-icon class="filterbtnicon">done</mat-icon>
          Save
        </button>
      </div>
    </div>
  </form>

  <form *ngIf="title == '5 Scale Rating'" [formGroup]="scaleform5">
    <div class="col-12">
      <div class="row label_space">
        <div class="col-3 subtitle">Label Code</div>
        <div class="col-4 subtitle">Label Title</div>
        <div class="col-4 subtitle">Passing Criteria</div>
        <div class="col-12">
          <img
            class="warning-cls"
            src="assets/images/information.svg"
            style="float: right; margin-top: -4%; width: 18px"
          />
          <span class="chooseimgtxt hide"
            >Select the ratings which will allow the steps to be passed</span
          >
        </div>

        <div class="col-3 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale5_labelcode1"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale5_labeltitle1"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space top_space">
          <mat-checkbox
            labelPosition="before"
            formControlName="scale5_criteria1"
            class="labelname create_select"
          >
            &#160;&#160;&#160;
          </mat-checkbox>
        </div>

        <div class="col-3 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale5_labelcode2"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale5_labeltitle2"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space top_space">
          <mat-checkbox
            labelPosition="before"
            formControlName="scale5_criteria2"
            class="labelname create_select pef_settings"
          >
            &#160;&#160;&#160;
          </mat-checkbox>
        </div>

        <div class="col-3 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale5_labelcode3"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale5_labeltitle3"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space top_space">
          <mat-checkbox
            labelPosition="before"
            formControlName="scale5_criteria3"
            class="labelname create_select"
          >
            &#160;&#160;&#160;
          </mat-checkbox>
        </div>

        <div class="col-3 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale5_labelcode4"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale5_labeltitle4"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space top_space">
          <mat-checkbox
            labelPosition="before"
            formControlName="scale5_criteria4"
            class="labelname create_select"
          >
            &#160;&#160;&#160;
          </mat-checkbox>
        </div>

        <div class="col-3 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale5_labelcode5"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space">
          <mat-form-field appearance="outline" class="formfieldusertable">
            <input
              matInput
              autocomplete="off"
              #inpgroupname
              class="textinputusertablefilter"
              formControlName="scale5_labeltitle5"
            />
          </mat-form-field>
        </div>

        <div class="col-4 input_space top_space">
          <mat-checkbox
            labelPosition="before"
            formControlName="scale5_criteria5"
            class="labelname create_select"
          >
            &#160;&#160;&#160;
          </mat-checkbox>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-6">
        <button
          mat-button
          class="cancelbtn"
          (click)="close('scaleform5')"
          style="float: right"
        >
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel
        </button>
      </div>

      <div class="col-6">
        <button mat-button class="Filterbtn" (click)="save('scaleform5')">
          <mat-icon class="filterbtnicon">done</mat-icon>
          Save
        </button>
      </div>
    </div>
  </form>
</div>
