<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>
<div class="applyrules rulesCls" id="applyRules-content" *ngIf="from=='apply'">
  <div class="row headerrow">
    <div class="col-10">
      <span class="heading">Apply Rules</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancel()">clear</mat-icon>
    </div>
  </div>
<div class="body-container">
  <div class="row" style="margin:0px 0px 40px">    
      <span class="reqName">{{requirementName}}</span>
  </div>
  <div class="row" style="margin:20px 0px;">
      <span class="Enable-Proctor">Enable Proctor</span>
       <mat-radio-group aria-label="Enable Proctor" (change)=change_enableproctor($event.value) [(ngModel)]="enableProctor">
        <mat-radio-button class="radioBtn" value="yes" >Yes</mat-radio-button>
        <mat-radio-button class="radioBtn" value="no" >No</mat-radio-button>
      </mat-radio-group>
  </div>

  <!-- <mat-card class="example-card mat-elevation-z8 matcard"> -->

    <mat-accordion>

        <ng-container>
          <mat-expansion-panel class="group" (opened)="panelOpenState = true" (closed)="panelOpenState = false" hideToggle="true" #xyz>
                <mat-expansion-panel-header>
                    <mat-panel-title class="mat-panel-title">
                        Set Prerequisites
                    </mat-panel-title>
                <mat-icon *ngIf="xyz.expanded" class="iconsize">arrow_drop_up</mat-icon>
                <mat-icon *ngIf="!xyz.expanded" class="iconsize">arrow_drop_down</mat-icon>
                </mat-expansion-panel-header>
          </mat-expansion-panel>
        
            <mat-accordion class="countries" *ngIf="panelOpenState" multi="true">
              <mat-card class="table-matcard audiencecontainer">
                  <div class="row user-box">
                  <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12 user-box-block">
                  <div class="list-header">Title List</div>
                  <div class="list-search" >
                      <mat-form-field appearance="outline" class="col-12 search-field" >
                      <input type="text" #searchinput1 id="sid1" matInput  placeholder="Search by title" autocomplete="off" (keydown)="title_search_left(searchinput1.value)" style="height:35px;color:#000">
                      <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                  </div>
                    <div class="list-scroll">
                      <div class="list-data" *ngIf="requirements_list.length>0">
                        <mat-checkbox [disabled]="isDisabled" [ngClass]="req_selectall==true?'checkboxbody ':'checkboxbody'" [(ngModel)]="req_selectall"  (change)="reqAll(ev)">&nbsp;&nbsp;Select All
                        </mat-checkbox>
                      </div>
                      <cdk-virtual-scroll-viewport itemSize="50" style="height:280px">
                  <div *cdkVirtualFor="let data of requirements_list" class="list-data">
                      <mat-checkbox [disabled]="isDisabled" [ngClass]="data.isSelected==true?'checkboxbody ':'checkboxbody'" [checked]="data.isSelected" (change)="title_selected_leftbox(data)"> &nbsp;&nbsp;{{data.requirementName}},{{data.type}}
                      </mat-checkbox>
                  </div>
                </cdk-virtual-scroll-viewport>

                  </div> 
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 btn-center">
                      <div class="inner-container">
                              <div class="row sub-row row-add">
                                  <button type="button" mat-button class="addbtn" (click)="add_titles()">
                                  Add
                                  <br>
                                  <mat-icon alt="clear">east</mat-icon>
                                  </button>
                              </div>
                              <div class="row sub-row">
                                  <button type="button" mat-button class="removebtn" (click)="remove_titles()">
                                      Remove <br>
                                  <mat-icon alt="clear">west</mat-icon>
                                  </button>
                              </div>
                      </div>
                  </div>
                  <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12 user-box-block">
                      <div class="list-header">Title Added</div>
                      <div class="list-search">
                      <mat-form-field appearance="outline" class="col-12 search-field">
                      <input type="text" id="sid2" #searchinput2 matInput  placeholder="Search" autocomplete="off" (keydown)="title_search_right(searchinput2.value)" style="height:35px;color:#000">
                      <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                      </div>
                      <div class="list-scroll">
                        <div class="list-data" *ngIf="selected_prerequisites.length>0">
                          <mat-checkbox [disabled]="isDisabled" [ngClass]="requirement_selectall_right==true?'checkboxbody ':'checkboxbody'" [(ngModel)]="requirement_selectall_right"  (change)="reqAll_right(ev)">&nbsp;&nbsp;Select All
                          </mat-checkbox>
                        </div>
                        <cdk-virtual-scroll-viewport itemSize="50" style="height:280px">
                      <div *cdkVirtualFor="let data of selected_prerequisites" class="list-data">
                          <mat-checkbox [disabled]="isDisabled" [ngClass]="data.isSelected==true?'checkboxbody checkbox-right ':'checkboxbody checkbox-right'"   [checked]="data.isSelected"  (change)="title_selected_rightbox(data)"> &nbsp;&nbsp;{{data.requirementName}},{{data.type}}
                          </mat-checkbox>
                          
                      </div>
                    </cdk-virtual-scroll-viewport>

                      </div>
                  </div>
                  </div>
              </mat-card>   
            </mat-accordion>
            
        </ng-container>

        <ng-container >
            <mat-expansion-panel
              class="group"
              (opened)="panelOpenStateAttempts = true"
              (closed)="panelOpenStateAttempts = false"
              hideToggle="true"
              #abc>
              <mat-expansion-panel-header>
                <mat-panel-title class="mat-panel-title">
                 Number of attempts
                </mat-panel-title>
                <mat-icon *ngIf="abc.expanded" class="iconsize">arrow_drop_up</mat-icon>
                <mat-icon *ngIf="!abc.expanded" class="iconsize">arrow_drop_down</mat-icon>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
           
            <mat-accordion class="countries" *ngIf="panelOpenStateAttempts"  multi="true">
             
              <mat-card class="table-matcard">
                <div class="row my-form-field marginleft">
                  <div class="col-lg-3 col-md-4 col-sm-6 noOfAttempt">Number of attempts allowed</div>
                  <div class="btn-left col-lg-9 col-md-8 col-sm-6" style="margin-top:10px;padding-left:15px;">
                    <input id="attemptnoid"  type=number min="0" class="exprInput" [(ngModel)]="attemptsno" style="width:60px;height:38px;margin-top:-5px !important" (keydown)="input_validate('attemptnoid', 'true', $event)" [disabled]="specificField_disable" (ngModelChange)="inputchange()">
                    
                      <img  (click)="increment()" width="25" height="25" src="assets/images/plus-button.svg" style="margin-left:20px">
                     <img (click)="decrement()"  width="25" height="25" src="assets/images/minus-button.svg" style="margin-left:20px">
                   </div>
                </div>
               
                  <div class="row my-form-field marginleft" *ngFor="let data of dynamic_attempt; let i=index">
                    <div class="noOfAttempt col-lg-3 col-md-4 col-sm-6 " *ngIf="i==0">{{i+1}}st Retake after </div>
                    <div class="noOfAttempt col-lg-3 col-md-4 col-sm-6 " *ngIf="i==1">{{i+1}}nd Retake after </div>
                    <div class="noOfAttempt col-lg-3 col-md-4 col-sm-6 " *ngIf="i==2">{{i+1}}rd Retake after </div>
                    <div class="noOfAttempt col-lg-3 col-md-4 col-sm-6 " *ngIf="i>2">{{i+1}}th Retake after </div>
                     <div class="col-lg-9 col-md-8 col-sm-6">
                      <!-- OQCS-4083 12-01-2024 -->
                      <input type=number class="exprInput" id={{i}} [(ngModel)]="data.retakeAfterVal" [disabled]="specificField_disable || data.retakeAfterUnit == 'N'" style="width:60px;height:38px" (keydown)="input_validate(i)">
                 
                    <mat-form-field appearance="outline"  class="form-field">
                      <mat-select matNativeControl [(ngModel)]="data.retakeAfterUnit"
                      placeholder="Select" [disabled]="specificField_disable" (selectionChange)="attemptsRetake($event,i)"  panelClass="myPanelmatselectClass" disableOptionCentering>
                      <mat-option  value="N" class="create_select">
                        None
                      </mat-option>
                      <!-- <mat-option value="Days" class="none_style">  Days </mat-option> -->
                      <mat-option *ngFor="let item of dropDownnoAttempt" [value]="item.id" class="create_select">
                      {{item.name}} 
                      </mat-option>
                      </mat-select>
                      </mat-form-field>
                    &nbsp;&nbsp;&nbsp; from Failed date
                    </div>
                </div>
           
                 
             </mat-card>  
          
            </mat-accordion>
            
        </ng-container>

        <ng-container >
            <mat-expansion-panel
              class="group"
              (opened)="panelOpenStateExpiration = true"
              (closed)="panelOpenStateExpiration = false"
              hideToggle="true"
              #abcd>
              <mat-expansion-panel-header>
                <mat-panel-title class="mat-panel-title">
                    Set Expiration
                </mat-panel-title>
                <mat-icon *ngIf="abcd.expanded" class="iconsize">arrow_drop_up</mat-icon>
                <mat-icon *ngIf="!abcd.expanded" class="iconsize">arrow_drop_down</mat-icon>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
           
            <mat-accordion class="countries" *ngIf="panelOpenStateExpiration"  multi="true">
             
              <mat-card class="table-matcard">
                <div class="row my-form-field marginleft">
                    <div class="noOfAttempt col-lg-3 col-md-4 col-sm-6 ">Set Expiration after </div>
                     <div class="col-lg-9 col-md-8 col-sm-6">
                      <input type=number min="0"  class="exprInput" id="expiration1Inputid" [(ngModel)]="expiration1Input" style="width:60px;height:38px" (keydown)="input_validate('expiration1Inputid')">
                 
                    <mat-form-field appearance="outline"  class="form-field">
                      <mat-select matNativeControl [(ngModel)]="expiration1"
                      placeholder="Select"  panelClass="myPanelmatselectClass" disableOptionCentering>
                      <mat-option  [value]="none" class="create_select">
                        None
                      </mat-option>
                      <mat-option *ngFor="let item of dropDown" [value]="item.id" class="create_select">
                      {{item.name}} 
                      </mat-option>
                      </mat-select>
                      </mat-form-field>
                      &nbsp;&nbsp;&nbsp; from Completion date
                    </div>
                </div>
           
                <div class="row marginleft" style="margin-bottom:15px;">

                  <div class="btn-left col-lg-3 col-md-4 col-sm-6">
                    
                    <span class="noOfAttempt">Grace Period</span>
                  </div>
                  <div class="col-lg-9 col-md-8 col-sm-6">
                    <mat-checkbox class="align audiencecontainer" [(ngModel)]="gracePeriod" >
                    </mat-checkbox>
                 </div>
              </div>

                <div class="row my-form-field marginleft" *ngIf="gracePeriod">
                  <div class="noOfAttempt col-lg-3 col-md-4 col-sm-6 "> </div>
                   <div class="col-lg-9 col-md-8 col-sm-6">
                    <mat-radio-group [(ngModel)]="sel_graceperiod1">
                    <mat-radio-button value="sel_graceperiod1" color="accent" class="marginleft"  (change)="change_graceperiod1()">
                    </mat-radio-button>
                  </mat-radio-group>
                  <mat-form-field appearance="outline"  class="form-field">
                    <mat-select matNativeControl [(ngModel)]="graceperiod1"
                    placeholder="Select"  panelClass="myPanelmatselectClass" disableOptionCentering>
                    <mat-option  [value]="none" class="create_select">
                      None
                    </mat-option>
                    <mat-option *ngFor="let item of dropDown1" [value]="item.id" class="create_select">
                    {{item.name}} 
                    </mat-option>
                    </mat-select>
                    </mat-form-field>
                
                  </div>
              </div>

                <div class="row my-form-field marginleft" *ngIf="gracePeriod">
                  <div class="noOfAttempt col-lg-3 col-md-4 col-sm-6 "></div>
                   <div class="col-lg-9 col-md-8 col-sm-6">
                    <mat-radio-group [(ngModel)]="sel_graceperiod2">
                    <mat-radio-button value="sel_graceperiod2" color="accent" class="marginleft"  (change)="change_graceperiod2()">
                    </mat-radio-button>
                  </mat-radio-group>
                  <input type=number  class="exprInput" id="graceperiod2inputid" [(ngModel)]="graceperiod2input" style="width:60px;height:38px" (keydown)="input_validate('graceperiod2inputid')">
               
                  <mat-form-field appearance="outline"  class="form-field">
                    <mat-select matNativeControl [(ngModel)]="graceperiod2"
                    placeholder="Select"  panelClass="myPanelmatselectClass" disableOptionCentering>
                    <mat-option  [value]="none" class="create_select">
                      None
                    </mat-option>
                    <mat-option *ngFor="let item of dropDown2" [value]="item.id" class="create_select">
                    {{item.name}} 
                    </mat-option>
                    </mat-select>
                    </mat-form-field>
                  
                  </div>
              </div>
              

                <div class="row my-form-field marginleft">
                  <div class="noOfAttempt col-lg-3 col-md-4 col-sm-6 ">Make available </div>
                   <div class="col-lg-9 col-md-8 col-sm-6">
                  <input type=number min="0" class="exprInput" id="graceperiod3inputid" [(ngModel)]="graceperiod3input" style="width:60px;height:38px" (keydown)="input_validate('graceperiod3inputid')">
               
                  <mat-form-field appearance="outline"  class="form-field">
                    <mat-select matNativeControl [(ngModel)]="graceperiod3"
                    placeholder="Select"  panelClass="myPanelmatselectClass" disableOptionCentering>
                    <!-- <mat-option  [value]="none" class="create_select">
                      None
                    </mat-option> -->
                    <mat-option *ngFor="let item of dropDown" [value]="item.id" class="create_select">
                    {{item.name}} 
                    </mat-option>
                    </mat-select>
                    </mat-form-field>
                    &nbsp;&nbsp;&nbsp; before Expiration date
                  </div>
              </div>
              
             </mat-card>  
          
            </mat-accordion>
            
        </ng-container>

    </mat-accordion>


    <div class="row btn-row">
        <div class="alignBottom">
          <button type="button" mat-button class="cancelbtn" (click)="cancelbtn()">
          <mat-icon alt="clear">clear</mat-icon>
          Cancel
          </button>
        
          <button mat-button class="createbtn" type="button" (click)="add_rule()">
            <mat-icon class="createbtnicon">done</mat-icon>
            Submit
          </button>
        </div>
    </div>

  <!-- </mat-card> -->
</div>
</div>

<div class="applyrules rulesCls" id="applyRules-content" *ngIf="from=='view'">
  <div class="row headerrow">
    <div class="col-10">
      <span class="heading">View Rules</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancel()">clear</mat-icon>
    </div>
  </div>
  <div class="body-container scrolldiv">
  <div class="row" style="margin:0px 0px 40px">    
      <span class="reqName">{{requirementName}}</span>
  </div>
  <div class="row" style="margin:20px 0px;">
      <span class="Enable-Proctor">Enable Proctor</span>
      <mat-radio-group aria-label="Enable Proctor" (change)=change_enableproctor($event.value) [(ngModel)]="enableProctor" [disabled]="isDisabled">
        <mat-radio-button class="radioBtn" value="yes" >Yes</mat-radio-button>
        <mat-radio-button class="radioBtn" value="no" >No</mat-radio-button>
      </mat-radio-group>
  </div>
 <mat-accordion>

        <ng-container>
          <mat-expansion-panel class="group" (opened)="panelOpenState = true" (closed)="panelOpenState = false" hideToggle="true" #xyz>
                <mat-expansion-panel-header>
                    <mat-panel-title class="mat-panel-title">
                        Set Prerequisites
                    </mat-panel-title>
                <mat-icon *ngIf="xyz.expanded" class="iconsize" >arrow_drop_up</mat-icon>
                <mat-icon *ngIf="!xyz.expanded" class="iconsize">arrow_drop_down</mat-icon>
                </mat-expansion-panel-header>
          </mat-expansion-panel>
        
            <mat-accordion class="countries" *ngIf="panelOpenState" multi="true">
              <mat-card class="table-matcard audiencecontainer">
                  <div class="row user-box">
                  <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12 user-box-block">
                  <div class="list-header">Title List</div>
                  <div class="list-search" >
                      <mat-form-field appearance="outline" class="col-12 search-field">
                      <input type="text" #searchinput1 id="sid1" matInput  placeholder="Search by title" autocomplete="off" style="height:35px;color:#000" [disabled]="isDisabled">
                      <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                  </div>
                 
                    <div class="list-scroll">
                  

                  </div> 
                  </div>
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 btn-center">
                      <div class="inner-container">
                      
                      </div>
                  </div>
                  <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12 user-box-block">
                      <div class="list-header">Title Added</div>
                      <div class="list-search">
                      <mat-form-field appearance="outline" class="col-12 search-field">
                      <input type="text" id="sid2" #searchinput2 matInput  placeholder="Search" autocomplete="off" style="width:60px;height:35px;color:#000" [disabled]="isDisabled">
                      <mat-icon matSuffix>search</mat-icon>
                      </mat-form-field>
                      </div>
                      <div class="list-scroll">
                     
                        <div *ngFor="let data of selected_prerequisites" class="list-data">
                          <mat-checkbox [disabled]="isDisabled" [ngClass]="data.isSelected==true?'checkboxbody checkbox-right ':'checkboxbody checkbox-right'"   checked="true"  > &nbsp;&nbsp;{{data.requirementName}}
                          </mat-checkbox>
                          
                      </div>

                      </div>
                  </div>
                  </div>
              </mat-card>  
            </mat-accordion>
            
        </ng-container>

        <ng-container >
            <mat-expansion-panel
              class="group"
              (opened)="panelOpenStateAttempts = true"
              (closed)="panelOpenStateAttempts = false"
              hideToggle="true"
              #abc>
              <mat-expansion-panel-header>
                <mat-panel-title class="mat-panel-title">
                 Number of attempts
                </mat-panel-title>
                <mat-icon *ngIf="abc.expanded" class="iconsize">arrow_drop_up</mat-icon>
                <mat-icon *ngIf="!abc.expanded" class="iconsize">arrow_drop_down</mat-icon>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
           
            <mat-accordion class="countries" *ngIf="panelOpenStateAttempts"  multi="true">
             
              <mat-card class="table-matcard">
                <div class="row my-form-field marginleft">
                  <div class="col-lg-3 col-md-4 col-sm-6 noOfAttempt">Number of attempts allowed</div>
                  <div class="btn-left col-lg-9 col-md-8 col-sm-6" style="margin-top:10px;padding-left:15px;">
                    
                    <input id=demoInput min="0" type=number  class="exprInput" [(ngModel)]="attemptsno" style="width:60px;height:38px;margin-top:-5px !important" [disabled]="isDisabled">
                  
                   </div>
                </div>
                
                
                <div class="row my-form-field marginleft" *ngFor="let data of dynamic_attempt; let i=index">
                  <div class="noOfAttempt col-lg-3 col-md-4 col-sm-6 " *ngIf="i==0">{{i+1}}st Retake after </div>
                  <div class="noOfAttempt col-lg-3 col-md-4 col-sm-6 " *ngIf="i==1">{{i+1}}nd Retake after </div>
                  <div class="noOfAttempt col-lg-3 col-md-4 col-sm-6 " *ngIf="i==2">{{i+1}}rd Retake after </div>
                  <div class="noOfAttempt col-lg-3 col-md-4 col-sm-6 " *ngIf="i>2">{{i+1}}th Retake after </div>
                   <div class="col-lg-9 col-md-8 col-sm-6">
                    
                    <input type=number class="exprInput" id={{i}} [(ngModel)]="data.retakeAfterVal" [disabled]="isDisabled" style="width:60px;height:38px" (keydown)="input_validate(i)">
               
                  <mat-form-field appearance="outline"  class="form-field">
                    <mat-select matNativeControl [(ngModel)]="data.retakeAfterUnit"
                    placeholder="Select" [disabled]="isDisabled"  panelClass="myPanelmatselectClass" disableOptionCentering>
                    <mat-option  value="N" class="create_select">
                      None
                    </mat-option>
                    <!-- <mat-option value="Days" class="none_style">  Days </mat-option> -->
                    <mat-option *ngFor="let item of dropDownnoAttempt" [value]="item.id" class="create_select">
                    {{item.name}} 
                    </mat-option>
                    </mat-select>
                    </mat-form-field>
                  &nbsp;&nbsp;&nbsp; from Failed date
                  </div>
              </div>
         
           
                 
             </mat-card>  
          
            </mat-accordion>
            
        </ng-container>

        <ng-container >
            <mat-expansion-panel
              class="group"
              (opened)="panelOpenStateExpiration = true"
              (closed)="panelOpenStateExpiration = false"
              hideToggle="true"
              #abcd>
              <mat-expansion-panel-header>
                <mat-panel-title class="mat-panel-title">
                    Set Expiration
                </mat-panel-title>
                <mat-icon *ngIf="abcd.expanded" class="iconsize">arrow_drop_up</mat-icon>
                <mat-icon *ngIf="!abcd.expanded" class="iconsize">arrow_drop_down</mat-icon>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
           
            <mat-accordion class="countries" *ngIf="panelOpenStateExpiration"  multi="true">
             
              <mat-card class="table-matcard">
                <div class="row my-form-field marginleft">
                  <div class="noOfAttempt col-lg-3 col-md-4 col-sm-6 ">Set Expiration after </div>
                   <div class="col-lg-9 col-md-8 col-sm-6">
                    <input type=number min="0" class="exprInput" [(ngModel)]="expiration1Input" style="width:60px;height:38px" [disabled]="isDisabled">
               
                  <mat-form-field appearance="outline"  class="form-field">
                    <mat-select matNativeControl [(ngModel)]="expiration1"
                    placeholder="Select" [disabled]="isDisabled" panelClass="myPanelmatselectClass" disableOptionCentering>
                    <mat-option  [value]="none" class="create_select">
                      None
                    </mat-option>
                    <mat-option *ngFor="let item of dropDown" [value]="item.id" class="create_select">
                    {{item.name}} 
                    </mat-option>
                    </mat-select>
                    </mat-form-field>
                    &nbsp;&nbsp;&nbsp; from Completion date
                  </div>
              </div>
         
                
                <div class="row marginleft" style="margin-bottom:15px;">

                  <div class="btn-left col-lg-3 col-md-4 col-sm-6">
                    
                    <span class="noOfAttempt">Grace Period</span>
                  </div>
                  <div class="col-lg-9 col-md-8 col-sm-6">
                    <mat-checkbox class="align audiencecontainer" [(ngModel)]="gracePeriod" [disabled]="isDisabled">
                    </mat-checkbox>
                 </div>
              </div>

              <div class="row my-form-field marginleft" *ngIf="gracePeriod">
                <div class="noOfAttempt col-lg-3 col-md-4 col-sm-6 "> </div>
                 <div class="col-lg-9 col-md-8 col-sm-6">
                  <mat-radio-group [(ngModel)]="sel_graceperiod1">
                  <mat-radio-button value="sel_graceperiod1" color="accent" class="marginleft"  (change)="change_graceperiod1()" [disabled]="isDisabled">
                  </mat-radio-button>
                </mat-radio-group>
                <mat-form-field appearance="outline"  class="form-field">
                  <mat-select matNativeControl [(ngModel)]="graceperiod1"
                  placeholder="Select"  panelClass="myPanelmatselectClass" [disabled]="isDisabled" disableOptionCentering>
                  <mat-option  [value]="none" class="create_select">
                    None
                  </mat-option>
                  <mat-option *ngFor="let item of dropDown1" [value]="item.id" class="create_select" [disabled]="isDisabled">
                  {{item.name}} 
                  </mat-option>
                  </mat-select>
                  </mat-form-field>
              
                </div>
            </div>

              <div class="row my-form-field marginleft" *ngIf="gracePeriod">
                <div class="noOfAttempt col-lg-3 col-md-4 col-sm-6 "></div>
                 <div class="col-lg-9 col-md-8 col-sm-6">
                <mat-radio-group [(ngModel)]="sel_graceperiod2">
                  <mat-radio-button value="sel_graceperiod2" color="accent" class="marginleft"  (change)="change_graceperiod2()" [disabled]="isDisabled">
                  </mat-radio-button>
                </mat-radio-group>
                <input type=number  class="exprInput" [(ngModel)]="graceperiod2input" [disabled]="isDisabled" style="width:60px;height:38px">
             
                <mat-form-field appearance="outline"  class="form-field">
                  <mat-select matNativeControl [(ngModel)]="graceperiod2"
                  placeholder="Select" [disabled]="isDisabled"  panelClass="myPanelmatselectClass" disableOptionCentering>
                  <mat-option  [value]="none" class="create_select">
                    None
                  </mat-option>
                  <mat-option *ngFor="let item of dropDown2" [value]="item.id" class="create_select">
                  {{item.name}} 
                  </mat-option>
                  </mat-select>
                  </mat-form-field>
                
                </div>
            </div>
            

              <div class="row my-form-field marginleft" >
                <div class="noOfAttempt col-lg-3 col-md-4 col-sm-6 ">Make available </div>
                 <div class="col-lg-9 col-md-8 col-sm-6">
                <input type=number min="0" class="exprInput" [(ngModel)]="graceperiod3input" [disabled]="isDisabled" style="width:60px;height:38px">
             
                <mat-form-field appearance="outline"  class="form-field">
                  <mat-select matNativeControl [(ngModel)]="graceperiod3"
                  placeholder="Select" [disabled]="isDisabled"  panelClass="myPanelmatselectClass" disableOptionCentering>
                  <mat-option  [value]="none" class="create_select">
                    None
                  </mat-option>
                  <mat-option *ngFor="let item of dropDown" [value]="item.id" class="create_select" [disabled]="isDisabled">
                  {{item.name}} 
                  </mat-option>
                  </mat-select>
                  </mat-form-field>
                  &nbsp;&nbsp;&nbsp;  before Expiration date
                </div>
            </div>
             </mat-card>  
          
            </mat-accordion>
            
        </ng-container>

    </mat-accordion>


   
  <!-- </mat-card> -->
</div>
</div>