<div class="spinner_overlay" *ngIf="loadspinner === true"></div>
<mat-spinner class="spinner" *ngIf="loadspinner === true"></mat-spinner>

<div class="filtermaincontainer" style="max-height: 87vh">
  <div class="row headerrow">
    <div class="col-1"><img src="assets/images/optimizer.svg" /></div>
    <div class="col-8">
      <span class="heading">Record Of Completion </span>
    </div>

    <div class="col-1">
      <button
        mat-icon-button
        class="usertablebtn"
        (click)="printcertificate('imageDIV')"
      >
        <img
          src="assets/images/print.svg"
          alt="filter"
          title="print"
          class="icons"
        />
      </button>
    </div>

    <div class="col-1">
      <button mat-icon-button class="usertablebtn" (click)="convert()">
        <img
          src="assets/images/download.svg"
          alt="filter"
          title="download"
          class="icons"
        />
      </button>
    </div>

    <div class="col-1 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()"
        >clear</mat-icon
      >
    </div>
  </div>

  <div style="background-color: #fff; padding: 1%" id="imageDIV">
    <div class="row mainrow">
      <div class="row">
        <div class="col-md-1">&#160;</div>
        <div class="col-md-11">
          <p class="new_title">Record Of Completion</p>
        </div>

        <div class="col-12">
          <img
            src="../../../../assets/images/group@3x.webp"
            style="width: 100%; margin-left: 0.5px"
          />
        </div>

        <div class="col-12">
          <p class="sub_title">
            This record of completion is proudly presented to
          </p>
        </div>

        <div class="row bgimage">
          <div *ngIf="category === 'Curriculum'"></div>
          <div *ngIf="category === 'Task'"></div>
          <div
            *ngIf="
              category === 'eCourse' ||
              category === 'Exam' ||
              category === 'Evaluation'
            "
          >
            <br /><br /><br />
          </div>
          &#160;

          <div class="col-12">
            <p class="name">{{ name }}</p>
            <hr class="hr" />
          </div>

          <div class="col-12">
            <div
              class="successfulcompletionsdiv"
              *ngIf="category === 'Curriculum'"
            >
              FOR SUCCESSFULL COMPLETION OF CURRICULUM
            </div>

            <div
              class="successfulcompletionsdiv"
              *ngIf="
                category === 'eCourse' ||
                category === 'Exam' ||
                category === 'Evaluation'
              "
            >
              For Successful completion of
            </div>

            <div class="successfulcompletionsdiv" *ngIf="category === 'Task'">
              For Successful completion of following task :
            </div>

            <div class="course">{{ course }}</div>
          </div>

          <div class="col-12" *ngIf="category === 'Curriculum'">
            <div class="successfulcompletionsdiv">
              Courses Included in Curriculum:
            </div>
          </div>

          <div *ngIf="category === 'Curriculum'" style="width: 100%">
            <div class="row requirementlistrow">
              <div
                class="col-12 requirementlist"
                *ngFor="let item of requirementlist1 | keyvalue; let i = index"
              >
                <div class="col-1">{{ item.key }}</div>
                <div class="col-5">
                  <div class="requirementlistvalue">{{ item.value }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12" *ngIf="category === 'Task'">
            <div class="successfulcompletionsdiv">
              Requirements Included in tasks :
            </div>
          </div>

          <div *ngIf="category === 'Task'" style="width: 100%">
            <div class="row requirementlistrow">
              <div
                class="col-12 requirementlist"
                *ngFor="let item of requirementlist1 | keyvalue; let i = index"
              >
                <div class="col-1">{{ item.key }}</div>
                <div class="col-5">
                  <div class="requirementlistvalue">{{ item.value }}</div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-12"
            *ngIf="
              category === 'eCourse' ||
              category === 'Exam' ||
              category === 'Evaluation'
            "
          >
            <div class="successfulcompletionsdiv" style="color: white">.</div>
          </div>
        </div>
      </div>

      <div class="col-1"></div>
      <div class="col-3 footercol">
        <br /><br />
        <strong> {{ completiondate }} </strong>
        <hr class="footercoltxt" />
        <span style="opacity: 0.8; font-size: 12px">COMPLETION DATE</span>
      </div>

      <div class="col-3 footercol">
        <br /><br />
        <strong> {{ companyname }} </strong>
        <hr class="footercoltxt" />
        <span style="opacity: 0.8; font-size: 12px">ORGANIZATION</span>
      </div>

      <div class="col-4 footercol">
        <br /><br />
        <strong> Stellar IT Solutions, INC. </strong>
        <hr class="footercoltxt" />
        <span style="opacity: 0.8; font-size: 12px">ISSUED BY</span>
      </div>
      <div class="col-1"></div>

      <br />
    </div>
  </div>
</div>
