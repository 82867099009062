import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiServiceService } from '../../../../Services/api-service.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ScaleratingComponent } from 'src/app/Popups/assignablecontent/scalerating/scalerating.component';
import { DialogpopupComponent } from 'src/app/Popups/dialog-popup/dialog-popup.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})

export class SettingsComponent implements OnInit {

  public selectTabIndex: any;
  loadspinner: any;
  formssettings: FormGroup;
  performancerating = ['2 Scale Rating', '3 Scale Rating', '4 Scale Rating', '5 Scale Rating'];
  settings_data: any;
  scalerating_val: any;
  savebutton: boolean = true;
  cancelbutton: boolean = true;
  A_C_FORMS_SETTINGS_EDIT: any;
  sharedForm: any = 'No';
  @ViewChild('formdetailscomponent') formdetailscomponent;
  @ViewChild('structurecomponent') structurecomponent;
  settingsdata;
  constructor(private formBuilder: FormBuilder, private apiservice: ApiServiceService, public dialog: MatDialog,) {
   
    if (localStorage.getItem("formsharedFlagtocompany") != null && localStorage.getItem("formsharedFlagtocompany") != 'null' && localStorage.getItem("formsharedFlagtocompany") != undefined && localStorage.getItem("formsharedFlagtocompany") != 'undefined') {
      this.sharedForm = localStorage.getItem("formsharedFlagtocompany")
    }
    this.formssettings = this.formBuilder.group({
      numberingformat: ['1'],
      assignmentscore: ['Passed/Failed'],
      performancerating: ['2 Scale Rating'],
      allowfrommarked: ['Yes'],
      evaluatorsignature: ['Required'],
      evaluateesignature: ['Required'],
      evaluateeimage: ['Required']
    })
    this.formssettings.disable();
  }

  ngOnInit(): void {
    this.setdefaultpermission();
    this.selectTabIndex = localStorage.getItem("forms-selectTabIndex");
    if (this.selectTabIndex == undefined) {
      this.selectTabIndex = 1;
    }
    this.default_load();
  }

  setdefaultpermission() {
    let a = localStorage.getItem("Permissiongroup");
    let Permissiongroupdata = JSON.parse(a)

    let login_role = localStorage.getItem("loginRole");
    if (login_role == 'System Administrator') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["System Administrator"]).length != 0) {
        this.A_C_FORMS_SETTINGS_EDIT = Permissiongroupdata["rolesPermissions"]["System Administrator"]["A_C_FORMS_SETTINGS_EDIT"];
      }
    }
    else if (login_role == 'Primary Administrator') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Primary Administrator"]).length != 0) {
        this.A_C_FORMS_SETTINGS_EDIT = Permissiongroupdata["rolesPermissions"]["Primary Administrator"]["A_C_FORMS_SETTINGS_EDIT"];
      }
    }
    else if (login_role == 'Company Administrator') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Company Administrator"]).length != 0) {
        this.A_C_FORMS_SETTINGS_EDIT = Permissiongroupdata["rolesPermissions"]["Company Administrator"]["A_C_FORMS_SETTINGS_EDIT"];
      }
    }
    else if (login_role == 'Manager') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Manager"]).length != 0) {
      }
      this.A_C_FORMS_SETTINGS_EDIT = Permissiongroupdata["rolesPermissions"]["Manager"]["A_C_FORMS_SETTINGS_EDIT"];
    }
    else if (login_role == 'Proctor') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Proctor"]).length != 0) {
      }
    }
    else if (login_role == 'Learner') {
      if (Object.keys(Permissiongroupdata["rolesPermissions"]["Learner"]).length != 0) {
      }
    }
  }

  enable_edit_icon(){
    
    this.loadspinner = true;
    
    if (localStorage.getItem("versionpopup") == "off") {
      this.version_popup();
      localStorage.setItem("versionpopup", "on");
    }
    this.apiservice.user_details('', 'Edit');
    document.getElementById("view_active").style.display = "none";
    document.getElementById("edit_active").style.display = "block";
    this.formssettings.enable();
    this.savebutton = false;
    this.cancelbutton = false;
    document.getElementById("formsettings_btn").style.display = "flex";
    this.loadspinner = false;
  }

  enable_edit() {
    
    this.enable_edit_icon();
    this.default_load();
    this.loadspinner = false;
  }

  disable_edit() {
    
    this.savebutton = true;
    this.cancelbutton = true;
    this.apiservice.user_details('', 'View');
    document.getElementById("edit_active").style.display = "none";
    document.getElementById("view_active").style.display = "block";
    this.formssettings.disable();
   
    document.getElementById("formsettings_btn").style.display = "none";
  }

  version_popup() {
    
    if(this.settings_data.pefView.status =="Published"){
      let element = document.getElementById("course-content");
      element.classList.add('blur-content');
      let data = {
        title: 'Editing the form will increment the version.',
        subtitle: 'The form will remain in draft mode until published. Evaluators will need to synchronize to obtain the new version',
        imagelogo: "assets/images/information.svg",
      };
      const dialogRef = this.dialog.open(DialogpopupComponent, {
        width: '550px',
        disableClose: true,
        data: {
          from: 'edit-formdetails-popup',
          name: data
        },
        position: {
          top: '40vh'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        let element = document.getElementById("course-content")
        element.classList.remove('blur-content');
        if (result == 'cancel') {
          localStorage.setItem("versionpopup", "off");
          this.disable_edit();
        } else {
          localStorage.setItem("versionpopup", "on");
          this.enable_edit();
        }
      });
    }    
  }

  default_load() {
    
    this.loadspinner = true;
    const endpoint1 = 'pef/getFormDetails?formId=' + localStorage.getItem("formId") + '&version=' + localStorage.getItem("version") + '';
    this.apiservice.getformdetails(endpoint1).subscribe((response) => {
      this.settings_data = response;
      localStorage.setItem("form_status", this.settings_data.pefView.status);
      if (this.settings_data.pefView.formId != null) {
        var dataToStore = JSON.stringify(this.settings_data.formSettings.stepsPerformanceRating);
        localStorage.setItem('default_scalerating', dataToStore);
        let rating;
        if (this.settings_data.formSettings.stepsPerformanceRating.ratingScale === null) {
          rating = "2 Scale Rating";
        } else {
          rating = this.settings_data.formSettings.stepsPerformanceRating.ratingScale;
        }
        let assignment_scoring;
        if (this.settings_data.formSettings.assignmentScoring === "Pass/Failed") {
          assignment_scoring = "Passed/Failed";
        } else {
          assignment_scoring = this.settings_data.formSettings.assignmentScoring;
        }
        if (this.settings_data.pefView.formId != null) {
          this.formssettings.patchValue({
            numberingformat: this.settings_data.formSettings.numberFormat,
            assignmentscore: assignment_scoring,
            performancerating: rating,
            allowfrommarked: this.settings_data.formSettings.failedSteps,
            evaluatorsignature: this.settings_data.formSettings.evaluatorSign,
            evaluateesignature: this.settings_data.formSettings.evaluateeSign,
            evaluateeimage: this.settings_data.formSettings.evaluateeImage,
          })
        }
      } else {
        this.formssettings = this.formBuilder.group({
          numberingformat: ['1'],
          assignmentscore: ['Passed/Failed'],
          performancerating: ['2 Scale Rating'],
          allowfrommarked: ['Yes'],
          evaluatorsignature: ['Required'],
          evaluateesignature: ['Required'],
          evaluateeimage: ['Required']
        })
      }
    });
    this.loadspinner = false;
  }

//oqcs-5106 07-03-2024
  nexttab_settings(){
    let scale_rating = this.formssettings.controls.performancerating.value;
    let ratingval;
    if (this.scalerating_val === "" || this.scalerating_val === undefined || this.scalerating_val.length === 0 ) {
      if(this.settings_data.formSettings.stepsPerformanceRating == null){
        
      if(scale_rating ==="2 Scale Rating"){
      ratingval = [{
        "labelCode": 1,
        "labelTitle": "Pass",
        "passingCriteria": true
      },
      {
        "labelCode": 2,
        "labelTitle": "Fail",
        "passingCriteria": false
      }
      ];
    } 
    else if(scale_rating ==="3 Scale Rating" ){
      ratingval = [{
        "labelCode": 1,
        "labelTitle": "Pass",
        "passingCriteria": true
      },
      {
        "labelCode": 2,
        "labelTitle": "Fail",
        "passingCriteria": false
      },
      {
        "labelCode": 3,
        "labelTitle": "Fail",
        "passingCriteria": false
      }
      ];
    }
    else if(scale_rating ==="4 Scale Rating" ){
      ratingval = [{
        "labelCode": 1,
        "labelTitle": "Pass",
        "passingCriteria": true
      },
      {
        "labelCode": 2,
        "labelTitle": "Fail",
        "passingCriteria": false
      },
      {
        "labelCode": 3,
        "labelTitle": "Fail",
        "passingCriteria": false
      },
      {
        "labelCode": 4,
        "labelTitle": "Fail",
        "passingCriteria": false
      }
      ];
    }
    else if(scale_rating ==="5 Scale Rating" ){
      ratingval = [{
        "labelCode": 1,
        "labelTitle": "Pass",
        "passingCriteria": true
      },
      {
        "labelCode": 2,
        "labelTitle": "Fail",
        "passingCriteria": false
      },
      {
        "labelCode": 3,
        "labelTitle": "Fail",
        "passingCriteria": false
      },
      {
        "labelCode": 4,
        "labelTitle": "Fail",
        "passingCriteria": false
      },
      {
        "labelCode": 5,
        "labelTitle": "Fail",
        "passingCriteria": false
      }
      ];
    }
  }else{
    ratingval = this.settings_data.formSettings.stepsPerformanceRating.ratings
  }
  }
    else {
      ratingval = this.scalerating_val.data;
    }
     this.settingsdata = {
      "numberFormat": this.formssettings.controls.numberingformat.value,
      "assignmentScoring": this.formssettings.controls.assignmentscore.value,
      "failedSteps": this.formssettings.controls.allowfrommarked.value,
      "evaluatorSign": this.formssettings.controls.evaluatorsignature.value,
      "evaluateeSign": this.formssettings.controls.evaluateesignature.value,
      "evaluateeImage": this.formssettings.controls.evaluateeimage.value,
      "stepsPerformanceRating": {
        "ratingScale": this.formssettings.controls.performancerating.value,
        "ratings": ratingval
      }
    }

    if(this.settings_data.formStructure.steps == null){
      this.settings_data.formStructure = {
        steps: [
          {
            camera: true, performanceRatingNA: false,
            levels: [{ label: '', inputType: 'TextBox', subLevel: [{ label: '', inputType: 'TextBox' }] }],
            stepDescription: { type: 'textarea', label: '' },
            stepText: { type: 'input', label: '' }
          }
        ]
      }
    }  
    localStorage.setItem('form_settings_tabchange', JSON.stringify(this.settingsdata));
    
  }


  save_settings() { 
    this.loadspinner=true;
    setTimeout(()=>{
      window.scrollTo(0,0);
    }, 200)
   
    localStorage.removeItem("form_settings_tabchange");
    this.nexttab_settings();  
     
    if(localStorage.getItem("form_details_tabchange") != undefined){
      let formdetailsdata = localStorage.getItem("form_details_tabchange");
      this.settings_data.pefView = JSON.parse(formdetailsdata);
      
      let catlog_data=localStorage.getItem("form_details_tabchange_catalogs")
      this.settings_data.catalogListNames=[];
      this.settings_data.catalogListNames.push(catlog_data)
    }

    if(localStorage.getItem("form_structure_tabchange") != undefined){
      let formsettingsdata = localStorage.getItem("form_structure_tabchange");
      this.settings_data.formStructure = JSON.parse(formsettingsdata);
    }

    let endpoint1 = 'pef/single/savecall?formId=' + localStorage.getItem("formId") + '&version=' + localStorage.getItem("version") + '';
    this.settings_data.formSettings = this.settingsdata;
    this.settings_data.pefView.version=localStorage.getItem("version");
    this.settings_data.pefView.status=localStorage.getItem("form_status");
    
    let temp_catalogs:any=[];
    if(this.settings_data.catalogListNames[0] != 'null' && this.settings_data.catalogListNames[0] != null){
      let temp_values=this.settings_data.catalogListNames[0];
      temp_values=temp_values.split(',');
      for(var p=0; p<temp_values.length; p++){
        temp_catalogs.push(temp_values[p])
      }
      this.settings_data.catalogListNames=temp_catalogs;
    }

    let body = this.settings_data;
    this.apiservice.saveformdetails(endpoint1, body).subscribe((response) => {
      var res;
      res = response;     
      if (res.message === "success") {
        localStorage.setItem("version", res.version);
        localStorage.setItem("formId", res.formId);
        localStorage.setItem("formSettingId", res.formSettingsId);
        document.getElementById("success_block1").style.display = "flex";
        this.loadspinner = false;
        setTimeout(() => {          
          document.getElementById("success_block1").style.display = "none";
          this.default_load();         
        }, 2000);
      }
    },
      (err) => {
      });
  }

  selectscalerating(){
  this.scalerating_val=[];
  }


  scale_popup() {
    
    let scale_val = this.formssettings.controls.performancerating.value;
    let popup_height;
    let popup_topspace;
    if (scale_val == "2 Scale Rating") {
      popup_height = "355px";
      popup_topspace = '27vh';
    } else if (scale_val == "3 Scale Rating") {
      popup_height = "405px";
      popup_topspace = '25vh';
    } else if (scale_val == "4 Scale Rating") {
      popup_height = "450px";
      popup_topspace = '20vh';
    } else if (scale_val == "5 Scale Rating") {
      popup_height = "510px";
      popup_topspace = '15vh';
    }
    let element = document.getElementById("user-content")
    element.classList.add('blur-content')
    const dialogRef = this.dialog.open(ScaleratingComponent, {
      width: '530px',
      height: popup_height,
      disableClose: true,
      data:
      {
        title: scale_val,
        from: "delete"
      },
      backdropClass: 'deletePopup',
      position: {
        top: popup_topspace
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.method != "cancel") {
        this.scalerating_val = result;
        if (result.data != undefined) {
          this.save_settings();
        }
      }
      let element = document.getElementById("user-content")
      element.classList.remove('blur-content');
    });
  }

  cancel() {
    event.preventDefault();
    let element = document.getElementById("course-content")
    element.classList.add('blur-content')
    const dialogRef = this.dialog.open(DialogpopupComponent, {
      width: '530px',
      height: '330px',
      disableClose: true,
      data: {
        from: 'settings-cancel'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      let element = document.getElementById("course-content")
      element.classList.remove('blur-content');
    });
  }

}
