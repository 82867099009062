import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AssignmentsComponent } from 'src/app/Learner/assignments/assignments.component';

@Component({
  selector: 'app-scorm-popup',
  templateUrl: './scorm-popup.component.html',
  styleUrls: ['./scorm-popup.component.scss']
})
export class ScormPopupComponent implements OnInit {
  tempdata: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, private sanitizer: DomSanitizer, public dialog: MatDialogRef<AssignmentsComponent>) {
    this.tempdata = this.sanitizer.bypassSecurityTrustResourceUrl(data['tempdata']);
  }

  ngOnInit(): void {

  }

  cancelbtn() {
    this.dialog.close({ method: 'close' })
  }
}
