<div class="spinner_overlay" *ngIf="loadspinner === true"></div>
<mat-spinner class="spinner" *ngIf="loadspinner === true"></mat-spinner>

<br />
<div class="row headr">
  <div
    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 left-sec"
    style="margin-top: -20px"
  >
    <img src="assets/images/create-u-ser.svg" />
    <span class="titles">Assign Users</span>

    <div class="close">
      <mat-icon class="clearicon" alt="clear" (click)="close()">clear</mat-icon>
    </div>
  </div>
</div>
<hr style="width: 1023px; margin-left: -33px" />

<div class="row">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <p class="titles">{{ title }}</p>
  </div>
</div>
<div class="row user-box">
  <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12 user-box-block">
    <div class="list-header">User List</div>

    <div class="list-search">
      <mat-form-field appearance="outline" class="matformfieldheader">
        <input
          type="text"
          #searchinput1
          id="sid1"
          matInput
          (keyup)="search_left(searchinput1.value)"
          class="textsearchinput"
          placeholder="Search by name, login and email"
          autocomplete="off"
        />
        <mat-icon matPrefix class="searchbtn">search</mat-icon>
      </mat-form-field>
    </div>
    <div class="list-scroll">
      <cdk-virtual-scroll-viewport itemSize="50" style="height: 300px">
        <div *cdkVirtualFor="let data of user_data" class="list-data">
          <mat-checkbox
            class="checkboxbody"
            [checked]="data.isSelected"
            (change)="itemSelected_leftbox(data)"
          >
          </mat-checkbox>
          &nbsp;&nbsp;&nbsp; {{ data.lastName }}, {{ data.firstName }} ({{
            data.loginName
          }})
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 btn-center">
    <div class="inner-container">
      <div class="row sub-row">
        <button type="button" mat-button class="addbtn" (click)="add_users()">
          Add
          <br />
          <mat-icon class="Cancelicon" style="color: white" alt="clear"
            >east</mat-icon
          >
        </button>
      </div>
      <div class="row sub-row" style="margin-top: 20px">
        <button
          type="button"
          mat-button
          class="removebtn"
          (click)="remove_user()"
        >
          Remove <br />
          <mat-icon class="Cancelicon" alt="clear">west</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12 user-box-block">
    <div class="list-header">User Added</div>
    <div class="list-search">
      <mat-form-field appearance="outline" class="matformfieldheader">
        <input
          type="text"
          #searchinput2
          id="sid2"
          matInput
          (keyup)="search_right(searchinput2.value)"
          class="textsearchinput"
          placeholder="Search..."
          autocomplete="off"
        />
        <mat-icon matSuffix class="searchbtn">search</mat-icon>
      </mat-form-field>
    </div>
    <div class="list-scroll">
      <cdk-virtual-scroll-viewport itemSize="50" style="height: 300px">
        <div
          *cdkVirtualFor="let data of selected_users_backup"
          class="list-data checkbox"
        >
          <mat-checkbox
            class="checkboxbody checkbox-right"
            [checked]="data.isSelected"
            (change)="itemSelected_rightbox(data)"
          >
          </mat-checkbox>
          {{ data.lastName }}, {{ data.firstName }} ({{ data.loginName }})
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 cancel-btn-col">
    <br /><br />
    <button mat-button class="cancel-btn" (click)="close()">
      <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
      Cancel
    </button>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol">
    <br /><br />
    <button mat-button class="Filterbtn" (click)="create_user()">
      <mat-icon class="filterbtnicon">done</mat-icon>
      Save
    </button>
  </div>
</div>
