import { Component, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { LocationStrategy, PlatformLocation } from '@angular/common';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DialogpopupComponent } from './Popups/dialog-popup/dialog-popup.component';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  title = 'OQCS';
  user;
  private previousUrl: string;
  private currentUrl: string;
  subscription: Subscription;
  @ViewChild('errorduplicate', { static: true }) errorduplicate: TemplateRef<any>;

  constructor(location: PlatformLocation, private router: Router, public dialog: MatDialog, private route: ActivatedRoute,
    private historylocation: LocationStrategy) {
      // disable the default dropdown search........sample
      document.addEventListener('keydown', e => {
        if ((e.target as any).nodeName === 'MAT-SELECT') {
          let p=(e.target as any).id;
            if(p==="companydropdownID"){
              e.stopImmediatePropagation();  
             } 
        }
      }, true);

      // storing the url in history (browser back button on role changes issue fixes)
      history.pushState(null, null, window.location.href);
      this.historylocation.onPopState(() => {
      // on back event checking instance running or not
      if (window.location.pathname != "/ScromExit") {
        // broadcast channnel api code
        bc.onmessage = (event) => {
          if (event.data === `is-open`) {
            bc.postMessage(`yes`);
            // original tab error message
            //alert(`Another tab of this site just got opened`);
            const dialogExist1 = this.dialog.getDialogById('session-pop-up1');
            const dialogExist2 = this.dialog.getDialogById('session-pop-up2');
            //check if session warning dialog box already opened
            if (!dialogExist1 && !dialogExist2) {
              this.dialog.open(this.errorduplicate, {
                id: 'session-pop-up1',
                width: '550px',
                autoFocus: false,
                // height: '350px',
                backdropClass: 'bdrop',
                disableClose: true,
                position: {
                  top: '30vh'
                  // left: '50%'
                }
              });
            }
          }
          if (event.data === `yes`) {
            // duplicate tab error message

            const dialogExist1 = this.dialog.getDialogById('session-pop-up1');
            const dialogExist2 = this.dialog.getDialogById('session-pop-up2');
            //check if session warning dialog box already opened
            if (!dialogExist1 && !dialogExist2) {
              this.dialog.open(this.errorduplicate, {
                id: 'session-pop-up2',
                width: '550px',
                autoFocus: false,
                // height: '350px',
                backdropClass: 'bdrop',
                disableClose: true,
                position: {
                  top: '30vh'
                  // left: '50%'
                }
              });
            }
          }
        };

        bc.postMessage(`is-open`);
        // code end gere
      }
      history.pushState(null, null, window.location.href);
    });
    const bc = new BroadcastChannel("OQCS");
    let impersonate_div = localStorage.getItem("impersonatemode");
    if (impersonate_div == null || impersonate_div == undefined) {
      localStorage.setItem("impersonatemode", "false");
    }



    // localStorage.removeItem("currentloginId");
    localStorage.removeItem("minisidemenu");
    location.onPopState(() => {

      this.user = localStorage.getItem('jwtToken');

      if (this.user === null || this.user == "") {
        this.router.navigate(['/login']);
      }
    });

    // browser button functionalities
    this.subscription = router.events.subscribe((event) => {
     
      if (window.location.pathname != "/ScromExit") {
        // broadcast channnel api code
        bc.onmessage = (event) => {
          if (event.data === `is-open`) {
            bc.postMessage(`yes`);
            // original tab error message
            //alert(`Another tab of this site just got opened`);
            const dialogExist1 = this.dialog.getDialogById('session-pop-up1');
            const dialogExist2 = this.dialog.getDialogById('session-pop-up2');
            //check if session warning dialog box already opened
            if (!dialogExist1 && !dialogExist2) {
              this.dialog.open(this.errorduplicate, {
                id: 'session-pop-up1',
                width: '550px',
                autoFocus: false,
                // height: '350px',
                backdropClass: 'bdrop',
                disableClose: true,
                position: {
                  top: '30vh'
                  // left: '50%'
                }
              });
            }
          }
          if (event.data === `yes`) {
            // duplicate tab error message

            const dialogExist1 = this.dialog.getDialogById('session-pop-up1');
            const dialogExist2 = this.dialog.getDialogById('session-pop-up2');
            //check if session warning dialog box already opened
            if (!dialogExist1 && !dialogExist2) {
              this.dialog.open(this.errorduplicate, {
                id: 'session-pop-up2',
                width: '550px',
                autoFocus: false,
                // height: '350px',
                backdropClass: 'bdrop',
                disableClose: true,
                position: {
                  top: '30vh'
                  // left: '50%'
                }
              });
            }
          }
        };

        bc.postMessage(`is-open`);
        // code end gere
      }

      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
        if (browserRefresh === true) {
          // clear cache on refresh
          localStorage.removeItem('searchvalue');
          localStorage.removeItem("submitclick")
        }
        else {
          // Dont clear userlist filter data on refresh... clears when routing changes
          localStorage.removeItem('filterdata');
          localStorage.removeItem('chipdisplayfilterdata');
          localStorage.removeItem('clearfilteritemkey');
          localStorage.removeItem('filterapibodycontent');

          localStorage.removeItem("selectTabIndex");
          localStorage.removeItem("selectTabIndexName");
          localStorage.removeItem("selectTabBreadCrumbs");

          // Dont clear grouplistp filter data
          localStorage.removeItem('groupfilterdata');
          localStorage.removeItem('group_chipdisplayfilterdata');
          localStorage.removeItem('group_clearfilteritemkey');
          localStorage.removeItem('group_filterapibodycontent');

          // Dont clear grouplistp filter data
          localStorage.removeItem('groupfilterdataforcurri');  // clear grouplistpopup filter data
          localStorage.removeItem('group_chipdisplayfilterdataforcurri');
          localStorage.removeItem('group_clearfilteritemkeyforcurri');
          localStorage.removeItem('group_filterapibodycontentforcurri');

          // Dont clear Manager filter data
          localStorage.removeItem('Manager_filterdata');
          localStorage.removeItem('Manager_chipdisplayfilterdata');
          localStorage.removeItem('Manager_clearfilteritemkey');
          localStorage.removeItem('Manager_filterapibodycontent');

          // Dont clear Manager filter data
          localStorage.removeItem('Evaluator_filterdata');
          localStorage.removeItem('Evaluator_chipdisplayfilterdata');
          localStorage.removeItem('Evaluator_clearfilteritemkey');
          localStorage.removeItem('Evaluator_filterapibodycontent');

          // localStorage.removeItem('user_details');
          localStorage.removeItem('chipdisplayfilterdataforterms');
        }
      }
    });


  }


}
