import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogpopupComponent } from 'src/app/Popups/dialog-popup/dialog-popup.component';
import { announcement,assignments,externaltraininglist,companylistMngmnt,
  createuserUrl,formlistimport,tasklistnameexporturl,uploadurl,createcourse,
  updatecurriclumUrl,filepeekurl,scorm_importcourse,companylogo,evaluation,filepeekurl1,transcriptprinturl, formlist
 } from 'src/environments/environment';
export class ApiServiceService {
  announcement = announcement;
  assignments=assignments;
  externaltraininglist =externaltraininglist;
  companylistMngmnt = companylistMngmnt;
  createuserUrl=createuserUrl;
  formlistimport=formlistimport;
  uploadurl= uploadurl;
  createcourse = createcourse;
  updatecurriclumUrl = updatecurriclumUrl;
  filepeekurl=filepeekurl;
  scorm_importcourse=scorm_importcourse;
  companylogo = companylogo;
  evaluation=evaluation;
  filepeekurl1 = filepeekurl1;
  transcriptprinturl=transcriptprinturl;
  formlist=formlist;
}
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(public dialog: MatDialog, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({ headers: request.headers.set('Cache-Control', 'no-cache')});
    request = request.clone({ headers: request.headers.set('Pragma', 'no-cache')});
    request = request.clone({ headers: request.headers.set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')});
    const token: string = localStorage.getItem('jwtToken');
    const loginId: string = localStorage.getItem('loginId');
    let loginRole= localStorage.getItem('loginRole');
    let compId = localStorage.getItem('CompIdchecktoken');

    if (this.router.url === "/login" || this.router.url === "/ForgotPassword") {
      let loginId = localStorage.getItem('loginId');

      let announcementlistlogin = announcement +'/announcement/announcementlistlogin/' + loginId;
      let getcompanyid =assignments+ '/course/getcompanyid/' + loginId;
  
      let evidStatus = createuserUrl+'/users/getevaluatoridstatus/'+ loginId;
      if (request.url === announcementlistlogin
        || request.url === getcompanyid || request.url === evidStatus) {
       
        if (token) {
          request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
          request = request.clone({ headers: request.headers.set('loginId', loginId) });
          request = request.clone({ headers: request.headers.set('roles', loginRole) });
          request = request.clone({ headers: request.headers.set('companyId', compId) });
        }
      }

    }
    else {

      if (token) {
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        request = request.clone({ headers: request.headers.set('loginId', loginId) });
        request = request.clone({ headers: request.headers.set('roles', loginRole) });
        request = request.clone({ headers: request.headers.set('companyId', compId) });
      }
    }


    let selectedUserLogin = localStorage.getItem("selectedUserLogin");
    let DEV_trainingURL = externaltraininglist + '/training/' + selectedUserLogin;

    let DEV_UpdateTrainingURL = externaltraininglist + '/training/update/' + selectedUserLogin;

    let DEV_customizebadge = companylistMngmnt + '/companies/addTemplates/' + loginId;
    
    let importcsvdev = createuserUrl + "/users/importcsvfile/" + loginId
   
    let importtrainingcsvdev = externaltraininglist + "/training/importcsvfile/" + loginId
   
    let importformscsvdev = formlistimport + "/pef/importcsvfile/" + loginId
   
    let importtaskcsvdev = tasklistnameexporturl + "/task/importcsvfile/" + loginId

    //Check with both dev, uat and prod url // to avaoid api 400 error with maultipart/form-data

    if (request.url == uploadurl

      || request.url === DEV_trainingURL 
  
      || request.url == DEV_UpdateTrainingURL 

      || request.url === DEV_customizebadge 

      || request.url === createcourse + "/course"

      || request.url === createcourse + "/course/update"

      || request.url === updatecurriclumUrl + "/curriculum/update"

      || request.url === updatecurriclumUrl + "/curriculum"
      
      || request.url == createcourse + "/course/uploadFile"
     
      || request.url == filepeekurl
    
      || request.url == scorm_importcourse
    
      || request.url == companylogo
  
      || request.url === importcsvdev 
     
      || request.url === importtaskcsvdev 
     
      || request.url === importtrainingcsvdev 

      || request.url === importformscsvdev 
   

      || request.url == evaluation + '/evaluator/evaluationsubmit/' + loginId

      || request.url === filepeekurl1 + "/import/revisioncontent"

      || request.url == transcriptprinturl + "/transcript/coursesubmit" 
      || request.url == transcriptprinturl + "//transcript/coursesubmit"
      || request.url == formlistimport+"/pef/emptypdf") 
      {

      }
    else {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json')
      });
      request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    }
    


    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),

      catchError((error: HttpErrorResponse) => {
        let data = {};
        if (this.router.url === "/login" || this.router.url === "/ForgotPassword") {

        }
        else {

          if (error.status != 200) {
            if (error.status === 403) {
              data = {
                status: "Your session is being timed out due to inactivity. Please re-login to access the system"
              };
            }
            else if (error.status === 502) {
              data = {
                status: error.error.message
              };
            }
            else if (error.status === 500) {
              data = {
                status: "Internal Server Error"
              };
            }
            else if (error.status === 504) {
              data = {
                status: "Gateway Timeout Error"
              };
            }
            else {
              data = {
                status: "Something went wrong. Please try after some time."
              };
            }
            this.dialog.closeAll();
            this.dialog.open(DialogpopupComponent, {
              width: '550px',
              autoFocus: false,
              data: {
                from: 'error-popup',
                name: data
              },
              disableClose: true,
              position: {
                top: '40vh'
              }
            });
          }
        }
        return throwError(error);
      })
    );


  }
}


