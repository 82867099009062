import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScromExitComponent } from './scrom-exit.component'
const routes: Routes = [
  {
    path: '',
    component: ScromExitComponent
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScromExitRoutingModule { }
