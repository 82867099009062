<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>



<div class="usermaincontainer formstructure" id="user-content">
<div class="col-12">
<mat-card class="example-card mat-elevation-z8 matcard">

<div class="row">
<div class="col-md-5"> </div>

<div class="col-md-2" *ngIf="!expandbtn">
<button mat-button *ngIf="structurelist?.length>1" (click)="openAll()" class="rowbtn collpsebtn">
<img src="assets/images/expand.webp">&#160;&#160;Expand All</button>
</div>
<div class="col-md-2" *ngIf="expandbtn">
<button mat-button *ngIf="structurelist?.length>1" (click)="openAll()" class="expandbtn">
<img src="../../../../../assets/images/bitmap@3x.webp"
style="width: 16px; margin-top: -3px;">&#160;&#160;Expand All</button>
</div>
<div class="col-md-2" *ngIf="collapsebtn">
<button mat-button *ngIf="structurelist?.length>1" (click)="closeAll()" class="expandbtn">
<img src="../../../../../assets/images/collapse1.png"
style="width: 16px; margin-top: -3px;">&#160;&#160;Collapse All</button>
</div>
<div class="col-md-2" *ngIf="!collapsebtn">
<button mat-button *ngIf="structurelist?.length>1" (click)="closeAll()" class="rowbtn">
<img src="assets/images/collapse.webp">&#160;&#160;Collapse All</button>
</div>
<div class="col-md-2">

</div>
<div class="col-md-1">


</div>
</div>

<br> <br>

<div class="row" style="margin-bottom: -5%; display: none;" id="save_sucess">
<div class="col-8"> </div>
<div class="col-4">

</div>
</div>

<div class="row" style="margin-bottom: -5%; display: none;" id="publish_sucess">
<div class="col-8"> </div>
<div class="col-4">

</div>
</div>


<form [formGroup]="structureForm" style="margin: 1.5%;">
<mat-accordion #accordion cdkDropList #unassignedList="cdkDropList" [cdkDropListData]="structurelist"
(cdkDropListDropped)="drop($event)" class="example-headers-align" multi>
<mat-expansion-panel *ngFor="let steps of structurelist let i=index;" cdkDrag>
<mat-expansion-panel-header style="box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);">
<mat-panel-title> <img src="../../../../../assets/images/bitmap.webp" class="drag_img">
&#160;&#160;
Step {{i+1}}
</mat-panel-title>
</mat-expansion-panel-header>
<br>

<!-- start Form -->

<div class="row">
<mat-label class="col-2 normal">Image Capturing</mat-label>
<div class="col-8">


<input type="radio" id="yes" [checked]="steps.camera == true" name="imagecap-{{i}}"
value="true">&#160;&#160; <span> Show Camera </span>&#160;&#160;
<input type="radio" id="yes" [checked]="steps.camera == false" name="imagecap-{{i}}"
value="false">&#160;&#160; <span> Hide Camera </span>






</div>
<div class="col-2">

</div>
</div>

<div class="row">
<mat-label class="col-3 normal">Performance Rating Not Applicable</mat-label>
<mat-checkbox labelPosition="after" class="labelname create_select"
[checked]="steps.performanceRatingNA" name="performance{{i}}"> </mat-checkbox>
</div>

<div class="row">
<div class="col-11">
<mat-card style="border-left: 10px solid #eaaa00; margin-left: 0.2%;">
<mat-label class="col-3">Enter Step Text</mat-label>
<mat-form-field appearance="outline" class="formfield" style="width: 100%;">
<input matInput placeholder="Enter Step Text" class="textinput" maxlength="300"
name="steptext{{i}}" value="{{steps.stepText.label}}">
</mat-form-field>
<br>
<mat-label class="col-3"> Enter Step Description </mat-label> <br>
<mat-form-field appearance="outline" class="formfield" style="width: 100%;">
<textarea matInput placeholder="Enter Step Description" maxlength="300" class="textinput" name="stepdesc{{i}}">{{steps.stepDescription.label}}</textarea>
</mat-form-field><br>
<div *ngIf="steps.htmlContent">
<mat-label class="col-3"> Enter Step Details </mat-label> <br>
<div class="editor-view-class">
    <quill-view-html [content]="steps.htmlContent" theme="snow"></quill-view-html>
    </div>
</div>
</mat-card>
</div>
<div class="col-1">
</div>
</div>



</mat-expansion-panel>
</mat-accordion>
</form>



</mat-card>
<br> <br>
<div class="row" id="btn_div" style="display: none;">
<div class="col-3"> </div>
<div class="col-2">

</div>
<div class="col-2">

</div>
<div class="col-2">

</div>
<div class="col-2"> </div>
</div>
<br>
</div>
</div>
