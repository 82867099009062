import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AssignableContentComponent } from 'src/app/Administrator/assignable-content/assignable-content.component';
import { DialogpopupComponent } from 'src/app/Popups/dialog-popup/dialog-popup.component';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-assign-withdrawcurriculam',
  templateUrl: './assign-withdrawcurriculam.component.html',
  styleUrls: ['./assign-withdrawcurriculam.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignWithdrawcurriculamComponent implements OnInit {

  rem_data: any = [];
  sel_data: any = [];
  loadspinner: boolean;
  user_data: any = [];
  backup_users: any = [];
  backup_data: any = [];
  selected_users = [];
  courseId: any;
  curriculumiId: any;
  title: any;
  loginName: string;
  firstName: string;
  lastName: string;
  companyId: any;
  companyId1: any;
  loginId: string;
  pagesize: any;
  Rowperpage: any;
  sortby: any;
  totalitemlength: any;
  sortOrder: any;
  dataSource: any;
  ELEMENT_DATA: any = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogopen: MatDialog, public dialog: MatDialogRef<AssignableContentComponent>, private formBuilder: FormBuilder, public _Service: ApiServiceService,) {

    this.curriculumiId = data['curriculumiId'];
    this.loginId = localStorage.getItem('loginId');
    this.title = localStorage.getItem("curriculumtitle");
    this.companyId1 = localStorage.getItem("ownerComp");
    this.totalitemlength = data['totalitemlength'];

    if (data["UsersList"] != "" && data["UsersList"] != undefined && data["UsersList"] != null) {
      for (var i = 0; i < data["UsersList"].length; i++) {
        data["UsersList"][i].isSelected = false;
      }
    }
    let tempdata = [];
    for (var i = 0; i < data["UsersList"].length; i++) {
      if (data["UsersList"][i].courseStatus == "Not Started" || data["UsersList"][i].courseStatus == "In Progress") {
        tempdata.push(data["UsersList"][i])
      }
    }
    this.selected_users = tempdata;
    this.backup_users = this.selected_users;
  }

  ngOnInit(): void {
    this.filterapipaginationcall();
  }

  close() {
    this.dialog.close();
    let element = document.getElementById("vieweditcurriculam-content")
    element.classList.remove('blur-content');
  }

  filterapipaginationcall() {
    // create bodu content for api call
    this.loadspinner = true;
    const endpoint1 = 'enrollment';
    const endpoint2 = 'search';
    this.pagesize = '1';
    this.Rowperpage = parseInt(this.totalitemlength) + 1;
    this.sortby = "loginName";
    this.sortOrder = "ASC";

    let compId = localStorage.getItem('procCompId');
    let cacheddata = localStorage.getItem('role');
    let data;
    if (cacheddata === "System Administrator") {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if (globalcompanyIDselected == '' || globalcompanyIDselected == undefined || globalcompanyIDselected == null) {
        data = {
          "courseId": this.curriculumiId,
          "companyId": "",
          "loginName": "",
          "displayName": "",
          "userStatus": "",
          "email": "",
          "managerName": "",
          "roleName": "",
          "groupName": "",
          "courseStatus": "",
          "enrollmentViewId": ""
        }
      }
      else {
        data = {
          "courseId": this.curriculumiId,
          "companyId": globalcompanyIDselected,
          "loginName": "",
          "displayName": "",
          "userStatus": "",
          "email": "",
          "managerName": "",
          "roleName": "",
          "groupName": "",
          "courseStatus": "",
          "enrollmentViewId": ""
        }
      }

    }
    else {
      data = {
        "courseId": this.curriculumiId,
        "companyId": compId,
        "loginName": "",
        "displayName": "",
        "userStatus": "",
        "email": "",
        "managerName": "",
        "roleName": "",
        "groupName": "",
        "courseStatus": "",
        "enrollmentViewId": ""
      }
    }



    let itemPerPage = this.Rowperpage;
    let body = {
      "filters": data
    }
    // call api get response
    this._Service.Enrollment_filterpaginationpost('1', this.sortby, this.sortOrder, itemPerPage, endpoint1, endpoint2, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
        this.ELEMENT_DATA = response['enrolledUersList'];
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.ELEMENT_DATA.map(i => { i["isSelected"] = false });
        if (this.ELEMENT_DATA != "" && this.ELEMENT_DATA != undefined && this.ELEMENT_DATA != null) {
          for (var i = 0; i < this.ELEMENT_DATA.length; i++) {
            this.ELEMENT_DATA[i].isSelected = false;
          }
        }
        let tempdata = this.ELEMENT_DATA;
        this.selected_users = tempdata;
        this.backup_users = this.selected_users;
        this.listUserApi();
      },
        (err) => {
          this.loadspinner = false;
        });

  }
  listUserApi() {
    let compId = localStorage.getItem('procCompId');
    this.loadspinner = true;
    const endpoint1 = 'users';
    const endpoint2 = 'searchusersfilter';
    let cacheddata = localStorage.getItem('role');
    let body;
    if (cacheddata === "System Administrator") {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if (globalcompanyIDselected == '' || globalcompanyIDselected == undefined || globalcompanyIDselected == null) {
        body = {
          "filters": {
            "firstName": "",
            "lastName": "",
            "loginName": "",
            "displayName": "",
            "email": "",
            "companyId": "",
            "empCode": "",
            "jobPositionName": "",
            "groupName": "",
            "roleName": "",
            "managerName": "",
            "statusName": ""
          }
        }
      }
      else {
        body = {
          "filters": {
            "firstName": "",
            "lastName": "",
            "loginName": "",
            "displayName": "",
            "email": "",
            "companyId": globalcompanyIDselected,
            "empCode": "",
            "jobPositionName": "",
            "groupName": "",
            "roleName": "",
            "managerName": "",
            "statusName": ""
          }
        }
      }

    } else {
      if (cacheddata === "Manager") {
        let loginId = localStorage.getItem('loginId');
        body = {
          "filters": {
            "firstName": "",
            "lastName": "",
            "loginName": "",
            "displayName": "",
            "email": "",
            "companyId": compId,
            "empCode": "",
            "jobPositionName": "",
            "groupName": "",
            "roleName": "",
            "managerName": loginId,
            "statusName": ""
          }
        }
      }
      else {
        body = {
          "filters": {
            "firstName": "",
            "lastName": "",
            "loginName": "",
            "displayName": "",
            "email": "",
            "companyId": compId,
            "empCode": "",
            "jobPositionName": "",
            "groupName": "",
            "roleName": "",
            "managerName": "",
            "statusName": ""
          }
        }
      }
    }

    this._Service.manager_filterpost(endpoint1, endpoint2, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
        let temp_data: any = response["userListFilter"];
        for (var i = 0; i < temp_data.length; i++) {
          temp_data[i].isSelected = false;
        }
        for (var i = 0; i < temp_data.length; i++) {
          for (var j = 0; j < this.selected_users.length; j++) {
            if (temp_data[i].loginName == this.selected_users[j].loginName) {
              temp_data[i].isSelected = true;
            }
          }
        }
        temp_data = temp_data.filter(x => x.isSelected != true);

        this.user_data = temp_data;
        var setValue = this.user_data;
        const dataRemoved = setValue.filter((el) => {
          return el.displayName !== this.lastName + "," + this.lastName;
        });
        this.user_data = dataRemoved;
        this.loadspinner = false;
        this.backup_data = this.user_data;

        this.loadspinner = false;
        const Divname: any = document.getElementById('sid1');
        setTimeout(() => {
          Divname.click();
        }, 1000)
      },
        (err) => {
          this.loadspinner = false;
        });
  }


  search_left(ev) {
    this.user_data = this.backup_data;
    let filterValue = ev;
    this.user_data = this.user_data.filter(option => (option.displayName.toLowerCase().includes(filterValue.toLowerCase()) || option.loginName.toLowerCase().includes(filterValue.toLowerCase())));
  }
  search_right(ev) {
    this.selected_users = this.backup_users;
    let filterValue = ev;
    this.selected_users = this.selected_users.filter(option => (option.displayName.toLowerCase().includes(filterValue.toLowerCase()) || option.loginName.toLowerCase().includes(filterValue.toLowerCase())));
  }
  itemSelected_leftbox(ev) {
    let present = false;
    for (var k = 0; k < this.sel_data.length; k++) {
      if (ev.loginName == this.sel_data[k].loginName) {
        present = true;
        this.sel_data.splice(k, 1);
      }
    }
    if (!present) {
      this.sel_data.push(ev)
    }

    for (var l = 0; l < this.backup_data.length; l++) {
      if (!present) {
        if (ev.loginName == this.backup_data[l].loginName) {
          this.backup_data[l].isSelected = true;
        }
      } else {
        if (ev.loginName == this.backup_data[l].loginName) {
          this.backup_data[l].isSelected = false;
        }
      }
    }
    for (var l = 0; l < this.user_data.length; l++) {
      if (!present) {
        if (ev.loginName == this.user_data[l].loginName) {
          this.user_data[l].isSelected = true;
        }
      } else {
        if (ev.loginName == this.user_data[l].loginName) {
          this.user_data[l].isSelected = false;
        }
      }
    }

  }

  itemSelected_rightbox(ev) {

    let present = false;
    for (var k = 0; k < this.rem_data.length; k++) {
      if (ev.loginName == this.rem_data[k].loginName) {
        present = true;
        this.rem_data.splice(k, 1);
      }
    }
    if (!present) {
      this.rem_data.push(ev)
    }
    for (var l = 0; l < this.backup_users.length; l++) {
      if (!present) {
        if (ev.loginName == this.backup_users[l].loginName) {
          this.backup_users[l].isSelected = true;
        }
      } else {
        if (ev.loginName == this.backup_users[l].loginName) {
          this.backup_users[l].isSelected = false;
        }
      }
    }
    for (var l = 0; l < this.selected_users.length; l++) {
      if (!present) {
        if (ev.loginName == this.selected_users[l].loginName) {
          this.selected_users[l].isSelected = true;
        }
      } else {
        if (ev.loginName == this.selected_users[l].loginName) {
          this.selected_users[l].isSelected = false;
        }
      }
    }
  }

  add_users() {
    if (this.sel_data.length > 0) {

      for (var j = 0; j < this.backup_data.length; j++) {
        if (this.backup_data[j].isSelected) {
          this.selected_users.push(this.backup_data[j])
        }
      }
      this.user_data = this.backup_data;
      this.user_data = this.user_data.filter(x => x.isSelected != true);
      this.sel_data = [];
      for (var m = 0; m < this.backup_data.length; m++) {
        for (var n = 0; n < this.selected_users.length; n++) {
          if (this.selected_users[n].loginName == this.backup_data[m].loginName) {
            this.backup_data[m].isSelected = true;
          }
        }
      }

      this.backup_data = this.backup_data.filter(x => x.isSelected != true);
      this.user_data = this.backup_data;
      for (var j = 0; j < this.selected_users.length; j++) {
        this.selected_users[j].isSelected = false;
      }
      this.backup_users = this.selected_users;
      //setting the serach value
      const name: any = document.getElementById('sid1');
      name.value = "";
      const Divname: any = document.getElementById('sid2');
      setTimeout(() => {
        Divname.click();
        this.selected_users = [...this.selected_users];
      }, 1000)
    }
  }

  remove_user() {

    if (this.rem_data.length != 0) {
      let element = document.getElementById("vieweditcurriculam1-content")
      element.classList.add('blur-content')
      let dialogRef = this.dialogopen.open(DialogpopupComponent, {
        width: '530px',
        height: '335px',
        disableClose: true,
        data:
        {
          title: 'Are you sure you want to withdraw?',
          subtitle: 'Withdrawing the selected user(s) will delete user data associated with the curriculum. It will not affect user requirement completion data. Would you like to proceed?',
          imagelogo: "assets/images/create-u-ser.svg",
          from: "other-confirm-popup"
        },
        backdropClass: 'deletePopup',

        position: {
          top: '27vh'
        }
      });
      dialogRef.afterClosed().subscribe(res => {

        if (res.method == "delete") {
          if (this.rem_data.length > 0) {

            for (var j = 0; j < this.backup_users.length; j++) {
              if (this.backup_users[j].isSelected) {
                this.user_data.push(this.backup_users[j])
              }
            }

            this.selected_users = this.backup_users;
            this.selected_users = this.selected_users.filter(x => x.isSelected != true);
            this.rem_data = [];
            for (var m = 0; m < this.backup_users.length; m++) {
              for (var n = 0; n < this.user_data.length; n++) {
                if (this.user_data[n].loginName == this.backup_users[m].loginName) {
                  this.backup_users[m].isSelected = true;
                }
              }
            }
          }

          this.backup_users = this.backup_users.filter(x => x.isSelected != true);
          this.selected_users = this.backup_users;
          for (var j = 0; j < this.user_data.length; j++) {
            this.user_data[j].isSelected = false;
          }
          this.backup_data = this.user_data;
          const name: any = document.getElementById('sid2');
          name.value = "";
          const Divname: any = document.getElementById('sid1');
          setTimeout(() => {
            Divname.click();
            this.user_data = [...this.user_data]
          }, 1000)
        }
        else if (res.method == "close") {
        }

      })
    }
  }

  create_user() {
    this.loadspinner = true;
    let endpoint = 'enrollment/adduserscurriculum/' + this.curriculumiId;
    let user_list: any = [];

    if (this.backup_users.length > 0) {
      for (var i = 0; i < this.backup_users.length; i++) {
        user_list.push(this.backup_users[i].loginName)
      }
    }

    let body = {

      "enrolledUsersList": user_list
    }
    this._Service.addwithdraw_enrolledCurriculum(endpoint, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
        if (response["message"] === 'success') {
          this.dialog.close('success');

        }
        else {
          this.loadspinner = false;

          let data = {
            status: response["message"]
          };
        }
      },
        (err) => {
          this.loadspinner = false;
        });
  }

}
