import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanyManagementComponent } from 'src/app/Administrator/company-management/company-management.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
@Component({
  selector: 'app-licenceapprovallistfilter',
  templateUrl: './licenceapprovallistfilter.component.html',
  styleUrls: ['./licenceapprovallistfilter.component.scss'],
  providers: [DatePipe]
})
export class LicenceapprovallistfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  apibodycontent: any;
  disablebutton: any;
  today = new Date();
  enddateconsistnull: any;
  enddateconsistnullApproveddenied: any;
  isDisabled: boolean;
  backupcompanylistdata:any;
  constructor(private datePipe: DatePipe, private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<CompanyManagementComponent>) {
    this.filterform = this.formBuilder.group({
      CompanyId: [''],
      Name: [''],
      LicenseRequested: [''],
      start: [''],
      end: [''],
      startApproveddenied: [''],
      endApproveddenied: [''],
      status: [''],
      ApprovedDeniedBy: [''],
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
  }

  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    this.apiService.Simpleget(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.companylistdata = response["companies"];
        this.backupcompanylistdata=this.companylistdata;
        this.statuslist = [
          { "status_id": 1, "statusName": "Approved" },
          { "status_id": 2, "statusName": "Denied" },
        ]
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
          

        });
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('Manager_filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton = true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton = false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['CompanyId'].setValue(data["Company (COID)"]);
      this.filterform.controls['Name'].setValue(data["Name"]);
      this.filterform.controls['LicenseRequested'].setValue(data["License Requested"]);
      this.filterform.controls['status'].setValue(data["Status"]);
      this.filterform.controls['ApprovedDeniedBy'].setValue(data["Approved/Denied By"]);

      // for date range date selected
      let a = data["Date Requested"].split('-')[0];
      let b = data["Date Requested"].split('-')[1];
      if (data["Date Requested"] === null || data["Date Requested"] === undefined || data["Date Requested"] === "") {
        this.filterform.controls['start'].setValue('');
        this.filterform.controls['end'].setValue("");

      } else {
        this.filterform.controls['start'].setValue(new Date(a));
        this.filterform.controls['end'].setValue(new Date(b));
      }

      // for date range date approved/denied
      let c = data["Date Approved/denied"].split('-')[0];
      let d = data["Date Approved/denied"].split('-')[1];
      if (data["Date Approved/denied"] === null || data["Date Approved/denied"] === undefined || data["Date Approved/denied"] === "") {
        this.filterform.controls['startApproveddenied'].setValue("");
        this.filterform.controls['endApproveddenied'].setValue("");
      } else {
        this.filterform.controls['startApproveddenied'].setValue(new Date(c));
        this.filterform.controls['endApproveddenied'].setValue(new Date(d));
      }
      this.clearfilterfunction();
    }

    let compId = localStorage.getItem('procCompId');
    let compName = localStorage.getItem('proccompanyName');
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      let data = compName + ' ' + '(' + compId + ')'
      this.filterform.controls['CompanyId'].setValue(data);
      this.isDisabled = true;
      this.disablebutton = false;
    }
    else {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      let globalcompanynameselected = localStorage.getItem('globalcompanynameselected');
      if (globalcompanyIDselected == '' || globalcompanyIDselected == undefined || globalcompanyIDselected == null) {
        this.isDisabled = false;
      }
      else {
        let data = globalcompanynameselected + ' ' + '(' + globalcompanyIDselected + ')'
        this.filterform.controls['CompanyId'].setValue(data);
        this.isDisabled = true;
        this.disablebutton = false;
      }
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('Manager_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if (clearfilteritemkey[i] === "Company (COID)") {
          this.filterform.controls['CompanyId'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Name") {
          this.filterform.controls['Name'].setValue("");
        }
        else if (clearfilteritemkey[i] === "License Requested") {
          this.filterform.controls['LicenseRequested'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Date Requested") {
          this.filterform.controls['start'].setValue("");
          this.filterform.controls['end'].setValue("");
        }

        else if (clearfilteritemkey[i] === "Date Approved/denied") {
          this.filterform.controls['startApproveddenied'].setValue("");
          this.filterform.controls['endApproveddenied'].setValue("");
        }

        else if (clearfilteritemkey[i] === "Status") {
          this.filterform.controls['status'].setValue("");
        }

        else if (clearfilteritemkey[i] === "Approved/Denied By") {
          this.filterform.controls['ApprovedDeniedBy'].setValue("");
        }
        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  // on keyup enable filter button
  selectCompanyID(value) {
     // update company list to default
     let divID= document.getElementById("inputpagenocompany");
     if(divID !=null && divID !=undefined){
     (document.getElementById("inputpagenocompany") as HTMLInputElement).value = "";
     }
     this.companylistdata = this.backupcompanylistdata;
     
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['CompanyId'].setValue("");

    }
    else {
      this.disablebutton = false;
    }
    this.dynamicEnableDisablebtn();
  }

  inputchangeID2(value) {
    this.dynamicEnableDisablebtn()
  }

  startChange(event) {
    
  }

  endChange(event) {
    
    if (event.value === null) {
      this.disablebutton = true;
      this.enddateconsistnull = true;
    }
    else {
      this.enddateconsistnull = false;
      this.dynamicEnableDisablebtn();
    }
  }

  startChangeApproveddenied(event) {
    
  }

  endChangeApproveddenied(event) {
    
    if (event.value === null) {
      this.disablebutton = true;
      this.enddateconsistnullApproveddenied = true;
    }
    else {
      this.enddateconsistnullApproveddenied = false;
      this.dynamicEnableDisablebtn();
    }
  }


  inputchangeID5(value) {
    this.dynamicEnableDisablebtn()
  }

  inputchangeID7(value) {
    this.dynamicEnableDisablebtn()
  }

  selectStatusName(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['status'].setValue("");
    }
    else {
      this.disablebutton = false;
    }
    this.dynamicEnableDisablebtn();
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('Manager_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let filterdata = this.getbodycontent();
      localStorage.setItem('Manager_filterdata', JSON.stringify(filterdata));
      localStorage.removeItem('Manager_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('Manager_filterdata');
      localStorage.removeItem('Manager_chipdisplayfilterdata');
      localStorage.removeItem('Manager_clearfilteritemkey');
      let filterdata = this.getbodycontent();
      localStorage.setItem('Manager_filterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
    }

  }

  EnterSubmit(event) {
    if (event.keyCode === 13) {
      this.dynamicEnableDisablebtn();
      if (this.disablebutton === true) {
        event.preventDefault();
      }
      else {
        event.preventDefault();
        this.Filter();
      }

    }
  }

  getbodycontent() {
    let daterange = "";
    let startdate = "";
    let enddate = "";
    let apistartdate = "";
    let apienddate = "";

    let startApprovedDeniedDate = "";
    let endApprovedDeniedDate = "";
    let apistartApprovedDeniedDate = "";
    let apiendApprovedDeniedDate = "";
    let daterangeapproveddenied = "";


    // date requested
    if (this.filterform.value.start === "" || this.filterform.value.start === undefined || this.filterform.value.start === null) {
      daterange = "";
    }
    else {
      startdate = this.datePipe.transform(this.filterform.value.start, 'MM/dd/yyyy');
      enddate = this.datePipe.transform(this.filterform.value.end, 'MM/dd/yyyy');
      daterange = startdate + " - " + enddate;

      apistartdate = this.datePipe.transform(this.filterform.value.start, 'yyyy-MM-dd');
      apienddate = this.datePipe.transform(this.filterform.value.end, 'yyyy-MM-dd');
    }

    // date approved/denied
    if (this.filterform.value.startApproveddenied === "" || this.filterform.value.startApproveddenied === undefined || this.filterform.value.startApproveddenied === null) {
      daterangeapproveddenied = "";
    }
    else {
      startApprovedDeniedDate = this.datePipe.transform(this.filterform.value.startApproveddenied, 'MM/dd/yyyy');
      endApprovedDeniedDate = this.datePipe.transform(this.filterform.value.endApproveddenied, 'MM/dd/yyyy');
      daterangeapproveddenied = startApprovedDeniedDate + " - " + endApprovedDeniedDate;

      apistartApprovedDeniedDate = this.datePipe.transform(this.filterform.value.startApproveddenied, 'yyyy-MM-dd');
      apiendApprovedDeniedDate = this.datePipe.transform(this.filterform.value.endApproveddenied, 'yyyy-MM-dd');
    }

    let CompanyID = "";
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      CompanyID = "";
    }
    else {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if (globalcompanyIDselected == '' || globalcompanyIDselected == undefined || globalcompanyIDselected == null) {
        CompanyID = this.filterform.value.CompanyId
      }
      else {
        CompanyID = "";
      }
    }

    let filterdata = {
      "Company (COID)": CompanyID,
      "Name": this.filterform.value.Name.trim(),
      "Date Requested": daterange,
      "Date Approved/denied": daterangeapproveddenied,
      "License Requested": this.filterform.value.LicenseRequested.trim(),
      "Status": this.filterform.value.status.trim(),
      'Approved/Denied By': this.filterform.value.ApprovedDeniedBy.trim()
    }
    this.getapibodycontent(daterange, apistartdate, apienddate, daterangeapproveddenied, apistartApprovedDeniedDate, apiendApprovedDeniedDate);
    return filterdata

  }

  getapibodycontent(daterange, apistartdate, apienddate, daterangeapproveddenied, apistartApprovedDeniedDate, apiendApprovedDeniedDate) {
    let companylistdataid = "";
    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i].companyName + ' ' + '(' + this.companylistdata[i].companyId + ')' === this.filterform.value.CompanyId) {
        companylistdataid = this.companylistdata[i].companyId;
      }
    }
    this.apibodycontent = {
      "companyId": companylistdataid,
      "requestorName": this.filterform.value.Name.trim(),
      "licenseRequested": this.filterform.value.LicenseRequested.trim(),
      "status": this.filterform.value.status.trim(),
      "approvedDeniedBy": this.filterform.value.ApprovedDeniedBy.trim(),

      "startCompletionDate": apistartdate,
      "endCompletionDate": apienddate,
      "startApprovedDeniedDate": apistartApprovedDeniedDate,
      "endApprovedDeniedDate": apiendApprovedDeniedDate
    }
  }


  checkformvalue() {
    let formempty;
    if ((this.filterform.value.CompanyId.trim() === "") && (this.filterform.value.Name.trim() === "") &&
      (this.filterform.value.LicenseRequested.trim() === "") && (this.filterform.value.start === "" || this.filterform.value.start === null) &&
      (this.filterform.value.end === "" || this.filterform.value.end === null) && (this.filterform.value.status.trim() === "") &&
      (this.filterform.value.ApprovedDeniedBy.trim() === "") && (this.filterform.value.startApproveddenied === "" || this.filterform.value.startApproveddenied === null) &&
      (this.filterform.value.endApproveddenied === "" || this.filterform.value.endApproveddenied === null)) {
      formempty = true;

    }
    else {
      formempty = false;
      if (this.enddateconsistnull === true || this.enddateconsistnullApproveddenied === true) {
        formempty = true;
      }

    }
    return formempty;
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }

  onKeyCompany(value) {
    if(value.trim().length===0){
      this.companylistdata=this.backupcompanylistdata;
    }
    else{
      const filterValue = value.toLowerCase();
      let data = this.backupcompanylistdata.filter(option => option.companyName.toLowerCase().includes(filterValue) ||
      option.companyId.toLowerCase().includes(filterValue));
      this.companylistdata = data;
    }
  }

  onKeyDownCompany(event) {
    // to disable mat select default search functionality
    event.stopImmediatePropagation();
  }

}
