import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { VieworedituserComponent } from 'src/app/Administrator/user/vieworedituser/vieworedituser.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-suspendeddetails',
  templateUrl: './suspendeddetails.component.html',
  styleUrls: ['./suspendeddetails.component.scss']
})
export class SuspendeddetailsComponent implements OnInit {
  displayedColumns: string[] = ['datePerformed','commentBy','comments','notesBy', 'notes'];
  dataSource: any;
  loadspinner: any = false;
  ELEMENT_DATA: any = [];
  loginName:any = '';

  constructor(public dialog: MatDialogRef<VieworedituserComponent>,@Inject(MAT_DIALOG_DATA) public data: string,private apiService: ApiServiceService) {
    this.loginName=data['loginId'];
   }

  ngOnInit(): void {
    this.default_data();
  }

  default_data() {
    this.loadspinner = true;
    const endpoint1 = 'users';
    const endpoint2 = 'getsuspenddatalist/' + this.loginName;
    this.apiService.getSuspendedcomments(endpoint1,endpoint2, 'GET').subscribe((response) => {
        this.ELEMENT_DATA = response['commentsList'];
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.loadspinner = false;
      },(err) => {
        this.loadspinner = false;
      });
  }

  cancelbtn(){
    this.dialog.close();
  }

}
