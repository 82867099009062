<div class="spinner_overlay" *ngIf="loadspinner === true"></div>
<mat-spinner class="spinner" *ngIf="loadspinner === true"></mat-spinner>

<div class="filtermaincontainer" (keydown)="EnterSubmit($event)">
  <div class="row headerrow">
    <div class="col-10">
      <img src="assets/images/filter-filled-tool-symbol.svg" alt="filter" />
      <span class="heading">&nbsp;&nbsp;Filter</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()"
        >clear</mat-icon
      >
    </div>
  </div>

  <form [formGroup]="filterform">
    <div class="row">
      <div class="col-12 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">User Name</mat-label>
        <mat-form-field
          appearance="outline"
          id="formfieldusernameid"
          class="formfieldusertable"
        >
          <input
            matInput
            placeholder="Enter here"
            autocomplete="off"
            #inpgroupname
            (keyup)="inputchangegroupname(inpgroupname.value)"
            class="textinputusertablefilter"
            formControlName="userName"
          />
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelnamec">Company ID</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertableC">
          <input
            matInput
            placeholder="Enter here"
            autocomplete="off"
            #inpgroupname
            class="textinputusertablefilterc"
            formControlName="CompanyId"
            readonly
          />
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Job Position</mat-label>
        <mat-form-field
          appearance="outline"
          id="formfieldusernameid"
          class="formfieldusertable"
        >
          <input
            matInput
            placeholder="Enter here"
            autocomplete="off"
            #inpgroupID
            (keyup)="inputchangegroupID(inpgroupID.value)"
            class="textinputusertablefilterGroupID"
            formControlName="jobPosition"
          />
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Assigned Groups</mat-label>
        <mat-form-field
          appearance="outline"
          id="formfieldusernameid"
          class="formfieldusertable"
        >
          <input
            matInput
            placeholder="Enter here"
            autocomplete="off"
            #inpgroupID
            (keyup)="inputchangegroupID(inpgroupID.value)"
            class="textinputusertablefilterGroupID"
            formControlName="assignedGroups"
          />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-12 inputcolumn">
        <mat-label class="labelname">Date Added</mat-label><br />
        <mat-form-field
          floatLabel="always"
          appearance="none"
          class="formfieldusertable"
        >
          <mat-date-range-input
            [rangePicker]="picker"
            (click)="picker.open()"
            style="cursor: pointer"
            placeholder="mm/dd/yyyy"
            class="textinputusertablefilterdate"
          >
            <input
              matStartDate
              matInput
              (dateChange)="changeStartDate($event)"
              style="cursor: pointer"
              placeholder="mm/dd/yyyy"
              formControlName="Startdate"
              readonly
            />
            <input
              matEndDate
              matInput
              (dateChange)="changeEndDate($event)"
              style="cursor: pointer"
              placeholder="mm/dd/yyyy"
              formControlName="Enddate"
              readonly
            />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker">
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6 cancelbtncol">
        <br /><br />
        <button
          mat-button
          id="btncancelid"
          class="cancelbtn"
          (click)="cancelbtn()"
        >
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel
        </button>
      </div>
      <div class="col-6 Filterbtncol">
        <br /><br />
        <button
          mat-button
          id="btnfiltertermid"
          class="Filterbtn"
          [disabled]="disablebutton"
          (click)="Filter()"
        >
          <mat-icon class="filterbtnicon">done</mat-icon>
          Filter
        </button>
      </div>
    </div>
  </form>
</div>
