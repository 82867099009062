<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>

<div class="awardcompletion-container">
  <div class="awardcompletion">
    <div class="row headerrow"> 
      <div class="col-10">
        <span class="heading">&nbsp;&nbsp;Award Completion</span>
      </div>
  
      <div class="col-2 cleariconcol">
        <mat-icon class="clearicon" alt="clear" (click)="close()">clear</mat-icon>
      </div>
    </div>
    <div class="content-cls">
      <form  #form="ngForm" [formGroup]="AwardForm" novalidate>
    <div class="row">
      <div class="col-3">
          <mat-label class="labelname">Type
          </mat-label>
        </div>
        <div class="col-7">
          <mat-form-field appearance="outline"  class="form-field">
          <mat-select matNativeControl  #awardType
          formControlName="awardType" (selectionChange)="typeChange($event.value)"
          placeholder="Select Type"  panelClass="myPanelmatselectClass" disableOptionCentering>
          <mat-option *ngFor="let item of Types" [value]="item" class="create_select">
          {{item}} 
          </mat-option>
          </mat-select>
          </mat-form-field>
        
        </div>
        <div class="col-2">
          </div>
        </div>
        <div class="row">
        <div class="col-3">
          <mat-label class="labelname">Title<span class="required"> * </span>
          </mat-label>
        </div>
        <div class="col-7">
          <mat-form-field appearance="outline"  class="form-field">
          <mat-select matNativeControl #awardTitle
          formControlName="awardTitle"
          placeholder="Select Title"  panelClass="myPanelmatselectClass" disableOptionCentering>
          <mat-option *ngFor="let item of titlesList" [value]="item" class="create_select">
          {{item.title}} 
          </mat-option>
          </mat-select>
          <mat-error
          *ngIf="AwardForm.get('awardTitle').hasError('required') && submit_click"
          class="mat-error">
          <img src="assets/images/error.svg" class="errormsgicon" alt="error icon">
          &nbsp; Please select title! <br>
          </mat-error>
          </mat-form-field>
         
          
      
        </div>
        <div class="col-2">
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <mat-label class="labelname">Completion Date<span class="required"> * </span>
          </mat-label>
        </div>
        <div class="col-7">
          <mat-form-field appearance="outline"  class="form-field">
            <input matInput [matDatepicker]="picker"  #completionDate
            formControlName="completionDate" [max]="maxdate"
            class="textinput" (click)="picker.open()" (dateChange)="dateValid()" placeholder="mm/dd/yyyy" readonly>
            <mat-datepicker-toggle matSuffix [for]="picker" class="hideshowicon">
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error
            *ngIf="AwardForm.get('completionDate').hasError('required') && submit_click"
            class="mat-error">
            <img src="assets/images/error.svg" class="errormsgicon" alt="error icon">
            &nbsp; Please select completion date! <br>
            </mat-error>
          
          </mat-form-field>
        
        </div>
        <div class="col-2">
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <mat-label class="labelname">Status<span class="required"> * </span>
          </mat-label>
        </div>
        <div class="col-7">
          <mat-form-field appearance="outline"  class="form-field">
          <mat-select matNativeControl #awardStatus
          formControlName="awardStatus"
          placeholder="Select Status"  panelClass="myPanelmatselectClass" disableOptionCentering>
          <mat-option *ngFor="let item of statusTypes" [value]="item" class="create_select">
          {{item}} 
          </mat-option>
          </mat-select>
          <mat-error
          *ngIf="AwardForm.get('awardStatus').hasError('required') && submit_click"
          class="mat-error">
          <img src="assets/images/error.svg" class="errormsgicon" alt="error icon">
          &nbsp; Please select status! <br>
          </mat-error>
          </mat-form-field>
         
        </div>
        <div class="col-2">
        </div>
        </div>
        <div class="row">
        <div class="col-3">
          <mat-label class="labelname">Attachment
          </mat-label>
          </div>
          <div class="col-9 file-btn-outer">
            <button type="button" class="file-button" mat-raised-button (click)="fileInput.click()"> <mat-icon class="attach-icon"> attach_file</mat-icon>Choose File</button>
            <input hidden (change)="onFileSelect($event)" #fileInput type="file" id="file">
            <span class="file-name" *ngIf="api_fileName!=''">{{api_fileName}}<mat-icon (click)="removeFile()">close</mat-icon></span>
           </div>
         
        </div>
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 cancel-btn-col"><br><br>
            <button mat-button class="cancel-btn" (click)="close()">
              <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
              Cancel
            </button>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol"><br><br>
            <button mat-button class="Filterbtn" (click)="submit()">
              <mat-icon class="filterbtnicon">done</mat-icon>
              Submit
            </button>
          </div>
        </div>
        </form>
    </div>
</div>
</div>
