import { Component, EventEmitter, OnInit, Inject, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogpopupComponent } from '../dialog-popup/dialog-popup.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UserComponent } from 'src/app/Administrator/user/user.component';
@Component({
  selector: 'app-sequence',
  templateUrl: './sequence.component.html',
  styleUrls: ['./sequence.component.scss']
})
export class SequenceComponent implements OnInit {
  sequence_data:any='';
  show_msg:any='';
  constructor(public sanitizer: DomSanitizer,public dialog: MatDialogRef<UserComponent>, @Inject(MAT_DIALOG_DATA) public data: string, public dialogopen: MatDialog) {
   this.sequence_data=this.data['sequence_array'];
   this.show_msg=this.data['show_msg']
  }
 
  ngOnInit(): void {
  }

  cancel(msg){
    this.dialog.close({method: msg});
  }

}