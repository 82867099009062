import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CreatetasklistnameComponent } from 'src/app/Administrator/tasks/createtasklistname/createtasklistname.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { DialogpopupComponent } from '../../dialog-popup/dialog-popup.component';
@Component({
  selector: 'app-apply-rules',
  templateUrl: './apply-rules.component.html',
  styleUrls: ['./apply-rules.component.scss']
})
export class ApplyRulesComponent implements OnInit {
  panelOpenState = false;
  panelOpenStateAttempts = false;
  panelOpenStateExpiration = false;
  loadspinner: any;
  title_list: any = [];
  isDisabled = false;
  dropDownnoAttempt= [{ 'name': 'New Day', 'id': 'D' }, { 'name': '24-hr Period', 'id': 'H' }, { 'name': 'Months', 'id': 'M' }];
  dropDown = [{ 'name': 'Days', 'id': 'D' }, { 'name': 'Months', 'id': 'M' }, { 'name': 'Year', 'id': 'Y' }];
  dropDown2 = [{ 'name': 'Days', 'id': 'D' }, { 'name': 'Months', 'id': 'M' }];
  //oqcs-4225 23/01/2024
  dropDown1 = [{ 'name': 'End of month', 'id': 'E' }, { 'name': 'End of Year', 'id': 'A' }]
  counter = 0;
  from: any = '';
  requirementName: any = '';
  taskid: any = '';
  req_selectall = false;
  requirement_selectall_right = false;
  enableProctor = 'no';
  attemptsno: any = '1';
  noOfAttempt = false;
  expiration1 = 'D';
  expiration1Input: any = '0';
  retake = 'D';
  retakeinput: any = '0';
  gracePeriod = false;
  graceperiod1 = 'E';
  graceperiod2input = '0';
  graceperiod2 = 'D';
  graceperiod3input: any = '0';
  graceperiod3 = 'D';
  sel_graceperiod1 = 'sel_graceperiod1';
  sel_graceperiod2 = '';
  tasklistId: any = '';
  taskId: any = '';
  groupId: any = '';
  requirementId: any = '';
  requirementType: any = '';
  ruleId: any = '';
  rule_info: any;
  taskreqId: any = '';
  sel_prerequisites: any = [];
  prerequisites: any = [];
  requirements_list: any = [];
  selected_prerequisites: any = [];
  req_backup_right: any = [];
  requirement_backup: any = [];
  rem_req: any = [];
  dynamic_attempt: any = [];
  specificField_disable = false;
  category: any = '';
  constructor(public dialog: MatDialogRef<CreatetasklistnameComponent>, public matdialog: MatDialog, private apiService: ApiServiceService, private router: Router, @Inject(MAT_DIALOG_DATA) public data: string) {

    this.loadspinner = true;
    this.from = data['from'];
    if (data['ruleId'] != '') {
      this.ruleId = data['ruleId'];
    } else {
      this.ruleId = 0;
    }
    this.noOfAttempt = data['enrolledTask'];
    this.rule_info = data['ruleInfo'];
    this.groupId = data['groupId'];
    this.requirementId = data['requirementId'];
    this.requirementType = data['requirementType'];
    this.requirementName = data['requirementName'];
    this.taskId = data['taskId'];
    this.tasklistId = data['taskListId'];
    this.category = data['category'];
    if (this.rule_info != '') {
      if (this.rule_info.proctorEnabled == true) {
        this.enableProctor = 'yes'
      } else {
        this.enableProctor = 'no'
      }

      this.attemptsno = this.rule_info.attempts;
      let temp_retake: any = this.rule_info.retakeAfterData;
      if (temp_retake != null && temp_retake != undefined && temp_retake != '') {
        let t_data = JSON.parse(temp_retake);

        this.dynamic_attempt = t_data.retakeAfterData;
      }
      this.expiration1 = this.rule_info.expirationAfterUnit;
      this.expiration1Input = this.rule_info.expirationAfter;
      this.gracePeriod = this.rule_info.graceEnabled;
      this.graceperiod2input = this.rule_info.gracePeriod;
      if (this.rule_info.prerequisites != null) {
        this.prerequisites = this.rule_info.prerequisites;
      }

      this.graceperiod3input = this.rule_info.availableBefore;
      if (this.rule_info.availableBeforeUnit != undefined && this.rule_info.availableBeforeUnit != null && this.rule_info.availableBeforeUnit != '') {
        this.graceperiod3 = this.rule_info.availableBeforeUnit;
      } else {
        this.graceperiod3 = 'D'
      }

      if (this.rule_info.gracePeriodUnit == 'E' || this.rule_info.gracePeriodUnit == 'e' || this.rule_info.gracePeriodUnit == 'A') {
        this.sel_graceperiod1 = 'sel_graceperiod1';
        this.sel_graceperiod2 = '';
        this.graceperiod1 = this.rule_info.gracePeriodUnit;
        this.graceperiod2 = 'D'
      } else if (this.rule_info.gracePeriodUnit == '') {
        this.sel_graceperiod1 = 'sel_graceperiod1';
        this.graceperiod2 = this.rule_info.gracePeriodUnit;
        this.sel_graceperiod2 = '';
        this.graceperiod2 = 'D'
      } else {
        this.sel_graceperiod1 = '';
        this.graceperiod2 = this.rule_info.gracePeriodUnit;
        this.sel_graceperiod2 = 'sel_graceperiod2';
        this.graceperiod2 = 'D'
      }
    }


    if (this.from == 'apply') {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }

    if (this.ruleId != 0 && this.rule_info == '') {
      this.get_rulesInfo();
    } else {
      this.get_defaultvalues();

    }
  }

  get_defaultvalues() {
    //  let companyId= localStorage.getItem("user_company")
    let companyId = localStorage.getItem("companyIdForReq");
    this.loadspinner = true;
    const loginName = localStorage.getItem('loginId');
    const endpoint1 = 'task/getcourseformList';
    let body: any = {
      "companyId": companyId
    }

    this.apiService.getTaskRequirement_post(endpoint1, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
        let req_list: any = response['courseFormsListData']
        req_list.forEach(element => {
          if (element.requirementName != null && (element.requirementId !== this.requirementId)) {
            this.requirements_list.push(element)
          }
        })

        this.requirements_list.forEach(element => {
          element.isSelected = false;
        });

        let temp_reqdata: any = [];
        for (var i = 0; i < this.requirements_list.length; i++) {
          for (var j = 0; j < this.prerequisites.length; j++) {
            if (this.requirements_list[i].requirementId == this.prerequisites[j].requirementId) {
              this.sel_prerequisites.push(this.requirements_list[i]);
              this.requirements_list[i].isSelected = true;
              temp_reqdata.push(this.requirements_list[i])
            }
          }
        }

        this.requirements_list = this.requirements_list.filter(x => x.isSelected != true);
        this.requirement_backup = this.requirements_list;
        temp_reqdata.forEach(element => {
          element.isSelected = false;
        })
        this.selected_prerequisites = temp_reqdata;
        this.req_backup_right = this.selected_prerequisites;

        this.loadspinner = false;
      },
        (err) => {

          this.loadspinner = false;
        }
      );
  }
  /*prerequisites box */
  title_search_left(ev) {

    this.requirements_list = this.requirement_backup;
    let filterValue = ev;
    this.requirements_list = this.requirements_list.filter(option => (option.requirementName.toLowerCase().includes(filterValue.toLowerCase())));
  }

  title_search_right(ev) {

    this.selected_prerequisites = this.req_backup_right;
    let filterValue = ev;
    this.selected_prerequisites = this.selected_prerequisites.filter(option => (option.requirementName.toLowerCase().includes(filterValue.toLowerCase())));

  }

  title_selected_leftbox(ev) {

    let instance = this;
    instance.req_selectall = null;
    let present = false;
    for (var k = 0; k < instance.sel_prerequisites.length; k++) {
      if (ev.requirementId == instance.sel_prerequisites[k].requirementId) {
        present = true;
        instance.sel_prerequisites.splice(k, 1);
      }
    }
    if (!present) {
      instance.sel_prerequisites.push(ev)
    }

    for (var l = 0; l < instance.requirement_backup.length; l++) {
      if (!present) {
        if (ev.requirementId == instance.requirement_backup[l].requirementId) {
          instance.requirement_backup[l].isSelected = true;
        }
      } else {
        if (ev.requirementId == instance.requirement_backup[l].requirementId) {
          instance.requirement_backup[l].isSelected = false;
        }
      }
    }
    for (var l = 0; l < instance.requirements_list.length; l++) {
      if (!present) {
        if (ev.requirementId == instance.requirements_list[l].requirementId) {
          instance.requirements_list[l].isSelected = true;
        }
      } else {
        if (ev.requirementId == instance.requirements_list[l].requirementId) {
          instance.requirements_list[l].isSelected = false;
        }
      }
    }


    if (this.requirements_list.length == this.sel_prerequisites.length) {
      this.req_selectall = true;
    }

  }


  title_selected_rightbox(ev) {
    this.requirement_selectall_right = false;
    let present = false;
    for (var k = 0; k < this.rem_req.length; k++) {
      if (ev.requirementId == this.rem_req[k].requirementId) {
        present = true;
        this.rem_req.splice(k, 1);
      }
    }
    if (!present) {
      this.rem_req.push(ev)
    }
    for (var l = 0; l < this.req_backup_right.length; l++) {
      if (!present) {
        if (ev.requirementId == this.req_backup_right[l].requirementId) {
          this.req_backup_right[l].isSelected = true;
        }
      } else {
        if (ev.requirementId == this.req_backup_right[l].requirementId) {
          this.req_backup_right[l].isSelected = false;
        }
      }
    }
    for (var l = 0; l < this.selected_prerequisites.length; l++) {
      if (!present) {
        if (ev.requirementId == this.selected_prerequisites[l].requirementId) {
          this.selected_prerequisites[l].isSelected = true;
        }
      } else {
        if (ev.requirementId == this.selected_prerequisites[l].requirementId) {
          this.selected_prerequisites[l].isSelected = false;
        }
      }
    }
    if (this.selected_prerequisites.length == this.rem_req.length) {
      this.requirement_selectall_right = true;
    }
  }

  add_titles() {
    this.req_selectall = null;
    this.requirement_selectall_right = null;
    if (this.sel_prerequisites.length > 0) {
      for (var j = 0; j < this.requirement_backup.length; j++) {
        if (this.requirement_backup[j].isSelected) {
          this.selected_prerequisites.push(this.requirement_backup[j])
        }
      }


      this.requirements_list = this.requirement_backup;
      this.requirements_list = this.requirements_list.filter(x => x.isSelected != true);
      this.sel_prerequisites = [];
      for (var m = 0; m < this.requirement_backup.length; m++) {
        for (var n = 0; n < this.selected_prerequisites.length; n++) {
          if (this.selected_prerequisites[n].requirementId == this.requirement_backup[m].requirementId) {
            this.requirement_backup[m].isSelected = true;
          }
        }
      }

      this.requirement_backup = this.requirement_backup.filter(x => x.isSelected != true);
      this.requirements_list = this.requirement_backup;
      for (var j = 0; j < this.selected_prerequisites.length; j++) {
        this.selected_prerequisites[j].isSelected = false;
      }
      this.req_backup_right = this.selected_prerequisites;
       //setting the serach value
       const name: any = document.getElementById('sid1');
       name.value = "";
       const Divname: any = document.getElementById('sid2');
       setTimeout(() => {
         Divname.click();
         this.selected_prerequisites = [...this.selected_prerequisites];
       }, 1000)

    }

  }

  remove_titles() {

    this.req_selectall = null;
    this.requirement_selectall_right = null;
    if (this.rem_req.length > 0) {

      for (var j = 0; j < this.req_backup_right.length; j++) {
        if (this.req_backup_right[j].isSelected) {
          this.requirements_list.push(this.req_backup_right[j])
        }
      }

      this.selected_prerequisites = this.req_backup_right;
      this.selected_prerequisites = this.selected_prerequisites.filter(x => x.isSelected != true);
      this.rem_req = [];
      for (var m = 0; m < this.req_backup_right.length; m++) {
        for (var n = 0; n < this.requirements_list.length; n++) {
          if (this.requirements_list[n].requirementId == this.req_backup_right[m].requirementId) {
            this.req_backup_right[m].isSelected = true;
          }
        }
      }
    }


    this.req_backup_right = this.req_backup_right.filter(x => x.isSelected != true);
    this.selected_prerequisites = this.req_backup_right;
    for (var j = 0; j < this.requirements_list.length; j++) {
      this.requirements_list[j].isSelected = false;
    }
    this.requirement_backup = this.requirements_list;
     //setting the serach value
     const name: any = document.getElementById('sid2');
     name.value = "";
     const Divname: any = document.getElementById('sid1');
     setTimeout(() => {
       Divname.click();
       this.requirements_list = [...this.requirements_list]
     }, 1000)
  }

  reqAll(ev) {
    if (this.req_selectall == true) {
      this.requirements_list.forEach(element => {
        element.isSelected = true;
      })
      this.sel_prerequisites = [];
      //this.requirement_backup = [];
      this.requirements_list.forEach(element => {
        this.sel_prerequisites.push(element);
        //this.requirement_backup.push(element)
      })

    } else if (this.req_selectall == false) {
      this.requirements_list.forEach(element => {
        element.isSelected = false;
      })

      this.sel_prerequisites = [];
      //this.requirement_backup = this.requirements_list;
      this.requirements_list.forEach(element => {
        this.sel_prerequisites.push(element);
      })
    }

  }
  reqAll_right(ev) {
    if (this.requirement_selectall_right == true) {
      this.selected_prerequisites.forEach(element => {
        element.isSelected = true;
      })
      this.rem_req = [];
      //this.req_backup_right = [];
      this.selected_prerequisites.forEach(element => {
        this.rem_req.push(element);
        //this.req_backup_right.push(element)
      })

    } else if (this.requirement_selectall_right == false) {
      this.selected_prerequisites.forEach(element => {
        element.isSelected = false;
      })

      this.rem_req = [];
      //this.req_backup_right = this.selected_prerequisites;
      this.selected_prerequisites.forEach(element => {
        this.rem_req.push(element);
      })
    }

  }
  /*pre requisites box */
  get_rulesInfo() {


    let endpoint1 = '/rules/requirement?ruleId=' + this.ruleId
    this.apiService.get_requirementstask(endpoint1, 'GET')
      .subscribe((response) => {
        
        let reqlist: any = response;

        if (reqlist != '') {
          if (reqlist.proctorEnabled == true) {
            this.enableProctor = 'yes'
          } else {
            this.enableProctor = 'no'
          }
          this.attemptsno = reqlist.attempts;
          
          if (this.noOfAttempt == true) {
            this.specificField_disable = true;
          } else {
            this.specificField_disable = false;
          }
          let temp_retake: any = reqlist.retakeAfterData;
          if (temp_retake != null && temp_retake != undefined && temp_retake != '') {
            let t_data = JSON.parse(temp_retake);

            this.dynamic_attempt = t_data.retakeAfterData;
          }
          this.expiration1 = reqlist.expirationAfterUnit;
          this.expiration1Input = reqlist.expirationAfter;
          this.gracePeriod = reqlist.graceEnabled;
          this.graceperiod2input = reqlist.gracePeriod;
          if (reqlist.prerequisites != null) {
            this.prerequisites = reqlist.prerequisites;
          }

          this.graceperiod3input = reqlist.availableBefore;
          if (reqlist.availableBeforeUnit != undefined && reqlist.availableBeforeUnit != null && reqlist.availableBeforeUnit != '') {
            this.graceperiod3 = reqlist.availableBeforeUnit;
          } else {
            this.graceperiod3 = 'D'
          }

          if (reqlist.gracePeriodUnit == 'E' || reqlist.gracePeriodUnit == 'e' || reqlist.gracePeriodUnit == 'A') {
            this.sel_graceperiod1 = 'sel_graceperiod1';
            this.sel_graceperiod2 = '';
            this.graceperiod1 = reqlist.gracePeriodUnit;
            this.graceperiod2 = 'D'
          } else if (reqlist.gracePeriodUnit == '') {
            this.sel_graceperiod1 = 'sel_graceperiod1';
            this.graceperiod2 = reqlist.gracePeriodUnit;
            this.sel_graceperiod2 = '';
            this.graceperiod2 = 'D'
          } else if (reqlist.gracePeriodUnit == 'M') {
            this.sel_graceperiod1 = '';
            this.graceperiod2 = reqlist.gracePeriodUnit;
            this.sel_graceperiod2 = 'sel_graceperiod2';
            this.graceperiod2 = 'M'
          } else {
            this.sel_graceperiod1 = '';
            this.graceperiod2 = reqlist.gracePeriodUnit;
            this.sel_graceperiod2 = 'sel_graceperiod2';
            this.graceperiod2 = 'D'
          }
        }

        this.get_defaultvalues();

      },
        (err) => {

          this.loadspinner = false;
        })
  }

  change_enableproctor(value) {
    this.enableProctor = value;
  }

  change_graceperiod1() {


    this.sel_graceperiod1 = 'sel_graceperiod1';
    this.sel_graceperiod2 = '';
  }
  change_graceperiod2() {

    this.sel_graceperiod1 = '';
    this.sel_graceperiod2 = 'sel_graceperiod2';
  }

  ngOnInit(): void {
  }

  add_rule() {

    this.loadspinner = true;
    let graceperiod: any = '';
    let graceperiodunit: any = '';
    let availableBefore: any = '0';
    let availableBeforeUnit: any = '';
    let req_data: any = [];
    if (this.selected_prerequisites.length > 0) {
      for (var i = 0; i < this.selected_prerequisites.length; i++) {
        req_data.push({
          'requirementId': this.selected_prerequisites[i].requirementId,
          'type': this.selected_prerequisites[i].type,
          'category': this.selected_prerequisites[i].category
        })
      }
    }

    if (this.gracePeriod == true) {
      if (this.sel_graceperiod1 == 'sel_graceperiod1') {
        graceperiod = '';
        graceperiodunit = this.graceperiod1;

      } else if (this.sel_graceperiod2 == 'sel_graceperiod2') {
        graceperiod = this.graceperiod2input;
        graceperiodunit = this.graceperiod2;

      } else {
        graceperiod = this.graceperiod2input;
        graceperiodunit = this.graceperiod2;
      }

    } else {
      graceperiod = '';
      graceperiodunit = '';

    }
    availableBefore = this.graceperiod3input;
    availableBeforeUnit = this.graceperiod3;
    let proctorEnabled: any = '';
    if (this.enableProctor == 'yes') {
      proctorEnabled = true;
    } else {
      proctorEnabled = false;
    }
    let attempt_value = '1';
    if (this.attemptsno == null || this.attemptsno == 'null' || this.attemptsno == undefined || this.attemptsno == '') {
      attempt_value = '1';
    } else {
      attempt_value = this.attemptsno;
    }
   
    let t_array: any = [];
    this.dynamic_attempt.forEach(element => {
      let value = element.retakeAfterVal
      t_array.push({
        'retakeAfterVal': value.toString(),
        'retakeAfterUnit': element.retakeAfterUnit
      })
    });
    let temp_data = { "retakeAfterData": t_array }
    let retake_data = JSON.stringify(temp_data)
    //console.log(retake_data)
    let oneToOneFlag;
    if(this.expiration1Input===1 && availableBefore===1){
      oneToOneFlag="true";
    }
    else{
      oneToOneFlag="";
    }

    let body = {
      "taskListId": this.tasklistId,
      "taskId": this.taskId,
      "ruleId": this.ruleId,
      "groupId": this.groupId,
      "requirementId": this.requirementId,
      "type": this.requirementType,
      "proctorEnabled": proctorEnabled,
      "attempts": attempt_value,
      "retakeAfterData": retake_data,
      "expirationAfter": this.expiration1Input,
      "expirationAfterUnit": this.expiration1,
      "graceEnabled": this.gracePeriod,
      "gracePeriod": graceperiod,
      "gracePeriodUnit": graceperiodunit,
      "availableBefore": availableBefore,
      "availableBeforeUnit": availableBeforeUnit,
      "prerequisites": req_data,
      "category": this.category,
      "oneToOneFlag":oneToOneFlag
    }
    this.dialog.close(body);

  }

  cancel() {
    this.dialog.close('')
  }

  cancelbtn() {
    let element = document.getElementById("applyRules-content")
    element.classList.add('blur-content')
    let title = 'Do you want to discard the changes? ?'
    let dialogRef = this.matdialog.open(DialogpopupComponent, {
      width: '550px',
      disableClose: true,
      autoFocus: false,
      data:
      {
        from: "cancel-qrcode",
        successmsg: "Do you want to cancel the changes?",
        successmsgbody: 'Your unsaved changes will be lost.',
        imagelogo: "../../../../assets/images/save.svg"
      },
      position: {
        top: '25vh'
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      let element = document.getElementById("applyRules-content")
      element.classList.remove('blur-content');
      if (res == 'yes') {
        this.dialog.close('');
      }
    })
  }


  get_ruleinfo() {
    this.loadspinner = true;
    const endpoint1 = '/rules/requirement?ruleId=' + this.taskreqId;
    this.apiService.get_reqrule(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
      })
  }


  decrement() {
    if (this.attemptsno > 1) {
      if (this.specificField_disable != true) {
        this.attemptsno--;
        this.inputchange('')
      }
    }
  }

  increment() {
    if (this.specificField_disable != true) {
      this.attemptsno++;
      this.inputchange('')
    }
  }

  input_validate(id) {

    var inputBox = document.getElementById(id);

    var invalidChars = [
      "-",
      "+",
      "e",
      "E",
      ".",
    ];

    inputBox.addEventListener("keydown", function (e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });

  }

  inputchange(ev) {
    //4083 12-01-2024
    let value: any;
    if (this.attemptsno != null) {
      value = parseInt(this.attemptsno)
      this.dynamic_attempt = [];
      for (var i = 0; i < value - 1; i++) {
        this.dynamic_attempt.push({
          'retakeAfterVal': '0',
          'retakeAfterUnit': 'N'
        })
      }
    } else {
      this.dynamic_attempt.length = 0;
    }

  }

  //4083 12-01-2024
  attemptsRetake(value,i){
    if(value.value == 'N'){
      this.dynamic_attempt[i].retakeAfterVal = 0
    }

  }
}
