
export const environment = {
  version: "0.0.0",
  production: false,
  message: 'hello dev',
  encrypt_text: 'cHangeTmrW21'
};

//local
export const launch_callback_serverurl="http://localhost:4200/";

// Dev URL ECS 
// export const loginapiUrl = 'https://demo1.stellar-oqcs.com'; //IDMS
// export const setpassapiUrl = 'https://demo1.stellar-oqcs.com'; //USERS
// export const filterservice = 'https://demo1.stellar-oqcs.com'; //LOOKUP
// export const usertable = 'https://import.dev.stellar-oqcs.com'; // USERLIST
// export const createuserUrl = 'https://demo1.stellar-oqcs.com'; //USERS
// export const grouplistTable = 'https://demo1.stellar-oqcs.com'; //GROUPS
// export const importUrl = 'https://6w7q2fvvs8.execute-api.us-east-1.amazonaws.com/dev/import/'; // NOT YET IMPLEMENTED------------
// export const uploadurl = "https://demo1.stellar-oqcs.com/content/upload"; //USER PROFILE
// export const companylistMngmnt = 'https://demo1.stellar-oqcs.com'; //COMPANIES
// export const managerlist = "https://import.dev.stellar-oqcs.com"; //USERSLIST
// export const license = 'https://demo1.stellar-oqcs.com'; //LICENSE
// export const courseurl = "https://demo1.stellar-oqcs.com/"; //EXTERNAL TRAINING
// export const externaltraininglist = "https://demo1.stellar-oqcs.com"; //EXTERNAL TRAINING
// export const companynamevalidate = "https://demo1.stellar-oqcs.com"; //COMPANIES
// export const assignableList = 'https://demo1.stellar-oqcs.com'; //COURSE
// export const createcourse = "https://demo1.stellar-oqcs.com"; //COURSE
// export const enrollmentlist = "https://demo1.stellar-oqcs.com"; //ENROLLMENT
// export const courseProperties = "https://demo1.stellar-oqcs.com";//COURSE
// export const curriculum = "https://demo1.stellar-oqcs.com";//CURRICULLUM
// export const updatecurriclumUrl = "https://demo1.stellar-oqcs.com";//CURRICULLUM
// export const assignments = "https://demo1.stellar-oqcs.com";//COURSE
// export const assignmentList = 'https://demo1.stellar-oqcs.com';//COURSE
// export const formlist = "https://demo1.stellar-oqcs.com";//PEF
// export const formlistimport = "https://demo1.stellar-oqcs.com";//PEFimport
// export const launch = "https://demo1.stellar-oqcs.com";//RUNTIME
// export const createforms = "https://demo1.stellar-oqcs.com";//PEF
// export const pefsettings = "https://demo1.stellar-oqcs.com"; //PEF
// export const announcement = "https://demo1.stellar-oqcs.com";//ANNOUNCEMENT
// export const task = "https://demo1.stellar-oqcs.com";//TASK
// export const learnertask = "https://demo1.stellar-oqcs.com"//TASK
// export const createtasklist = "https://demo1.stellar-oqcs.com"; //TASK
// export const launchlink = "https://demo1.stellar-oqcs.com/";//RUNTIME
// export const curriculasequesnce = "https://demo1.stellar-oqcs.com"//COURSE
// //export const launch_callback_serverurl = "https://dev.stellar-oqcs.com/"; //--------
// export const evaluatorlist = 'https://import.dev.stellar-oqcs.com'; //USERSLIST
// export const evaluatoraddremove = 'https://demo1.stellar-oqcs.com'; //USERS
// export const calendarview = "https://demo1.stellar-oqcs.com"; //TRANSCRIPT
// export const groupstastsexporturl = 'https://demo1.stellar-oqcs.com'; //GROUPS
// export const assignmentlistexporturl = 'https://demo1.stellar-oqcs.com'; //TRANSCRIPT
// export const tasklistnameexporturl = 'https://demo1.stellar-oqcs.com'; //TASK
// export const scorm_importcourse = 'https://import.dev.stellar-oqcs.com/course/import/';
// export const filepeekurl = 'https://import.dev.stellar-oqcs.com/course/peek';
// export const filepeekurl1 = 'https://import.dev.stellar-oqcs.com/course';
// export const activeuserlistprinturl = 'https://demo1.stellar-oqcs.com'; //COMPANIES
// export const groupStatsprinturl = 'https://demo1.stellar-oqcs.com'; //GROUPS
// export const qrcodeinfo = 'https://demo1.stellar-oqcs.com'; //USERS
// export const transcriptprinturl = 'https://demo1.stellar-oqcs.com'; //TRANSCRIPT
// export const requirementRule = 'https://demo1.stellar-oqcs.com'; //----
// export const taskrule = 'https://demo1.stellar-oqcs.com'; //--------
// export const delete_groupurl = 'https://demo1.stellar-oqcs.com'; //TASK
// export const curriculumprinturl = 'https://demo1.stellar-oqcs.com'; //CURRICULLUM
// export const enrollmentprinturl = 'https://demo1.stellar-oqcs.com'; //ENROLLMENT
// export const learnerAssignmentprinturl = 'https://demo1.stellar-oqcs.com'; //COURSE
// export const userListprinturl = 'https://import.dev..stellar-oqcs.com' ;
// export const companyListprinturl = 'https://demo1.stellar-oqcs.com' ;
// export const reportlink = 'https://demo1.stellar-oqcs.com' ;
// export const jobPositionprinturl = 'https://demo1.stellar-oqcs.com' ;
// export const learnertaskprinturl = 'https://demo1.stellar-oqcs.com' ;
// export const learnerviewtaskurl = 'https://demo1.stellar-oqcs.com' ;
// export const mergeUserurl = 'https://demo1.stellar-oqcs.com' ; //USERS
// export const homeCounturl = 'https://demo1.stellar-oqcs.com' ;
// export const homeAnnouncementurl = 'https://demo1.stellar-oqcs.com' ;
// export const isntransferurl = 'https://demo1.stellar-oqcs.com' ;
// export const cataloglist = 'https://demo1.stellar-oqcs.com' ;
// export const generatePortability = 'https://demo1.stellar-oqcs.com'
// export const sharedRecCompanyId = 'https://demo1.stellar-oqcs.com'
// export const companylogo = 'https://demo1.stellar-oqcs.com/companies/uploadlogo';
// export const ExamManagement='https://demo1.stellar-oqcs.com';
// export const DemoLogin='https://demo1.stellar-oqcs.com';
// export const siteKey = '6LcfHP4fAAAAABVkzdTQ2bhzMRFaBVP8AF2689Tx';
// export const extnltrningdnldcsvtemplate ='https://externaltrainingproperties.s3.amazonaws.com/ExternalTraining.csv';
// export const extnltrningdnldcsvinstruction ='https://externaltrainingproperties.s3.amazonaws.com/ExternalTrainingCsvFormatInstructions.pdf';
// export const formsdnldcsvtemplate ='https://pef-properties.s3.amazonaws.com/FormDetails.csv';
// export const formsdnldcsvinstruction ='https://pef-properties.s3.amazonaws.com/FormDetailsCsvFormatInstructions.pdf';
// export const Tasksdnldcsvtemplate ='https://taskproperties.s3.amazonaws.com/TaskDetails.csv';
// export const Tasksdnldcsvinstruction ='https://taskproperties.s3.amazonaws.com/TaskDetailsCsvFormatInstructions.pdf';
// //OQCS-4682 (11-01-2024)
// export const usersdnldcsvtemplate ='https://dev-users-properties.s3.amazonaws.com/UserDetails.csv';
// export const usersdnldcsvinstruction ='https://dev-users-properties.s3.amazonaws.com/UserDetailsCsvFormatInstructions.pdf';
// export const evaluation="https://demo1.stellar-oqcs.com"
// export const MEA_user_QRcode="https://qrcodes.stellar-oqcs.com";
//export const helpfileurl="https://d17nzlegqdq03e.cloudfront.net/help.htm";

//Test URL
// export const loginapiUrl = 'https://lcp8ihx612.execute-api.us-east-1.amazonaws.com/test'; //IDMS
// export const setpassapiUrl = 'https://7y5we7dgs5.execute-api.us-east-1.amazonaws.com/test'; //USERS
// export const filterservice = 'https://5zzag9g15j.execute-api.us-east-1.amazonaws.com/test'; //LOOKUP
// export const usertable = 'https://bfx933ntn3.execute-api.us-east-1.amazonaws.com/test'; // USERLIST
// export const createuserUrl = 'https://7y5we7dgs5.execute-api.us-east-1.amazonaws.com/test'; //USERS
// export const grouplistTable = 'https://axoe6yh0r7.execute-api.us-east-1.amazonaws.com/test'; //GROUPS
// export const importUrl = 'https://6w7q2fvvs8.execute-api.us-east-1.amazonaws.com/dev/import/'; // NOT YET IMPLEMENTED
// export const uploadurl = "https://yh1rupl1q1.execute-api.us-east-1.amazonaws.com/test/content/upload"; //USER PROFILE
// export const companylistMngmnt = 'https://buzvtgvhq2.execute-api.us-east-1.amazonaws.com/test'; //COMPANIES
// export const managerlist = "https://bfx933ntn3.execute-api.us-east-1.amazonaws.com/test"; //USERSLIST
// export const license = 'https://l6zxxqakxl.execute-api.us-east-1.amazonaws.com/test'; //LICENSE
// export const courseurl = "https://vaeqlqrvze.execute-api.us-east-1.amazonaws.com/test/"; //EXTERNAL TRAINING
// export const externaltraininglist = "https://vaeqlqrvze.execute-api.us-east-1.amazonaws.com/test"; //EXTERNAL TRAINING
// export const companynamevalidate = "https://buzvtgvhq2.execute-api.us-east-1.amazonaws.com/test"; //COMPANIES
// export const assignableList = 'https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test'; //COURSE
// export const createcourse = "https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test"; //COURSE
// export const enrollmentlist = "https://a13kew48pj.execute-api.us-east-1.amazonaws.com/test"; //ENROLLMENT
// export const courseProperties = "https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test";//COURSE
// export const curriculum = "https://7ixtonpioh.execute-api.us-east-1.amazonaws.com/test";//CURRICULLUM
// export const updatecurriclumUrl = "https://7ixtonpioh.execute-api.us-east-1.amazonaws.com/test";//CURRICULLUM
// export const assignments = "https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test";//COURSE
// export const assignmentList = 'https://7islc1gvwj.execute-api.us-east-1.amazonaws.com/test';//COURSE
// export const formlist = "https://3lwajia9rk.execute-api.us-east-1.amazonaws.com/test";//PEF
// export const formlistimport = "https://3lwajia9rk.execute-api.us-east-1.amazonaws.com/test";//PEF
// export const launch = "https://i0ci4j1tl7.execute-api.us-east-1.amazonaws.com/test";//LAUNCH
// export const createforms = "https://3lwajia9rk.execute-api.us-east-1.amazonaws.com/test";//PEF
// export const pefsettings = "https://3lwajia9rk.execute-api.us-east-1.amazonaws.com/test"; //PEF
// export const announcement = "https://i98bbrpmfd.execute-api.us-east-1.amazonaws.com/test/";//AANNOUNCEMENT
// export const task = "https://zlemmi77s2.execute-api.us-east-1.amazonaws.com/test";//TASK
// export const learnertask = "https://zlemmi77s2.execute-api.us-east-1.amazonaws.com/test"//TASK
// export const createtasklist = "https://zlemmi77s2.execute-api.us-east-1.amazonaws.com/test"; //TASK
// export const launchlink = "https://i0ci4j1tl7.execute-api.us-east-1.amazonaws.com/test/";//LAUNCH
// export const curriculasequesnce = "https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test"//COURSE
// export const launch_callback_serverurl = "https://test.stellar-oqcs.com/";
// export const evaluatoraddremove = 'https://7y5we7dgs5.execute-api.us-east-1.amazonaws.com/test'; // USERS
// export const evaluatorlist = 'https://bfx933ntn3.execute-api.us-east-1.amazonaws.com/test';  // USERLIST
// export const calendarview = "https://7islc1gvwj.execute-api.us-east-1.amazonaws.com/test";  //COURSE
// export const assignmentlistexporturl = 'https://7islc1gvwj.execute-api.us-east-1.amazonaws.com/test'; //COURSE
// export const groupstastsexporturl = "https://axoe6yh0r7.execute-api.us-east-1.amazonaws.com/test/";  //GROUPS
// export const tasklistnameexporturl = 'https://zlemmi77s2.execute-api.us-east-1.amazonaws.com/test'; //TASK
// export const scorm_importcourse = 'https://import.test.stellar-oqcs.com/course/import/'
// export const filepeekurl = 'https://import.test.stellar-oqcs.com/course/peek';
// export const filepeekurl1 = 'https://import.test.stellar-oqcs.com/course';
// export const qrcodeinfo = 'https://7y5we7dgs5.execute-api.us-east-1.amazonaws.com/test'  // USERS
// export const activeuserlistprinturl = 'https://buzvtgvhq2.execute-api.us-east-1.amazonaws.com/test'; //COMPANIES
// export const groupStatsprinturl = 'https://axoe6yh0r7.execute-api.us-east-1.amazonaws.com/test';  //GROUPS
// export const transcriptprinturl = 'https://7islc1gvwj.execute-api.us-east-1.amazonaws.com/test';  //COURSE
// export const requirementRule = 'https://nmivseawzi.execute-api.us-east-1.amazonaws.com/test'
// export const taskrule = 'https://nmivseawzi.execute-api.us-east-1.amazonaws.com/test';
// export const delete_groupurl = 'https://zlemmi77s2.execute-api.us-east-1.amazonaws.com/test';  //TASK
// export const curriculumprinturl = 'https://7ixtonpioh.execute-api.us-east-1.amazonaws.com/test'; //CURRICULLUM
// export const enrollmentprinturl = 'https://a13kew48pj.execute-api.us-east-1.amazonaws.com/test';  //ENROLLMENT
// export const learnerAssignmentprinturl = 'https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test';  //COURSE
// export const userListprinturl = 'https://bfx933ntn3.execute-api.us-east-1.amazonaws.com/test';  // USERLIST
// export const companyListprinturl = 'https://buzvtgvhq2.execute-api.us-east-1.amazonaws.com/test'; //COMPANIES
// export const reportlink = 'https://l18tvuo37j.execute-api.us-east-1.amazonaws.com/test';
// export const jobPositionprinturl = 'https://zlemmi77s2.execute-api.us-east-1.amazonaws.com/test'; //TASK
// export const learnertaskprinturl = 'https://7islc1gvwj.execute-api.us-east-1.amazonaws.com/test'; //COURSE
// export const learnerviewtaskurl = 'https://zlemmi77s2.execute-api.us-east-1.amazonaws.com/test';  //TASK
// export const mergeUserurl = 'https://7y5we7dgs5.execute-api.us-east-1.amazonaws.com/test'; //USERS
// export const homeCounturl = 'https://buzvtgvhq2.execute-api.us-east-1.amazonaws.com/test';  //COMPANIES
// export const homeAnnouncementurl = 'https://i98bbrpmfd.execute-api.us-east-1.amazonaws.com/test'; //AANNOUNCEMENT
// export const isntransferurl = 'https://buzvtgvhq2.execute-api.us-east-1.amazonaws.com/test';  //COMPANIES
// export const cataloglist = 'https://ul0r0yi3ja.execute-api.us-east-1.amazonaws.com/test';  //COURSE
// export const generatePortability = 'https://7y5we7dgs5.execute-api.us-east-1.amazonaws.com/test'  // USERS
// export const sharedRecCompanyId = 'https://7y5we7dgs5.execute-api.us-east-1.amazonaws.com/test'   // USERS
// export const companylogo = 'https://buzvtgvhq2.execute-api.us-east-1.amazonaws.com/test/companies/uploadlogo';  //COMPANIES
// export const ExamManagement = 'https://ibxbohooji.execute-api.us-east-1.amazonaws.com/test';
// export const DemoLogin = 'https://7y5we7dgs5.execute-api.us-east-1.amazonaws.com/test';    // USERS
// export const siteKey = '6LcwHP4fAAAAAG75mhICNHv2e7EIZXf2qItRF6Ez';
// export const extnltrningdnldcsvtemplate = 'https://externaltraining-properties.s3.amazonaws.com/ExternalTraining.csv';
// export const extnltrningdnldcsvinstruction = 'https://externaltraining-properties.s3.amazonaws.com/ExternalTrainingCsvFormatInstructions.pdf';
// export const formsdnldcsvtemplate = 'https://form-properties.s3.amazonaws.com/FormDetails.csv';
// export const formsdnldcsvinstruction = 'https://form-properties.s3.amazonaws.com/FormDetailsCsvFormatInstructions.pdf';
// export const Tasksdnldcsvtemplate = 'https://task-properties.s3.amazonaws.com/TaskDetails.csv';
// export const Tasksdnldcsvinstruction = 'https://task-properties.s3.amazonaws.com/TaskDetailsCsvFormatInstructions.pdf';
// export const usersdnldcsvtemplate = 'https://usersproperties.s3.amazonaws.com/UserDetails.csv';
// export const usersdnldcsvinstruction = 'https://usersproperties.s3.amazonaws.com/UserDetailsCsvFormatInstructions.pdf';
// export const evaluation = "https://xxrmcaaj31.execute-api.us-east-1.amazonaws.com/test"
// export const MEA_user_QRcode = "https://qrcode.stellar-oqcs.com/";
//export const helpfileurl="https://d17nzlegqdq03e.cloudfront.net/help.htm";

// // // UAT new env
// export const loginapiUrl = 'https://wbqayilfb0.execute-api.us-east-1.amazonaws.com/uat'; //IDMS
// export const setpassapiUrl = 'https://n5jinrlfa4.execute-api.us-east-1.amazonaws.com/uat'; //USERS
// export const filterservice = 'https://zzjo3wawbb.execute-api.us-east-1.amazonaws.com/uat'; //LOOKUP
// export const usertable = 'https://6sb3uswr9l.execute-api.us-east-1.amazonaws.com/uat'; // USERLIST
// export const createuserUrl = 'https://n5jinrlfa4.execute-api.us-east-1.amazonaws.com/uat'; //USERS
// export const grouplistTable = 'https://lcspzrrwe1.execute-api.us-east-1.amazonaws.com/uat'; //GROUPS
// export const importUrl = 'https://6w7q2fvvs8.execute-api.us-east-1.amazonaws.com/dev/import/'; // NOT YET IMPLEMENTED------------
// export const uploadurl = "https://pxm4n7ryk4.execute-api.us-east-1.amazonaws.com/uat/content/upload"; //USER PROFILE
// export const companylistMngmnt = 'https://kcbq8syvi1.execute-api.us-east-1.amazonaws.com/uat'; //COMPANIES
// export const managerlist = "https://6sb3uswr9l.execute-api.us-east-1.amazonaws.com/uat"; //USERSLIST
// export const license = 'https://bd6mra0z71.execute-api.us-east-1.amazonaws.com/uat'; //LICENSE
// export const courseurl = "https://rnp1i5fcna.execute-api.us-east-1.amazonaws.com/uat/"; //EXTERNAL TRAINING
// export const externaltraininglist = "https://rnp1i5fcna.execute-api.us-east-1.amazonaws.com/uat"; //EXTERNAL TRAINING
// export const companynamevalidate = "https://kcbq8syvi1.execute-api.us-east-1.amazonaws.com/uat"; //COMPANIES
// export const assignableList = 'https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat'; //COURSE
// export const createcourse = "https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat"; //COURSE
// export const enrollmentlist = "https://iyeh4122k7.execute-api.us-east-1.amazonaws.com/uat"; //ENROLLMENT
// export const courseProperties = "https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat";//COURSE
// export const curriculum = "https://ekl9kytp9k.execute-api.us-east-1.amazonaws.com/uat";//CURRICULLUM
// export const updatecurriclumUrl = "https://ekl9kytp9k.execute-api.us-east-1.amazonaws.com/uat";//CURRICULLUM
// export const assignments = "https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat";//COURSE
// export const assignmentList = 'https://oyn03g25s0.execute-api.us-east-1.amazonaws.com/uat/';//COURSE
// export const formlist = "https://c843slq7vc.execute-api.us-east-1.amazonaws.com/uat/";//PEF
// export const formlistimport = "https://c843slq7vc.execute-api.us-east-1.amazonaws.com/uat";//PEFimport
// export const launch = "https://94n2vvdy70.execute-api.us-east-1.amazonaws.com/uat";//RUNTIME
// export const createforms = "https://c843slq7vc.execute-api.us-east-1.amazonaws.com/uat/";//PEF
// export const pefsettings = "https://c843slq7vc.execute-api.us-east-1.amazonaws.com/uat/"; //PEF
// export const announcement = "https://tvasax3kf0.execute-api.us-east-1.amazonaws.com/uat/";//ANNOUNCEMENT
// export const task = "https://sm0nglpb9e.execute-api.us-east-1.amazonaws.com/uat/";//TASK
// export const learnertask = "https://sm0nglpb9e.execute-api.us-east-1.amazonaws.com/uat/"//TASK
// export const createtasklist = "https://sm0nglpb9e.execute-api.us-east-1.amazonaws.com/uat/"; //TASK
// export const launchlink = "https://94n2vvdy70.execute-api.us-east-1.amazonaws.com/uat/";//RUNTIME
// export const curriculasequesnce = "https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat"//COURSE
// export const launch_callback_serverurl = "https://uat.stellar-oqcs.com/"; //--------
// export const evaluatorlist = 'https://6sb3uswr9l.execute-api.us-east-1.amazonaws.com/uat'; //USERSLIST
// export const evaluatoraddremove = 'https://n5jinrlfa4.execute-api.us-east-1.amazonaws.com/uat'; //USERS
// export const calendarview = "https://oyn03g25s0.execute-api.us-east-1.amazonaws.com/uat"; //TRANSCRIPT
// export const groupstastsexporturl = 'https://lcspzrrwe1.execute-api.us-east-1.amazonaws.com/uat'; //GROUPS
// export const assignmentlistexporturl = 'https://oyn03g25s0.execute-api.us-east-1.amazonaws.com/uat/'; //TRANSCRIPT
// export const tasklistnameexporturl = 'https://sm0nglpb9e.execute-api.us-east-1.amazonaws.com/uat'; //TASK
// export const scorm_importcourse = 'https://import.uat.stellar-oqcs.com/course/import/';
// export const filepeekurl = 'https://import.uat.stellar-oqcs.com/course/peek';
// export const filepeekurl1 = 'https://import.uat.stellar-oqcs.com/course';
// export const activeuserlistprinturl = 'https://kcbq8syvi1.execute-api.us-east-1.amazonaws.com/uat'; //COMPANIES
// export const groupStatsprinturl = 'https://lcspzrrwe1.execute-api.us-east-1.amazonaws.com/uat'; //GROUPS
// export const qrcodeinfo = 'https://n5jinrlfa4.execute-api.us-east-1.amazonaws.com/uat'; //USERS
// export const transcriptprinturl = 'https://oyn03g25s0.execute-api.us-east-1.amazonaws.com/uat'; //TRANSCRIPT
// export const requirementRule = 'https://5pg9y5od0a.execute-api.us-east-1.amazonaws.com/uat'; //----
// export const taskrule = 'https://5pg9y5od0a.execute-api.us-east-1.amazonaws.com/uat'; //--------
// export const delete_groupurl = 'https://sm0nglpb9e.execute-api.us-east-1.amazonaws.com/uat'; //TASK
// export const curriculumprinturl = 'https://ekl9kytp9k.execute-api.us-east-1.amazonaws.com/uat'; //CURRICULLUM
// export const enrollmentprinturl = 'https://iyeh4122k7.execute-api.us-east-1.amazonaws.com/uat'; //ENROLLMENT
// export const learnerAssignmentprinturl = 'https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat'; //COURSE
// export const userListprinturl = 'https://6sb3uswr9l.execute-api.us-east-1.amazonaws.com/uat' ;
// export const companyListprinturl = 'https://kcbq8syvi1.execute-api.us-east-1.amazonaws.com/uat' ;
// export const reportlink = 'https://qmg7wqag3h.execute-api.us-east-1.amazonaws.com/uat' ;
// export const jobPositionprinturl = 'https://sm0nglpb9e.execute-api.us-east-1.amazonaws.com/uat' ;
// export const learnertaskprinturl = 'https://oyn03g25s0.execute-api.us-east-1.amazonaws.com/uat' ;
// export const learnerviewtaskurl = 'https://sm0nglpb9e.execute-api.us-east-1.amazonaws.com/uat' ;
// export const mergeUserurl = 'https://n5jinrlfa4.execute-api.us-east-1.amazonaws.com/uat' ; //USERS
// export const homeCounturl = 'https://kcbq8syvi1.execute-api.us-east-1.amazonaws.com/uat' ;
// export const homeAnnouncementurl = 'https://tvasax3kf0.execute-api.us-east-1.amazonaws.com/uat' ;
// export const isntransferurl = 'https://kcbq8syvi1.execute-api.us-east-1.amazonaws.com/uat' ;
// export const cataloglist = 'https://u48i0yzbi1.execute-api.us-east-1.amazonaws.com/uat' ;
// export const generatePortability = 'https://n5jinrlfa4.execute-api.us-east-1.amazonaws.com/uat'
// export const sharedRecCompanyId = 'https://n5jinrlfa4.execute-api.us-east-1.amazonaws.com/uat'
// export const companylogo = 'https://kcbq8syvi1.execute-api.us-east-1.amazonaws.com/uat/companies/uploadlogo';
// export const ExamManagement='https://v4ik9w16yj.execute-api.us-east-1.amazonaws.com/uat';
// export const DemoLogin='https://n5jinrlfa4.execute-api.us-east-1.amazonaws.com/uat';
// export const siteKey = '6LcfHP4fAAAAABVkzdTQ2bhzMRFaBVP8AF2689Tx';
// export const extnltrningdnldcsvtemplate ='https://externaltrainingproperties.s3.amazonaws.com/ExternalTraining.csv';
// export const extnltrningdnldcsvinstruction ='https://externaltrainingproperties.s3.amazonaws.com/ExternalTrainingCsvFormatInstructions.pdf';
// export const formsdnldcsvtemplate ='https://pef-properties.s3.amazonaws.com/FormDetails.csv';
// export const formsdnldcsvinstruction ='https://pef-properties.s3.amazonaws.com/FormDetailsCsvFormatInstructions.pdf';
// export const Tasksdnldcsvtemplate ='https://taskproperties.s3.amazonaws.com/TaskDetails.csv';
// export const Tasksdnldcsvinstruction ='https://taskproperties.s3.amazonaws.com/TaskDetailsCsvFormatInstructions.pdf';
// export const usersdnldcsvtemplate ='https://users-properties-new.s3.amazonaws.com/UserDetails.csv';
// export const usersdnldcsvinstruction ='https://users-properties-new.s3.amazonaws.com/UserDetailsCsvFormatInstructions.pdf';
// export const evaluation="https://bm1b49l2t4.execute-api.us-east-1.amazonaws.com/uat"
// export const MEA_user_QRcode="https://qrcodes.stellar-oqcs.com";
//export const helpfileurl="https://d17nzlegqdq03e.cloudfront.net/help.htm";

//ECS 
// export const loginapiUrl = 'https://ecsuat.stellar-oqcs.com'; //IDMS
// export const setpassapiUrl = 'https://ecsuat.stellar-oqcs.com'; //USERS
// export const filterservice = 'https://ecsuat.stellar-oqcs.com'; //LOOKUP
// export const usertable = 'https://ecsuserlist.stellar-oqcs.com'; // USERLIST
// export const createuserUrl = 'https://ecsuat.stellar-oqcs.com'; //USERS
// export const grouplistTable = 'https://ecsuat.stellar-oqcs.com'; //GROUPS
// export const importUrl = 'https://6w7q2fvvs8.execute-api.us-east-1.amazonaws.com/dev/import/'; // NOT YET IMPLEMENTED------------
// export const uploadurl = "https://ecsuat.stellar-oqcs.com/content/upload"; //USER PROFILE
// export const companylistMngmnt = 'https://ecsuat.stellar-oqcs.com'; //COMPANIES
// export const managerlist = "https://ecsuserlist.stellar-oqcs.com"; //USERSLIST
// export const license = 'https://ecsuat.stellar-oqcs.com'; //LICENSE
// export const courseurl = "https://ecsuat.stellar-oqcs.com/"; //EXTERNAL TRAINING
// export const externaltraininglist = "https://ecsuat.stellar-oqcs.com"; //EXTERNAL TRAINING
// export const companynamevalidate = "https://ecsuat.stellar-oqcs.com"; //COMPANIES
// export const assignableList = 'https://ecsuat.stellar-oqcs.com'; //COURSE
// export const createcourse = "https://ecsuat.stellar-oqcs.com"; //COURSE
// export const enrollmentlist = "https://ecsuat.stellar-oqcs.com"; //ENROLLMENT
// export const courseProperties = "https://ecsuat.stellar-oqcs.com";//COURSE
// export const curriculum = "https://ecsuat.stellar-oqcs.com";//CURRICULLUM
// export const updatecurriclumUrl = "https://ecsuat.stellar-oqcs.com";//CURRICULLUM
// export const assignments = "https://ecsuat.stellar-oqcs.com";//COURSE
// export const assignmentList = 'https://ecsuat.stellar-oqcs.com';//COURSE
// export const formlist = "https://ecsuat.stellar-oqcs.com";//PEF
// export const formlistimport = "https://ecsuat.stellar-oqcs.com";//PEFimport
// export const launch = "https://ecsuat.stellar-oqcs.com";//RUNTIME
// export const createforms = "https://ecsuat.stellar-oqcs.com";//PEF
// export const pefsettings = "https://ecsuat.stellar-oqcs.com"; //PEF
// export const announcement = "https://ecsuat.stellar-oqcs.com";//ANNOUNCEMENT
// export const task = "https://ecsuat.stellar-oqcs.com";//TASK
// export const learnertask = "https://ecsuat.stellar-oqcs.com"//TASK
// export const createtasklist = "https://ecsuat.stellar-oqcs.com"; //TASK
// export const launchlink = "https://ecsuat.stellar-oqcs.com/";//RUNTIME
// export const curriculasequesnce = "https://ecsuat.stellar-oqcs.com"//COURSE
// // export const launch_callback_serverurl = "https://uatdemo.stellar-oqcs.com/"; //--------
// export const evaluatorlist = 'https://ecsuserlist.stellar-oqcs.com'; //USERSLIST
// export const evaluatoraddremove = 'https://ecsuat.stellar-oqcs.com'; //USERS
// export const calendarview = "https://ecsuat.stellar-oqcs.com"; //TRANSCRIPT
// export const groupstastsexporturl = 'https://ecsuat.stellar-oqcs.com'; //GROUPS
// export const assignmentlistexporturl = 'https://ecsuat.stellar-oqcs.com'; //TRANSCRIPT
// export const tasklistnameexporturl = 'https://ecsuat.stellar-oqcs.com'; //TASK
// export const scorm_importcourse = 'https://courseimport.stellar-oqcs.com/course/import/';
// export const filepeekurl = 'https://courseimport.stellar-oqcs.com/course/peek';
// export const filepeekurl1 = 'https://courseimport.stellar-oqcs.com/course';
// export const activeuserlistprinturl = 'https://ecsuat.stellar-oqcs.com'; //COMPANIES
// export const groupStatsprinturl = 'https://ecsuat.stellar-oqcs.com'; //GROUPS
// export const qrcodeinfo = 'https://ecsuat.stellar-oqcs.com'; //USERS
// export const transcriptprinturl = 'https://ecsuat.stellar-oqcs.com'; //TRANSCRIPT
// export const requirementRule = 'https://ecsuat.stellar-oqcs.com'; //----
// export const taskrule = 'https://ecsuat.stellar-oqcs.com'; //--------
// export const delete_groupurl = 'https://ecsuat.stellar-oqcs.com'; //TASK
// export const curriculumprinturl = 'https://ecsuat.stellar-oqcs.com'; //CURRICULLUM
// export const enrollmentprinturl = 'https://ecsuat.stellar-oqcs.com'; //ENROLLMENT
// export const learnerAssignmentprinturl = 'https://ecsuat.stellar-oqcs.com'; //COURSE
// export const userListprinturl = 'https://ecsuserlist.stellar-oqcs.com' ;
// export const companyListprinturl = 'https://ecsuat.stellar-oqcs.com' ;
// export const reportlink = 'https://ecsuat.stellar-oqcs.com' ;
// export const jobPositionprinturl = 'https://ecsuat.stellar-oqcs.com' ;
// export const learnertaskprinturl = 'https://ecsuat.stellar-oqcs.com' ;
// export const learnerviewtaskurl = 'https://ecsuat.stellar-oqcs.com' ;
// export const mergeUserurl = 'https://ecsuat.stellar-oqcs.com' ; //USERS
// export const homeCounturl = 'https://ecsuat.stellar-oqcs.com' ;
// export const homeAnnouncementurl = 'https://ecsuat.stellar-oqcs.com' ;
// export const isntransferurl = 'https://ecsuat.stellar-oqcs.com' ;
// export const cataloglist = 'https://ecsuat.stellar-oqcs.com' ;
// export const generatePortability = 'https://ecsuat.stellar-oqcs.com'
// export const sharedRecCompanyId = 'https://ecsuat.stellar-oqcs.com'
// export const companylogo = 'https://ecsuat.stellar-oqcs.com/companies/uploadlogo';
// export const ExamManagement='https://ecsuat.stellar-oqcs.com';
// export const DemoLogin='https://ecsuat.stellar-oqcs.com';
// export const siteKey = '6LcfHP4fAAAAABVkzdTQ2bhzMRFaBVP8AF2689Tx';
// export const extnltrningdnldcsvtemplate ='https://externaltrainingproperties.s3.amazonaws.com/ExternalTraining.csv';
// export const extnltrningdnldcsvinstruction ='https://externaltrainingproperties.s3.amazonaws.com/ExternalTrainingCsvFormatInstructions.pdf';
// export const formsdnldcsvtemplate ='https://pef-properties.s3.amazonaws.com/FormDetails.csv';
// export const formsdnldcsvinstruction ='https://pef-properties.s3.amazonaws.com/FormDetailsCsvFormatInstructions.pdf';
// export const Tasksdnldcsvtemplate ='https://taskproperties.s3.amazonaws.com/TaskDetails.csv';
// export const Tasksdnldcsvinstruction ='https://taskproperties.s3.amazonaws.com/TaskDetailsCsvFormatInstructions.pdf';
// export const usersdnldcsvtemplate ='https://users-properties-new.s3.amazonaws.com/UserDetails.csv';
// export const usersdnldcsvinstruction ='https://users-properties-new.s3.amazonaws.com/UserDetailsCsvFormatInstructions.pdf';
// export const evaluation="https://ecsuat.stellar-oqcs.com"
// export const MEA_user_QRcode="https://qrcodes.stellar-oqcs.com";
//export const helpfileurl="https://d17nzlegqdq03e.cloudfront.net/help.htm";



// Test URL - single loadbalancer
// export const loginapiUrl = 'https://fargate.stellar-oqcs.com'; //IDMS
// export const setpassapiUrl = 'https://fargate.stellar-oqcs.com'; //USERS
// export const filterservice = 'https://fargate.stellar-oqcs.com'; //LOOKUP
// export const usertable = 'https://userlist.stellar-oqcs.com'; // USERLIST
// export const createuserUrl = 'https://fargate.stellar-oqcs.com'; //USERS
// export const grouplistTable = 'https://fargate.stellar-oqcs.com'; //GROUPS
// export const importUrl='https://6w7q2fvvs8.execute-api.us-east-1.amazonaws.com/dev/import/'; // NOT YET IMPLEMENTED
// export const uploadurl="https://fargate.stellar-oqcs.com/content/upload"; //USER PROFILE
// export const companylistMngmnt='https://fargate.stellar-oqcs.com'; //COMPANIES
// export const managerlist="https://userlist.stellar-oqcs.com"; //USERSLIST
// export const license = ' https://fargate.stellar-oqcs.com'; //LICENSE
// export const courseurl="https://fargate.stellar-oqcs.com/"; //EXTERNAL TRAINING
// export const externaltraininglist="https://fargate.stellar-oqcs.com"; //EXTERNAL TRAINING
// export const companynamevalidate = "https://fargate.stellar-oqcs.com"; //COMPANIES
// export const assignableList = 'https://fargate.stellar-oqcs.com'; //COURSE
// export const createcourse = "https://fargate.stellar-oqcs.com"; //COURSE
// export const enrollmentlist= "https://fargate.stellar-oqcs.com"; //ENROLLMENT
//  export const courseProperties="https://fargate.stellar-oqcs.com";//COURSE
//  export const curriculum = "https://fargate.stellar-oqcs.com";//CURRICULLUM
//  export const updatecurriclumUrl ="https://fargate.stellar-oqcs.com";//CURRICULLUM
//  export const assignments="https://fargate.stellar-oqcs.com";//COURSE
//  export const assignmentList = 'https://fargate.stellar-oqcs.com';//COURSE
//  export const formlist="https://fargate.stellar-oqcs.com";//PEF
//  export const formlistimport="https://fargate.stellar-oqcs.com";//PEFimport
//  export const launch="https://fargate.stellar-oqcs.com";//LAUNCH
//  export const createforms="https://fargate.stellar-oqcs.com";//PEF
//  export const pefsettings = "https://fargate.stellar-oqcs.com"; //PEF
//  export const announcement = "https://fargate.stellar-oqcs.com";//AANNOUNCEMENT
//  export const task = "https://fargate.stellar-oqcs.com";//TASK
//  export const learnertask = "https://fargate.stellar-oqcs.com"//TASK
//  export const createtasklist = "https://fargate.stellar-oqcs.com"; //TASK
//  export const launchlink="https://fargate.stellar-oqcs.com/";//LAUNCH
//  export const curriculasequesnce="https://fargate.stellar-oqcs.com"//COURSE
// //export const launch_callback_serverurl="https://fargate.stellar-oqcs.com/";
//   export const evaluatoraddremove = 'https://fargate.stellar-oqcs.com';
//   export const evaluatorlist = 'https://userlist.stellar-oqcs.com';
//   export const calendarview = "https://fargate.stellar-oqcs.com";
//  export const assignmentlistexporturl = 'https://fargate.stellar-oqcs.com';
//  export const groupstastsexporturl = "https://fargate.stellar-oqcs.com";
//  export const tasklistnameexporturl = 'https://fargate.stellar-oqcs.com';
// export const scorm_importcourse = 'https://import.test.stellar-oqcs.com/course/import/'
// export const filepeekurl = 'https://userlist.stellar-oqcs.com/course/peek';
// export const filepeekurl1 = 'https://userlist.stellar-oqcs.com/course';
// export const qrcodeinfo = 'https://fargate.stellar-oqcs.com'
// export const activeuserlistprinturl = 'https://fargate.stellar-oqcs.com';
// export const groupStatsprinturl = 'https://fargate.stellar-oqcs.com';
// export const transcriptprinturl = 'https://fargate.stellar-oqcs.com';
// export const requirementRule = 'https://fargate.stellar-oqcs.com'
// export const taskrule = 'https://fargate.stellar-oqcs.com';
// export const delete_groupurl = 'https://fargate.stellar-oqcs.com';
// export const curriculumprinturl = 'https://fargate.stellar-oqcs.com';
//  export const enrollmentprinturl = 'https://fargate.stellar-oqcs.com';
// export const learnerAssignmentprinturl = 'https://fargate.stellar-oqcs.com';
// export const userListprinturl = 'https://userlist.stellar-oqcs.com' ;
// export const companyListprinturl = 'https://fargate.stellar-oqcs.com' ;
// export const reportlink = 'https://fargate.stellar-oqcs.com' ;
// export const jobPositionprinturl = 'https://fargate.stellar-oqcs.com' ;
// export const learnertaskprinturl = 'https://fargate.stellar-oqcs.com' ;
// export const learnerviewtaskurl = 'https://fargate.stellar-oqcs.com' ;
// export const mergeUserurl = 'https://fargate.stellar-oqcs.com' ; //USERS
// export const homeCounturl = 'https://fargate.stellar-oqcs.com' ;
// export const homeAnnouncementurl = 'https://fargate.stellar-oqcs.com' ;
// export const isntransferurl = 'https://fargate.stellar-oqcs.com' ;
// export const cataloglist = 'https://fargate.stellar-oqcs.com' ;
// export const generatePortability = 'https://fargate.stellar-oqcs.com'
// export const sharedRecCompanyId = 'https://fargate.stellar-oqcs.com'
// export const companylogo = 'https://fargate.stellar-oqcs.com/companies/uploadlogo';
// export const ExamManagement='https://fargate.stellar-oqcs.com';
// export const DemoLogin='https://fargate.stellar-oqcs.com';
// export const siteKey = '6LcfHP4fAAAAABVkzdTQ2bhzMRFaBVP8AF2689Tx';
// export const extnltrningdnldcsvtemplate ='https://externaltraining-properties.s3.amazonaws.com/ExternalTraining.csv';
// export const extnltrningdnldcsvinstruction ='https://externaltraining-properties.s3.amazonaws.com/ExternalTrainingCsvFormatInstructions.pdf';
// export const formsdnldcsvtemplate ='https://form-properties.s3.amazonaws.com/FormDetails.csv';
// export const formsdnldcsvinstruction ='https://form-properties.s3.amazonaws.com/FormDetailsCsvFormatInstructions.pdf';
// export const Tasksdnldcsvtemplate ='https://task-properties.s3.amazonaws.com/TaskDetails.csv';
// export const Tasksdnldcsvinstruction ='https://task-properties.s3.amazonaws.com/TaskDetailsCsvFormatInstructions.pdf';
// export const usersdnldcsvtemplate ='https://usersproperties.s3.amazonaws.com/UserDetails.csv';
// export const usersdnldcsvinstruction ='https://usersproperties.s3.amazonaws.com/UserDetailsCsvFormatInstructions.pdf';
// export const evaluation="https://fargate.stellar-oqcs.com"
// export const MEA_user_QRcode="https://qrcode.stellar-oqcs.com/";
// export const helpfileurl="https://d17nzlegqdq03e.cloudfront.net/help.htm";


//preprod server 

// export const loginapiUrl = 'https://preprodlb.stellar-oqcs.com'; //IDMS
// export const setpassapiUrl = 'https://preprodlb.stellar-oqcs.com'; //USERS
// export const filterservice = 'https://preprodlb.stellar-oqcs.com'; //LOOKUP
// export const usertable = 'https://preprodul.stellar-oqcs.com'; // USERLIST
// export const createuserUrl = 'https://preprodlb.stellar-oqcs.com'; //USERS
// export const grouplistTable = 'https://preprodlb.stellar-oqcs.com'; //GROUPS
// export const importUrl = 'https://6w7q2fvvs8.execute-api.us-east-1.amazonaws.com/dev/import/'; // NOT YET IMPLEMENTED------------
// export const uploadurl = "https://preprodlb.stellar-oqcs.com/content/upload"; //USER PROFILE
// export const companylistMngmnt = 'https://preprodlb.stellar-oqcs.com'; //COMPANIES
// export const managerlist = "https://preprodul.stellar-oqcs.com"; //USERSLIST
// export const license = 'https://preprodlb.stellar-oqcs.com'; //LICENSE
// export const courseurl = "https://preprodlb.stellar-oqcs.com/"; //EXTERNAL TRAINING
// export const externaltraininglist = "https://preprodlb.stellar-oqcs.com"; //EXTERNAL TRAINING
// export const companynamevalidate = "https://preprodlb.stellar-oqcs.com"; //COMPANIES
// export const assignableList = 'https://preprodlb.stellar-oqcs.com'; //COURSE
// export const createcourse = "https://preprodlb.stellar-oqcs.com"; //COURSE
// export const enrollmentlist = "https://preprodlb.stellar-oqcs.com"; //ENROLLMENT
// export const courseProperties = "https://preprodlb.stellar-oqcs.com";//COURSE
// export const curriculum = "https://preprodlb.stellar-oqcs.com";//CURRICULLUM
// export const updatecurriclumUrl = "https://preprodlb.stellar-oqcs.com";//CURRICULLUM
// export const assignments = "https://preprodlb.stellar-oqcs.com";//COURSE
// export const assignmentList = 'https://preprodlb.stellar-oqcs.com';//COURSE
// export const formlist = "https://preprodlb.stellar-oqcs.com";//PEF
// export const formlistimport = "https://preprodlb.stellar-oqcs.com";//PEFimport
// export const launch = "https://preprodlb.stellar-oqcs.com";//RUNTIME
// export const createforms = "https://preprodlb.stellar-oqcs.com";//PEF
// export const pefsettings = "https://preprodlb.stellar-oqcs.com"; //PEF
// export const announcement = "https://preprodlb.stellar-oqcs.com";//ANNOUNCEMENT
// export const task = "https://preprodlb.stellar-oqcs.com";//TASK
// export const learnertask = "https://preprodlb.stellar-oqcs.com"//TASK
// export const createtasklist = "https://preprodlb.stellar-oqcs.com"; //TASK
// export const launchlink = "https://preprodlb.stellar-oqcs.com/";//RUNTIME
// export const curriculasequesnce = "https://preprodlb.stellar-oqcs.com"//COURSE
// export const launch_callback_serverurl = "https://preprod.stellar-oqcs.com/"; //--------
// export const evaluatorlist = 'https://preprodul.stellar-oqcs.com'; //USERSLIST
// export const evaluatoraddremove = 'https://preprodlb.stellar-oqcs.com'; //USERS
// export const calendarview = "https://preprodlb.stellar-oqcs.com"; //TRANSCRIPT
// export const groupstastsexporturl = 'https://preprodlb.stellar-oqcs.com'; //GROUPS
// export const assignmentlistexporturl = 'https://preprodlb.stellar-oqcs.com'; //TRANSCRIPT
// export const tasklistnameexporturl = 'https://preprodlb.stellar-oqcs.com'; //TASK
// export const scorm_importcourse = 'https://preprodul.stellar-oqcs.com/course/import/';
// export const filepeekurl = 'https://preprodul.stellar-oqcs.com/course/peek';
// export const filepeekurl1 = 'https://preprodul.stellar-oqcs.com/course';
// export const activeuserlistprinturl = 'https://preprodlb.stellar-oqcs.com'; //COMPANIES
// export const groupStatsprinturl = 'https://preprodlb.stellar-oqcs.com'; //GROUPS
// export const qrcodeinfo = 'https://preprodlb.stellar-oqcs.com'; //USERS
// export const transcriptprinturl = 'https://preprodlb.stellar-oqcs.com'; //TRANSCRIPT
// export const requirementRule = 'https://preprodlb.stellar-oqcs.com'; //----
// export const taskrule = 'https://preprodlb.stellar-oqcs.com'; //--------
// export const delete_groupurl = 'https://preprodlb.stellar-oqcs.com'; //TASK
// export const curriculumprinturl = 'https://preprodlb.stellar-oqcs.com'; //CURRICULLUM
// export const enrollmentprinturl = 'https://preprodlb.stellar-oqcs.com'; //ENROLLMENT
// export const learnerAssignmentprinturl = 'https://preprodlb.stellar-oqcs.com'; //COURSE
// export const userListprinturl = 'https://preprodul.stellar-oqcs.com' ;
// export const companyListprinturl = 'https://preprodlb.stellar-oqcs.com' ;
// export const reportlink = 'https://preprodlb.stellar-oqcs.com' ;
// export const jobPositionprinturl = 'https://preprodlb.stellar-oqcs.com' ;
// export const learnertaskprinturl = 'https://preprodlb.stellar-oqcs.com' ;
// export const learnerviewtaskurl = 'https://preprodlb.stellar-oqcs.com' ;
// export const mergeUserurl = 'https://preprodlb.stellar-oqcs.com' ; //USERS
// export const homeCounturl = 'https://preprodlb.stellar-oqcs.com' ;
// export const homeAnnouncementurl = 'https://preprodlb.stellar-oqcs.com' ;
// export const isntransferurl = 'https://preprodlb.stellar-oqcs.com' ;
// export const cataloglist = 'https://preprodlb.stellar-oqcs.com' ;
// export const generatePortability = 'https://preprodlb.stellar-oqcs.com'
// export const sharedRecCompanyId = 'https://preprodlb.stellar-oqcs.com'
// export const companylogo = 'https://preprodlb.stellar-oqcs.com/companies/uploadlogo';
// export const ExamManagement='https://preprodlb.stellar-oqcs.com';
// export const DemoLogin='https://preprodlb.stellar-oqcs.com';
// export const siteKey = '6LdUexclAAAAAHVNnu1Bx0nlTTmS-ZM9mSAa9psC';
// export const extnltrningdnldcsvtemplate ='https://externaltraining-properties-preprod1.s3.amazonaws.com/ExternalTraining.csv';
// export const extnltrningdnldcsvinstruction ='https://externaltraining-properties-preprod1.s3.amazonaws.com/ExternalTrainingCsvFormatInstructions.pdf';
// export const formsdnldcsvtemplate ='https://pef-properties-preprod1.s3.amazonaws.com/FormDetails.csv';
// export const formsdnldcsvinstruction ='https://pef-properties-preprod1.s3.amazonaws.com/FormDetailsCsvFormatInstructions.pdf';
// export const Tasksdnldcsvtemplate ='https://task-properties-preprod1.s3.amazonaws.com/TaskDetails.csv';
// export const Tasksdnldcsvinstruction ='https://task-properties-preprod1.s3.amazonaws.com/TaskDetailsCsvFormatInstructions.pdf';
// export const usersdnldcsvtemplate ='https://d2pvd3bkh8mxth.cloudfront.net/UserDetails.csv';
// export const usersdnldcsvinstruction ='https://d2pvd3bkh8mxth.cloudfront.net/UserDetailsCsvFormatInstructions.pdf';
// export const evaluation="https://preprodlb.stellar-oqcs.com"
// export const MEA_user_QRcode="https://qrcodes.stellar-oqcs.com";
//export const helpfileurl="https://d17nzlegqdq03e.cloudfront.net/help.htm";



//prod server

// export const loginapiUrl = 'https://mealb.stellar-oqcs.com'; //IDMS
// export const setpassapiUrl = 'https://mealb.stellar-oqcs.com'; //USERS
// export const filterservice = 'https://mealb.stellar-oqcs.com'; //LOOKUP
// export const usertable = 'https://meaul.stellar-oqcs.com'; // USERLIST
// export const createuserUrl = 'https://mealb.stellar-oqcs.com'; //USERS
// export const grouplistTable = 'https://mealb.stellar-oqcs.com'; //GROUPS
// export const importUrl = 'https://6w7q2fvvs8.execute-api.us-east-1.amazonaws.com/dev/import/'; // NOT YET IMPLEMENTED------------
// export const uploadurl = "https://mealb.stellar-oqcs.com/content/upload"; //USER PROFILE
// export const companylistMngmnt = 'https://mealb.stellar-oqcs.com'; //COMPANIES
// export const managerlist = "https://meaul.stellar-oqcs.com"; //USERSLIST
// export const license = 'https://mealb.stellar-oqcs.com'; //LICENSE
// export const courseurl = "https://mealb.stellar-oqcs.com/"; //EXTERNAL TRAINING
// export const externaltraininglist = "https://mealb.stellar-oqcs.com"; //EXTERNAL TRAINING
// export const companynamevalidate = "https://mealb.stellar-oqcs.com"; //COMPANIES
// export const assignableList = 'https://mealb.stellar-oqcs.com'; //COURSE
// export const createcourse = "https://mealb.stellar-oqcs.com"; //COURSE
// export const enrollmentlist = "https://mealb.stellar-oqcs.com"; //ENROLLMENT
// export const courseProperties = "https://mealb.stellar-oqcs.com";//COURSE
// export const curriculum = "https://mealb.stellar-oqcs.com";//CURRICULLUM
// export const updatecurriclumUrl = "https://mealb.stellar-oqcs.com";//CURRICULLUM
// export const assignments = "https://mealb.stellar-oqcs.com";//COURSE
// export const assignmentList = 'https://mealb.stellar-oqcs.com';//COURSE
// export const formlist = "https://mealb.stellar-oqcs.com";//PEF
// export const formlistimport = "https://mealb.stellar-oqcs.com";//PEFimport
// export const launch = "https://mealb.stellar-oqcs.com";//RUNTIME
// export const createforms = "https://mealb.stellar-oqcs.com";//PEF
// export const pefsettings = "https://mealb.stellar-oqcs.com"; //PEF
// export const announcement = "https://mealb.stellar-oqcs.com";//ANNOUNCEMENT
// export const task = "https://mealb.stellar-oqcs.com";//TASK
// export const learnertask = "https://mealb.stellar-oqcs.com"//TASK
// export const createtasklist = "https://mealb.stellar-oqcs.com"; //TASK
// export const launchlink = "https://mealb.stellar-oqcs.com/";//RUNTIME
// export const curriculasequesnce = "https://mealb.stellar-oqcs.com"//COURSE
// export const launch_callback_serverurl = "https://mea.stellar-oqcs.com/"; //--------
// export const evaluatorlist = 'https://meaul.stellar-oqcs.com'; //USERSLIST
// export const evaluatoraddremove = 'https://mealb.stellar-oqcs.com'; //USERS
// export const calendarview = "https://mealb.stellar-oqcs.com"; //TRANSCRIPT
// export const groupstastsexporturl = 'https://mealb.stellar-oqcs.com'; //GROUPS
// export const assignmentlistexporturl = 'https://mealb.stellar-oqcs.com'; //TRANSCRIPT
// export const tasklistnameexporturl = 'https://mealb.stellar-oqcs.com'; //TASK
// export const scorm_importcourse = 'https://meaul.stellar-oqcs.com/course/import/';
// export const filepeekurl = 'https://meaul.stellar-oqcs.com/course/peek';
// export const filepeekurl1 = 'https://meaul.stellar-oqcs.com/course';
// export const activeuserlistprinturl = 'https://mealb.stellar-oqcs.com'; //COMPANIES
// export const groupStatsprinturl = 'https://mealb.stellar-oqcs.com'; //GROUPS
// export const qrcodeinfo = 'https://mealb.stellar-oqcs.com'; //USERS
// export const transcriptprinturl = 'https://mealb.stellar-oqcs.com'; //TRANSCRIPT
// export const requirementRule = 'https://mealb.stellar-oqcs.com'; //----
// export const taskrule = 'https://mealb.stellar-oqcs.com'; //--------
// export const delete_groupurl = 'https://mealb.stellar-oqcs.com'; //TASK
// export const curriculumprinturl = 'https://mealb.stellar-oqcs.com'; //CURRICULLUM
// export const enrollmentprinturl = 'https://mealb.stellar-oqcs.com'; //ENROLLMENT
// export const learnerAssignmentprinturl = 'https://mealb.stellar-oqcs.com'; //COURSE
// export const userListprinturl = 'https://meaul.stellar-oqcs.com' ;
// export const companyListprinturl = 'https://mealb.stellar-oqcs.com' ;
// export const reportlink = 'https://mealb.stellar-oqcs.com' ;
// export const jobPositionprinturl = 'https://mealb.stellar-oqcs.com' ;
// export const learnertaskprinturl = 'https://mealb.stellar-oqcs.com' ;
// export const learnerviewtaskurl = 'https://mealb.stellar-oqcs.com' ;
// export const mergeUserurl = 'https://mealb.stellar-oqcs.com' ; //USERS
// export const homeCounturl = 'https://mealb.stellar-oqcs.com' ;
// export const homeAnnouncementurl = 'https://mealb.stellar-oqcs.com' ;
// export const isntransferurl = 'https://mealb.stellar-oqcs.com' ;
// export const cataloglist = 'https://mealb.stellar-oqcs.com' ;
// export const generatePortability = 'https://mealb.stellar-oqcs.com'
// export const sharedRecCompanyId = 'https://mealb.stellar-oqcs.com'
// export const companylogo = 'https://mealb.stellar-oqcs.com/companies/uploadlogo';
// export const ExamManagement='https://mealb.stellar-oqcs.com';
// export const DemoLogin='https://mealb.stellar-oqcs.com';
// export const siteKey = '6LeffhclAAAAAJOAv9v1XK_gbKP0xdCNW9F9YodL';
// export const extnltrningdnldcsvtemplate ='https://mea-externaltraining-properties-9311-6103-8466.s3.amazonaws.com/ExternalTraining.csv';
// export const extnltrningdnldcsvinstruction ='https://mea-externaltraining-properties-9311-6103-8466.s3.amazonaws.com/ExternalTrainingCsvFormatInstructions.pdf';
// export const formsdnldcsvtemplate ='https://mea-pef-properties-9311-6103-8466.s3.amazonaws.com/FormDetails.csv';
// export const formsdnldcsvinstruction ='https://mea-pef-properties-9311-6103-8466.s3.amazonaws.com/FormDetailsCsvFormatInstructions.pdf';
// export const Tasksdnldcsvtemplate ='https://mea-task-properties-9311-6103-8466.s3.amazonaws.com/TaskDetails.csv';
// export const Tasksdnldcsvinstruction ='https://mea-task-properties-9311-6103-8466.s3.amazonaws.com/TaskDetailsCsvFormatInstructions.pdf';
// export const usersdnldcsvtemplate ='https://mea-users-properties-9311-6103-8466.s3.amazonaws.com/UserDetails.csv';
// export const usersdnldcsvinstruction ='https://mea-users-properties-9311-6103-8466.s3.amazonaws.com/UserDetailsCsvFormatInstructions.pdf';
// export const evaluation="https://mealb.stellar-oqcs.com"
// export const MEA_user_QRcode="https://meaqrcode.stellar-oqcs.com";
//export const helpfileurl="https://d122a1fixariu.cloudfront.net/help.htm";


// Demo URL - Stellar LMS
export const loginapiUrl = 'https://lmsbe.stellar-oqcs.com'; //IDMS
export const setpassapiUrl = 'https://lmsbe.stellar-oqcs.com'; //USERS
export const filterservice = 'https://lmsbe.stellar-oqcs.com'; //LOOKUP
export const usertable = 'https://lmsbe.stellar-oqcs.com'; // USERLIST
export const createuserUrl = 'https://lmsbe.stellar-oqcs.com'; //USERS
export const grouplistTable = 'https://lmsbe.stellar-oqcs.com'; //GROUPS
export const importUrl='https://6w7q2fvvs8.execute-api.us-east-1.amazonaws.com/dev/import/'; // NOT YET IMPLEMENTED
export const uploadurl="https://lmsbe.stellar-oqcs.com/content/upload"; //USER PROFILE
export const companylistMngmnt='https://lmsbe.stellar-oqcs.com'; //COMPANIES
export const managerlist="https://lmsbe.stellar-oqcs.com"; //USERSLIST
export const license = ' https://lmsbe.stellar-oqcs.com'; //LICENSE
export const courseurl="https://lmsbe.stellar-oqcs.com/"; //EXTERNAL TRAINING
export const externaltraininglist="https://lmsbe.stellar-oqcs.com"; //EXTERNAL TRAINING
export const companynamevalidate = "https://lmsbe.stellar-oqcs.com"; //COMPANIES
export const assignableList = 'https://lmsbe.stellar-oqcs.com'; //COURSE
export const createcourse = "https://lmsbe.stellar-oqcs.com"; //COURSE
export const enrollmentlist= "https://lmsbe.stellar-oqcs.com"; //ENROLLMENT
 export const courseProperties="https://lmsbe.stellar-oqcs.com";//COURSE
 export const curriculum = "https://lmsbe.stellar-oqcs.com";//CURRICULLUM
 export const updatecurriclumUrl ="https://lmsbe.stellar-oqcs.com";//CURRICULLUM
 export const assignments="https://lmsbe.stellar-oqcs.com";//COURSE
 export const assignmentList = 'https://lmsbe.stellar-oqcs.com';//COURSE
 export const formlist="https://lmsbe.stellar-oqcs.com";//PEF
 export const formlistimport="https://lmsbe.stellar-oqcs.com";//PEFimport
 export const launch="https://lmsbe.stellar-oqcs.com";//LAUNCH
 export const createforms="https://lmsbe.stellar-oqcs.com";//PEF
 export const pefsettings = "https://lmsbe.stellar-oqcs.com"; //PEF
 export const announcement = "https://lmsbe.stellar-oqcs.com";//AANNOUNCEMENT
 export const task = "https://lmsbe.stellar-oqcs.com";//TASK
 export const learnertask = "https://lmsbe.stellar-oqcs.com"//TASK
 export const createtasklist = "https://lmsbe.stellar-oqcs.com"; //TASK
 export const launchlink="https://lmsbe.stellar-oqcs.com/";//LAUNCH
 export const curriculasequesnce="https://lmsbe.stellar-oqcs.com"//COURSE
// export const launch_callback_serverurl="https://lmsbe.stellar-oqcs.com/";
  export const evaluatoraddremove = 'https://lmsbe.stellar-oqcs.com';
  export const evaluatorlist = 'https://lmsbe.stellar-oqcs.com';
  export const calendarview = "https://lmsbe.stellar-oqcs.com";
 export const assignmentlistexporturl = 'https://lmsbe.stellar-oqcs.com';
 export const groupstastsexporturl = "https://lmsbe.stellar-oqcs.com";
 export const tasklistnameexporturl = 'https://lmsbe.stellar-oqcs.com';
export const scorm_importcourse = 'https://lmsbe.stellar-oqcs.com/course/import/'
export const filepeekurl = 'https://lmsbe.stellar-oqcs.com/course/peek';
export const filepeekurl1 = 'https://lmsbe.stellar-oqcs.com/course';
export const qrcodeinfo = 'https://lmsbe.stellar-oqcs.com'
export const activeuserlistprinturl = 'https://lmsbe.stellar-oqcs.com';
export const groupStatsprinturl = 'https://lmsbe.stellar-oqcs.com';
export const transcriptprinturl = 'https://lmsbe.stellar-oqcs.com';
export const requirementRule = 'https://lmsbe.stellar-oqcs.com'
export const taskrule = 'https://lmsbe.stellar-oqcs.com';
export const delete_groupurl = 'https://lmsbe.stellar-oqcs.com';
export const curriculumprinturl = 'https://lmsbe.stellar-oqcs.com';
 export const enrollmentprinturl = 'https://lmsbe.stellar-oqcs.com';
export const learnerAssignmentprinturl = 'https://lmsbe.stellar-oqcs.com';
export const userListprinturl = 'https://lmsbe.stellar-oqcs.com' ;
export const companyListprinturl = 'https://lmsbe.stellar-oqcs.com' ;
export const reportlink = 'https://lmsbe.stellar-oqcs.com' ;
export const jobPositionprinturl = 'https://lmsbe.stellar-oqcs.com' ;
export const learnertaskprinturl = 'https://lmsbe.stellar-oqcs.com' ;
export const learnerviewtaskurl = 'https://lmsbe.stellar-oqcs.com' ;
export const mergeUserurl = 'https://lmsbe.stellar-oqcs.com' ; //USERS
export const homeCounturl = 'https://lmsbe.stellar-oqcs.com' ;
export const homeAnnouncementurl = 'https://lmsbe.stellar-oqcs.com' ;
export const isntransferurl = 'https://lmsbe.stellar-oqcs.com' ;
export const cataloglist = 'https://lmsbe.stellar-oqcs.com' ;
export const generatePortability = 'https://lmsbe.stellar-oqcs.com'
export const sharedRecCompanyId = 'https://lmsbe.stellar-oqcs.com'
export const companylogo = 'https://lmsbe.stellar-oqcs.com/companies/uploadlogo';
export const ExamManagement='https://lmsbe.stellar-oqcs.com';
export const DemoLogin='https://lmsbe.stellar-oqcs.com';
export const siteKey = '6LcfHP4fAAAAABVkzdTQ2bhzMRFaBVP8AF2689Tx';
export const extnltrningdnldcsvtemplate ='https://externaltraining-properties.s3.amazonaws.com/demoexternal/ExternalTraining.csv';
export const extnltrningdnldcsvinstruction ='https://externaltraining-properties.s3.amazonaws.com/demoexternal/ExternalTrainingCsvFormatInstructions.pdf';
export const formsdnldcsvtemplate ='https://form-properties.s3.amazonaws.com/demoform/FormDetails.csv';
export const formsdnldcsvinstruction ='https://form-properties.s3.amazonaws.com/demoform/FormDetailsCsvFormatInstructions.pdf';
export const Tasksdnldcsvtemplate ='https://task-properties.s3.amazonaws.com/demotask/TaskDetails.csv';
export const Tasksdnldcsvinstruction ='https://task-properties.s3.amazonaws.com/demotask/TaskDetailsCsvFormatInstructions.pdf';
export const usersdnldcsvtemplate ='https://usersproperties.s3.amazonaws.com/demouser/UserDetails.csv';
export const usersdnldcsvinstruction ='https://usersproperties.s3.amazonaws.com/demouser/UserDetailsCsvFormatInstructions.pdf';
export const evaluation="https://lmsbe.stellar-oqcs.com"
export const MEA_user_QRcode="https://qrdemo.stellar-oqcs.com/";
export const helpfileurl="https://d17nzlegqdq03e.cloudfront.net/help.htm";