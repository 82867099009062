<div class="spinner_overlay" *ngIf="loadspinner === true"></div>
<mat-spinner class="spinner" *ngIf="loadspinner === true"></mat-spinner>

<div class="filtermaincontainer" (keydown)="EnterSubmit($event)">
  <div class="row headerrow">
    <div class="col-10">
      <img src="assets/images/filter-filled-tool-symbol.svg" alt="filter" />
      <span class="heading">&nbsp;&nbsp;Filter</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()"
        >clear</mat-icon
      >
    </div>
  </div>

  <form [formGroup]="filterform">
    <div class="row">
      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Title</mat-label>
        <mat-form-field
          appearance="outline"
          id="formfieldusernameid"
          class="formfieldusertable"
        >
          <input
            matInput
            placeholder="Title"
            autocomplete="off"
            #inpgroupname
            (keyup)="inputchangeTitle(inpgroupname.value)"
            class="inputClassText"
            formControlName="title"
          />
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Category</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select
            formControlName="category"
            placeholder="Category"
            class="inputClass"
            panelClass="myPanelmatselectClassCOID"
            (selectionChange)="selectcategory($event.value)"
            disableOptionCentering
          >
            <mat-option [value]="" class="none_style"> None </mat-option>
            <mat-option
              *ngFor="let item of categorylist"
              [value]="item.categoryName"
            >
              <div class="matoptiontxt">{{ item.categoryName }}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Type</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select
            formControlName="type"
            placeholder="Type"
            class="inputClass"
            panelClass="myPanelmatselectClassCOID"
            (selectionChange)="selecttype($event.value)"
            disableOptionCentering
          >
            <mat-option [value]="" class="none_style"> None </mat-option>
            <mat-option *ngFor="let item of typelist" [value]="item.type">
              <div class="matoptiontxt">{{ item.type }}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Status</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select
            formControlName="status"
            placeholder="Status"
            class="inputClass"
            panelClass="myPanelmatselectClass"
            (selectionChange)="selectstatus($event.value)"
            disableOptionCentering
          >
            <mat-option [value]="" class="none_style"> None </mat-option>
            <mat-option *ngFor="let item of statuslist" [value]="item.status">
              <div class="matoptiontxt">{{ item.status }}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6 cancelbtncol">
        <br /><br />
        <button
          mat-button
          id="btncancelid"
          class="cancelbtn"
          (click)="cancelbtn()"
        >
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel
        </button>
      </div>
      <div class="col-6 Filterbtncol">
        <br /><br />
        <button
          mat-button
          id="btnfiltertermid"
          class="Filterbtn"
          [disabled]="disablebutton"
          (click)="Filter()"
        >
          <mat-icon class="filterbtnicon">done</mat-icon>
          Filter
        </button>
      </div>
    </div>
  </form>
</div>
