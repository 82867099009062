  <div class="filtermaincontainer" >
  <div class="row headerrow">
  <div class="col-1"> <img src="assets/images/optimizer.svg"> </div>
  <div class="col-8">
  <span class="heading">Record Of Completion </span>
  </div>

  <div class="col-1">
  <button mat-icon-button class="usertablebtn" style="width:45px; margin-top: -10px; margin-bottom: 5px;">
  <img src="assets/images/print.svg" alt="filter" title="Filter" class="icons">
  </button>
  </div>

  <div class="col-1">
  <button mat-icon-button class="usertablebtn" style="width:45px; margin-top: -10px; margin-bottom: 5px;" (click)="convert()">
  <img src="assets/images/download.svg" alt="filter" title="Filter" class="icons">
  </button>
  </div>

  <div class="col-1 cleariconcol">
  <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
  </div>
  </div>

  <div style="background-color: #fff; padding: 1%;" id="imageDIV">

  <div class="row" style="border: 2px solid #000; margin: 1%;margin-top: 1%; background-color: rgb(255, 255, 255);" >

  <div class="row">
  <div class="col-md-1"> &#160; </div>
  <div class="col-md-11">
  <p class="new_title"> Record Of Completion </p>
  </div>

  <div class="col-12">
  <img src="../../../../assets/images/group@3x.webp" style="width: 100%; margin-left: -1px;">
  </div>

  <div class="col-12">

  <p class="sub_title"> This record of completion is proudly presented to </p>

  </div>

  <br> &#160; <br>

  <div class="col-12">
  <p class="name"> {{name}} </p>
  <hr class="hr">
  </div>

  <div class="col-12">

  <p style="font-weight: bold; text-align: center; margin: 2%;"> For Successful completion of </p>
  <p style="text-align: center;"> {{course}} </p>
  
  <br> &#160; <br> &#160;

  </div>

 

 
  <div class="col-1"> </div>
  <div class="col-3" style="text-align: center;"> <strong> {{completiondate}} </strong>
  <hr style="width: 80%; margin-left: 10%; margin-top: 3%; margin-bottom: 3%;"> COMPLETION DATE
  </div>

  <div class="col-3" style="text-align: center;"> <strong> {{companyname}} </strong>
    <hr style="width: 80%; margin-left: 10%; margin-top: 3%; margin-bottom: 3%;"> ORGANIZATION
  </div>

  <div class="col-3" style="text-align: center;"> <strong> Stellar IT Solutions, INC. </strong>
    <hr style="width: 80%; margin-left: 10%; margin-top: 3%; margin-bottom: 3%;"> ISSUED BY
  </div>
  <div class="col-1">  </div>
  
  

  </div>
  <br>

  </div>

  </div>