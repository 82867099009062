import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';

@Component({
  selector: 'app-proctor-reset-filter',
  templateUrl: './proctor-reset-filter.component.html',
  styleUrls: ['./proctor-reset-filter.component.scss']
})
export class ProctorResetFilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton:any;
  today = new Date();
  companyId: any;
  constructor(private formBuilder: FormBuilder, public dialog: MatDialogRef<UserComponent>) { 
    this.filterform = this.formBuilder.group({
      login: [''],
      name: [''],
      empId: [''],
      courseId: [''],
      coursetitle: [''],

    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('groupfilterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    // this.filterform.controls['CompanyId'].setValue(this.companyId)
    
  }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['login'].setValue(data["Login"]);
      this.filterform.controls['name'].setValue(data["Name"])
      this.filterform.controls['empId'].setValue(data["Emp ID"]);
      this.filterform.controls['courseId'].setValue(data["Course ID"]);
      this.filterform.controls['coursetitle'].setValue(data["Course Title"]);
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
    
        if(clearfilteritemkey[i]==="Login"){
          this.filterform.controls['login'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Name"){
          this.filterform.controls['name'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Emp ID"){
          this.filterform.controls['empId'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Course ID"){
          this.filterform.controls['courseId'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Course Title"){
          this.filterform.controls['coursetitle'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  apicallgetdefaultdropdown() {
    this.setdefaultdropdownvalues(); 
    }

  // on keyup enable filter button
  input1changename(value){
    this.dynamicEnableDisablebtn()
   }
   input2changename(value){
    this.dynamicEnableDisablebtn()
   }
   input3changename(value){
    this.dynamicEnableDisablebtn()
   }
   input4changename(value){
    this.dynamicEnableDisablebtn()
   }
   input5changename(value){
    this.dynamicEnableDisablebtn()
   }

   cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('group_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('groupfilterdata');
      localStorage.removeItem('group_chipdisplayfilterdata');
      localStorage.removeItem('group_clearfilteritemkey');
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      this.dialog.close({ data: groupfilterdata, groupapibodycontent: this.groupapibodycontent, method: 'filter' });
    }

  }

  getbodycontent() {
    let groupfilterdata = {
      "Login": this.filterform.value.login.trim(),
      "Name": this.filterform.value.name.trim(),
      "Emp ID": this.filterform.value.empId.trim(),
      "Course ID": this.filterform.value.courseId.trim(),
      "Course Title": this.filterform.value.coursetitle.trim()
    }
    this.getapibodycontent();
    return groupfilterdata
  }

  getapibodycontent() {
    let compId = localStorage.getItem('procCompId')
    this.groupapibodycontent = {
      "loginName": this.filterform.value.login.trim(),
      "displayName":this.filterform.value.name.trim(),
      "empId": this.filterform.value.empId.trim(),
      "requirementId":this.filterform.value.courseId.trim(),
      "title": this.filterform.value.coursetitle.trim(),
      "companyId":compId
    }
  }

  checkformvalue(){
    let formempty;
    if(this.filterform.value.login.trim()==="" 
     && this.filterform.value.name.trim()==="" && this.filterform.value.empId.trim()==="" 
     && this.filterform.value.courseId.trim()==="" && this.filterform.value.coursetitle.trim()===""){
      formempty=true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn(){
    let checkformfield= this.checkformvalue();
    if(checkformfield===true){
      this.disablebutton=true;
    }
    else{
      this.disablebutton=false;
    }
  }

  EnterSubmit(event){
    if(event.keyCode === 13){
     //this.Filter();
     this.dynamicEnableDisablebtn();
     if(this.disablebutton===true){
      event.preventDefault();
     }
     else{
      event.preventDefault();
      this.Filter();
     }

    }
  }

 

}
