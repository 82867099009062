import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EvaluationComponent } from 'src/app/Evaluator/assigned-user/evaluation/evaluation.component';
@Component({
  selector: 'app-evaluationresult',
  templateUrl: './evaluationresult.component.html',
  styleUrls: ['./evaluationresult.component.scss']
})
export class EvaluationresultComponent implements OnInit {
  label1:any='';
  label2:any='';
  hours:any='';
  minutes:any='';
  seconds:any='';
  submitbtn=true;
  passbtn=false;
  failbtn=false;
  evaluation_status:any='';
  constructor( @Inject(MAT_DIALOG_DATA) public data: string, public dialog: MatDialogRef<EvaluationComponent>) { 
    
    if(data['result']['assignment_score'] == 'Qualified/Not Qualified'){
      this.label1='Qualified';
      this.label2='Not Qualified';
    }else{
      this.label1='Passed';
      this.label2='Failed'
    }
        
    let seconds=data['result']['duration'];
    const result = new Date(seconds * 1000).toISOString().slice(11, 19);
    
    if(result){
      let temp_data=result.split(':');
      this.hours=temp_data[0];
      this.minutes=temp_data[1];
      this.seconds=temp_data[2]
    }
    if(data['result']['result']=='fail'){
      this.passbtn=true;
    }
  }

  ngOnInit(): void {
  }

  changeevent(){
    this.submitbtn=false;
  }
  cancelbtn(){
    this.dialog.close();
  }
  submit(){
   this.dialog.close({status: this.evaluation_status, hours: this.hours, minutes:this.minutes, seconds:this.seconds })
  }
}
