import { AbstractType, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransScriptComponent } from 'src/app/Learner/assignments/trans-script/trans-script.component';
import domtoimage from 'dom-to-image';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { DatePipe } from '@angular/common';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-completion-record',
  templateUrl: './completion-record.component.html',
  styleUrls: ['./completion-record.component.scss'],
  providers: [DatePipe]
})
export class CompletionRecordComponent implements OnInit {
  name: any;
  course: any;
  completiondate: any;
  companyname: AsyncGenerator;
  requirementlist: any = [];
  requirementlist1: any = [];
  requirementlistalphabet: any = [];
  requirementlist2: any = [];
  albhabetlist: any = [];
  albhabetlist1: any = [];
  albhabetlist2: any = [];
  curriculumId: any;
  status: any;
  loginName: any;
  loadspinner: any;
  category: any;
  courseTitleList: any = [];
  todaydate: any;
  taskId: any;
  sharedname: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, private datePipe: DatePipe, public dialog: MatDialogRef<TransScriptComponent>, private apiService: ApiServiceService) {
    this.name = data['name'];
    this.course = data['course'];
    this.completiondate = this.datePipe.transform(data['completiondate'], 'MM/dd/yyyy');
    this.companyname = data['companyname'];
    this.category = data['category'];
    this.loginName = data['loginName'];
    this.taskId = data["taskId"];
    this.sharedname = data["sharedname"];
    this.todaydate = this.datePipe.transform(new Date(), 'MMMM d, y');
    if (this.category === "Curriculum") {
      this.loadspinner = true;
      const endpoint1 = 'transcript';
      const endpoint2 = 'coursetitlelist';

      let body = {
        curriculumId: data["curriculumId"],
        status: data["status"],
        loginName: data["loginName"]
      }
      this.apiService.Transript_viewcompletionrecord(body, endpoint1, endpoint2, 'POST')
        .subscribe((response) => {
          this.courseTitleList = response['courseTitleList'];
          this.loadspinner = false;

          this.requirementlist = this.courseTitleList;
          this.albhabetlist = ["a.", "b.", "c.", "d.", "e.", "f.", "g.", "h.", "i.", "j.", "k.", "l.", "m.", "n.", "o.", "p.", "q.", "r.", "s.", "t.", "u.", "v.", "w.", "x.", "y.", "z."]

          if (this.requirementlist.length > 0) {
            for (let i = 0; i < this.requirementlist.length; i++) {
              this.requirementlist1[this.albhabetlist[i]] = this.requirementlist[i];
            }
          }
        },
          (err) => {
            this.loadspinner = false;


          });
    }

    else if (this.category == "Task" || this.category == "task") {
      this.loadspinner = true;
      let endpoint1 = 'transcript/task';
      let endpoint2 = 'assignmentview/' + this.sharedname;
      let pageNo = "1";
      let itemPerPage = "10";
      let taskId = this.taskId;
      let body = "";

      this.apiService.viewassignmenttask(taskId, this.loginName, pageNo, itemPerPage, endpoint1, endpoint2, body, 'POST').subscribe((response) => {

        this.loadspinner = false;
        let data = response["requirementList"];
        let requirement_list: any = [];
        for (let i = 0; i < data.length; i++) {
          requirement_list.push(data[i]['requirementTitle'])
        }

        //to avoid duplicate display for the requirements
        this.requirementlist = [... new Set(requirement_list.map(data => data))]
        //to avoid duplicate display for the requirements
        this.albhabetlist = ["a.", "b.", "c.", "d.", "e.", "f.", "g.", "h.", "i.", "j.", "k.", "l.", "m.", "n.", "o.", "p.", "q.", "r.", "s.", "t.", "u.", "v.", "w.", "x.", "y.", "z."]
        if (this.requirementlist.length > 0) {
          for (let i = 0; i < this.requirementlist.length; i++) {
            this.requirementlist1[this.albhabetlist[i]] = this.requirementlist[i];

          }

        }

      },
        (err) => {
          this.loadspinner = false;
        });
    }
    else {

    }

  }

  ngOnInit(): void {
  }

  cancelbtn() {
    this.dialog.close({ method: 'close' })
  }

  convert() {

    //let element = document.querySelector("#imageDIV");
    html2canvas(document.getElementById("imageDIV")).then(function (canvas) {
      // Convert the canvas to blob
      canvas.toBlob(function (blob) {
        // To download directly on browser default 'downloads' location
        let link = document.createElement("a");
        link.download = "image.png";
        link.href = URL.createObjectURL(blob);
        link.click();

      }, 'image/png');
    });


  }

  printcertificate(type) {

    html2canvas(document.getElementById(type)).then(canvas => {
      let instance = this;

      canvas.toBlob(function (blob) {

        var img = canvas.toDataURL();

        instance.printdata(img);
      }, 'image/png');
    },
    );
  }


  printdata(data) {
    var frame1 = document.createElement('iframe');
    frame1.name = "frame1";
    frame1.style.position = "absolute";
    frame1.style.top = "1000000px";
    frame1.style.verticalAlign = "middle";
    document.body.appendChild(frame1);
    var frameDoc = frame1.contentWindow;
    let file: any = '';
    file += '<div style=" display: flex;justify-content: center;align-items: center;height: 100%;">'
    file += '<img style="width:100%" src="' + data + '" />'
    file += '</div>'


    frameDoc.document.open();
    frameDoc.document.write(`<html><head><title></title>`);
    frameDoc.document.write('</head><body>');
    frameDoc.document.write(file);
    frameDoc.document.write('</body></html>');
    frameDoc.document.close();
    var instance = this;
    setTimeout(function () {
      window.frames["frame1"].focus();
      window.frames["frame1"].print();
      document.body.removeChild(frame1);
    }, 500);
  }

}
