import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-scrom-exit',
  templateUrl: './scrom-exit.component.html',
  styleUrls: ['./scrom-exit.component.scss']
})
export class ScromExitComponent implements OnInit {
  menuTitle: string;
  loadspinner: any;
  constructor() {
    var closeiframemodal = $('#closeButton', window.parent.document);
    closeiframemodal.click();
    
   
  }

  ngOnInit() {  
  }


  ngOnDestroy(){
   
  }

}
