import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import * as moment from 'moment';

@Component({
  selector: 'app-suspendeduser',
  templateUrl: './suspendeduser.component.html',
  styleUrls: ['./suspendeduser.component.scss']
})
export class SuspendeduserComponent implements OnInit {
  Suspendedform: FormGroup;  
  today = new Date();
  unsuspendedform: FormGroup;
  from:any;
  disablebutton: any;
  Headingmessage:any

  constructor(public dialog: MatDialogRef<UserComponent>,private formBuilder: FormBuilder,@Inject(MAT_DIALOG_DATA) public data: string) { 
    this.from=data['from'];
  }

  ngOnInit(): void {
    this.Suspendedform = this.formBuilder.group({
      SuspendedDate: [this.today,  [Validators.required]],
      notes:['',  [Validators.required]]
    });
    
    this.unsuspendedform = this.formBuilder.group({
      UnSuspendedDate: [this.today,  [Validators.required]],
      unsuspendednotes:['',  [Validators.required]]
    });

    if(this.from==="Suspended"){
      this.Headingmessage="User status was changed to Suspended"

    }
    else if(this.from==="Active" || this.from==="Inactive" || this.from==="Archived"){
      this.Headingmessage="User status was changed to "+ this.from
    }
    else if(this.from==="Suspend_Task"){
      this.Headingmessage="change task status to Suspended";
      this.Suspendedform.controls['SuspendedDate'].setValue(new Date());
      this.Suspendedform.get('SuspendedDate').disable()
    }
    else if(this.from==="UnSuspend_Task"){
      this.Headingmessage="Unsuspend Task"
      this.unsuspendedform.controls['UnSuspendedDate'].setValue(new Date());
      this.unsuspendedform.get('UnSuspendedDate').disable()
    }
  }

  cancelbtn(){
    this.dialog.close();
  }

  changeSuspendedDate(value) {
    this.dynamicEnableDisablebtn();
  }

  changeUnSuspendedDate(value) {
    this.dynamicEnableDisableunbtn();
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }

  checkformvalue() {
    let formempty;
    if (this.Suspendedform.value.SuspendedDate === "" && this.Suspendedform.value.notes === ""
    ) {
      formempty = true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisableunbtn() {
    let checkformfield = this.checkunformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }

  checkunformvalue() {
    let formempty;
    if (this.unsuspendedform.value.UnSuspendedDate === "" && this.unsuspendedform.value.unsuspendednotes === ""
    ) {
      formempty = true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  suspended_submit(){
    if(this.Suspendedform.valid) {
      let dateofbirth = this.formatDate(this.Suspendedform.controls.SuspendedDate.value);
      localStorage.setItem('suspendedDate',dateofbirth);
      localStorage.setItem('suspendedNote',this.Suspendedform.controls.notes.value);
      this.dialog.close('yes');
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  unsuspended_submit(){
    if(this.unsuspendedform.valid) {
      let dateofbirth = this.formatDate(this.unsuspendedform.controls.UnSuspendedDate.value);
      localStorage.setItem('UnsuspendedDate',dateofbirth);
      localStorage.setItem('UnsuspendedNote',this.unsuspendedform.controls.unsuspendednotes.value);
      this.dialog.close('yes');
    }
  }

}
