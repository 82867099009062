import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-assignedcompanylist',
  templateUrl: './assignedcompanylist.component.html',
  styleUrls: ['./assignedcompanylist.component.scss']
})
export class AssignedcompanylistComponent implements OnInit {
  id:any;
  title:any;
  loadspinner: any = false;
  ELEMENT_DATA: any = [];
  dataSource: any;
  from: any;
  displayedColumns: string[] = ['company'];
  constructor(@Inject(MAT_DIALOG_DATA) public data: string,private apiService: ApiServiceService,public dialog: MatDialogRef<AssignedcompanylistComponent>) { 
    this.id=data['id'];
    this.title=data['title'];
    this.from=data['from'];
    this.loaddefaultValue();
  }

  ngOnInit(): void {
  }

  loaddefaultValue(){
    if(this.from=='announcement'){
      this.loadspinner = true;
      const endpoint1 = 'announcement';
      const endpoint2 = 'getcompaniesann';
      let queryparameter = this.id;
      this.apiService.termsandcondition_companies(endpoint1, endpoint2, queryparameter, 'GET')
        .subscribe((response) => {
          this.loadspinner = false;
          this.ELEMENT_DATA = response['companiesAudience'];
          this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        },
          (err) => {
            this.loadspinner = false;
        });
    }else if(this.from=='termsandcondition'){
      this.loadspinner = true;
      const endpoint1 = 'announcement';
      const endpoint2 = 'getcompaniesterms';
      let queryparameter = this.id;
      this.apiService.termsandcondition_companies(endpoint1, endpoint2, queryparameter, 'GET')
        .subscribe((response) => {
          this.loadspinner = false;
          this.ELEMENT_DATA = response['companiesAudience'];
          this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        },
          (err) => {
            this.loadspinner = false;
          });
    }

  }

  cancelbtn(){
    this.dialog.close({ method: 'close' })
  }

}
