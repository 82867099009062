import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Observable } from 'rxjs';
import { TasksComponent } from 'src/app/Administrator/tasks/tasks.component';
@Component({
  selector: 'app-equivalent',
  templateUrl: './equivalent.component.html',
  styleUrls: ['./equivalent.component.scss']
})
export class EquivalentComponent implements OnInit {
  loadspinner: boolean;
  title;
  user_data: any = []
  selected_users: any = [];
  EquivalentForm: FormGroup;
  groupList: any = [];
  statusTypes = ["Group-name1", "Group-name2", "Group-name3", "Group-name4", "Group-name5"];
  groupreslist: any = [];
  operatorCounter: any = [];
  operatorOptions: Observable<string[]>[] = [];
  array: any = [];
  statuslist: any = []
  request: any = [];
  selectedData: { value: any; text: string; };
  isChecked: any;
  frompage: any;
  equivalentapiresponse: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialog: MatDialogRef<TasksComponent>, private formBuilder: FormBuilder) {
    this.groupList = data['groupListData'];
    this.frompage = data['frompage'];
    this.equivalentapiresponse = data['equivalentapiresponse'];

    if (data['groupListData'] != undefined && data['groupListData'] != null) {
    }
    this.EquivalentForm = this.formBuilder.group({

    });

  }

  ngOnInit(): void {


    this.apicallgetdefaultdropdown();
  }


  group(name) {
    this.groupreslist.push("Group" + name)
    return "Group " + name
  }
  apicallgetdefaultdropdown() {

    this.statuslist = []

    for (let n = 0; n < this.groupList.length; n++) {

      let value = n + 1
      let data = { "status_id": n, "statusName": "Group" + value, "FormName":"Group" + value}
      this.statuslist.push(data);
      this.operatorCounter.push({ 'groupName': this.groupList[n].groupName})
      this.EquivalentForm.addControl("group" + (n + 1), new FormControl({ value: [], }));
    }
    for (let i = 1; i <= this.operatorCounter.length; i++) {
      let data;
      let tempArray = []
      for (let j = 1; j <= this.groupList.length; j++) {
        data = { "status_id": j, "statusName": this.groupList[j-1].groupName, "FormName":"Group" + j}	
        if (i != j) {
          tempArray.push(data)
        }
      }
      this.operatorCounter[i - 1].statuslist = tempArray;
    }

      let preGroup;
      let preGroup1;
      if(this.frompage=="create"){
        preGroup1 = JSON.parse(localStorage.getItem("groupArrForReq"))
        preGroup = preGroup1.groupList;
      }
      else{
        if(this.equivalentapiresponse==null || this.equivalentapiresponse==undefined){
          let submitclick= localStorage.getItem("submitclick");
          if(submitclick=="true"){
            preGroup1 = JSON.parse(localStorage.getItem("groupArrForReq"))
            preGroup = preGroup1.groupList;
          } 
        }
        else{
        preGroup1 =  this.equivalentapiresponse;
        preGroup = preGroup1.groupList;
        }
      }
      let array = []
      for (let n = 0; n < this.groupList.length; n++) {
        for (let m = 0; m <= preGroup.length; m++) {
          if (preGroup != null && preGroup != undefined) {
            let groups = []
            groups.push(preGroup[m].equivalencyGroups)
            let grp;
            grp = preGroup[m].group

            for (let j = 0; j < groups.length; j++) {
              this.EquivalentForm.controls[grp.toLowerCase()].setValue(groups[j]);
            }
          }
        }
      }
  }

  selectvalues() {

  }
  click(selected: boolean, sel_value, groupList, groupName, n, event) {

    let selectedGroupName: any;
    let d1 = n + 1;
    let d = "group" + d1;
    selectedGroupName = this.EquivalentForm.get(d).value
    // 
    if (selected == false) {
      for (var i = 0; i < this.groupList.length; i++) {
        // 
        let value = i + 1;
        let id = "group" + value;
        let sel_data = sel_value;
        let sel_groupname = groupName;
        let gname=this.groupList[i].groupName
        let data = this.EquivalentForm.get(id).value;//selected value
        if (gname == sel_data) {
          let temp_data: any = data;
          if (data.length > 0) {
            for (var k = 0; k < data.length; k++) {

              if (sel_groupname == data[k]) {
                temp_data.splice(k, 1);
                if (temp_data.length != 0) {
                  let values = []
                  this.EquivalentForm.controls[id].setValue(values)
                  this.EquivalentForm.controls[id].patchValue(temp_data);

                } else {
                  let values = []
                  this.EquivalentForm.controls[id].setValue(values)
                }
              }
            }
          } else {
            let values = []
            this.EquivalentForm.controls[id].setValue(values)
          }
        }




      }
    } else {
      for (let item of groupList) {
        for (let i = 0; i <= groupList.length; i++) {
          let array = []
          array.push(groupName)
           let statusName = item.statusName
           let Name=item.FormName;
          if (selectedGroupName.indexOf(statusName) != -1) {
            let grpname = Name.toLowerCase()
            let data: any = this.EquivalentForm.get(grpname).value
            if (data['value'] == undefined) {
              data = data
              data.push(array[0])
              let data1 = data.filter((item, i, ar) => ar.indexOf(item) === i)

              this.EquivalentForm.controls[Name.toLowerCase()].setValue(data1.map(str => str.replace(/\s/g, ' ')))
            } else {

              this.EquivalentForm.controls[Name.toLowerCase()].setValue(array)
            }

          } else {
          }
        }

      }
    }
  }

  selectGroup(groupList, groupName, selectedGroupName: string[], event) {

  }
  save() {
    var groupArr = {};

    var groupArr1 = new Array();
    var groupArrForReq = new Array();
    let rightArr = [];
    for (let i = 1; i <= this.operatorCounter.length; i++) {
      var groupObj = new Group();
      groupObj.group = "Group" + i;
      groupObj.groupname = this.operatorCounter[i-1].groupName;
      if(this.EquivalentForm.get("group" + i).value.length>0){
      groupObj.equivalencyGroups = this.EquivalentForm.get("group" + i).value;
      }
      else{
        groupObj.equivalencyGroups = []
      }
      groupArr1.push(groupObj);

    }

    let updatedgroupObj={
      "groupList":groupArr1
    }
    groupArr=updatedgroupObj
    var storedreqs = localStorage.setItem("group_list", JSON.stringify(groupArr))
    var storedreqs = localStorage.setItem("groupArrForReq", JSON.stringify(groupArr))
    localStorage.setItem("submitclick", "true")

    this.dialog.close('success');

  }

  close() {
    this.dialog.close();
    var groupArr = {};
    let updatedgroupObj={
      "groupList": this.equivalentapiresponse.groupList
    }
    groupArr=updatedgroupObj
    var storedreqs = localStorage.setItem("group_list", JSON.stringify(groupArr))
    var storedreqs = localStorage.setItem("groupArrForReq", JSON.stringify(groupArr))
    
    let element = document.getElementById("task-content")
    element.classList.remove('blur-content');
  }

}

class Group {
  equivalencyGroups: any;
  group: any;
  groupname:any;
}