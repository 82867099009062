<div class="row headerrow">
  <div class="col-12 cleariconcol">
    <mat-icon
      id="closeButton"
      class="clearicon"
      alt="clear"
      (click)="cancelbtn()"
      style="cursor: pointer"
      >clear</mat-icon
    >
  </div>

  <iframe [src]="tempdata" width="100%" height="70%" scrolling="no"></iframe>
</div>
