import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { VieworeditcurriculamComponent } from 'src/app/Administrator/assignable-content/vieworeditcurriculam/vieworeditcurriculam.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
@Component({
  selector: 'app-enrollmentlistfilter',
  templateUrl: './enrollmentlistfilter.component.html',
  styleUrls: ['./enrollmentlistfilter.component.scss']
})
export class EnrollmentlistfilterComponent implements OnInit {
  filterform: FormGroup;
  disablebutton: any;
  loadspinner: any = false;
  grouplist: any = [];
  roleslist: any = [];
  companylistdata: any = [];
  groupapibodycontent: any;
  allSelected = false;
  statuslist: any;
  @ViewChild('select') select: MatSelect;
  courseId: any;
  groupslist: any;
  managers: any;
  isDisabled: boolean;
  backupcompanylistdata:any;
  constructor(private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<VieworeditcurriculamComponent>) {
    this.courseId = localStorage.getItem("courseId");
    this.filterform = this.formBuilder.group({
      CompanyID: [''],
      Group: [''],
      Name: [''],
      Login: [''],
      Role: [''],
      Manager: [''],
      EmailID: ['', [Validators.pattern('(?!.{51})[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-zA-Z]{2,3}')]],
      Status: ['']
    });
    this.apicallgetdefaultdropdown();
  }


  ngOnInit(): void {
  }
  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    this.apiService.Simpleget(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.companylistdata = response["companies"];
        this.backupcompanylistdata=this.companylistdata;
        this.roleslist = response["roles"];
        this.statuslist = [
          { "status_id": 1, "statusName": "Not Started" },
          { "status_id": 2, "statusName": "Completed" },
          { "status_id": 3, "statusName": "In Progress" },
          { "status_id": 4, "statusName": "Failed" },
        ]
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
          

        });
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('groupfilterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton = true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton = false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['CompanyID'].setValue(data["Company (COID)"]);
      this.selectCompanyID(data["Company (COID)"]);
      this.filterform.controls['Group'].setValue(data["Group"]);
      this.filterform.controls['Name'].setValue(data["Name"]);
      this.filterform.controls['Manager'].setValue(data["Manager"]);
      this.filterform.controls['Role'].setValue(data["Role"]);
      this.filterform.controls['Login'].setValue(data["Login"]);
      this.filterform.controls['EmailID'].setValue(data["EmailID"]);
      this.filterform.controls['Status'].setValue(data["Status"]);

      // check if all value selected by default- set selectall true
      let newStatus = false;
      for (let i = 0; i < data["Role"].length; i++) {
        if (data["Role"].length === 7) {
          newStatus = true;
        }
      }
      this.allSelected = newStatus;
      this.clearfilterfunction();
    }
    let compId = localStorage.getItem('procCompId');
    let compName = localStorage.getItem('proccompanyName');
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      let data = compName + ' ' + '(' + compId + ')'
      this.filterform.controls['CompanyID'].setValue(data);
      this.selectCompanyID(data);
      this.disablebutton = false;
      this.isDisabled = true;
    }
    else {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      let globalcompanynameselected = localStorage.getItem('globalcompanynameselected');
      if (globalcompanyIDselected == '' || globalcompanyIDselected == undefined || globalcompanyIDselected == null) {
        this.isDisabled = false;
      }
      else {
        let data = globalcompanynameselected + ' ' + '(' + globalcompanyIDselected + ')'
        this.filterform.controls['CompanyID'].setValue(data);
        this.selectCompanyID(data);
        this.isDisabled = true;
        this.disablebutton = false;
      }
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if (clearfilteritemkey[i] === "Company (COID)") {
          this.filterform.controls['CompanyID'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Group") {
          this.filterform.controls['Group'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Name") {
          this.filterform.controls['Name'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Role") {
          this.filterform.controls['Role'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Manager") {
          this.filterform.controls['Manager'].setValue("");
        }
        else if (clearfilteritemkey[i] === "EmailID") {
          this.filterform.controls['EmailID'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Status") {
          this.filterform.controls['Status'].setValue("");
        }
        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  inputonchangeIDO(value) {
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    const queryparameter1 = value;
    this.apiService.getwithparam(queryparameter1, endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.groupslist = response["groups"];
      },
        (err) => {
          this.loadspinner = false;
        });
  }

  // on keyup enable filter button
  inputchangeID0(value) {
    this.dynamicEnableDisablebtn();
  }

  inputchangeID1(value) {
    this.dynamicEnableDisablebtn()
  }

  inputchangeID2(value) {
    this.dynamicEnableDisablebtn()
  }
  inputchangeID3(value) {
    this.dynamicEnableDisablebtn()
  }

  selectCompanyID(value) {
     // update company list to default
     let divID= document.getElementById("inputpagenocompany");
     if(divID !=null && divID !=undefined){
     (document.getElementById("inputpagenocompany") as HTMLInputElement).value = "";
     }
     this.companylistdata = this.backupcompanylistdata;

    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['CompanyID'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      this.disablebutton = false;
      let companylistdataid = "";
      for (let i = 0; i < this.companylistdata.length; i++) {
        if (this.companylistdata[i].companyName + ' ' + '(' + this.companylistdata[i].companyId + ')' === this.filterform.value.CompanyID) {
          companylistdataid = this.companylistdata[i].companyId;
        }
      }
      this.inputonchangeIDO(companylistdataid);
    }
  }

  selectGroupName(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['Group'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      this.disablebutton = false;
    }
  }


  selectStatusName(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['Status'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      this.disablebutton = false;
    }
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('group_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('groupfilterdata');
      localStorage.removeItem('group_chipdisplayfilterdata');
      localStorage.removeItem('group_clearfilteritemkey');
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      this.dialog.close({ data: groupfilterdata, apibodycontent: this.groupapibodycontent, method: 'filter' });
    }
  }
  EnterSubmit(event) {
    if (event.keyCode === 13) {
      this.dynamicEnableDisablebtn();
      if (this.disablebutton === true) {
        event.preventDefault();
      }
      else {
        event.preventDefault();
        this.Filter();
      }

    }
  }

  getbodycontent() {
    let CompanyID = "";
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      CompanyID = "";
    }
    else {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if (globalcompanyIDselected == '' || globalcompanyIDselected == undefined || globalcompanyIDselected == null) {
        CompanyID = this.filterform.value.CompanyID
      }
      else {
        CompanyID = "";
      }
    }

    let groupfilterdata = {
      "Company (COID)": CompanyID,
      "Group": this.filterform.value.Group.trim(),
      "Name": this.filterform.value.Name.trim(),
      "Login": this.filterform.value.Login.trim(),
      "Role": this.filterform.value.Role,
      "Manager": this.filterform.value.Manager.trim(),
      "EmailID": this.filterform.value.EmailID.trim(),
      "Status": this.filterform.value.Status.trim(),
    }
    this.getapibodycontent();
    return groupfilterdata

  }

  getapibodycontent() {
    let companylistdataid = "";
    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i].companyName + ' ' + '(' + this.companylistdata[i].companyId + ')' === this.filterform.value.CompanyID) {
        companylistdataid = this.companylistdata[i].companyId;
      }
    }
    this.groupapibodycontent = {
      "companyId": companylistdataid,
      "groupName": this.filterform.value.Group.trim(),
      "displayName": this.filterform.value.Name.trim(),
      "loginName": this.filterform.value.Login.trim(),
      "roleName": this.filterform.value.Role.toString().trim(),
      "managerName": this.filterform.value.Manager.trim(),
      "email": this.filterform.value.EmailID.trim(),
      "courseStatus": this.filterform.value.Status.trim(),
      "courseId": this.courseId
    }
  }

  checkformvalue() {
    let formempty;
    if (this.filterform.value.CompanyID.trim() === "" && this.filterform.value.Group.trim() === "" &&
      this.filterform.value.Name.trim() === "" && this.filterform.value.Login.trim() === "" &&
      this.filterform.value.Role.toString().trim() === "" && this.filterform.value.Manager.trim() === "" &&
      this.filterform.value.EmailID.trim() === "" && this.filterform.value.Status.trim() === "") {
      formempty = true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }

  onKeyCompany(value) {
    if(value.trim().length===0){
      this.companylistdata=this.backupcompanylistdata;
    }
    else{
      const filterValue = value.toLowerCase();
      let data = this.backupcompanylistdata.filter(option => option.companyName.toLowerCase().includes(filterValue) ||
      option.companyId.toLowerCase().includes(filterValue));
      this.companylistdata = data;
    }
  }

  onKeyDownCompany(event) {
    // to disable mat select default search functionality
    event.stopImmediatePropagation();
  }
}
