<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>

<div class="filtermaincontainer" (keydown)="EnterSubmit($event)">
  <div class="row headerrow">
    <div class="col-10"  style="padding-bottom: 12px;">
      <img src="assets/images/filter-filled-tool-symbol.svg" alt="filter">
      <span class="heading">&nbsp;&nbsp;Filter</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()">clear</mat-icon>
    </div>
  </div>
  <hr style="width: 558px; margin-left: -33px; margin-top: 5px;">
  <form [formGroup]="filterform">
    <div class="row">
      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Login</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpLogin (keyup)="inputchangeLogin(inpLogin.value)" class="inputClassText"
            formControlName="Login">
        </mat-form-field>

      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Name</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpName (keyup)="inputchangeName(inpName.value)" class="inputClassText"
            formControlName="Name">
        </mat-form-field>

      </div>


      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">EMP ID</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpEMPID (keyup)="inputchangeEmpID(inpEMPID.value)" class="inputClassText"
            formControlName="EmpID">
        </mat-form-field>

      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Company (COID)</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="CompanyId" placeholder="Company (COID)" panelClass="myPanelmatselectClassCOID" id="companydropdownID" class="inputClass"
            (selectionChange)=selectCompanyID($event.value) disableOptionCentering [disabled]="isDisabled">
            <input placeholder="Search" id="inputpagenocompany" class="input_matselect" (keyup)="onKeyCompany($event.target.value)"
             (keydown)="onKeyDownCompany($event)">
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of companylistdata" [value]="item.companyName + ' ' +'('+ item.companyId + ')'">
              <div class="matoptiontxt">{{item.companyName}}&nbsp;({{item.companyId}})</div>
            </mat-option>
          </mat-select>
        </mat-form-field>


      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Job Position</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="JobPosition" placeholder="Job Position" panelClass="myPanelmatselectClass" class="inputClass"
          (selectionChange)=selectJobPositionName($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of JobPosition" [value]="item.jobPositionTitle">
              <div class="matoptiontxt">{{item.jobPositionTitle}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>


      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Email</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #inpEmail (keyup)="inputchangeEmail(inpEmail.value)" class="inputClassText"
            formControlName="Email">
            
        </mat-form-field>

      </div>

  </div>



  <div class="row">
    <div class="col-6 cancelbtncol"><br><br>
      <button mat-button id="btncancelid" class="cancelbtn" (click)="cancelbtn()">
        <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
        Cancel
      </button>
    </div>
    <div class="col-6 Filterbtncol"><br><br>
      <button mat-button id="btnfiltertermid" class="Filterbtn" [disabled]="disablebutton" (click)="Filter()">
        <mat-icon class="filterbtnicon">done</mat-icon>
        Filter
      </button>
    </div>
  </div>

</form>


  </div>
