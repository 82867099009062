import { Component, Inject, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { DialogpopupComponent } from '../../dialog-popup/dialog-popup.component';

@Component({
  selector: 'app-addremoveevaluators',
  templateUrl: './addremoveevaluators.component.html',
  styleUrls: ['./addremoveevaluators.component.scss']
})
export class AddremoveevaluatorsComponent implements OnInit {

  sel_data: any = [];
  backup_data: any = [];
  user_data: any = [];
  selected_users = [];
  backup_users: any = [];
  rem_data: any = [];
  loadspinner: boolean;
  AlluserList: any = [];
  companyId: any;
  Login: any;
  username: any;
  loginId: string;
  sel_user_array = [];
  temp_group_data: any = [];
  filter_companyid: any = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogopen: MatDialog, public dialog: MatDialogRef<UserComponent>, private formBuilder: FormBuilder, public _Service: ApiServiceService,) {
    this.username = data['Name'];
    this.companyId = data['companyId'];
    this.Login = data['Login'];

    this.loginId = localStorage.getItem('loginId');
    if (data["UsersList"] != "" && data["UsersList"] != undefined && data["UsersList"] != null) {
      for (var i = 0; i < data["UsersList"].length; i++) {
        data["UsersList"][i].isSelected = false;
      }
    }
    let endpoint1 = "users";
    let endpoint2 = "existingEvaluatorusers/" + this.Login;
    let cacheddata = localStorage.getItem('role');
    let body;
    if (cacheddata === "System Administrator") {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if (globalcompanyIDselected == '' || globalcompanyIDselected == undefined || globalcompanyIDselected == null) {
        body = {
          companyId: ""
        }
      }
      else{
        body = {
          companyId: globalcompanyIDselected
        }
      }
    }
    else{
      body = {
        companyId: this.companyId
      }
    }
    this._Service.manager_filterpost(endpoint1, endpoint2, body, 'POST')
      .subscribe((response) => {
        this.selected_users = response["existingUsers"];
        this.backup_users = this.selected_users;

      },
        (err) => {
          this.loadspinner = false;
        });

  }

  ngOnInit() {
    this.listUserApi();
  }
  close() {

    this.dialog.close();
    let element = document.getElementById("user-content")
    element.classList.remove('blur-content');

  }

  listUserApi() {
    // create bodu content for api call
    this.loadspinner = true;
    const endpoint1 = 'users';
    const endpoint2 = 'searchusersfilter';
    let cacheddata = localStorage.getItem('role');
    let body;
    if (cacheddata === "System Administrator") {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if (globalcompanyIDselected == '' || globalcompanyIDselected == undefined || globalcompanyIDselected == null) {
        body = {
          "filters": {
            "firstName": "",
            "lastName": "",
            "loginName": "",
            "displayName": "",
            "email": "",
            "companyId": this.companyId,
            "empCode": "",
            "jobPositionName": "",
            "groupName": "",
            "roleName": "",
            "managerName": "",
            "profileUrl": "",
            "statusName": ""
          }
        }
      }
      else {
        body = {
          "filters": {
            "firstName": "",
            "lastName": "",
            "loginName": "",
            "displayName": "",
            "email": "",
            "companyId": globalcompanyIDselected,
            "empCode": "",
            "jobPositionName": "",
            "groupName": "",
            "roleName": "",
            "managerName": "",
            "profileUrl": "",
            "statusName": ""
          }
        }
      }
    }
    else {
      if (cacheddata === "Manager") {
        let loginId = localStorage.getItem('loginId');
        body = {
          "filters": {
            "firstName": "",
            "lastName": "",
            "loginName": "",
            "displayName": "",
            "email": "",
            "companyId": this.companyId,
            "empCode": "",
            "jobPositionName": "",
            "groupName": "",
            "roleName": "",
            "managerName": loginId,
            "profileUrl": "",
            "statusName": ""
          }
        }
      }
      else {
        body = {
          "filters": {
            "firstName": "",
            "lastName": "",
            "loginName": "",
            "displayName": "",
            "email": "",
            "companyId": this.companyId,
            "empCode": "",
            "jobPositionName": "",
            "groupName": "",
            "roleName": "",
            "managerName": "",
            "profileUrl": "",
            "statusName": ""
          }
        }
      }
    }

    this._Service.evaluator_filterpost(endpoint1, endpoint2, body, 'POST')
      .subscribe((response) => {
        let temp_data = response['userListFilter'];
        for (var i = 0; i < temp_data.length; i++) {
          temp_data[i].isSelected = false;
        }
        for (var i = 0; i < temp_data.length; i++) {
          for (var j = 0; j < this.selected_users.length; j++) {
            if (temp_data[i].loginName == this.selected_users[j].loginName) {
              temp_data[i].isSelected = true;
            }
          }
        }
        temp_data = temp_data.filter(x => x.isSelected != true);

        this.user_data = temp_data;
        var setValue = this.user_data;

        const dataRemoved = setValue.filter((el) => {
          return el.loginName !== this.Login;
        });
        this.user_data = dataRemoved;
        this.loadspinner = false;
        this.backup_data = this.user_data;

        this.loadspinner = false;
        const Divname: any = document.getElementById('sid1');
        setTimeout(() => {
          Divname.click();
        }, 1000)
      },
        (err) => {
          this.loadspinner = false;
        });

  }

  create_user() {

    this.loadspinner = true;
    let endpoint = 'users/' + 'addusersevaluator/' + this.Login;
    let user_list: any = [];

    if (this.backup_users.length > 0) {
      for (var i = 0; i < this.backup_users.length; i++) {
        user_list.push(this.backup_users[i].loginName)
      }
    }

    let body = {

      "usersList": user_list

    }
    this._Service.add_remove_evaluator(endpoint, body, 'PATCH')
      .subscribe((response) => {
        this.loadspinner = false;
        if (response["message"] === 'success') {
          this.dialogopen.open(DialogpopupComponent, {
            width: '550px',
            disableClose: true,
            data:
            {
              from: "popup-success",
              successmsg: "Hurray!!",
              successmsgbody: 'Evaluator users list updated successfully ',
              imagelogo: "assets/images/thumbsup.svg"
            },
            position: {
              top: '25vh'
            }
          });
          this.dialog.close('success');
          let element = document.getElementById("user-content")
          element.classList.remove('blur-content');
        }
        else {
          this.loadspinner = false;

          let data = {
            status: response["message"]
          };
          
        }
      },
        (err) => {
          this.loadspinner = false;
          

        });


  }

  search_left(ev) {

    this.user_data = this.backup_data;
    let filterValue = ev;
    this.user_data = this.user_data.filter(option => (option.displayName.toLowerCase().includes(filterValue.toLowerCase()) || option.loginName.toLowerCase().includes(filterValue.toLowerCase())));
  }

  search_right(ev) {

    this.selected_users = this.backup_users;
    let filterValue = ev;
    this.selected_users = this.selected_users.filter(option => (option.displayName.toLowerCase().includes(filterValue.toLowerCase()) || option.loginName.toLowerCase().includes(filterValue.toLowerCase())));
  }

  itemSelected_leftbox(ev) {
    let present = false;
    for (var k = 0; k < this.sel_data.length; k++) {
      if (ev.loginName == this.sel_data[k].loginName) {
        present = true;
        this.sel_data.splice(k, 1);
      }
    }
    if (!present) {
      this.sel_data.push(ev)
    }

    for (var l = 0; l < this.backup_data.length; l++) {
      if (!present) {
        if (ev.loginName == this.backup_data[l].loginName) {
          this.backup_data[l].isSelected = true;
        }
      } else {
        if (ev.loginName == this.backup_data[l].loginName) {
          this.backup_data[l].isSelected = false;
        }
      }
    }
    for (var l = 0; l < this.user_data.length; l++) {
      if (!present) {
        if (ev.loginName == this.user_data[l].loginName) {
          this.user_data[l].isSelected = true;
        }
      } else {
        if (ev.loginName == this.user_data[l].loginName) {
          this.user_data[l].isSelected = false;
        }
      }
    }

  }
  itemSelected_rightbox(ev) {

    let present = false;
    for (var k = 0; k < this.rem_data.length; k++) {
      if (ev.loginName == this.rem_data[k].loginName) {
        present = true;
        this.rem_data.splice(k, 1);
      }
    }
    if (!present) {
      this.rem_data.push(ev)
    }
    for (var l = 0; l < this.backup_users.length; l++) {
      if (!present) {
        if (ev.loginName == this.backup_users[l].loginName) {
          this.backup_users[l].isSelected = true;
        }
      } else {
        if (ev.loginName == this.backup_users[l].loginName) {
          this.backup_users[l].isSelected = false;
        }
      }
    }
    for (var l = 0; l < this.selected_users.length; l++) {
      if (!present) {
        if (ev.loginName == this.selected_users[l].loginName) {
          this.selected_users[l].isSelected = true;
        }
      } else {
        if (ev.loginName == this.selected_users[l].loginName) {
          this.selected_users[l].isSelected = false;
        }
      }
    }
  }

  add_users() {

    if (this.sel_data.length > 0) {

      for (var j = 0; j < this.backup_data.length; j++) {
        if (this.backup_data[j].isSelected) {
          this.selected_users.push(this.backup_data[j])
        }
      }


      this.user_data = this.backup_data;
      this.user_data = this.user_data.filter(x => x.isSelected != true);
      this.sel_data = [];
      for (var m = 0; m < this.backup_data.length; m++) {
        for (var n = 0; n < this.selected_users.length; n++) {
          if (this.selected_users[n].loginName == this.backup_data[m].loginName) {
            this.backup_data[m].isSelected = true;
          }
        }
      }

      this.backup_data = this.backup_data.filter(x => x.isSelected != true);
      this.user_data = this.backup_data;
      for (var j = 0; j < this.selected_users.length; j++) {
        this.selected_users[j].isSelected = false;
      }
      this.backup_users = this.selected_users;
      //setting the serach value
      const name: any = document.getElementById('sid1');
      name.value = "";
      const Divname: any = document.getElementById('sid2');
      setTimeout(() => {
        Divname.click();
        this.selected_users = [...this.selected_users];
      }, 1000)
    }
  }

  remove_user() {

    if (this.rem_data.length > 0) {

      for (var j = 0; j < this.backup_users.length; j++) {
        if (this.backup_users[j].isSelected) {
          this.user_data.push(this.backup_users[j])
        }
      }

      this.selected_users = this.backup_users;
      this.selected_users = this.selected_users.filter(x => x.isSelected != true);
      this.rem_data = [];
      for (var m = 0; m < this.backup_users.length; m++) {
        for (var n = 0; n < this.user_data.length; n++) {
          if (this.user_data[n].loginName == this.backup_users[m].loginName) {
            this.backup_users[m].isSelected = true;
          }
        }
      }
    }


    this.backup_users = this.backup_users.filter(x => x.isSelected != true);
    this.selected_users = this.backup_users;
    for (var j = 0; j < this.user_data.length; j++) {
      this.user_data[j].isSelected = false;
    }
    this.backup_data = this.user_data;
    const name: any = document.getElementById('sid2');
    name.value = "";
    const Divname: any = document.getElementById('sid1');
    setTimeout(() => {
      Divname.click();
      this.user_data = [...this.user_data]
    }, 1000)
  }
}
