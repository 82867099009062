<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>
<div class="Processing" *ngIf="processingtxt===true"> Processing... </div>


<div class="row headr" id="addremovetasknameid">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 left-sec">
    <span class="titles">Add/Remove Users</span>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="close()"
      style="cursor: pointer"
    >
      <mat-icon class="clear-btn">clear</mat-icon>
    </button>
  </div>
</div>
<hr style="margin-left: -20px; width: 104%" />

<div class="row"></div>
<div class="row user-box">
  <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12">
    <div class="user-box-block">
      <div class="list-header">User List</div>
      <div class="list-search">
        <mat-form-field appearance="outline" class="matformfieldheader">
          <input type="text" #searchinput1 id="sid1" matInput (keyup)="search_left(searchinput1.value)"
            class="textsearchinput" placeholder="Search by login" autocomplete="off">
          <mat-icon matPrefix class="searchbtn">search</mat-icon>
        </mat-form-field>
      </div>
      <div class="list-scroll">
        <cdk-virtual-scroll-viewport itemSize="50" style="height: 300px">
          <div *cdkVirtualFor="let data of user_data" class="list-data">
            <div class="row">
              <div class="col-1" style="text-align: center; margin-left: 10px">
                <mat-checkbox
                  class="checkboxbody"
                  [(ngModel)]="data.isSelected"
                  (change)="itemSelected_leftbox(data, $event.checked)"
                >
                </mat-checkbox>
              </div>
              <div class="col-10">
                {{ data.displayName }} ({{ data.loginName }})
              </div>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>

    <div style="text-align: right">
      <mat-icon
        class="paginationiconleft"
        id="paginationarrowleftid_taskEnrollment"
        (click)="onPaginateChange('arrowleft')"
        >chevron_left</mat-icon
      >

      <mat-icon
        class="paginationiconright"
        id="paginationarrowrightid_taskEnrollment"
        (click)="onPaginateChange('arrowright')"
        >chevron_right</mat-icon
      >
    </div>
  </div>
  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 btn-center">
    <div class="inner-container">
      <div class="row sub-row">
        <button type="button" mat-button class="addbtn" (click)="add_users()">
          Add
          <br />
          <mat-icon class="Cancelicon" style="color: white" alt="clear"
            >east</mat-icon
          >
        </button>
      </div>
      <div class="row sub-row" style="margin-top: 20px">
        <button
          type="button"
          mat-button
          class="removebtn"
          (click)="remove_user()"
        >
          Remove <br />
          <mat-icon class="Cancelicon" alt="clear">west</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12">
    <div class="user-box-block">
      <div class="list-header">User Added</div>
      <div class="list-search">
        <mat-form-field appearance="outline" class="matformfieldheader">
          <input
            type="text"
            #searchinput2
            id="sid2"
            matInput
            (keyup)="search_right(searchinput2.value)"
            class="textsearchinput"
            placeholder="Search..."
            autocomplete="off"
          />
          <mat-icon matSuffix class="searchbtn">search</mat-icon>
        </mat-form-field>
      </div>
      <div class="list-scroll">
        <cdk-virtual-scroll-viewport itemSize="50" style="height: 300px">
          <div
            *cdkVirtualFor="let data of selected_users"
            class="list-data checkbox"
          >
            <div class="row">
              <div class="col-1" style="text-align: center; margin-left: 10px">
                <mat-checkbox
                  class="checkboxbody checkbox-right"
                  [(ngModel)]="data.isSelected"
                  (change)="itemSelected_rightbox(data, $event.checked)"
                >
                </mat-checkbox>
              </div>

              <div class="col-10">
                {{ data.displayName }} ({{ data.loginName }})
              </div>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>

    <div style="text-align: right">
      <mat-icon
        class="paginationiconleft"
        id="paginationarrowleftid_taskEnrollmentRightbox"
        (click)="onPaginateChangeRightSide('arrowleft')"
        >chevron_left</mat-icon
      >

      <mat-icon
        class="paginationiconright"
        id="paginationarrowrightid_taskEnrollmentRightbox"
        (click)="onPaginateChangeRightSide('arrowright')"
        >chevron_right</mat-icon
      >
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 cancel-btn-col">
    <br /><br /><br />
    <button mat-button class="cancel-btn" (click)="close()">
      <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
      Cancel
    </button>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol">
    <br /><br /><br />
    <button mat-button class="Filterbtn" (click)="save()">
      <mat-icon class="filterbtnicon">done</mat-icon>
      Save
    </button>
  </div>
</div>
