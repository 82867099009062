import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
@Component({
  selector: 'app-transcriptlist-filter',
  templateUrl: './transcriptlist-filter.component.html',
  styleUrls: ['./transcriptlist-filter.component.scss'],
  providers: [DatePipe]
})
export class TranscriptlistFilterComponent implements OnInit {
   filterform: FormGroup;
  statuslist: any = [""];
  loadspinner: any = false;
  apibodycontent: any;
  disablebutton:any;
  enddateconsistnull:any;
  today = new Date();
  category: any = [""];
  type: any = [""];
  sharedRec: any = [""];
  constructor(private datePipe: DatePipe, private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>) {
    this.filterform = this.formBuilder.group({
      title: [''],
      start: [''],
      end: [''],
      courseId:[""]

    });
   this.apicallgetdefaultdropdown();
  }
  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
        this.statuslist = [
          { "status_id": 1, "statusName": "Not Started" },
          { "status_id": 4, "statusName": "In Progress" },
          { "status_id": 2, "statusName": "Completed" },
          { "status_id": 3, "statusName": "Failed" },
          { "status_id": 5, "statusName": "Passed" },
          { "status_id": 6, "statusName": "Expired" },
          { "status_id": 7, "statusName": "Qualified" },
          { "status_id": 8, "statusName": "Not Qualified" },
        ]
        this.category = [
          { "categoryName": "eCourse" },
          { "categoryName": "Evaluation" },
          { "categoryName": "Exam" },

        ]
        this.type = [
          {"typeName": "TNG" },
          {"typeName": "KNT" },
          {"typeName": "PEF" },
          {"typeName": "Video" },
          {"typeName": "Other" },

        ]
        this.sharedRec = [
          { "status_id": 1, "statusName": "Yes" },
          { "status_id": 4, "statusName": "No" },
        ]
       
        this.setdefaultdropdownvalues();
  }

  ngOnInit(): void {
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('Manager_filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton = true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton = false;
      let data = JSON.parse(cacheddata);
      if (data["Requirement ID"] === null || data["Requirement ID"] === undefined || data["Requirement ID"] === "") {
        this.filterform.controls['courseId'].setValue("");
      } else {
        this.filterform.controls['courseId'].setValue(data["Requirement ID"]);
      }

      if (data["Requirement Name"] === null || data["Requirement Name"] === undefined || data["Requirement Name"] === "") {
        this.filterform.controls['title'].setValue("");
      } else {
        this.filterform.controls['title'].setValue(data["Requirement Name"]);
      }

      // for date range
      let a = data["Completion Date"].split('-')[0];
      let b = data["Completion Date"].split('-')[1];

      if (data["Completion Date"] === null || data["Completion Date"] === undefined || data["Completion Date"] === "") {
        this.filterform.controls['start'].setValue("");
        this.filterform.controls['end'].setValue("");
      } else {
        this.filterform.controls['start'].setValue(new Date(a));
        this.filterform.controls['end'].setValue(new Date(b));
      }
      this.clearfilterfunction();
    }
  }


  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('Manager_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if (clearfilteritemkey[i] === "Requirement Name") {
          this.filterform.controls['title'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Requirement ID") {
          this.filterform.controls['courseId'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Completion Date") {
          this.filterform.controls['start'].setValue("");
          this.filterform.controls['end'].setValue("");
        }
        
        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }


  selectStatusName(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['Status'].setValue("");
    }
    this.dynamicEnableDisablebtn();
  }

  selectCategory(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['Category'].setValue("");
    }
    this.dynamicEnableDisablebtn();
  }

  selectType(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['CompanyType'].setValue("");
    }
    this.dynamicEnableDisablebtn();
  }

  inputchangeID1(value) {
    this.dynamicEnableDisablebtn()

  }

  inputchangeID2(value) {
    this.dynamicEnableDisablebtn()

  }

  selectcheckbox(value)
  {
    this.dynamicEnableDisablebtn()
  }

  startChange(event) {
    

  }
  endChange(event) {
    if (event.value === null) {
      this.enddateconsistnull=true;
      this.disablebutton = true;
    }
    else {
      this.enddateconsistnull=false;
      this.dynamicEnableDisablebtn();

    }
    //
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('Manager_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let filterdata = this.getbodycontent();
      localStorage.setItem('Manager_filterdata', JSON.stringify(filterdata));
      localStorage.removeItem('Manager_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('Manager_filterdata');
      localStorage.removeItem('Manager_chipdisplayfilterdata');
      localStorage.removeItem('Manager_clearfilteritemkey');
      let filterdata = this.getbodycontent();
      localStorage.setItem('Manager_filterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
    }

  }

  EnterSubmit(event) {
    if (event.keyCode === 13) {
      this.dynamicEnableDisablebtn();
      if (this.disablebutton === true) {
        event.preventDefault();
      }
      else {
        event.preventDefault();
        this.Filter();
      }

    }
  }


  getbodycontent() {
    let daterange = "";
    let startdate = "";
    let enddate = "";
    let apidaterange = "";
    let apistartdate = "";
    let apienddate = "";
    if (this.filterform.value.start === "" || this.filterform.value.start === undefined || this.filterform.value.start === null) {
      daterange = "";
    }
    else {
      startdate = this.datePipe.transform(this.filterform.value.start, 'MM/dd/yyyy');
      enddate = this.datePipe.transform(this.filterform.value.end, 'MM/dd/yyyy');
      daterange = startdate + " - " + enddate;
      apistartdate = this.datePipe.transform(this.filterform.value.start, 'yyyy-MM-dd');
      apienddate = this.datePipe.transform(this.filterform.value.end, 'yyyy-MM-dd');
    }

    let filterdata = {
      "Requirement Name":this.filterform.value.title.trim(),
      "Completion Date": daterange,
      "Requirement ID":this.filterform.value.courseId.trim(),

    }
    this.getapibodycontent( apistartdate, apienddate);
    return filterdata

  }

  getapibodycontent(apistartdate, apienddate) {
  
    let loginName;
    if(localStorage.getItem("currentloginId") == null){
      loginName = localStorage.getItem('loginId');
    } else {
      loginName = localStorage.getItem("currentloginId");
    }

    this.apibodycontent = {
    "title":this.filterform.value.title.trim(),
    "startCompletionDate":apistartdate,
    "endCompletionDate":apienddate,
    "loginName": loginName,
    "courseId":this.filterform.value.courseId.trim(),
    }
  }

  checkformvalue() {
    let formempty;
    if ((this.filterform.value.title.trim() === "")  && (this.filterform.value.courseId.trim() === "") && (this.filterform.value.start === "" || this.filterform.value.start === null) && (this.filterform.value.end === "" || this.filterform.value.end === null)) {
      formempty = true;
    }
    else {
      formempty = false;
      if(this.enddateconsistnull===true){
        formempty = true;
      }
    }
    return formempty;
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }
  selectSharedRec(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['sharedRec'].setValue("");
    }
    this.dynamicEnableDisablebtn();
  }
}