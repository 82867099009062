<div class="spinner_overlay" *ngIf="loadspinner === true"></div>
<mat-spinner class="spinner" *ngIf="loadspinner === true"></mat-spinner>
<div class="tabbtnshadow2"></div>

<div (keydown)="EnterSubmit($event)">
  <div class="row headerrow">
    <div class="col-10">
      <span class="heading">&nbsp;&nbsp;Templates</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()"
        >clear</mat-icon
      >
    </div>
  </div>
 
  <form #form="ngForm" [formGroup]="templateForm" novalidate>
    <div class="row">
      <div class="col-sm-12 table-padding">
        <div class="">
          <div
            class="col-lg-5"
            style="
              margin-left: -6px;
              position: relative;
              margin-top: 8px;
              margin-bottom: 10px;
              overflow: hidden;
            "
          >
            <mat-form-field
              appearance="outline"
              class="matformfieldheader header-field"
            >
              <input
                type="text"
                formControlName="search"
                #searchinput
                matInput
                class="textsearchinput"
                (ngModelChange)="clearTable(searchinput.value)"
                placeholder="Search..."
                autocomplete="off"
              />
              <mat-icon
                matSuffix
                (click)="search(searchinput.value)"
                class="searchbtn"
                style="cursor: pointer"
                >search</mat-icon
              >
            </mat-form-field>
          </div>
        
          <div
            *ngIf="groupELEMENT_DATA.length === 0"
            class="col-12 col-md-2 col-lg-4 col-xl-4 tableheadcol"
          >
          <div style="font-size: 16px" *ngIf="groupELEMENT_DATA.length === 0">
              No record found
            </div>
            <br />
          </div>
          <div class="tableheight">
            <table
              mat-table
              [dataSource]="groupdataSource"
              #grouplisttable
              style="width: 100%"
              matSort
            >
              <!-- Name Column -->
              <ng-container matColumnDef="Template ID">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="headerdata"
                  style="border-radius: 6px 0px 0px 6px"
                >
                  <div class="status">
                    <span class="spanstatus">TEMPLATE ID</span>
                    &nbsp;
                    <mat-icon
                      class="arrowupward"
                      alt="arrowupward"
                      (click)="
                        Grouplistarrow_upward('string', 'formId', group_ids[0])
                      "
                      id="{{ group_ids[0] }}"
                      >arrow_upward</mat-icon
                    >
                    <mat-icon
                      class="downupward"
                      alt="arrowdownward"
                      (click)="
                        Grouplistarrow_downward(
                          'string',
                          'formId',
                          group_ids[1]
                        )
                      "
                      id="{{ group_ids[1] }}"
                      >arrow_downward</mat-icon
                    >
                  </div>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="group_tablecontent">{{ element.formId }}</div>
                </td>
              </ng-container>

              <!-- Position Column -->
              <ng-container matColumnDef="Template Name">
                <th mat-header-cell *matHeaderCellDef class="headerdata">
                  <div class="statusbigcontainer">
                    <span class="spanstatusbigwidth">TEMPLATE NAME</span>
                    &nbsp;
                    <mat-icon
                      class="arrowupward"
                      alt="arrowupward"
                      (click)="
                        Grouplistarrow_upward('string', 'title', group_ids[2])
                      "
                      id="{{ group_ids[2] }}"
                      >arrow_upward</mat-icon
                    >
                    <mat-icon
                      class="downupward"
                      alt="arrowdownward"
                      (click)="
                        Grouplistarrow_downward('string', 'title', group_ids[3])
                      "
                      id="{{ group_ids[3] }}"
                      >arrow_downward</mat-icon
                    >
                  </div>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="groupname_tablecontent">{{ element.title }}</div>
                </td>
              </ng-container>

              <ng-container matColumnDef="ACTIONS">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="headerdata"
                  style="border-radius: 0px 6px 6px 0px"
                >
                  <div class="group_status">
                    <span class="">ACTIONS</span>
                  </div>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="group_tablecontent">
                    <button
                      mat-button
                      class="playbtn"
                      (click)="editFromDetails(element.formId, element.version)"
                    >
                      Use Template
                    </button>
                  </div>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsGrouplist; sticky: true"
                style="background-color: #143249"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsGrouplist"
              ></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- </mat-card> -->

  <!-- sticky paginator  -->
  <div
    class="paginatorfooterrow right-align"
    *ngIf="groupELEMENT_DATA.length > 0"
  >
    <div class="row">
      <div class="col-12 col-md-12 col-lg-12 col-xl-12 page-right">
        <span class="paginatorgotocol">
           <form>
            <span class="label">Page:&nbsp;</span>
            <mat-form-field class="paginatorselect">
              <input
                type="number"
                #input
                aria-label="Number"
                matInput
                [formControl]="pagecountform"
                [matAutocomplete]="auto"
                (change)="
                  grouplistupdateManualPageinput(input.value, totalpages)
                "
                style="width: 60%"
                id="pagination_viewgroupuserstable"
              />
              <mat-icon matSuffix class="matautocompleteicon"
                >arrow_drop_down</mat-icon
              >
              <mat-autocomplete
                #auto="matAutocomplete"
                activeOption="activeOption"
                (optionSelected)="
                  grouplistupdateManualPage($event.option.value)
                "
              >
                <mat-option
                  value="{{ i + 1 }}"
                  *ngFor="let item of [].constructor(totalpages); let i = index"
                >
                  {{ i + 1 }}</mat-option
                >
              </mat-autocomplete>
            </mat-form-field>
          </form>
        </span>

        <span class="paginatorrowperpagecol">
          <span class="label">&nbsp;&nbsp;Rows Per Page:&nbsp;</span>
          <mat-form-field class="paginatorselect">
            <mat-select
              [(ngModel)]="Rowperpage"
              (selectionChange)="grouplistupdateRowManualPage(Rowperpage)"
            >
              <mat-option
                *ngFor="let item of pageSizeOptions"
                [value]="item.value"
              >
                {{ item.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <span class="paginationtxt"
            >&nbsp; {{ indexfirst }} - {{ indexlast }} of {{ totalitemlength }}
          </span>

          <mat-icon
            class="paginationiconleft"
            id="paginationarrowleftid12"
            (click)="grouplistonPaginateChange('arrowleft')"
            >chevron_left</mat-icon
          >&nbsp;

          <mat-icon
            class="paginationiconright"
            id="paginationarrowrightidss123"
            (click)="grouplistonPaginateChange('arrowright')"
            >chevron_right</mat-icon
          >
        </span>
      </div>
    </div>
  </div>
</div>
