<div class="spinner_overlay" *ngIf="loadspinner === true"></div>
<mat-spinner class="spinner" *ngIf="loadspinner === true"></mat-spinner>

<br />
<div class="">
  <div class="row">
    <div
      class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 left-sec"
      style="margin-top: -20px"
    >
      <span class="titles">Equivalency Groups</span>

      <div class="close">
        <mat-icon class="clearicon" alt="clear" (click)="close()"
          >clear</mat-icon
        >
      </div>
    </div>
  </div>
  <hr style="width: 908px; margin-left: -33px" />
  <div class="scrolldiv">
    <form #form="ngForm" [formGroup]="EquivalentForm" novalidate>
      <div class="row" *ngFor="let data of operatorCounter; let n = index">
        <div class="col-xl-3 col-xs-12">
          <p class="titlesG">{{ data.groupName }}</p>
        </div>

        <div class="col-1 col-xs-12">
          <img class="align" src="assets/images/group-13.png" />
        </div>

        <div class="col-6 col-xs-12">
          <mat-form-field appearance="outline" class="form-field">
            <mat-select
              formControlName="group{{ n + 1 }}"
              placeholder="Select Groups"
              multiple
              panelClass="myPanelmatselectClass"
              disableOptionCentering
            >
              <mat-option
                *ngFor="let item of data.statuslist"
                #matOption
                (click)="
                  click(
                    matOption.selected,
                    item.statusName,
                    data.statuslist,
                    data.groupName,
                    n,
                    $event
                  )
                "
                [value]="item.statusName"
                class="create_select"
              >
                {{ item.statusName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div class="row outerdiv">
    <button mat-button class="Filterbtn" (click)="save()">
      <mat-icon class="filterbtnicon">done</mat-icon>
      Submit
    </button>
  </div>
</div>
