import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyManagementComponent } from 'src/app/Administrator/company-management/company-management.component';

@Component({
  selector: 'app-viewcomment',
  templateUrl: './viewcomment.component.html',
  styleUrls: ['./viewcomment.component.scss']
})
export class ViewcommentComponent implements OnInit {
  filterform: FormGroup;
  from:any;
  comment:any;
  reason:any;
  invoiceNumber:any;
  approverComments:any;
  status:any;

  constructor(public dialog: MatDialogRef<CompanyManagementComponent>, @Inject(MAT_DIALOG_DATA) public data: string,private formBuilder: FormBuilder,) {
    this.filterform = this.formBuilder.group({
      RequestorComments: [''],
      ReasonforDenial: [''],
      InvoiceNumber:[''],
      approvercomment:['']
    });
    this.from=data['from'];
    this.comment=data['comment'];
    this.filterform.controls['RequestorComments'].setValue(this.comment);
    if(this.from==="licenceApproval"){
      this.reason=data['reason'];
      this.status=data['status'];
      this.invoiceNumber=data['invoiceNumber'];
      this.approverComments=data['approverComments'];
      this.filterform.controls['ReasonforDenial'].setValue(this.reason);
      this.filterform.controls['InvoiceNumber'].setValue(this.invoiceNumber);
      this.filterform.controls['approvercomment'].setValue(this.approverComments);
    }
  }

  ngOnInit(): void {
  }

  cancelbtn(){
    this.dialog.close();
  }

}
