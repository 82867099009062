<div class="spinner_overlay" *ngIf="loadspinner === true"></div>
<mat-spinner class="spinner" *ngIf="loadspinner === true"></mat-spinner>
<div class="filtermaincontainer">
  <div class="row headerrow">
    <div class="col-10">
      <img src="assets/images/icon-comment.svg" />
      <span class="heading" *ngIf="this.from === 'approvelicense'"
        >&nbsp;&nbsp;Approve</span
      >
      <span class="heading" *ngIf="this.from === 'denylicense'"
        >&nbsp;&nbsp;Deny</span
      >
    </div>
    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()"
        >clear</mat-icon
      >
    </div>
  </div>

  <form [formGroup]="approveform" *ngIf="this.from === 'approvelicense'">
    <div class="row">
      <div class="col-12 col-md-12 inputcolumn">
        <mat-label class="labelname">Requestor Comment</mat-label>
        <mat-form-field
          appearance="outline"
          id="formfieldusernameid"
          class="formfieldusertable"
        >
          <textarea
            placeholder="Requestor Comments"
            matInput
            class="textarea"
            formControlName="RequestorComments"
            readonly
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-12 inputcolumn" style="margin-top: -2%">
        <mat-label class="labelname">Invoice Number</mat-label>
        <mat-form-field
          appearance="outline"
          id="formfieldusernameid"
          class="formfieldusertable"
        >
          <textarea
            placeholder="Enter Invoice Number"
            matInput
            class="textarea"
            formControlName="InvoiceNumber"
            style="height: 40px"
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-12 inputcolumn" style="margin-top: -2%">
        <mat-label class="labelname">Approver Comments</mat-label>
        <mat-form-field
          appearance="outline"
          id="formfieldusernameid"
          class="formfieldusertable"
        >
          <textarea
            matInput
            placeholder="Enter Approver Comments"
            class="textarea"
            formControlName="approvercomment"
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="row" style="margin-top: -37px">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 cancel-btn-col">
        <br /><br />
        <button mat-button class="cancel-btn" (click)="cancelbtn()">
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel
        </button>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol">
        <br /><br />
        <button mat-button class="Filterbtn" (click)="approve_submit()">
          <mat-icon class="filterbtnicon">done</mat-icon>
          Submit
        </button>
      </div>
    </div>
  </form>

  <form [formGroup]="denyform" *ngIf="this.from === 'denylicense'">
    <div class="row">
      <div class="col-12 col-md-12 inputcolumn" style="margin-top: 5%">
        <mat-label class="labelname">Reason for Denial</mat-label>
        <mat-form-field
          appearance="outline"
          id="formfieldusernameid"
          class="formfieldusertable"
        >
          <textarea
            matInput
            placeholder="Enter Reason for Denial"
            class="textareas"
            maxlength="250"
            formControlName="ReasonforDenial"
          ></textarea>
          <mat-error
            class="errorlabelname"
            *ngIf="
              denyform.get('ReasonforDenial').hasError('required') &&
              denyBtn == true
            "
          >
            <img
              src="assets/images/error.svg"
              class="errormsgicon"
              alt="error icon"
            />
            &nbsp;
            <span>Enter reason for denial</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row" style="margin-top: -27px">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 cancel-btn-col">
        <br /><br />
        <button mat-button class="cancel-btn" (click)="cancelbtn()">
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel
        </button>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 Filterbtncol">
        <br /><br />
        <button mat-button class="Filterbtn" (click)="deny_submit()">
          <mat-icon class="filterbtnicon">done</mat-icon>
          Submit
        </button>
      </div>
    </div>
  </form>
</div>
