import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
@Component({
  selector: 'app-taskfilter',
  templateUrl: './taskfilter.component.html',
  styleUrls: ['./taskfilter.component.scss']
})
export class TaskfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton: any;
  tasklist: any = [""];
  loginId: string;
  totalRows: any;
  uniqueTaskListName: any = [];
  TaskListName: any = [];

  constructor(private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>) {
    this.filterform = this.formBuilder.group({
      TasklistName: [''],
      TaskName: [''],
      Status: [''],
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
  }

  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call

    this.loadspinner = true;
    const endpoint1 = 'task';
    const endpoint2 = 'learner/gettasklist';
    let loginId;

    if (localStorage.getItem("currentloginId") == null) {
      loginId = localStorage.getItem('loginId');
    } else {
      loginId = localStorage.getItem("currentloginId");
    }

    let body = {
      "loginName": loginId
    }

    this.apiService.Learnertask_Tasklikstnamenew(endpoint1, endpoint2, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
        this.uniqueTaskListName =response["learnerTaskListView"]
           this.statuslist = [
          { "status_id": 1, "statusName": "Qualified" },
          { "status_id": 2, "statusName": "Not Qualified" },
          { "status_id": 3, "statusName": "Suspended" },
        ]

        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
          

        });
  }
 
  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('groupfilterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton = true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton = false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['TasklistName'].setValue(data["Task List Name"]);
      this.filterform.controls['TaskName'].setValue(data["Task Name"]);
      this.filterform.controls['Status'].setValue(data["Status"]);
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if (clearfilteritemkey[i] === "Task List Name") {
          this.filterform.controls['TasklistName'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Task Name") {
          this.filterform.controls['TaskName'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Status") {
          this.filterform.controls['Status'].setValue("");
        }
        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  inputchange0(value) {
    this.dynamicEnableDisablebtn()
  }

  selectStatusName(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['Status'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      this.disablebutton = false;
    }
  }
  selectTasklistName(value) {
    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['TasklistName'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else {
      this.disablebutton = false;
    }
  }
  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('group_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  EnterSubmit(event) {
    if (event.keyCode === 13) {
      this.dynamicEnableDisablebtn();
      if (this.disablebutton === true) {
        event.preventDefault();
      }
      else {
        event.preventDefault();
        this.Filter();
      }

    }
  }


  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('groupfilterdata');
      localStorage.removeItem('group_chipdisplayfilterdata');
      localStorage.removeItem('group_clearfilteritemkey');
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      this.dialog.close({ data: groupfilterdata, groupapibodycontent: this.groupapibodycontent, method: 'filter' });
    }

  }

  getbodycontent() {
    let groupfilterdata = {
      "Task List Name": this.filterform.value.TasklistName.trim(),
      "Task Name": this.filterform.value.TaskName.trim(),
      "Status": this.filterform.value.Status.trim(),
    }
    this.getapibodycontent();
    return groupfilterdata

  }

  getapibodycontent() {
    let loginName;
    if (localStorage.getItem("currentloginId") == null) {
      loginName = localStorage.getItem('loginId');
    } else {
      loginName = localStorage.getItem("currentloginId");
    }
    this.groupapibodycontent = {
      "taskListName": this.filterform.value.TasklistName.trim(),
      "taskName": this.filterform.value.TaskName.trim(),
      "status": this.filterform.value.Status.trim(),
      "loginName": loginName
    }
  }

  checkformvalue() {
    let formempty;
    if (this.filterform.value.TasklistName.trim() === "" && this.filterform.value.TaskName.trim() === "" && this.filterform.value.Status.trim() === "") {
      formempty = true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }
}


