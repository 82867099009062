<div class="spinner_overlay" *ngIf="loadspinner===true">
</div>
<mat-spinner class="spinner" *ngIf="loadspinner===true"></mat-spinner>

<div class="row headerrow" stype="overflow-y: hidden;">
    <div class="col-10">
      <span class="heading">&nbsp;&nbsp;View Comments</span>
    </div>
    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()" style="float:right;">clear</mat-icon>
    </div>
  </div>
  <br/>

<div class="row" style="overflow-x:scroll">
<div class="tableCls">
  <table mat-table [dataSource]="dataSource" style="width: 100%">

      <ng-container matColumnDef="datePerformed">
          <th mat-header-cell *matHeaderCellDef class="headerdata">
            <div class="datestatus">
              <span class="spanstatus">Date performed</span>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="group_content">{{element.suspend_date | date: 'MM/dd/yyyy'}}</div>
          </td>
      </ng-container>

      <ng-container matColumnDef="commentBy">
          <th mat-header-cell *matHeaderCellDef class="headerdata">
            <div class="status">
              <span class="spanstatus">Comment by</span>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="group_tablecontent">{{element.updatedby}}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="comments">
          <th mat-header-cell *matHeaderCellDef class="headerdata">
            <div class="status">
              <span class="spanstatus">Comments</span>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="group_tablecontent">{{element.comments}}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="notesBy">
          <th mat-header-cell *matHeaderCellDef class="headerdata">
            <div class="status">
              <span class="spanstatus">Additional Notes by</span>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="group_tablecontent">{{element.notesby}}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef class="headerdata">
            <div class="status">
              <span class="spanstatus">Additional Notes</span>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="group_tablecontent">{{element.notes}}</div>
          </td>
        </ng-container>
   

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" style="background-color: #143249"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
</div>