import { Component, EventEmitter, OnInit, Inject, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiServiceService } from '../../../Services/api-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
@Component({
  selector: 'app-scalerating',
  templateUrl: './scalerating.component.html',
  styleUrls: ['./scalerating.component.scss']
})
export class ScaleratingComponent implements OnInit {

  scaleform2: FormGroup;
  scaleform3: FormGroup;
  scaleform4: FormGroup;
  scaleform5: FormGroup;
  title: any = '';
  settings_data;
  loadspinner: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialog: MatDialogRef<ScaleratingComponent>, public formBuilder: FormBuilder, private apiservice: ApiServiceService) {

    this.title = data['title'];
    this.loadspinner = true;
    this.scaleform2 = this.formBuilder.group({
      scale2_labelcode1: ['1'],
      scale2_labelcode2: ['2'],
      scale2_labeltitle1: ['Pass'],
      scale2_labeltitle2: ['Fail'],
      scale2_criteria1: false,
      scale2_criteria2: false
    })

    this.scaleform3 = this.formBuilder.group({
      scale3_labelcode1: ['1'],
      scale3_labelcode2: ['2'],
      scale3_labelcode3: ['3'],
      scale3_labeltitle1: ['Poor'],
      scale3_labeltitle2: ['Satisfactory'],
      scale3_labeltitle3: ['Excellent'],
      scale3_criteria1: false,
      scale3_criteria2: false,
      scale3_criteria3: false,
    })

    this.scaleform4 = this.formBuilder.group({
      scale4_labelcode1: ['1'],
      scale4_labelcode2: ['2'],
      scale4_labelcode3: ['3'],
      scale4_labelcode4: ['4'],
      scale4_labeltitle1: ['Poor'],
      scale4_labeltitle2: ['Satisfactory'],
      scale4_labeltitle3: ['Good'],
      scale4_labeltitle4: ['Excellent'],
      scale4_criteria1: false,
      scale4_criteria2: false,
      scale4_criteria3: false,
      scale4_criteria4: false,
    })

    this.scaleform5 = this.formBuilder.group({
      scale5_labelcode1: ['1'],
      scale5_labelcode2: ['2'],
      scale5_labelcode3: ['3'],
      scale5_labelcode4: ['4'],
      scale5_labelcode5: ['5'],
      scale5_labeltitle1: ['Very Poor'],
      scale5_labeltitle2: ['Poor'],
      scale5_labeltitle3: ['Acceptable'],
      scale5_labeltitle4: ['Good'],
      scale5_labeltitle5: ['Excellent'],
      scale5_criteria1: false,
      scale5_criteria2: false,
      scale5_criteria3: false,
      scale5_criteria4: false,
      scale5_criteria5: false,
    })
  }

  ngOnInit(): void {
    this.default_load();
  }

  default_load() {
    const endpoint1 = 'pef/getFormDetails?formId=' + localStorage.getItem("formId") + '&version=' + localStorage.getItem("version") + '';
    this.apiservice.getformdetails(endpoint1).subscribe((response) => {
      this.settings_data = response;
      this.settings_data = this.settings_data.formSettings.stepsPerformanceRating;
      let rating_val;
      if(this.settings_data != null && this.settings_data != 'null'){
        if(this.settings_data.ratings !=null){
            rating_val = this.settings_data.ratings.length + " Scale Rating";
        }
      let rating_arr = this.settings_data.ratings;

      if (rating_val === "2 Scale Rating") {
        this.scaleform2.patchValue({
          scale2_labelcode1: rating_arr[0].labelCode,
          scale2_labelcode2: rating_arr[1].labelCode,
          scale2_labeltitle1: rating_arr[0].labelTitle,
          scale2_labeltitle2: rating_arr[1].labelTitle,
          scale2_criteria1: rating_arr[0].passingCriteria,
          scale2_criteria2: rating_arr[1].passingCriteria,
        })
      } else if (rating_val === "3 Scale Rating") {
        this.scaleform3.patchValue({
          scale3_labelcode1: rating_arr[0].labelCode,
          scale3_labelcode2: rating_arr[1].labelCode,
          scale3_labelcode3: rating_arr[2].labelCode,
          scale3_labeltitle1: rating_arr[0].labelTitle,
          scale3_labeltitle2: rating_arr[1].labelTitle,
          scale3_labeltitle3: rating_arr[2].labelTitle,
          scale3_criteria1: rating_arr[0].passingCriteria,
          scale3_criteria2: rating_arr[1].passingCriteria,
          scale3_criteria3: rating_arr[2].passingCriteria,
        })
      } else if (rating_val === "4 Scale Rating") {
        this.scaleform4.patchValue({
          scale4_labelcode1: rating_arr[0].labelCode,
          scale4_labelcode2: rating_arr[1].labelCode,
          scale4_labelcode3: rating_arr[2].labelCode,
          scale4_labelcode4: rating_arr[3].labelCode,
          scale4_labeltitle1: rating_arr[0].labelTitle,
          scale4_labeltitle2: rating_arr[1].labelTitle,
          scale4_labeltitle3: rating_arr[2].labelTitle,
          scale4_labeltitle4: rating_arr[3].labelTitle,
          scale4_criteria1: rating_arr[0].passingCriteria,
          scale4_criteria2: rating_arr[1].passingCriteria,
          scale4_criteria3: rating_arr[2].passingCriteria,
          scale4_criteria4: rating_arr[3].passingCriteria,
        })
      } else if (rating_val === "5 Scale Rating") {
        this.scaleform5.patchValue({
          scale5_labelcode1: rating_arr[0].labelCode,
          scale5_labelcode2: rating_arr[1].labelCode,
          scale5_labelcode3: rating_arr[2].labelCode,
          scale5_labelcode4: rating_arr[3].labelCode,
          scale5_labelcode5: rating_arr[4].labelCode,
          scale5_labeltitle1: rating_arr[0].labelTitle,
          scale5_labeltitle2: rating_arr[1].labelTitle,
          scale5_labeltitle3: rating_arr[2].labelTitle,
          scale5_labeltitle4: rating_arr[3].labelTitle,
          scale5_labeltitle5: rating_arr[4].labelTitle,
          scale5_criteria1: rating_arr[0].passingCriteria,
          scale5_criteria2: rating_arr[1].passingCriteria,
          scale5_criteria3: rating_arr[2].passingCriteria,
          scale5_criteria4: rating_arr[3].passingCriteria,
          scale5_criteria5: rating_arr[4].passingCriteria,
        })
      }
    }
      this.loadspinner = false;
    })

  }



  close(val) {
    let ratings = this.scale_rating(val);
    this.dialog.close({ data: ratings, method: 'cancel' });
  }

  cancelbtn() {
    this.dialog.close({  method: 'cancel' })
  }


  save(val) {
    let ratings = this.scale_rating(val);
    this.dialog.close({ data: ratings, method: 'delete' });
  }

  scale_rating(val) {
    let ratings;

    if (val === "scaleform2") {

      ratings = [
        {
          "labelCode": this.scaleform2.controls.scale2_labelcode1.value,
          "labelTitle": this.scaleform2.controls.scale2_labeltitle1.value,
          "passingCriteria": this.scaleform2.controls.scale2_criteria1.value,
        },
        {
          "labelCode": this.scaleform2.controls.scale2_labelcode2.value,
          "labelTitle": this.scaleform2.controls.scale2_labeltitle2.value,
          "passingCriteria": this.scaleform2.controls.scale2_criteria2.value,
        }
      ]

      return ratings;

    } else if (val === "scaleform3") {

      ratings = [
        {
          "labelCode": this.scaleform3.controls.scale3_labelcode1.value,
          "labelTitle": this.scaleform3.controls.scale3_labeltitle1.value,
          "passingCriteria": this.scaleform3.controls.scale3_criteria1.value,
        },
        {
          "labelCode": this.scaleform3.controls.scale3_labelcode2.value,
          "labelTitle": this.scaleform3.controls.scale3_labeltitle2.value,
          "passingCriteria": this.scaleform3.controls.scale3_criteria2.value,
        },
        {
          "labelCode": this.scaleform3.controls.scale3_labelcode3.value,
          "labelTitle": this.scaleform3.controls.scale3_labeltitle3.value,
          "passingCriteria": this.scaleform3.controls.scale3_criteria3.value,
        },
      ]

      return ratings;

    } else if (val === "scaleform4") {

      ratings = [
        {
          "labelCode": this.scaleform4.controls.scale4_labelcode1.value,
          "labelTitle": this.scaleform4.controls.scale4_labeltitle1.value,
          "passingCriteria": this.scaleform4.controls.scale4_criteria1.value,
        },
        {
          "labelCode": this.scaleform4.controls.scale4_labelcode2.value,
          "labelTitle": this.scaleform4.controls.scale4_labeltitle2.value,
          "passingCriteria": this.scaleform4.controls.scale4_criteria2.value,
        },
        {
          "labelCode": this.scaleform4.controls.scale4_labelcode3.value,
          "labelTitle": this.scaleform4.controls.scale4_labeltitle3.value,
          "passingCriteria": this.scaleform4.controls.scale4_criteria3.value,
        },
        {
          "labelCode": this.scaleform4.controls.scale4_labelcode4.value,
          "labelTitle": this.scaleform4.controls.scale4_labeltitle4.value,
          "passingCriteria": this.scaleform4.controls.scale4_criteria4.value,
        },
      ]

      return ratings;

    } else if (val === "scaleform5") {

      ratings = [
        {
          "labelCode": this.scaleform5.controls.scale5_labelcode1.value,
          "labelTitle": this.scaleform5.controls.scale5_labeltitle1.value,
          "passingCriteria": this.scaleform5.controls.scale5_criteria1.value,
        },
        {
          "labelCode": this.scaleform5.controls.scale5_labelcode2.value,
          "labelTitle": this.scaleform5.controls.scale5_labeltitle2.value,
          "passingCriteria": this.scaleform5.controls.scale5_criteria2.value,
        },
        {
          "labelCode": this.scaleform5.controls.scale5_labelcode3.value,
          "labelTitle": this.scaleform5.controls.scale5_labeltitle3.value,
          "passingCriteria": this.scaleform5.controls.scale5_criteria3.value,
        },
        {
          "labelCode": this.scaleform5.controls.scale5_labelcode4.value,
          "labelTitle": this.scaleform5.controls.scale5_labeltitle4.value,
          "passingCriteria": this.scaleform5.controls.scale5_criteria4.value,
        },
        {
          "labelCode": this.scaleform5.controls.scale5_labelcode5.value,
          "labelTitle": this.scaleform5.controls.scale5_labeltitle5.value,
          "passingCriteria": this.scaleform5.controls.scale5_criteria5.value,
        },
      ]
      return ratings;
    }
  }

}
