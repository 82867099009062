<div class="spinner_overlay" *ngIf="loadspinner === true"></div>
<mat-spinner class="spinner" *ngIf="loadspinner === true"></mat-spinner>
<div (keydown)="EnterSubmit($event)">
  <div class="row headerrow">
    <div class="col-10">
      <span class="heading">&nbsp;&nbsp;Assign/Remove Content</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()"
        >clear</mat-icon
      >
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 table-padding">
      <div class="">
      <div class="row">
          <div class="col-lg-8" style="margin: auto">
            <div class="userlist">{{ catalogName }}</div>
            <span class="labelInfo">Check to add, uncheck to remove</span>
          </div>
          <div
            class="col-lg-4"
            style="
              margin-left: -6px;
              position: relative;
              margin-top: 8px;
              margin-bottom: 10px;
              overflow: hidden;
            "
          >
            <mat-form-field
              appearance="outline"
              class="matformfieldheader header-field"
            >
              <input
                type="text"
                [(ngModel)]="searchvalue"
                #searchinput
                matInput
                class="textsearchinput"
                (ngModelChange)="clearTable(searchinput.value)"
                placeholder="Search..."
                autocomplete="off"
              />
              <mat-icon
                matSuffix
                (click)="search(searchinput.value)"
                class="searchbtn"
                style="cursor: pointer"
                >search</mat-icon
              >
            </mat-form-field>
          </div>
        </div>

        <div
          *ngIf="groupELEMENT_DATA.length === 0"
          class="col-12 col-md-2 col-lg-4 col-xl-4 tableheadcol"
        >
          <div style="font-size: 16px" *ngIf="groupELEMENT_DATA.length === 0">
            No record found
          </div>
          <br />
        </div>

        <div class="tableheight">
          <table
            mat-table
            [dataSource]="groupdataSource"
            #grouplisttable
            style="width: 100%"
            matSort
          >
            <!--checkbox Column -->
            <ng-container matColumnDef="chechbox">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="headerdata"
                style="width: 50px"
              >
                <div class="checkbox">
                  <mat-checkbox
                    class="checkboxheading"
                    [(ngModel)]="group_masterSelected"
                    (change)="group_checkUncheckAll()"
                  ></mat-checkbox>
                </div>
              </th>
              <td mat-cell *matCellDef="let element; let i = index">
                <div class="checkbox">
                  <mat-checkbox
                    class="checkboxbody"
                    [(ngModel)]="element.isSelected"
                    (change)="group_isitemSelected(element)"
                  >
                  </mat-checkbox>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef class="headerdata">
                <div class="statusbigwidth">
                  <span class="spanstatusbigwidth">TITLE</span>
                  &nbsp;
                  <mat-icon
                    class="arrowupward"
                    alt="arrowupward"
                    (click)="
                      Grouplistarrow_upward('string', 'title', group_ids[0])
                    "
                    id="{{ group_ids[0] }}"
                    >arrow_upward</mat-icon
                  >
                  <mat-icon
                    class="downupward"
                    alt="arrowdownward"
                    (click)="
                      Grouplistarrow_downward('string', 'title', group_ids[1])
                    "
                    id="{{ group_ids[1] }}"
                    >arrow_downward</mat-icon
                  >
                </div>
              </th>
              <td mat-cell *matCellDef="let element; let i = index">
                <div class="tablecontentstatusbigwidth">
                  {{ element.title }}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="category">
              <th mat-header-cell *matHeaderCellDef class="headerdata">
                <div class="statusbigcontainer">
                  <span class="spanstatusbigwidth">CATEGORY</span>
                  &nbsp;
                  <mat-icon
                    class="arrowupward"
                    alt="arrowupward"
                    (click)="
                      Grouplistarrow_upward('string', 'category', group_ids[2])
                    "
                    id="{{ group_ids[2] }}"
                    >arrow_upward</mat-icon
                  >
                  <mat-icon
                    class="downupward"
                    alt="arrowdownward"
                    (click)="
                      Grouplistarrow_downward(
                        'string',
                        'category',
                        group_ids[3]
                      )
                    "
                    id="{{ group_ids[3] }}"
                    >arrow_downward</mat-icon
                  >
                </div>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="groupname_tablecontent">{{ element.category }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef class="headerdata">
                <div class="statusbigcontainer">
                  <span class="spanstatusbigwidth">TYPE</span>
                  &nbsp;
                  <mat-icon
                    class="arrowupward"
                    alt="arrowupward"
                    (click)="
                      Grouplistarrow_upward('string', 'type', group_ids[4])
                    "
                    id="{{ group_ids[4] }}"
                    >arrow_upward</mat-icon
                  >
                  <mat-icon
                    class="downupward"
                    alt="arrowdownward"
                    (click)="
                      Grouplistarrow_downward('string', 'type', group_ids[5])
                    "
                    id="{{ group_ids[5] }}"
                    >arrow_downward</mat-icon
                  >
                </div>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="groupname_tablecontent">{{ element.type }}</div>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumnsGrouplist; sticky: true"
              style="background-color: #143249"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsGrouplist"
            ></tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- sticky paginator  -->
  <div
    class="paginatorfooterrow right-align"
    *ngIf="groupELEMENT_DATA.length > 0"
  >
    <div class="row">
      <div class="col-12 col-md-12 col-lg-12 col-xl-12 page-right">
        <span class="paginatorgotocol">
          <form>
            <span class="label">Page:&nbsp;</span>
            <mat-form-field class="paginatorselect">
              <input
                type="number"
                #input
                aria-label="Number"
                matInput
                [formControl]="pagecountform"
                [matAutocomplete]="auto"
                (change)="
                  grouplistupdateManualPageinput(input.value, totalpages)
                "
                style="width: 60%"
                id="pagination_grouptable"
              />
              <mat-icon matSuffix class="matautocompleteicon"
                >arrow_drop_down</mat-icon
              >
              <mat-autocomplete
                #auto="matAutocomplete"
                activeOption="activeOption"
                (optionSelected)="
                  grouplistupdateManualPage($event.option.value)
                "
              >
                <mat-option
                  value="{{ i + 1 }}"
                  *ngFor="let item of [].constructor(totalpages); let i = index"
                >
                  {{ i + 1 }}</mat-option
                >
              </mat-autocomplete>
            </mat-form-field>
          </form>
        </span>

        <span class="paginatorrowperpagecol">
          <span class="label">&nbsp;&nbsp;Rows Per Page:&nbsp;</span>
          <mat-form-field class="paginatorselect">
            <mat-select
              [(ngModel)]="Rowperpage"
              (selectionChange)="grouplistupdateRowManualPage(Rowperpage)"
            >
              <mat-option
                *ngFor="let item of pageSizeOptions"
                [value]="item.value"
              >
                {{ item.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <span class="paginationtxt"
            >&nbsp; {{ indexfirst }} - {{ indexlast }} of {{ totalitemlength }}
            <!-- </span> -->

            <mat-icon
              class="paginationiconleft"
              id="paginationarrowleftid1"
              (click)="grouplistonPaginateChange('arrowleft')"
              >chevron_left</mat-icon
            >

            <mat-icon
              class="paginationiconright"
              id="paginationarrowrightid1"
              (click)="grouplistonPaginateChange('arrowright')"
              >chevron_right</mat-icon
            >
          </span>
        </span>
      </div>
    </div>
  </div>

  <br />
  <div class="row headertop"></div>
  <br />
  <div class="row">
    <div class="col-2"></div>
    <div class="col-4">
      <button
        type="button"
        mat-button
        class="cancelbtn margin-btn"
        (click)="cancelbtn()"
      >
        <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
        Cancel
      </button>
    </div>
    <div class="col-4">
      <button
        mat-button
        class="createbtn save-btn margin-btn"
        type="button"
        (click)="save()"
      >
        <mat-icon class="createbtnicon">done</mat-icon>
        Save
      </button>
    </div>
    <div class="col-2"></div>
  </div>
</div>
