import { Component, OnInit, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-acknowledgement',
  templateUrl: './acknowledgement.component.html',
  styleUrls: ['./acknowledgement.component.scss']
})
export class AcknowledgementComponent implements OnInit {
  loadspinner:any;
  acknowledgement_list:any=[];
  itemNumber=0;
  postedby:any='';
  posteddate='';
  logindetails:any=[];
  isAcknowledge:any;
  heading:any='';
   constructor(@Inject(MAT_DIALOG_DATA) public data: string, private apiService: ApiServiceService, public dialog: MatDialog) {
     this.acknowledgement_list=data['Announcement_list'];
     this.logindetails=data['login_details'];
  setTimeout(()=>{
    document.getElementById("description").innerHTML = this.acknowledgement_list[this.itemNumber].body;
    if(this.acknowledgement_list[this.itemNumber].acknwoledgementFlag){
      this.heading='Acknowledgement';
    }else if(!this.acknowledgement_list[this.itemNumber].acknwoledgementFlag){
      this.heading='Announcement';
    }
    this.postedby=this.acknowledgement_list[this.itemNumber].companyName;
    this.posteddate=this.acknowledgement_list[this.itemNumber].updatedDate;
    this.isAcknowledge=this.acknowledgement_list[this.itemNumber].acknwoledgementFlag;
  }, 1000)

    }

  ngOnInit() {
    
  }

  confirm(){
    if(this.itemNumber>(this.acknowledgement_list.length-1)){
      localStorage.removeItem("user_loggedIN")
      this.dialog.closeAll()
    }else{
      this.confirm_ack()
    }
   
  }
  confirm_ack(){
    this.loadspinner = true;
    let loginId= localStorage.getItem('loginId');
    const endpoint1 = 'announcement/confirm/'+loginId;
    
    let body={
      "annoucementId":this.acknowledgement_list[this.itemNumber].announcementId,
      "confirmResult":true
  }
    this.apiService.confirm_Acknowledgement(endpoint1, body, 'POST')
      .subscribe((response) => {
      if(this.itemNumber<(this.acknowledgement_list.length-1)){
     this.itemNumber=this.itemNumber+1;
      document.getElementById("description").innerHTML = this.acknowledgement_list[this.itemNumber].body;
      if(this.acknowledgement_list[this.itemNumber].acknwoledgementFlag){
        this.heading='Acknowledgement';
      }else if(!this.acknowledgement_list[this.itemNumber].acknwoledgementFlag){
        this.heading='Announcement';
      }
      this.posteddate=this.acknowledgement_list[this.itemNumber].updatedDate;
      this.isAcknowledge=this.acknowledgement_list[this.itemNumber].acknwoledgementFlag;
      }else{
        this.dialog.closeAll()
      }
        this.loadspinner = false;
       },
       (err) => {​​​
        this.loadspinner = false;
        }​​​
       );
  }
}
