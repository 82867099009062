<div class="spinner_overlay" *ngIf="loadspinner === true"></div>
<mat-spinner class="spinner" *ngIf="loadspinner === true"></mat-spinner>

<div class="filtermaincontainer" (keydown)="EnterSubmit($event)">
  <div class="row headerrow">
    <div class="col-10" style="padding-bottom: 12px">
      <img src="assets/images/filter-filled-tool-symbol.svg" alt="filter" />
      <span class="heading">&nbsp;&nbsp;Filter</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()"
        >clear</mat-icon
      >
    </div>
  </div>

  <form [formGroup]="filterform">
    <div class="row">
      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname"
          >Exam Categories Name</mat-label
        >
        <mat-form-field
          appearance="outline"
          id="formfieldusernameid"
          class="formfieldusertable"
        >
          <input
            matInput
            placeholder="Enter Categories Name"
            autocomplete="off"
            #inpName
            (keyup)="inputchangeName(inpName.value)"
            class="inputClassText"
            formControlName="examPoolName"
          />
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname"
          >Owner/Company (COID)</mat-label
        >
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select
            formControlName="owner"
            placeholder="Owner/Company (COID)"
            id="companydropdownID"
            class="inputClass"
            panelClass="myPanelmatselectClassCOID"
            (selectionChange)="selectCompanyID($event.value)"
            disableOptionCentering
            [disabled]="isDisabled"
          >
            <input
              placeholder="Search"
              id="inputpagenocompany"
              class="input_matselect"
              (keyup)="onKeyCompany($event.target.value)"
              (keydown)="onKeyDownCompany($event)"
            />
            <mat-option [value]="" class="none_style"> None </mat-option>
            <mat-option
              *ngFor="let item of companylistdata"
              [value]="item.companyName + ' ' + '(' + item.companyId + ')'"
            >
              <div class="matoptiontxt">
                {{ item.companyName }}&nbsp;({{ item.companyId }})
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <br />
      &#160; <br />
    </div>

    <div class="row">
      <div class="col-6 cancelbtncol">
        <br /><br />
        <button
          mat-button
          id="btncancelid"
          class="cancelbtn"
          (click)="cancelbtn()"
        >
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel
        </button>
      </div>
      <div class="col-6 Filterbtncol">
        <br /><br />
        <button
          mat-button
          id="btnfiltertermid"
          class="Filterbtn"
          [disabled]="disablebutton"
          (click)="Filter()"
        >
          <mat-icon class="filterbtnicon">done</mat-icon>
          Filter
        </button>
      </div>
    </div>
  </form>
</div>
