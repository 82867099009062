import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { DialogpopupComponent } from '../dialog-popup/dialog-popup.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  loginname: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialog: MatDialogRef<UserComponent>, public dialogopen: MatDialog, private formBuilder: FormBuilder, public _Service: ApiServiceService,) {
    this.loginname = data['data'];
  }
  sel_option = false;
  metadata: any;
  loadspinner: any;
  myVar1 = false;
  hidePassword: any = false;
  hideconfirmPassword: any = false;
  resetPassForm: FormGroup;
  policyOption: any;
  policy1ExistError: any = false;
  spclcharExistError: any = false;
  phoneExistErrorMessage: string;

  ngOnInit(): void {
    this.resetPassForm = this.formBuilder.group({
      passwordControl: ['', [Validators.required]],
      confirmpasswordControl: ['', [Validators.required]],

      changepasswordflagControl: [''],
    }, {
      validator: MustMatch('passwordControl', 'confirmpasswordControl'),
    });
    this.policyOption = localStorage.getItem("policyOption");
  }
  onSubmits() {
    if (this.resetPassForm.valid && (!this.policy1ExistError && !this.spclcharExistError)) {
      var passwordflag;

      var body = {
        "newPassword": this.resetPassForm.value.confirmpasswordControl.trim(),
        "changePasswordFlag": this.resetPassForm.value.changepasswordflagControl,
      }
      this.loadspinner = true;
      const endpoint = 'users/resetpassword/' + this.loginname

      this._Service.resetpassword(endpoint, body, 'PATCH').subscribe(response => {
        this.loadspinner = false;

        if (response["message"] === 'Success') {
          this.dialogopen.open(DialogpopupComponent, {
            width: '550px',
            disableClose: true,
            data:
            {
              from: "popup-success",
              successmsg: "Password Reset Successful!",
              successmsgbody: ' Your password has been changed successfully. You can login with new password.',
              imagelogo: "assets/images/change-pw.svg"
            },
            position: {
              top: '25vh'
            }
          });
          this.dialog.close();
          let element = document.getElementById("user-content")
          element.classList.remove('blur-content');
        }
        else {
          this.loadspinner = false;

          let data = {
            status: response["message"]
          };
          this.dialogopen.open(DialogpopupComponent, {
            width: '550px',
            data: {
              from: 'error-popup',
              name: data
            },
            position: {
              top: '40vh'
            }
          });
        }
      },
        (err) => {
          this.loadspinner = false;
        });
    }
  }
  update() {
    let value = this.sel_option;
    this.metadata = { "changePasswordFlag": false }
  }
  cancel() {
    this.dialog.close();
    let element = document.getElementById("user-content")
    element.classList.remove('blur-content');
  }

  passValidation() {
    var p = this.resetPassForm.controls.passwordControl.value;
    let policyNum = this.policyOption;
    if (/[`~,.;':"\\[\]|{}()=_+]/.test(p)) {
      this.spclcharExistError = true;
    }
    else {
      this.spclcharExistError = false;
      if (policyNum == "policy1") {
        if (p.length < 6) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Password must be at least 6 characters";
        } else if (p.length > 16) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Password must be maximium 16 characters";
        }
        else if (p.search(/[0-9]/) < 0) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Password must contain at least one digit.";
        }
        else if (p.search(/(?=.*[@!#$%^&*-/<>?])/)) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Password must contain at least one special character.";
        } else if (p.search(/[a-zA-Z]/) < 0) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Password must contain at least one letter.";
        } else {
          this.policy1ExistError = false;
        }
      } else if (policyNum == "policy2") {
        if (p.length < 8) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Password must be at least 8 characters";
        } else if (p.length > 16) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Password must be maximium 16 characters";
        }
        else if (p.search(/[0-9]/) < 0) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Password must contain at least one digit.";
        }
        else if (p.search(/(?=.*[@!#$%^&*-/<>?])/)) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Password must contain at least one special character.";
        } else if (p.search(/[a-zA-Z]/) < 0) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Password must contain at least one letter.";
        } else {
          this.policy1ExistError = false;
        }
      } else if (policyNum == "policy3") {
        if (p.length < 8) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Password must be at least 8 characters";
        } else if (p.length > 16) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Password must be maximium 16 characters";
        } else if (p.search(/(?=(.*[a-zA-Z]){2})/)) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Password must contain at least two letter.";
        }
        else if (p.search(/(\D*\d){2,}/)) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Password must contain at least two digit.";
        }
        else if (p.search(/(?:[^`@!#$%^&*-/<>?]*[`@!#$%^&*-/<>?]){2}/)) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Password must contain at least two special character.";
        }
        else {
          this.policy1ExistError = false;
        }
      } else {
        if (p.length < 8) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Password must be at least 8 characters.";
        } else if (p.length > 16) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Password must be maximium 16 characters";
        } else if (p.search(/[a-zA-Z0-9@!#$%^&*-/<>?]+/)) {
          this.policy1ExistError = true;
          this.phoneExistErrorMessage = "Please enter a valid password";
        }
        else {
          this.policy1ExistError = false;
        }
      }

    }

  }
}
// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
