<div class="spinner_overlay" *ngIf="loadspinner === true"></div>
<mat-spinner class="spinner" *ngIf="loadspinner === true"></mat-spinner>

<br />

<!-- <div class="create-group adduser-container"> -->
<div class="row header">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 left-sec">
    <img src="assets/images/user.svg" />
    <span class="head-title">Add/Remove User</span>

    <button type="button" class="close" aria-label="Close" (click)="cancel()">
      <mat-icon class="clear-btn">clear</mat-icon>
    </button>
  </div>
</div>

<hr style="width: 1016px; margin-left: -26px" />
<div class="content-sec">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <p class="title">Add/Remove User to Job Position</p>
    </div>
  </div>
  <div class="row user-box">
    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12 user-box-block">
      <div class="list-header">User List</div>
      <div class="list-search">
        <mat-form-field appearance="outline" class="matformfieldheader">
          <input
            type="text"
            #searchinput1
            id="sid1"
            matInput
            (keyup)="search_left(searchinput1.value)"
            class="textsearchinput"
            placeholder="Search by name, login and email"
            autocomplete="off"
          />
          <mat-icon matSuffix class="searchbtn">search</mat-icon>
        </mat-form-field>
      </div>
      <div class="list-scroll">
        <cdk-virtual-scroll-viewport itemSize="50" style="height: 300px">
          <div *cdkVirtualFor="let data of user_data" class="list-data">
            <mat-checkbox
              class="checkboxbody"
              [checked]="data.isSelected"
              (change)="itemSelected_leftbox(data)"
            >
            </mat-checkbox>
            &nbsp;&nbsp;&nbsp;{{ data.displayName }} ({{ data.loginName }})
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 btn-center">
      <div class="inner-container">
        <div class="row sub-row">
          <button type="button" mat-button class="addbtn" (click)="add_users()">
            Add
            <br />
            <mat-icon class="Cancelicon" alt="clear">east</mat-icon>
          </button>
        </div>
        <div class="row sub-row">
          <button
            type="button"
            mat-button
            class="removebtn"
            (click)="remove_user()"
          >
            Remove <br />
            <mat-icon class="Cancelicon" alt="clear">west</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-xs-12 user-box-block">
      <div class="list-header">User Added</div>
      <div class="list-search">
        <mat-form-field appearance="outline" class="matformfieldheader">
          <input
            type="text"
            #searchinput2
            id="sid2"
            matInput
            (keyup)="search_right(searchinput2.value)"
            class="textsearchinput"
            placeholder="Search by name, login and email"
            autocomplete="off"
          />
          <mat-icon matSuffix class="searchbtn">search</mat-icon>
        </mat-form-field>
      </div>
      <div class="list-scroll">
        <cdk-virtual-scroll-viewport itemSize="50" style="height: 300px">
          <div
            *cdkVirtualFor="let data of selected_users"
            class="list-data checkbox"
          >
            <mat-checkbox
              class="checkboxbody checkbox-right"
              [checked]="data.isSelected"
              (change)="itemSelected_rightbox(data)"
            >
            </mat-checkbox>
            {{ data.displayName }} ({{ data.loginName }})
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </div>
  <div class="row height-space"></div>
  <div class="row">
    <div class="col-md-3 col-lg-3 col-sm-0 col-12"></div>
    <div class="col-md-3 col-lg-3 col-sm-6 col-12 cancelbtn-outer">
      <button
        type="button"
        mat-button
        class="cancelbtn"
        (click)="cancel_user()"
      >
        Cancel
      </button>
    </div>
    <div class="col-md-3 col-lg-3 col-sm-6 col-12 createbtn-outer">
      <button
        mat-button
        class="createbtn"
        type="submit"
        (click)="add_users_api()"
      >
        Save
      </button>
    </div>
    <div class="col-md-3 col-lg-3 col-sm-0 col-12"></div>
  </div>
</div>
