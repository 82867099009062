<router-outlet></router-outlet>

<div style="display:none">
    <ng-template #errorduplicate>
        <div class="row popup-error">
            <div class="col-12" style="text-align: center"><br>
                <img src="assets/images/failure.svg" alt="popuplogo">
            </div>

            <div class="col-12" style="text-align: center;"><br>
                <div class="bodymsg">
                    An instance of this site is already running. The Site cannot be opened
                </div><br><br>
            </div>
        </div>
    </ng-template>
</div>