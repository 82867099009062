import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { HeaderComponent } from 'src/app/shared/header/header.component';
import { CommondataserviceService } from 'src/app/Services/commondataservice.service'
import { UserComponent } from 'src/app/Administrator/user/user.component';

@Component({
  selector: 'app-createfromtemplate',
  templateUrl: './createfromtemplate.component.html',
  styleUrls: ['./createfromtemplate.component.scss']
})
export class CreatefromtemplateComponent implements OnInit {
  // grouplist
  menuTitle: any;
  displayedColumnsGrouplist: string[] = ['Template ID', 'Template Name', 'ACTIONS'];
  groupdataSource: any;
  groupELEMENT_DATA: any = [];
  group_todeletefilterdata: any = [];
  group_filterdata: any = {};
  group_filterbodydata: any;
  group_hidbenclearbtn: any = true;
  group_masterSelected: boolean = false;
  group_checkedList: any = [];

  public selectTabIndex: any;
  totalitemlength: any;
  indexfirst: any;
  indexlast: any;
  totalpages: any;
  pagesize: any;
  Rowperpage: any;
  sortby: any;
  sortOrder: any;
  loadspinner: any;
  pageSizeOptions: any;
  group_ids = ["idp1", "idp2", "idp3", "idp4"];
  pagecountform = new FormControl();
  @ViewChild(MatAutocompleteTrigger) matAutocomplete: MatAutocompleteTrigger;
  dataList: any;
  companyId: any;
  data_details: any;
  companyName: any;
  groupName: any;
  filterValue: any = '';
  templateForm: FormGroup;
  searchvalue: any = '';
  compId: string;

  constructor(private activatedRoute: ActivatedRoute, public dialog: MatDialog, public dialogclose: MatDialogRef<UserComponent>, private formBuilder: FormBuilder, private router: Router,
    private headerservice: HeaderComponent, private apiService: ApiServiceService, private CommondataserviceService: CommondataserviceService) {
    this.templateForm = this.formBuilder.group({
      search: [''],
    });
    this.pagesize = '1';
    this.Rowperpage = "10";
    this.sortby = "title";
    this.sortOrder = "ASC";
    this.group_todeletefilterdata = [];
    this.Group_cacheddatadisplaychip();
    this.pageSizeOptions = [
      { id: '10', value: '10' },
      { id: '25', value: '25' },
      { id: '50', value: '50' },
      { id: '100', value: '100' },
    ];
  }

  ngOnInit() {

  }


  // on load display chip if cached data present
  Group_cacheddatadisplaychip() {
    // on relead check if existing chips selected to display on UI
    let data = localStorage.getItem("group_chipdisplayfilterdata");
    if (data === null || data === undefined || data === "") {

    } else {
      this.group_hidbenclearbtn = false;
      this.group_filterdata = JSON.parse(data);
    }
    // if filter selected call api --- else call default api
    var group_filterapibodycontent;
    try {
      group_filterapibodycontent = JSON.parse(localStorage.getItem("group_filterapibodycontent"));
      if (group_filterapibodycontent === null || group_filterapibodycontent === undefined || group_filterapibodycontent === "") {
        this.loaddefaultpaginationapi();
      }
      else {
        this.loaddefaultpaginationapi()
      }
    } catch (e) {
      
      group_filterapibodycontent = {};    // set default value if localStorage parsing failed
    }

  }

  // on pagination change check if any filter item present

  checkfilteredchipCache() {
    if (this.groupELEMENT_DATA.length > 0) {
      var group_filterapibodycontent;
      try {
        group_filterapibodycontent = JSON.parse(localStorage.getItem("group_filterapibodycontent"));
        if (group_filterapibodycontent === null || group_filterapibodycontent === undefined || group_filterapibodycontent === "") {
          this.loaddefaultpaginationapi();
        }
        else {
          this.loaddefaultpaginationapi();
        }
      } catch (e) {
        
        group_filterapibodycontent = {};    // set default value if localStorage parsing failed
      }
    }
  }

  // grouptable -- pagination -- input
  grouplistupdateManualPageinput(value, totalpagesarray) {
    if (value > totalpagesarray) {
      $("#pagination_viewgroupuserstable").val(totalpagesarray);
      value = totalpagesarray;
    }
    this.matAutocomplete.closePanel();
    if (parseInt(value) >= 1 && parseInt(value) <= totalpagesarray) {
      this.pagesize = value;
      this.checkfilteredchipCache();
    }
    else {
      this.pagesize = totalpagesarray;
      this.checkfilteredchipCache();
    }
  }



  // grouptable -- pagination
  grouplistupdateManualPage(value) {
    this.pagesize = value;
    this.checkfilteredchipCache();
  }

  // Table - Row per page
  grouplistupdateRowManualPage(value) {
    this.Rowperpage = value;
    this.pagesize = '1';
    this.pagecountform.setValue(this.pagesize);
    this.checkfilteredchipCache();
  }

  grouplistonPaginateChange(arrowdirection) {
    let pagesizenumber: any;
    if (arrowdirection === "arrowright") {
      pagesizenumber = parseInt(this.pagesize) + 1;
      this.pagesize = pagesizenumber.toString();
      this.checkfilteredchipCache();
    }
    else if (arrowdirection === "arrowleft") {
      pagesizenumber = parseInt(this.pagesize) - 1;
      this.pagesize = pagesizenumber.toString();
      this.checkfilteredchipCache();
    }
  }

  Grouplistarrow_upward(datatype, data, id) {
    this.sortby = data;
    this.sortOrder = "ASC";
    this.sortorderCSS(id);
    this.checkfilteredchipCache();
  }

  Grouplistarrow_downward(datatype, data, id) {
    this.sortby = data;
    this.sortOrder = "DESC";
    this.sortorderCSS(id);
    this.checkfilteredchipCache();
  }

  sortorderCSS(id) {
    for (var i = 0; i < this.group_ids.length; i++) {
      if (id == this.group_ids[i]) {
        let elemt = document.getElementById(id);
        elemt.style.color = '#eaa22c'
      } else {
        let elemt1 = document.getElementById(this.group_ids[i]);
        elemt1.style.color = "white";
      }
    }
  }


  // default load first method
  loaddefaultpaginationapi() {
    let cacheddata = localStorage.getItem('role');
    this.compId = localStorage.getItem('procCompId');
      let body
      if (cacheddata === "System Administrator") {
        let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
        if(globalcompanyIDselected=='' || globalcompanyIDselected==undefined || globalcompanyIDselected==null){
          body = {
            'template': true,
            "owner": "",
          }
          }
          else{
            body = {
              'template': true,
              "owner": globalcompanyIDselected,
            }
          }
      }
      else {
        body = {
          'template': true,
          "owner": this.compId,
        }
      }
    
    this.group_filterapipaginationcall(body)

  }


  // post --- on click sort after filter fourth method
  group_filterapipaginationcall(data) {
    // create bodu content for api call
    this.loadspinner = true;
    const endpoint1 = 'pef'
    const endpoint2 = 'template';
    const endpoint3 = 'search';
    let pageNo = this.pagesize;
    let sortby = this.sortby;
    let sortOrder = this.sortOrder;
    let itemPerPage = this.Rowperpage;
    let body = {
      "filters": data
    }
    // call api get response
    this.apiService.createFromTemplate(pageNo, sortby, sortOrder, itemPerPage, endpoint1, endpoint2, endpoint3, body, 'POST')
      .subscribe((response) => {
        this.loadspinner = false;
        this.groupELEMENT_DATA = response['templateList'];
        this.groupdataSource = new MatTableDataSource<any>(this.groupELEMENT_DATA);
        this.groupELEMENT_DATA.map(i => { i["isSelected"] = false });
        setTimeout(() => {
          this.pagecountform.setValue(this.pagesize);
          this.totalitemlength = response['data']['totalrows'];
          this.totalpages = response['data']['totalpages'];
          this.pagesize = this.pagesize;
          this.Rowperpage = this.Rowperpage;
          this.indexfirst = ((parseInt(this.pagesize) - 1) * parseInt(this.Rowperpage)) + 1;
          this.indexlast = parseInt(this.pagesize) * parseInt(this.Rowperpage) > this.totalitemlength ? this.totalitemlength : parseInt(this.pagesize) * parseInt(this.Rowperpage);
          this.customCSS();
        }, 1000);
      },
        (err) => {
          this.loadspinner = false;
        });

  }


  customCSS() {
    if (parseInt(this.pagesize) === 1) {
      $('#paginationarrowleftid12').css("color", '#c9c7c7');   // disable
      $('#paginationarrowrightidss123').css("color", '#696969'); // enable
      $('#paginationarrowleftid12').css("pointer-events", 'none');
      $('#paginationarrowrightidss123').css("pointer-events", 'auto');
      if (this.totalpages === 1) {
        $('#paginationarrowleftid12').css("color", '#c9c7c7');
        $('#paginationarrowrightidss123').css("color", '#c9c7c7');
        $('#paginationarrowleftid12').css("pointer-events", 'none');
        $('#paginationarrowrightidss123').css("pointer-events", 'none');
      }

    }
    else if (parseInt(this.pagesize) === this.totalpages) {
      $('#paginationarrowleftid12').css("color", '#696969');
      $('#paginationarrowrightidss123').css("color", '#c9c7c7');
      $('#paginationarrowleftid12').css("pointer-events", 'auto');
      $('#paginationarrowrightidss123').css("pointer-events", 'none');
    }
    else {
      $('#paginationarrowleftid12').css("color", '#696969');
      $('#paginationarrowrightidss123').css("color", '#696969');
      $('#paginationarrowleftid12').css("pointer-events", 'auto');
      $('#paginationarrowrightidss123').css("pointer-events", 'auto');
    }
  }




  group_getCheckedItemList() {
    for (var i = 0; i < this.groupELEMENT_DATA.length; i++) {
      let present = false;
      for (var j = 0; j < this.group_checkedList.length; j++) {
        if (this.group_checkedList[j].formId == this.groupELEMENT_DATA[i].formId) {
          present = true;
          if (!this.groupELEMENT_DATA[i].isSelected) {
            this.group_checkedList.splice(j, 1);
          }
        }
      }
      if (this.groupELEMENT_DATA[i].isSelected) {
        if (!present) {
          this.group_checkedList.push(this.groupELEMENT_DATA[i]);
        }
      }
    }
  }


  print_group() {
    let prefix = '';
    let dynamic = '';
    let suffix = '';
    let prefix1 = '';
    let dynamic1 = '';
    let suffix1 = '';
    let closetag = '';
    prefix += '<html><head><link href="https://fonts.googleapis.com/css2?family=Open+Sans:&display=swap"> <meta name="viewport" content="width=device-width, initial-scale=0.8"/> <style> th:nth-child(odd){ background-color:#143249; color-adjust: exact; /*firefox & IE */ -webkit-print-color-adjust: exact; height: 50px; border-left: 1px solid #143249; border-right: 1px solid #143249;} th:nth-child(even){ background-color:#143249; color-adjust: exact; /*firefox & IE */ -webkit-print-color-adjust: exact;height: 50px;border-left: 1px solid #143249; border-right: 1px solid #143249;}.status { font-family: "Open Sans", sans-serif; font-size: 12px; font-weight: 800; font-stretch: normal; font-style: normal; line-height: normal; letter-spacing: normal; color: #ffffff; background-color: #1b405c; -webkit-print-color-adjust: exact; height: 35px; display: flex; align-items: center; border-radius: 5px; margin-left: 10px; margin-right: 10px;  justify-content:center; }td{padding-bottom:10px}.tablecontent { font-family: "Open Sans", sans-serif; padding-top:10px; font-size: 14px; font-weight: normal; font-stretch: normal; font-style: normal; line-height: normal; letter-spacing: normal; color: #0e0e0e; text-align: center; word-wrap: break-word;}.userlist{font-family: "Open Sans", sans-serif;font-size: 18px;font-weight: bold;font-stretch: normal;font-style: normal;line-height: normal;letter-spacing: normal;color: #143249;padding-top: 2%;padding-left: 15px;padding-bottom: 5px;} </style></head><body><div style="overflow:visible"><div class="userlist">Group List</div>'
    prefix += '<table width="670" cellpadding="0" border="0" align="center" cellspacing="0" style="width:100%">'
    prefix += '<tr>'
    prefix += '<th><div class="status">GROUP NAME</div></th>'
    prefix += '<th><div class="status">NO OF USERS</div></th> '
    prefix += '<th><div class="status">COMPANY ID</div></th> '
    prefix += '<th><div class="status">STATUS</div></th><th></tr>'
    let print_data: any = [];
    if (this.group_checkedList.length != 0) {
      print_data = this.group_checkedList;
    } else {
      print_data = this.groupELEMENT_DATA;
    }

    print_data.forEach(element => {
      dynamic += '<tr>'
      dynamic += '<td><div class="tablecontent">' + element.groupName + ' </div</td>'
      dynamic += '<td><div class="tablecontent">' + element.noOfUsers + '</div></td>'
      dynamic += '<td><div class="tablecontent">' + element.companyId + '</div></td>'
      dynamic += '<td><div class="tablecontent">' + element.groupStatus + ' </div</td>'
      dynamic += '</tr>'
    });
    suffix += '</table>'
    closetag += '</div> </body></html>';
    var frame1 = document.createElement('iframe');
    frame1.name = "frame1";
    frame1.style.position = "absolute";
    frame1.style.top = "-1000000px";
    document.body.appendChild(frame1);
    var frameDoc = frame1.contentWindow;
    frameDoc.document.open();
    frameDoc.document.write(`<html><head><title></title>`);
    frameDoc.document.write('</head><body>');
    frameDoc.document.write(prefix + dynamic + suffix + closetag);
    frameDoc.document.write('</body></html>');
    frameDoc.document.close();
    var instance = this;
    setTimeout(function () {
      window.frames["frame1"].focus();
      window.frames["frame1"].print();
      document.body.removeChild(frame1);
    }, 500);
    return false;
  }


  CreateGroups() {
    let data = "Users >" + 'Create group';
    // route to particular component but set menutitle as Users as Users sidemenu has to be selected
    this.router.navigate(['user/Create_group'], { queryParams: { menuTitle: 'Users' } });
    // set the local storage and call the header component to set the breadcrumb.
    localStorage.setItem("selectTabIndexName", 'Create group');
    localStorage.setItem("selectTabBreadCrumbs", data);
    setTimeout(() => {
      this.headerservice.updateHeaderBreadcrumb("Users", data);
    }, 500);
  }

  cancelbtn() {
    localStorage.removeItem('group_filterapibodycontent');
    // if filterdata not present --- on first load cancel - clear data
    this.dialogclose.close({ method: 'close' })
  }
  search(searchinput) {
    let cacheddata = localStorage.getItem('role');
    this.compId = localStorage.getItem('procCompId');
      let body
      if (cacheddata === "System Administrator") {
        let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
        if(globalcompanyIDselected=='' || globalcompanyIDselected==undefined || globalcompanyIDselected==null){
          body = {
            'template': true,
            "owner": "",
            "formId": searchinput
          }
          }
          else{
            body = {
              'template': true,
              "owner": globalcompanyIDselected,
              "formId": searchinput
            }
          }
      }
      else {
        body = {
          'template': true,
          "owner": this.compId,
          "formId": searchinput
        }
      }
    localStorage.setItem("group_filterapibodycontent", JSON.stringify(body));
    this.group_filterapipaginationcall(body)
  }
  clearTable(searchinput) {
    this.searchvalue = searchinput;
    if (searchinput === "") {
      let cacheddata = localStorage.getItem('role');
      this.compId = localStorage.getItem('procCompId');
        let body
        if (cacheddata === "System Administrator") {
          let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
          if(globalcompanyIDselected=='' || globalcompanyIDselected==undefined || globalcompanyIDselected==null){
            body = {
              'template': true,
              "owner": "",
              "formId": searchinput
            }
            }
            else{
              body = {
                'template': true,
                "owner": globalcompanyIDselected,
                "formId": searchinput
              }
            }
        }
        else {
          body = {
            'template': true,
            "owner": this.compId,
            "formId": searchinput
          }
        }
      this.group_filterapipaginationcall(body)
    }
  }
  launch() {

  }

  editFromDetails(formId, version) {
    localStorage.setItem("formId", formId);
    localStorage.setItem("versiongETAPI", version);
    localStorage.setItem("frompage", 'view');
    this.apiService.user_details('', 'Edit');

    let data = "Assignable Content > Form Details";
    // route to particular component but set menutitle as Users as Users sidemenu has to be selected
    this.router.navigate(['AssignableContent/Formstemplate'], { queryParams: { menuTitle: 'AssignableContent' } });

    // set the local storage and call the header component to set the breadcrumb.
    localStorage.setItem("selectTabIndexName", 'Form Details');
    localStorage.setItem("selectTabBreadCrumbs", data);

    setTimeout(() => {
      this.headerservice.updateHeaderBreadcrumb("Form Details", data);
    }, 500);
    this.cancelbtn()
  }

  EnterSubmit(event) {
    if (event.keyCode === 13) {
      this.search(this.searchvalue);
      event.preventDefault();
    }
  }

}