<div class="spinner_overlay" *ngIf="loadspinner === true"></div>
<mat-spinner class="spinner" *ngIf="loadspinner === true"></mat-spinner>

<div class="filtermaincontainer" (keydown)="EnterSubmit($event)">
  <div class="row headerrow">
    <div class="col-10">
      <img src="assets/images/filter-filled-tool-symbol.svg" alt="filter" />
      <span class="heading">&nbsp;&nbsp;Filter</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()"
        >clear</mat-icon
      >
    </div>
  </div>

  <form [formGroup]="filterform">
    <div class="row">
      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Login</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #input1 (keyup)="input1changename(input1.value)" class="textinputusertablefilter"
            formControlName="login">
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Name</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #input2 (keyup)="input2changename(input2.value)" class="textinputusertablefilter"
            formControlName="name">
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Emp ID</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #input3 (keyup)="input3changename(input3.value)" class="textinputusertablefilter"
            formControlName="empId">
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Requirement Name</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #input4 (keyup)="input4changename(input4.value)" class="textinputusertablefilter"
            formControlName="requirementName">
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Task Name</mat-label>
        <mat-form-field appearance="outline" id="formfieldusernameid" class="formfieldusertable">
          <input matInput placeholder="Enter here" autocomplete="off" #input5 (keyup)="input5changename(input5.value)" class="textinputusertablefilter"
            formControlName="taskName">
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Status</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select
            formControlName="status"
            placeholder="Status"
            panelClass="myPanelmatselectClass"
            class="textinputusertablefilter"
            (selectionChange)="selectStatus($event.value)"
            disableOptionCentering
          >
            <mat-option [value]="" class="none_style"> None </mat-option>
            <mat-option
              *ngFor="let item of statuslist"
              [value]="item.statusName"
            >
              <div class="matoptiontxt">{{ item.statusName }}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-6 col-md-6 inputcolumn">
        <mat-label id="usernameid" class="labelname">Action</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select formControlName="action" placeholder="Action" panelClass="myPanelmatselectClass" class="textinputusertablefilter"
          (selectionChange)=selectAction($event.value) disableOptionCentering>
            <mat-option [value]="" class="none_style">  None </mat-option>
            <mat-option *ngFor="let item of actionlist" [value]="item.actionName">
              <div class="matoptiontxt">{{item.actionName}}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  </div>

  
    <div class="row">
      <div class="col-6 cancelbtncol">
        <br /><br />
        <button mat-button id="btncancelid" class="cancelbtn" (click)="cancelbtn()" >
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel
        </button>
      </div>
      <div class="col-6 Filterbtncol">
        <br /><br />
        <button mat-button id="btnfiltertermid" class="Filterbtn" [disabled]="disablebutton" (click)="Filter()">
          <mat-icon class="filterbtnicon">done</mat-icon>
          Filter
        </button>
      </div>
    </div>
  </form>
</div>
