import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { LayoutComponent } from './layout/layout.component';
import { ScromExitModule } from './Authentication/scrom-exit/scrom-exit.module';

const routes: Routes = [
  {
  path: '',
  redirectTo: 'login',
  pathMatch: 'full'
  },

  {
    path: 'login',
    loadChildren: () => import('./Authentication/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'SignUp',
    loadChildren: () => import('./Authentication/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'Confirm_Signup',
    loadChildren: () => import('./Authentication/confirm-signup/confirm-signup.module').then(m => m.ConfirmSignupModule)
  },
  {
    path: 'ForgotPassword',
    loadChildren: () => import('./Authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'SetPassword',
    canActivate: [AuthGuard],
    loadChildren: () => import('./Authentication/set-password/set-password.module').then(m => m.SetPasswordModule)
  },
  {
    path: 'learner_exammanagement',
    loadChildren: () => import('./Learner/assignments/exammanagement/exammangement.module').then(m => m.ExammangementModule)
  },
  {
        path:'ScromExit',
        loadChildren: () => import('./Authentication/scrom-exit/scrom-exit.module').then(m=>ScromExitModule)
      },

  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      {
        path:'Home',
        loadChildren: () => import('./Administrator/home/home.module').then(m => m.HomeModule)
      },
      {
        path:'AccessDenied',
        loadChildren: () => import('./Administrator/access-denied/access-denied.module').then(m => m.AccessDeniedModule)
      },
      {
        path:'user',
        loadChildren: () => import('./Administrator/user/user.module').then(m => m.UserModule)
      },
      {
        path:'AssignableContent',
        loadChildren: () => import('./Administrator/assignable-content/assignable-content.module').then(m => m.AssignableContentModule)
      },

      {
        path:'CompanyManagement',
        loadChildren: () => import('./Administrator/company-management/company-management.module').then(m=>m.CompanyManagementModule)
      },
      {
        path:'ExamManagement',
        loadChildren: () => import('./Administrator/exam-management/exam-management.module').then(m=>m.ExamManagementModule)
      },
      {
        path:'Reports',
        loadChildren: () => import('./Administrator/reports/reports.module').then(m=>m.ReportsModule)
      },
      {
        path:'LearnerHome',
        loadChildren: () => import('./Learner/learner-home/learner-home.module').then(m=>m.LearnerHomeModule)
      },
      {
        path:'Assignments',
        loadChildren: () => import('./Learner/assignments/assignments.module').then(m=>m.AssignmentsModule)
      },
      {
        path:'LearnerTask',
        loadChildren: () => import('./Learner/learner-tasks/learner-tasks.module').then(m=>m.LearnerTasksModule)
      },
      {
        path:'LearnerTranscripts',
        loadChildren: () => import('./Learner/learner-transcripts/learner-transcripts.module').then(m=>m.LearnerTranscriptsModule)
      },

      {
        path:'LearnerCalendar',
        loadChildren: () => import('./Learner/learner-calendar/learner-calendar.module').then(m=>m.LearnerCalendarModule)
      },

      {
        path:'Tasks',
        loadChildren: () => import('./Administrator/tasks/tasks.module').then(m=>m.TasksModule)
      },
      {
        path:'Announcements',
        loadChildren: () => import('./Administrator/announcements/announcements.module').then(m=>m.AnnouncementsModule)
      },
      {
        path:'Adminsetting',
        loadChildren: () => import('./Setting/settingpage/settingpage.module').then(m=>m.SettingpageModule)
      },
      {
        path:'Proctor',
        loadChildren: () => import('./Proctor/CourseList/proctor.module').then(m=>m.proctorModule)
      },
      {
        path:'ProctorHome',
        loadChildren: () => import('./Proctor/proctorhome/proctorhome.module').then(m=>m.ProctorHomeModule)
      },
      {
        path:'AssignedUser',
        loadChildren: () => import('./Evaluator/assigned-user/assigned-user.module').then(m => m.AssignedUserModule)
      },
      {
        path:'HelpAdmin',
        loadChildren: () => import('./Administrator/admin-help/admin-help.module').then(m => m.AdminHelpModule)
      },
      
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { scrollPositionRestoration: 'top' }
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
