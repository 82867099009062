
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, Observable, Subscription } from 'rxjs';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  a: any;
  menuTitle: any;
  screenwidth: any;
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription;
  params: any;

  constructor(private activatedRoute: ActivatedRoute, private router: Router,) {
    this.menuTitle = this.activatedRoute.snapshot.paramMap.get('menuTitle');
  }

  ngOnInit() {
    this.screenwidth = window.innerWidth;
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
      this.screenwidth = evt.target["innerWidth"];
    })
  }

  // get search-input from shared header
  // Header -- function for search and select value
  searchInput(value) {
    this.commonfunction();
  }

  SelectCompanylist(value) {
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.params=params;
      if(this.params.params.menuTitle === "Home"){
        this.commonfunction();
      }
      else{
        this.router.navigate(['Home'], { queryParams: { menuTitle: 'Home' } });
      }
    })

  }

  commonfunction() {
    // cache value for display breadcrumb and refresh component
    let data = localStorage.getItem("selectTabIndexName");
    let data1 = localStorage.getItem("selectTabBreadCrumbs");
    let selectTabIndex= localStorage.getItem("selectTabIndex");

    let currentUrl = this.router.url.split("?");
    let menutitle = this.router.url.split("=");
    
    
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    this.router.navigate([currentUrl[0]], { queryParams: { menuTitle: menutitle[1] } });
    localStorage.setItem("selectTabIndex", selectTabIndex);
    localStorage.setItem("selectTabIndexName", data);
    localStorage.setItem("selectTabBreadCrumbs", data1);

  }
}
