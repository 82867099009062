<div class="spinner_overlay" *ngIf="loadspinner === true"></div>
<mat-spinner class="spinner" *ngIf="loadspinner === true"></mat-spinner>

<div class="filtermaincontainer" (keydown)="EnterSubmit($event)">
  <div class="row headerrow">
    <div class="col-10">
      <img src="assets/images/filter-filled-tool-symbol.svg" alt="filter" />
      <span class="heading">&nbsp;&nbsp;Filter</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()"
        >clear</mat-icon
      >
    </div>
  </div>

  <form [formGroup]="filterform">
    <div class="row">
      <div class="col-12 col-md-12 inputcolumn">
        <mat-label id="usernameid" class="labelname">Course Name</mat-label>
        <mat-form-field
          appearance="outline"
          id="formfieldusernameid"
          class="formfieldusertable"
        >
          <input
            matInput
            placeholder="Enter here"
            autocomplete="off"
            #inpID0
            (keyup)="inputchangeID0(inpID0.value)"
            class="textinputmedium"
            formControlName="Name"
          />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-12 inputcolumn">
        <mat-label id="usernameid" class="labelname">Type</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select
            formControlName="Type"
            placeholder="Type"
            class="inputClass"
            panelClass="myPanelmatselectClass"
            (selectionChange)="selectTypeName($event.value)"
            disableOptionCentering
          >
            <mat-option [value]="" class="none_style"> None </mat-option>
            <mat-option *ngFor="let item of typelist" [value]="item">
              <div class="matoptiontxt">{{ item }}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-12 inputcolumn">
        <mat-label id="usernameid" class="labelname">Status</mat-label>
        <mat-form-field appearance="outline" class="formfieldusertable">
          <mat-select
            formControlName="Status"
            placeholder="Status"
            class="inputClass"
            panelClass="myPanelmatselectClass"
            (selectionChange)="selectStatusName($event.value)"
            disableOptionCentering
          >
            <mat-option [value]="" class="none_style"> None </mat-option>
            <mat-option *ngFor="let item of statuslist" [value]="item">
              <div class="matoptiontxt">{{ item }}</div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-12 inputcolumn">
        <mat-label class="labelname">Completion Date Range</mat-label><br />
        <mat-form-field
          floatLabel="always"
          appearance="none"
          class="formfieldusertable"
        >
          <mat-date-range-input
            [rangePicker]="picker"
            (click)="picker.open()"
            [max]="today"
            style="cursor: pointer"
            class="textinputdate"
            placeholder="mm/dd/yyyy"
            readonly
          >
            <input
              matStartDate
              placeholder="mm/dd/yyyy"
              formControlName="Completionstart"
              (dateChange)="startChange($event)"
              style="cursor: pointer"
              readonly
            />
            <input
              matEndDate
              placeholder="mm/dd/yyyy"
              formControlName="Completionend"
              (dateChange)="endChange($event)"
              style="cursor: pointer"
              readonly
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div class="col-12 col-md-12 inputcolumn">
        <mat-label class="labelname">Expiration Date Range</mat-label><br />
        <mat-form-field
          floatLabel="always"
          appearance="none"
          class="formfieldusertable"
        >
          <mat-date-range-input
            [rangePicker]="picker1"
            (click)="picker1.open()"
            [max]="today"
            style="cursor: pointer"
            class="textinputdate"
            placeholder="mm/dd/yyyy"
            readonly
          >
            <input
              matStartDate
              placeholder="mm/dd/yyyy"
              formControlName="Expiredstart"
              (dateChange)="startChangeApproveddenied($event)"
              style="cursor: pointer"
              readonly
            />
            <input
              matEndDate
              placeholder="mm/dd/yyyy"
              formControlName="Expiredend"
              (dateChange)="endChangeApproveddenied($event)"
              style="cursor: pointer"
              readonly
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker1></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6 cancelbtncol">
        <br /><br />
        <button
          mat-button
          id="btncancelid"
          class="cancelbtn"
          (click)="cancelbtn()"
        >
          <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
          Cancel
        </button>
      </div>
      <div class="col-6 Filterbtncol">
        <br /><br />
        <button
          mat-button
          id="btnfiltertermid"
          class="Filterbtn"
          [disabled]="disablebutton"
          (click)="Filter()"
        >
          <mat-icon class="filterbtnicon">done</mat-icon>
          Filter
        </button>
      </div>
    </div>
  </form>
</div>
