import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanyManagementComponent } from 'src/app/Administrator/company-management/company-management.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';


@Component({
  selector: 'app-managenotificationsfilter',
  templateUrl: './managenotificationsfilter.component.html',
  styleUrls: ['./managenotificationsfilter.component.scss']
})
export class ManagenotificationsfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  apibodycontent: any;
  disablebutton: any;
  isDisabled: boolean;
  backupcompanylistdata:any;
  constructor(private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<CompanyManagementComponent>) {
    this.filterform = this.formBuilder.group({
      CompanyName: [''],
      CompanyId: ['']
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
  }


  apicallgetdefaultdropdown() {
    // get default value on load companylist -- api call
    this.loadspinner = true;
    const endpoint1 = 'lookup';
    this.apiService.Simpleget(endpoint1, 'GET')
      .subscribe((response) => {
        this.loadspinner = false;
        this.companylistdata = response["companies"];
        this.backupcompanylistdata=this.companylistdata; 
        this.statuslist = [
          { "status_id": 1, "statusName": "Enabled" },
          { "status_id": 2, "statusName": "Disabled" },
        ]
        this.setdefaultdropdownvalues();
      },
        (err) => {
          this.loadspinner = false;
          

        });
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton = true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton = false;
      let data = JSON.parse(cacheddata);
    
      this.filterform.controls['CompanyId'].setValue(data["Company (COID)"]);
     
      this.clearfilterfunction();
    }
    let compId = localStorage.getItem('procCompId');
    let compName = localStorage.getItem('proccompanyName');
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      let data = compName + ' ' + '(' + compId + ')'
      this.filterform.controls['CompanyId'].setValue(data);
      this.isDisabled = true;
      this.disablebutton = false;
    }
    else {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      let globalcompanynameselected = localStorage.getItem('globalcompanynameselected');
      if (globalcompanyIDselected == '' || globalcompanyIDselected == undefined || globalcompanyIDselected == null) {
        this.isDisabled = false;
      }
      else {
        let data = globalcompanynameselected + ' ' + '(' + globalcompanyIDselected + ')'
        this.filterform.controls['CompanyId'].setValue(data);
        this.isDisabled = true;
        this.disablebutton = false;
      }
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
       if (clearfilteritemkey[i] === "Company (COID)") {
          this.filterform.controls['CompanyId'].setValue("");
        }
       
        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  selectCompanyID(value) {
      // update company list to default
    let divID= document.getElementById("inputpagenocompany");
    if(divID !=null && divID !=undefined){
    (document.getElementById("inputpagenocompany") as HTMLInputElement).value = "";
    }
    this.companylistdata = this.backupcompanylistdata;

    if (value === "" || value === undefined || value === null) {
      // if none select set value as ''
      this.filterform.controls['CompanyId'].setValue("");
      this.dynamicEnableDisablebtn();
    }
    else {
      this.disablebutton = false;
    }
  }

 

  // on keyup enable filter button
  inputchangeCompanyName(value) {
    this.dynamicEnableDisablebtn();
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }


  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('filterdata');
      localStorage.removeItem('chipdisplayfilterdata');
      localStorage.removeItem('clearfilteritemkey');
      let filterdata = this.getbodycontent();
      localStorage.setItem('filterdata', JSON.stringify(filterdata));
      this.dialog.close({ data: filterdata, apibodycontent: this.apibodycontent, method: 'filter' });
    }

  }

  EnterSubmit(event) {
    if (event.keyCode === 13) {
      this.dynamicEnableDisablebtn();
      if (this.disablebutton === true) {
        event.preventDefault();
      }
      else {
        event.preventDefault();
        this.Filter();
      }

    }
  }

  getbodycontent() {
    let CompanyID = "";
    let rolecacheddata = localStorage.getItem('role');
    if (rolecacheddata != "System Administrator") {
      CompanyID = "";
    }
    else {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if (globalcompanyIDselected == '' || globalcompanyIDselected == undefined || globalcompanyIDselected == null) {
        CompanyID = this.filterform.value.CompanyId
      }
      else {
        CompanyID = "";
      }
    }

    let filterdata = {
     
      "Company (COID)": CompanyID,
     
    }
    this.getapibodycontent();
    return filterdata

  }

  getapibodycontent() {
    let companylistdataid = "";
    for (let i = 0; i < this.companylistdata.length; i++) {
      if (this.companylistdata[i].companyName + ' ' + '(' + this.companylistdata[i].companyId + ')' === this.filterform.value.CompanyId) {
        companylistdataid = this.companylistdata[i].companyId;
      }
    }
   
    this.apibodycontent = {
      "companyName": '',
      "companyId": companylistdataid,
    }
  }


  checkformvalue() {
    let formempty;
    if (this.filterform.value.CompanyName.trim() === "" && this.filterform.value.CompanyId.trim() === "" ) {
      formempty = true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }

  onKeyCompany(value) {
    if(value.trim().length===0){
      this.companylistdata=this.backupcompanylistdata;
    }
    else{
      const filterValue = value.toLowerCase();
      let data = this.backupcompanylistdata.filter(option => option.companyName.toLowerCase().includes(filterValue) ||
      option.companyId.toLowerCase().includes(filterValue));
      this.companylistdata = data;
    }
  }

  onKeyDownCompany(event) {
    // to disable mat select default search functionality
    event.stopImmediatePropagation();
  }

}

