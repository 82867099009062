import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';
import { CommondataserviceService } from "../../../Services/commondataservice.service";

@Component({
  selector: 'app-assignedlistfilter',
  templateUrl: './assignedlistfilter.component.html',
  styleUrls: ['./assignedlistfilter.component.scss'],
  providers: [DatePipe]
})
export class AssignedlistfilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton:any;
  today = new Date();
  data_details: any;
  dataList: any;
  companyId: any;
  groupName: any;
  category: any = [""];
  type: any = [""];

  constructor(private datePipe: DatePipe,private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>,private CommondataserviceService:CommondataserviceService) {
    this.data_details = this.CommondataserviceService.getdetails();
    this.groupName = (this.data_details['data2'])
    this.filterform = this.formBuilder.group({
      title: [''],
      Category: [''],
      CompanyType: [''],
      Startdate: [''],
      Enddate: [''],
      CompanyStatus: [''],
    });
    this.apicallgetdefaultdropdown();
  }

  ngOnInit(): void {
  }

  dateAddedFrom(value) {
    this.dynamicEnableDisablebtn();
  }
  dateAddedTo(value) {
    this.dynamicEnableDisablebtn();
  }

  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('groupfilterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton=true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton=false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['title'].setValue(data["Title"]);
      this.filterform.controls['Category'].setValue(data["Category"]);
      this.filterform.controls['CompanyType'].setValue(data["Type"]);
      this.filterform.controls['CompanyStatus'].setValue(data["Status"]);
         // for date range

         let a = data["Earlier Expiration Date"].split('-')[0];
         let b = data["Earlier Expiration Date"].split('-')[1];
        
         if (data["Earlier Expiration Date"] === null || data["Earlier Expiration Date"] === undefined || data["Earlier Expiration Date"] === "") {
           this.filterform.controls['Startdate'].setValue("");
           this.filterform.controls['Enddate'].setValue("");
         } else {
           this.filterform.controls['Startdate'].setValue(new Date(a));
           this.filterform.controls['Enddate'].setValue(new Date(b));
         }
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if(clearfilteritemkey[i]==="Title"){
          this.filterform.controls['title'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Category"){
          this.filterform.controls['Category'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Type"){
          this.filterform.controls['CompanyType'].setValue("");
        }
        else if(clearfilteritemkey[i]==="Status"){
          this.filterform.controls['CompanyStatus'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Earlier Expiration Date") {
          this.filterform.controls['Startdate'].setValue("");
          this.filterform.controls['Enddate'].setValue("");
        }
        else{
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  apicallgetdefaultdropdown() {
    
        this.statuslist = [
          { "status_id": 1, "statusName": "Not Started" },
          { "status_id": 2, "statusName": "Completed" },
          { "status_id": 3, "statusName": "Failed" },
          { "status_id": 4, "statusName": "In Progress" },
          { "status_id": 5, "statusName": "Passed" },
          { "status_id": 6, "statusName": "Expired" }
        ]
        this.setdefaultdropdownvalues();

        this.category = [
          { "categoryName": "eCourse" },
          { "categoryName": "Exam" },
        ]
        this.type = [
          {"typeName": "TNG" },
          {"typeName": "KNT" },
          {"typeName": "Video" },
          {"typeName": "Other" }
        ]
  }


  // on keyup enable filter button
  inputchangegroupname(value){
   this.dynamicEnableDisablebtn()
  }

  inputchangegroupID(value){
  this.dynamicEnableDisablebtn()
  }


  selectStatusName(value){
    if(value==="" || value === undefined || value===null){
      // if none select set value as ''
      this.filterform.controls['CompanyStatus'].setValue("");
      this.dynamicEnableDisablebtn()
    }
    else{
      this.disablebutton=false;
    }
  }

  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('group_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('group_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }



  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('groupfilterdata');
      localStorage.removeItem('group_chipdisplayfilterdata');
      localStorage.removeItem('group_clearfilteritemkey');
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('groupfilterdata', JSON.stringify(groupfilterdata));
      this.dialog.close({ data: groupfilterdata, groupapibodycontent: this.groupapibodycontent, method: 'filter' });
    }

  }

  getbodycontent() {
    let daterange="";
    let startdate="";
    let enddate="";
    let apistartdate="";
    let apienddate="";
    if(this.filterform.value.Startdate==="" || this.filterform.value.Startdate===undefined || this.filterform.value.Startdate===null){
      daterange="";
    }
    else{
     startdate=this.datePipe.transform(this.filterform.value.Startdate, 'MM/dd/yyyy');
     enddate=this.datePipe.transform(this.filterform.value.Enddate, 'MM/dd/yyyy');
     daterange=startdate + " - " + enddate;
     apistartdate=this.datePipe.transform(this.filterform.value.Startdate, 'yyyy-MM-dd');
     apienddate=this.datePipe.transform(this.filterform.value.Enddate, 'yyyy-MM-dd');

    }
    
    let groupfilterdata = {
      "Title": this.filterform.value.title.trim(),
      "Category": this.filterform.value.Category.trim(),
      "Type": this.filterform.value.CompanyType.trim(),
      "Status": this.filterform.value.CompanyStatus.trim(),
      "Earlier Expiration Date":  daterange,
      
    }
    this.getapibodycontent(apistartdate,apienddate);
    return groupfilterdata

  }

  getapibodycontent(apistartdate,apienddate) {
    let loginName;
    if(localStorage.getItem("currentloginId") == null){
      loginName = localStorage.getItem('loginId');
    } else {
      loginName = localStorage.getItem("currentloginId");
    }
    this.groupapibodycontent = {
      "title":this.filterform.value.title.trim(),
      "category": this.filterform.value.Category.trim(),
      "type": this.filterform.value.CompanyType.trim(),
      "courseStatus":this.filterform.value.CompanyStatus.trim(),
      "loginName":loginName,
      "courseId":"",
      "startExpirationDate":apistartdate,
      "endExpirationDate":apienddate
    }
  }

  checkformvalue(){

    let formempty;
    if(this.filterform.value.Startdate != ""){
      if(this.filterform.value.Enddate === null){
        formempty=true;
        return formempty;
      }
    }
    if(this.filterform.value.title.trim()==="" && this.filterform.value.Category.trim()==="" && 
    this.filterform.value.CompanyType.trim()==="" && this.filterform.value.CompanyStatus.trim()==="" && this.filterform.value.Startdate==="" &&  this.filterform.value.Enddate === ""){
        formempty=true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn(){
    let checkformfield= this.checkformvalue();
    if(checkformfield===true ){
      this.disablebutton=true;
    }
    else{
      this.disablebutton=false;
    }
  }

  EnterSubmit(event){
    
    if(event.keyCode === 13){
     this.dynamicEnableDisablebtn();
     if(this.disablebutton===true){
      event.preventDefault();
     }
     else{
      event.preventDefault();
      this.Filter();
     }

    }
  }

  changeStartDate(event) {
  }
   changeEndDate(event) {
    
    if(event.value === null){
      this.disablebutton=true;
    }
    else{
     this.dynamicEnableDisablebtn();
    }
}

selectCategory(value){
  if(value==="" || value === undefined || value===null){
    // if none select set value as ''
    this.filterform.controls['Category'].setValue("");
    this.dynamicEnableDisablebtn()
  }
  else{
    this.disablebutton=false;
  }
}

selectType(value){
  if(value==="" || value === undefined || value===null){
    // if none select set value as ''
    this.filterform.controls['CompanyType'].setValue("");
    this.dynamicEnableDisablebtn()
  }
  else{
    this.disablebutton=false;
  }
}
}