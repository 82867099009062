import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScromExitRoutingModule } from './scrom-exit-routing.module';
import { ScromExitComponent } from './scrom-exit.component';
import { MaterialModuleModule } from 'src/app/shared/sharedmodule/material-module.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedmoduleModule } from 'src/app/shared/sharedmodule/sharedmodule.module';


@NgModule({
  declarations: [ScromExitComponent],
  imports: [
    CommonModule,
    ScromExitRoutingModule,
    MaterialModuleModule,
  ]
})
export class ScromExitModule { }
