<div class="spinner_overlay" *ngIf="loadspinner === true"></div>
<mat-spinner class="spinner" *ngIf="loadspinner === true"></mat-spinner>

<div class="addremovequestionscontainer" (keydown)="EnterSubmit($event)">
  <div class="row headerrow">
    <div class="col-10">
      <img src="assets/images/exam-mgmt-copy.svg" alt="filter" />
      <span class="heading">&nbsp;&nbsp;Add/Remove Questions</span>
    </div>

    <div class="col-2 cleariconcol">
      <mat-icon class="clearicon" alt="clear" (click)="cancelbtn()"
        >clear</mat-icon
      >
    </div>
  </div>

  <div class="row">
    <div class="col-6 sub-label">
      <span>List of Question Pool</span>
    </div>
    <div class="col-6">
      <mat-form-field
        appearance="outline"
        class="matformfieldheader header-field"
      >
        <input
          type="text"
          #searchinput
          matInput
          [(ngModel)]="filterValue"
          (keyup)="inputchangeLogin(searchinput.value)"
          class="textsearchinput"
          placeholder="Search..."
          autocomplete="off"
        />
        <mat-icon
          matSuffix
          (click)="search(searchinput.value)"
          class="searchbtn"
          style="
            position: absolute;
            top: -25px !important;
            right: 0px;
            cursor: pointer;
          "
          >search</mat-icon
        >
      </mat-form-field>
    </div>
  </div>
  <br />
  <div
    class="row"
    style="display: block; margin-right: -24px; margin-left: -24px"
  >
    <div class="tableheight">
      <table
        mat-table
        [dataSource]="dataSource"
        #usertable
        style="width: 100%"
        matSort
      >
        <ng-container matColumnDef="POOL NAME">
          <th mat-header-cell *matHeaderCellDef class="headerdata">
            <div class="status">
              <span class="spanstatus">POOL NAME</span>
              &nbsp; &nbsp;
              <mat-icon
                class="arrowupward"
                alt="arrowupward"
                (click)="arrow_upward('string', 'questPoolName', user_ids[0])"
                id="{{ user_ids[0] }}"
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="downupward"
                alt="arrowdownward"
                (click)="arrow_downward('string', 'questPoolName', user_ids[1])"
                id="{{ user_ids[1] }}"
                >arrow_downward</mat-icon
              >
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="tablecontent" style="cursor: pointer">
              {{ element.questPoolName }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="TOTAL NUMBER OF QUESTIONS">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="headerdata"
            style="width: 280px"
          >
            <div class="status" style="width: 280px">
              <span class="spanstatus">TOTAL NUMBER OF QUESTIONS</span>

              &nbsp;
              <mat-icon
                class="arrowupward"
                alt="arrowupward"
                (click)="
                  arrow_upward('string', 'totalNumbeOfQuestions', user_ids[2])
                "
                id="{{ user_ids[2] }}"
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="downupward"
                alt="arrowdownward"
                (click)="
                  arrow_downward('string', 'totalNumbeOfQuestions', user_ids[3])
                "
                id="{{ user_ids[3] }}"
                >arrow_downward</mat-icon
              >
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="tablecontent" style="cursor: pointer; width: 280px">
              {{ element.totalNumbeOfQuestions }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="SELECT QUESTIONS">
          <th mat-header-cell *matHeaderCellDef class="headerdata">
            <div class="status">
              <span class="spanstatus">SELECT QUESTIONS</span>
              &nbsp;
            </div>
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <div class="tablecontent" style="text-align: center !important">
              <mat-form-field
                appearance="outline"
                id="formfieldusernameid"
                class="formfieldusertable"
              >
                <input
                  matInput
                  type="number"
                  [ngClass]="
                    isDisabledselectedQuestions == true ? 'disableClass' : ''
                  "
                  placeholder=""
                  [readonly]="isDisabledselectedQuestions"
                  autocomplete="off"
                  [id]="element.selectedQuestions + i"
                  #inpTitleWeight
                  [(ngModel)]="element.selectedQuestions"
                  (blur)="
                    blurTitkeweight(
                      inpTitleWeight.value,
                      element.selectedQuestions + i,
                      i
                    )
                  "
                  (keyup)="
                    inputchangeinpTitleWeight(
                      inpTitleWeight.value,
                      element.selectedQuestions + i,
                      i
                    )
                  "
                  class="inputClassText"
                />
              </mat-form-field>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          style="background-color: #143249"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>

  <div class="row" *ngIf="!isDisabledselectedQuestions">
    <div class="col-6 cancelbtncol">
      <br /><br />
      <button
        mat-button
        id="btncancelid"
        class="cancelbtn"
        (click)="cancelbtn()"
      >
        <mat-icon class="Cancelicon" alt="clear">clear</mat-icon>
        Cancel
      </button>
    </div>
    <div class="col-6 Filterbtncol">
      <br /><br />
      <button
        mat-button
        id="btnfiltertermid"
        class="Filterbtn"
        [disabled]="disablebutton"
        (click)="save()"
      >
        <mat-icon class="filterbtnicon">done</mat-icon>
        Save
      </button>
    </div>
  </div>
</div>
