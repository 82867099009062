import { Component, EventEmitter, OnInit, Inject, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiServiceService } from '../../../Services/api-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import domtoimage from 'dom-to-image';
import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform
} from 'ngx-image-cropper'
import { DialogpopupComponent } from '../../dialog-popup/dialog-popup.component';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-uploadimage',
  templateUrl: './uploadimage.component.html',
  styleUrls: ['./uploadimage.component.scss']
})
export class UploadimageComponent implements OnInit {
  imageURL:any;
  title = 'angular-image-uploader';
  imageChangedEvent: any = '';
  backup_imageChangedEvent: any='';
  croppedImage: any ;
  from:any='';
  fileToUpload1:any='';
  fileToUpload:any='';
  edit=false;
  nocrop=true;
  imagecropped=false;
  prev_image:any='';
  backup_croppedimage:any='';
  backup_originalimg:any='';
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialog: MatDialogRef<UploadimageComponent>, public formBuilder: FormBuilder, public dialogopen: MatDialog,private sanitizer: DomSanitizer) {  
   
   this.from=data['from'];
   let url1:any=data['file'];
   let url2:any=data['sel_image'];

 if(url1 == ''){
   this.nocrop=false;
 }
 if(url1.size == undefined){
 if(url1 != ''){
 if(url1.includes('data:')){
  this.imageChangedEvent=data['file'];
  this.backup_originalimg=data['file']
 }else{
   if(url1.includes('https://')){
 this.converttobase64_file(url1).then(dataUrl => {
  this.imageChangedEvent=dataUrl;
  this.backup_imageChangedEvent=dataUrl;
  this.backup_originalimg=dataUrl;
})
   }else{
     url1="https://"+url1;
     this.converttobase64_file(url1).then(dataUrl => {
      this.imageChangedEvent=dataUrl;
      this.backup_imageChangedEvent=dataUrl;
      this.backup_originalimg=dataUrl;
    })
   }
 }
}else{
  this.imageChangedEvent='';
}
 }else{
   this.fileTobase64url1(url1);
 }
 if(url2.size == undefined){
   this.prev_image=url2;
if(url2 != ''){
 if(url2.includes('data:')){
  this.croppedImage=data['sel_image'];
  this.backup_croppedimage=data['sel_image']
 }else{
  if(url2.includes('https://')){
    setTimeout(() => {
 this.converttobase64_image(url2).then(dataUrl => {
  this.croppedImage=dataUrl;
  this.backup_croppedimage=dataUrl;
  this.imageChangedEvent=dataUrl;
  //console.log(this.croppedImage)
})
}, 1000);
  }else{
    url2="https://"+url2;
    setTimeout(() => {
    this.converttobase64_image(url2).then(dataUrl => {
      this.croppedImage=dataUrl;
      this.backup_croppedimage=dataUrl;
      this.imageChangedEvent=dataUrl;
      //console.log(this.croppedImage)
    })
  }, 1000);
  }
 }
}else{
  this.croppedImage='';
}
 }else{
this.fileTobase64url2(url2)
 }

 }
 
converttobase64_file = url => fetch(url
 )
.then(response => response.blob())
.then(blob => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onloadend = () => resolve(reader.result)
  reader.onerror = reject
  reader.readAsDataURL(blob)
}))

converttobase64_image = url => fetch(url)
.then(response => response.blob())
.then(blob => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onloadend = () => resolve(reader.result)
  reader.onerror = reject
  reader.readAsDataURL(blob)
}))

  ngOnInit(){

  }

  edit_image(){
   
    this.edit=true;
    if(this.imageChangedEvent == 'data:')
    this.imageChangedEvent = this.croppedImage; 
  }

  fileTobase64url1(file){
    this.getBase64(file).then(
      data =>{
        this.imagecropped=false;
      this.imageChangedEvent=data;
      this.backup_imageChangedEvent=data;
      this.backup_originalimg=data;
      });
  }

  fileTobase64url2(file){
    this.getBase64(file).then(
      data =>{
        this.imagecropped=false;
      this.croppedImage=data;
      this.prev_image=data;
      this.backup_croppedimage=data;
      });
  }

  onFileSelect(event: any): void {
   
     if (event.target.files.length > 0) {

      let file=event.target.files[0];
      if(file.size > 1048576){
        this.dialog.close();
        this.open_modal('Image size is too large', 'Maximum size allowed is 1 MB');
      }else{
        this.edit=true;
      this.getBase64(file).then(
        data =>{
          this.imagecropped=false;
        this.imageChangedEvent=data;
        });
      }
     }
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    
    this.imagecropped=true;
    this.croppedImage = event.base64;
    

  }

  scale(){

    this.imagecropped=true;
    this.croppedImage = this.imageChangedEvent; 
    
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }
  cancel_image(){
    let element = document.getElementById("imag-upload-container")
    element.classList.add('blur-content');
    const dialogRef = this.dialogopen.open(DialogpopupComponent, {
      width: '530px',
      height: '330px',
      disableClose: true,
      data:{
        from:'image-cancel'
      }
      });
    
      dialogRef.afterClosed().subscribe(result => {
        let element = document.getElementById("imag-upload-container")
        element.classList.remove('blur-content');
        });
    }
    image_cancel_no(){
    this.dialog.close();
    }

    save_image(){     
      this.uploadoriginalimage(this.imageChangedEvent); 
      
      }
      
      uploadoriginalimage(data) {
        
      const originalfileToUpload: File = new File([this.dataURItoBlob(data)], 'filename.jpeg', {type: "image/jpeg"});
      this.uploadAttachmentToServer( this.croppedImage, originalfileToUpload);
      }

      dataURItoBlob(dataURI): Blob {
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
      }
      dataURItoBlob1(dataURI): Blob {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
        }
      
      uploadAttachmentToServer(data, originalfileToUpload) {
  
      const fileToUpload: File = new File([this.dataURItoBlob(data)], 'filename.jpeg', {type: "image/jpeg"});
      
      if(fileToUpload.size > 1048576){
      
       this.open_modal('Image size is too large', 'Maximum size allowed is 1 MB');
      }else{
 
      this.dialog.close({ method:'upload', file:fileToUpload, crop_image:this.croppedImage, originalfile:originalfileToUpload})
      }
      
      }

      

      open_modal(title, subtitle){
        let element = document.getElementById("imag-upload-container")
        element.classList.add('blur-content');
        const dialogRef = this.dialogopen.open(DialogpopupComponent, {
          width: '530px',
          height: '330px',
          disableClose: true,
          data:{
            from:'image-size',
            title:title,
            subtitle:subtitle
          }
          });
        
          dialogRef.afterClosed().subscribe(result => {
           
           this.edit=false;
           this.croppedImage=this.backup_croppedimage;
           this.imageChangedEvent=this.backup_originalimg;
            let element = document.getElementById("imag-upload-container")
            element.classList.remove('blur-content');
            });
        }

        close(){
          this.dialog.close();
        }

      
        delete() {
         
          let title = 'Are you sure you want to delete?'
          let element = document.getElementById("imag-upload-container")
          element.classList.add('blur-content');
          let dialogRef = this.dialogopen.open(DialogpopupComponent, {
            width: '530px',
            height: '330px',
            disableClose: true,
            data:
            {
              title: title,
              subtitle: 'By clicking on delete, image will delete.',
              from: "delete"
            },
            backdropClass: 'deletePopup',
        
            position: {
              top: '27vh'
            }
          });
        
          dialogRef.afterClosed().subscribe(res => {
            // received data from dialog-component
            let element = document.getElementById("imag-upload-container")
            element.classList.remove('blur-content');
            if (res.method == 'delete') {
              this.dialog.close({ method:'delete', file:'', crop_image:'', originalfile:''})
            }
          })
        }

        public getSantizeUrl(url : string) {
          return this.sanitizer.bypassSecurityTrustUrl(url);
      }
}