import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserComponent } from 'src/app/Administrator/user/user.component';
import { ApiServiceService } from 'src/app/Services/api-service.service';

@Component({
  selector: 'app-tasklistnamefilter',
  templateUrl: './tasklistnamefilter.component.html',
  styleUrls: ['./tasklistnamefilter.component.scss']
})
export class TasklistnamefilterComponent implements OnInit {
  filterform: FormGroup;
  companylistdata: any = [""];
  statuslist: any = [""];
  loadspinner: any = false;
  groupapibodycontent: any;
  disablebutton: any;
  isDisabled: boolean;
  compId: string;
  taskListId: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, private formBuilder: FormBuilder, private apiService: ApiServiceService, public dialog: MatDialogRef<UserComponent>) {
    this.taskListId = data['managerName'];

    this.filterform = this.formBuilder.group({
      TaskName: [''],
      TaskID: ['']
    });
  }

  ngOnInit(): void {
    this.setdefaultdropdownvalues()
  }


  setdefaultdropdownvalues() {
    let cacheddata = localStorage.getItem('Manager_filterdata');
    if (cacheddata === '' || cacheddata === undefined || cacheddata === null) {
      this.disablebutton = true;
    }
    // if cached data present -- once filter btn was clicked
    else {
      this.disablebutton = false;
      let data = JSON.parse(cacheddata);
      this.filterform.controls['TaskName'].setValue(data["Task Name"]);
      this.filterform.controls['TaskID'].setValue(data["Task ID"]);
      this.clearfilterfunction();
    }
  }

  clearfilterfunction() {
    // if filterchip to remove filter is selcted remove the data on modal
    let clearfilteritemkey = JSON.parse(localStorage.getItem('Manager_clearfilteritemkey'));
    if (clearfilteritemkey === "" || clearfilteritemkey === undefined || clearfilteritemkey === null) {
    }
    else {
      for (let i = 0; i < clearfilteritemkey.length; i++) {
        if (clearfilteritemkey[i] === "Task Name") {
          this.filterform.controls['TaskName'].setValue("");
        }
        else if (clearfilteritemkey[i] === "Task ID") {
          this.filterform.controls['TaskID'].setValue("");
        }
        else {
          this.filterform.controls[clearfilteritemkey[i]].setValue("");
        }

      }
    }
  }

  inputchange0(value) {
    this.dynamicEnableDisablebtn()
  }

  inputchange1(value) {
    this.dynamicEnableDisablebtn()
  }



  cancelbtn() {
    // if filterdata not present --- on first load cancel - clear data
    let data = JSON.parse(localStorage.getItem('Manager_clearfilteritemkey'));
    if (data === "" || data === undefined || data === null) {
    }
    else {
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('Manager_filterdata', JSON.stringify(groupfilterdata));
      localStorage.removeItem('Manager_clearfilteritemkey');
    }
    this.dialog.close({ method: 'close' })
  }

  EnterSubmit(event) {
    if (event.keyCode === 13) {
      this.dynamicEnableDisablebtn();
      if (this.disablebutton === true) {
        event.preventDefault();
      }
      else {
        event.preventDefault();
        this.Filter();
      }

    }
  }


  Filter() {
    if (this.filterform.valid) {
      localStorage.removeItem('Manager_filterdata');
      localStorage.removeItem('Manager_chipdisplayfilterdata');
      localStorage.removeItem('Manager_clearfilteritemkey');
      let groupfilterdata = this.getbodycontent();
      localStorage.setItem('Manager_filterdata', JSON.stringify(groupfilterdata));
      this.dialog.close({ data: groupfilterdata, apibodycontent: this.groupapibodycontent, method: 'filter' });
    }

  }

  getbodycontent() {
    let groupfilterdata = {
      "Task Name": this.filterform.value.TaskName.trim(),
      "Task ID": this.filterform.value.TaskID.trim(),
    }
    this.getapibodycontent();
    return groupfilterdata

  }

  getapibodycontent() {
    this.compId = localStorage.getItem('procCompId');
    let cacheddata = localStorage.getItem('role');
    let body
    if (cacheddata === "System Administrator") {
      let globalcompanyIDselected = localStorage.getItem('globalcompanyIDselected');
      if (globalcompanyIDselected == '' || globalcompanyIDselected == undefined || globalcompanyIDselected == null) {
        this.groupapibodycontent = {
          "taskName": this.filterform.value.TaskName.trim(),
          "taskId": this.filterform.value.TaskID.trim(),
          "taskListId": this.taskListId,
        }
      }
      else {
        this.groupapibodycontent = {
          "taskName": this.filterform.value.TaskName.trim(),
          "taskId": this.filterform.value.TaskID.trim(),
          "companyId": "",
          "taskListId": this.taskListId,
        }
      }

    }
    else {
      this.groupapibodycontent = {
        "taskName": this.filterform.value.TaskName.trim(),
        "taskId": this.filterform.value.TaskID.trim(),
        "companyId": "",
        "taskListId": this.taskListId,
      }
    }
  }

  checkformvalue() {
    let formempty;
    if (this.filterform.value.TaskName.trim() === "" && this.filterform.value.TaskID.trim() === "") {
      formempty = true;
    }
    else {
      formempty = false;
    }
    return formempty;
  }

  dynamicEnableDisablebtn() {
    let checkformfield = this.checkformvalue();
    if (checkformfield === true) {
      this.disablebutton = true;
    }
    else {
      this.disablebutton = false;
    }
  }
}

