<div style="height: 100%;" class="sidebarcontainer">
  <div class="row sidebarlogorow">
    <div class="col-12">
      <div class="menutext">
        <img  *ngIf="companylogo=='https://uat.stellar-oqcs.com/assets/images/mea-logo.svg'" src="{{companylogo}}" alt="Stellar logo" class="sidemenulogoicon" style="width: 200px; height: 50px;margin-left: 10%;"/>
        <img  *ngIf="companylogo!='https://uat.stellar-oqcs.com/assets/images/mea-logo.svg'" src="{{companylogo}}" alt="Stellar logo" class="sidemenulogoicon" style="margin-left: 10%;"/>
      </div>

    </div>

  </div>


  <div class="col-12 sidebarprofilecol">
    <div class="row">
      <div class="col-3">
        <img src={{logo}}  class="sidemenuiconprofile">
      </div>

      <div class="col-7" style="margin-top:5%">
        <span class="candidatename">&nbsp;{{name}}</span> <br>
        <span class="candidateprofile">&nbsp;{{role}}</span>
      </div>
      <div class="formstaructure_view col-2" style="margin-top:7%;">
          <img src="assets/images/downarrow.svg" alt="downarrow" *ngIf="rolearray.length>1" [matMenuTriggerFor]="menu" />
         

          <mat-menu #menu="matMenu" class="matmenusidemenu" >
            <button mat-menu-item *ngFor="let item of rolearray" (click)="matMenubtn(item)" style="line-height:23px;white-space: normal;"
            [ngClass]="{ 'selected': item == role }">
              <span [ngClass]=" (EVID_Status =='EXPIRED'||EVID_Status=='SUSPENDED') && item == 'Evaluator'?'icontext gray-text':'icontext'"> {{item}}</span>
            </button>
          </mat-menu>

      </div>
    </div>

  </div>


  <div class="scrollablediv">
    <mat-nav-list style="margin-bottom: 10%;">

      <div *ngFor="let data of appPages;let i=index;">
        <div class="nested-menu " *ngIf="data.Showdata===true" >
        <br>
        <!-- side menu no submenu exist-->

        <div class="menutext" mat-list-item [routerLinkActive]="'is-active'" #rla="routerLinkActive"
          (click)="addExpandClass(data, 'idx'+i)" [routerLink]="[data.path]" [queryParams]="{ menuTitle: data.title }"
           *ngIf="data.path !='' && data.path !='/HelpAdmin1'">
          <mat-grid-list cols="1" rowHeight="50px">
            <mat-grid-tile>
              <div class="left-align-forced">
               <span> <img src="{{rla.isActive ? data.Icon1 : data.Icon}}" alt={{data.title}} class="sidemenuicon" /></span>

                <span class="sidemenutxtocol">{{data.sideMenutitle}}</span>
              </div>

            </mat-grid-tile>
          </mat-grid-list>
        </div>
        <!-- help menu onclick redirect to external url-->
        <div class="menutext" mat-list-item [routerLinkActive]="'is-active'" #rla="routerLinkActive"
          (click)="helpRedirect()"  
           *ngIf="data.path !='' && data.path =='/HelpAdmin1'">
          <mat-grid-list cols="1" rowHeight="50px">
            <mat-grid-tile>
              <div class="left-align-forced">
               <span> <img src="{{rla.isActive ? data.Icon1 : data.Icon}}" alt={{data.title}} class="sidemenuicon" /></span>

                <span class="sidemenutxtocol">{{data.sideMenutitle}}</span>
              </div>

            </mat-grid-tile>
          </mat-grid-list>
        </div>
        </div>




        <!-- side menu if submenu exist-- show icon -->
        <div id="menuitem-{{i}}">


          <div class="menutext" mat-list-item [routerLinkActive]="'is-active'" (click)="addExpandClass(data, i)"
            *ngIf="data.path===''">
            <mat-grid-list cols="1" rowHeight="50px">
              <mat-grid-tile>
                <div class="left-align-forced">
                  <img src={{data.Icon}} alt={{data.title}} style="margin-left: 30px;">
                  <span class="sidemenutxtocol">{{data.title}}</span>
                  <img [src]="isLevel1Shown('idx'+i) ? 'assets/images/arrowup.png' : 'assets/images/downarrow.svg'"
                    style="margin-left: 30px;">
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </div>

          <!-- loop and display the sub menu child items -->
          <ul class="nested submenu" *ngFor="let sub of data.SubMenu" [class.expand]="showMenu === data.title">
            <li>
              <div class="menutext" [routerLink]="sub.path" [queryParams]="{ menuTitle: data.title }" [routerLinkActive]="'is-active'" #rla="routerLinkActive">
                <mat-grid-list cols="1" rowHeight="50px">
                  <mat-grid-tile>
                    <div class="left-align-forced">
                      <img src="{{rla.isActive ? data.Icon1 : data.Icon}}" alt={{data.title}}
                        style="margin-left: 10px;" />

                      <span class="sidesubmenutxtocol">{{sub.title}}</span>

                    </div>
                  </mat-grid-tile>
                </mat-grid-list>
              </div>


            </li>
          </ul>
        </div>

      </div>


    </mat-nav-list>
  </div>


  <!-- sidebar footer content -->

  <div class="fixed-bottom footersidemenudiv" id="footersidemenudivid">
    <div class="row" *ngFor="let sub of footerlist">

      <div class="col-4"  [routerLink]="sub.path" [queryParams]="{ menuTitle: sub.title }" [routerLinkActive]="'is-activefooter'" #rla="routerLinkActive" 
      *ngIf="(SETTING_MENU!=false) && (cacheddata==='System Administrator'|| cacheddata==='Primary Administrator'|| cacheddata==='Company Administrator')">
       <div >
        <div class="settingbtn logoutandsetting_btn" id="settingbtnid">
          <img src="{{rla.isActive ? sub.Icon1 : sub.Icon}}" alt={{sub.title}} class="settingicons"  title="Settings">
        </div>
      </div>
    </div>

    <div class="col-4"  *ngIf="(SETTING_MENU!=false) && (cacheddata!='System Administrator'&& cacheddata!='Primary Administrator'&& cacheddata!='Company Administrator')">
     <div >
      
    </div>
  </div>

    <div class="col-4"  *ngIf="(SETTING_MENU===false) && (cacheddata!='System Administrator'&& cacheddata!='Primary Administrator'&& cacheddata!='Company Administrator')">
     <div >
     
    </div>
  </div>

     <div class="col-4" *ngIf="(SETTING_MENU===false) && (cacheddata==='System Administrator'|| cacheddata==='Primary Administrator'|| cacheddata==='Company Administrator')">
     <div >
        
      </div>
    </div>

      <div class="col-4" style="text-align: center;">
        <img src="/assets/images/logo.png" alt="Stellar footer logo" style="height: 40px;width:auto;margin-left:-27px;">
      </div>

      <div class="col-4" style="text-align: right;" [ngStyle]="{'display': impersonate == true ? 'none' : 'block' }" >
        <div class="powerbtn logoutandsetting_btn"  (click)="logout()">
          <img src="assets/images/power_settings_new_white_24dp.svg" alt="powerbtn" class="settingicons" title="Logout">
        </div>
      </div>

      <div class="col-12 MEALearningpower" style="text-align: center;">
      </div>

      <div class="col-12" style="text-align: center;">
        <div class="copyright">{{commondata.currentYear}}. Stellar IT Solutions, INC.</div>
      </div>

      <div class="col-12" style="text-align: center;">
        <div class="version">Version {{commondata.version}}</div>
      </div>

    </div>

  </div>
</div>
